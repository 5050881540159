import React, { useState, useEffect } from 'react';
import axios from 'axios';
import config from '../config';

const EditRequestPopup = ({ onClose, setSelectedRequestData,userdata,token}) => {
    const [requests, setRequests] = useState([]);
    const [selectedRequest, setSelectedRequest] = useState(null);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 1020);
    // console.log(userdata);

    useEffect(() => {
      const handleResize = () => {
        setIsMobile(window.innerWidth < 1020);
      };
  
      window.addEventListener("resize", handleResize);
  
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, []);

  //   const fetchTankerRequests = async () => {
  //     try {
  //         const response = await axios.get(`${config.apiUrl}/newtanker/tankerRequests`); // Adjust the API URL
  //         setRequests(response.data);
  //         // console.log('Fetched requests:', response.data);
  //     } catch (error) {
  //         console.error('Error fetching tanker requests:', error);
  //     }
  // };

  // fetchTankerRequests();

    useEffect(() => {
        // Fetch tanker requests from the backend
        const fetchTankerRequests = async () => {
   
          try {
            
            
            const configToken = {
              headers: {
                Authorization: `Bearer ${token}`, // Set the Authorization header with the token
              // Add Content-Type header if needed
              },
            };
            const url = `${config.apiUrl}/newtanker/getTankerRequestData`;
            const params = {
              user_role: userdata.user_role,
              firstName: userdata.user_first_name,
              lastName: userdata.user_last_name
            };
        
            const response = await axios.get(url, {
              params,
              headers: configToken.headers, // Include the headers from configToken
            });
            const data = response.data;
            
            if (Array.isArray(data)) {
              setRequests(data);
            } else {
              console.error('Error: Data is not in the expected format');
            }
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        };

        // const fetchTankerRequests = async () => {
        //       try {
        //         const url = `${config.apiUrl}/newtanker/getTankerRequestData`;
        //             const params = {
        //               user_role: userdata.user_role,
        //               firstName: userdata.user_first_name,
        //               lastName: userdata.user_last_name
        //             }; // Adjust the API URL
        //             const response = await axios.get(url, { params });
        //           setRequests(response.data);
        //           console.log('Fetched requests:', response.data);
        //       } catch (error) {
        //           console.error('Error fetching tanker requests:', error);
        //       }
        //   };
        

        fetchTankerRequests();
    }, []);

    // Function to format the date in 'YYYY-MM-DD' format
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    // Function to handle checkbox selection
    const handleCheckboxChange = (request) => {
      // console.log('requser',request);
        setSelectedRequest(request);
        // Parse the JSON string to extract the locations data
        const locationsData = JSON.parse(request.location_data || "[]");
        // Fill formData with selected request data
        setSelectedRequestData({
          id: request.id, 
          requestedQuantity: request.requestedQuantity || '',
          numberOfRequests: request.numberOfRequests || '',
          locality: request.locality || '',
          wardName: request.wardName || '',
          zone: request.zone || '',
          city: request.city || 'Madurai, Tamilnadu',
          requestDate: request.requestDate || getCurrentDate(),
          requestTime: request.requestTime || getCurrentTime(),
          locations: locationsData,
          fillingstation: request.fillingstation || ''
        });
      
        // Log the selected request data
        //  console.log("Selected Request Data:", {
        //   id: request.id, 
        //   requestedQuantity: request.requestedQuantity || '',
        //   numberOfRequests: request.numberOfRequests || '',
        //   locality: request.locality || '',
        //   wardName: request.wardName || '',
        //   zone: request.zone || '',
        //   city: request.city || 'Madurai, Tamilnadu',
        //   requestDate: request.requestDate || getCurrentDate(),
        //   requestTime: request.requestTime || getCurrentTime(),
        //   locations: locationsData,
        //   fillingstation: request.fillingstation || ''
        // });
      };
      

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50 overflow-auto">
        <div className="bg-white p-5 rounded-lg shadow-lg w-full max-w-screen-md">
          <button className="close-btn" onClick={onClose}>
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
          <h2 className="text-xl font-semibold mb-4 text-center text-blue-600">Edit Request</h2>
          <div className="overflow-y-auto max-h-80">
            <table className="w-full table-auto border-collapse border border-gray-300">
              <thead className="bg-gray-200  sticky top-0">
                <tr>
                  <th className={`${isMobile? "text-xs px-2 py-2 ":"text-md py-2"} `}>Request ID</th>
                  <th className={`${isMobile? "text-xs px-2 py-2 ":"text-md py-2"} `}>Request Date</th>
                  <th className={`${isMobile? "text-xs px-2 py-2 ":"text-md py-2"} `}>Request Time</th>
                  <th className={`${isMobile? "text-xs px-2 py-2 ":"text-md py-2"} `}>Requested By</th>
                  <th className={`${isMobile? "text-xs px-2 py-2 ":"text-md py-2"} `}>Select</th>
                </tr>
              </thead>
              <tbody>
                {requests.map(request => (
                  <tr key={request.id}>
                    <td className={`${isMobile? "text-xs text-center px-1 py-1":"py-2 text-center w-1/5"}`}>{request.id}</td>
                    <td className={`${isMobile? "text-xs text-center px-1  py-1":"py-2 text-center w-1/5"}`}>{formatDate(request.request_date)}</td>
                    <td className={`${isMobile? "text-xs text-center px-1 py-1":"py-2 text-center w-1/5"}`}>{request.request_time}</td>
                    <td className={`${isMobile? "text-xs text-center px-1 py-1":"py-2 text-center w-1/5"}`}>{request.username}</td>
                    <td className={`${isMobile? "text-xs text-center px-1 py-1":"py-2 text-center w-1/5"}`}>
                      <input
                        type="checkbox"
                        className="h-5 w-5 text-blue-500"
                        onChange={() => handleCheckboxChange(request)}
                        onClick={onClose}
                        checked={selectedRequest && selectedRequest.id === request.id}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      
      
      
      
    );
};
function getCurrentDate() {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }
  
  function getCurrentTime() {
    const date = new Date();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    return `${hours}:${minutes}`;
  }
export default EditRequestPopup;