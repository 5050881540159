import React, { useState, useEffect ,useCallback} from 'react';
import axios from 'axios';
import moment from 'moment';
import DatePicker from 'react-datepicker'; // Import react-datepicker
import 'react-datepicker/dist/react-datepicker.css'; // Import the styles
import config from '../../config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt,faWater ,faTruck,faTint,faFileExcel, faFileExport } from '@fortawesome/free-solid-svg-icons';
import * as XLSX from 'xlsx';
import jsPDF from "jspdf";
import "jspdf-autotable";
import FillingStationRow from './Tooltip';

const DeliveredData = ({vehicleNo, userData, vehiFromDate,vehiToDate,token}) => {
  // console.log('delivery',userData);
  // console.log('vehiFromDate',vehiFromDate);
  // console.log('vehiToDate',vehiToDate);

  const [fromDate, setFromDate] = useState(vehiFromDate ? new Date(vehiFromDate) : new Date());
  const [toDate, setToDate] = useState(vehiToDate ? new Date(vehiToDate) : new Date()); // Initialize toDate with current date
    const [data, setData] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);
    const [vehicleModal,setVehicleModal] = useState(false);
    const [searchType, setSearchType] = useState('referenceNo');
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [suggestions, setSuggestions] = useState([]);
    const [isDatePickerInitialized, setIsDatePickerInitialized] = useState(false);
    const [error, setError] = useState('');
    const [showLoginForm, setShowLoginForm] = useState(false);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const isTimeKeeper = userData.user_role === 'Timekeeper' ;

    const isAdmin = userData.user_role === 'Admin'
    const isAE = userData.user_role === 'AE'


  //   useEffect(() => {
  //     fetchData();
  // }, [vehicleNo]);


  const openVehicleModal = (item) => {
    setSelectedItem(item); // Set the selected item
    setVehicleModal(true);
    // console.log('item',item);
  };

  const closeVehicleModal = (item) => {
    setSelectedItem(item); // Set the selected item
    setVehicleModal(false);
    // console.log('item',item);
  };


  const [isMobile, setIsMobile] = useState(window.innerWidth < 1020);
  useEffect(() => {
          const handleResize = () => {
            setIsMobile(window.innerWidth < 1020);
          };
      
          window.addEventListener('resize', handleResize);
      
          return () => {
            window.removeEventListener('resize', handleResize);
          };
        }, []); //





  const handleSearch = (query) => {
    setSearchQuery(query);
    // Filter the tanker data based on the selected search type and search query
    const filtered = data.filter(item => {
      switch (searchType) {
        case 'referenceNo':
          return item.id.toString().toLowerCase().includes(query.toLowerCase());
          case 'requestedDateTime':
  return `${item.request_date.split(' ')[0]} ${item.request_time}`.toLowerCase().includes(query.toLowerCase());
  case 'deliveredTime':
  return formatDateTime(item.deliveredTime).toLowerCase().includes(query.toLowerCase())


        case 'quantity':
          return item.requestedQuantity.toString().includes(query);
        case 'fillingStation':
          return item.fillingstation.toLowerCase().includes(query.toLowerCase());
        case 'location':
          return item.locality.toLowerCase().includes(query.toLowerCase());// Placeholder, replace with actual logic
        case 'officer':
          // Assuming there is an officer field in your data
          return item.officer.toLowerCase().includes(query.toLowerCase());
       case 'assignedTime':
            return formatDateTime(item.assignedTime).toLowerCase().includes(query.toLowerCase());  
              case 'vehicleNo':
          return item.vehicleNo.toLowerCase().includes(query.toLowerCase());   
        default:
          return true; // If no specific search type is selected, return all data
      }
    });
    setFilteredData(filtered);
  };


  const handleSearchTypeChange = (type) => {
    setSearchType(type);
    setSearchQuery('');
    setFilteredData([]);
   
    // Get the array of values based on the selected search type
    let values = [];
    switch (type) {
      case 'referenceNo':
        values = data.map(item => item.id);
        break;
      case 'requestedDateTime':
        values = data.map(item => `${item.request_date.split(' ')[0]} ${item.request_time}`);
        break;
        case 'deliveredTime':
          values = data.map(item => formatDateTime(item.deliveredTime));
          break;
      case 'quantity':
        values = data.map(item => item.requestedQuantity.toString());
        break;
      case 'fillingStation':
        values = data.map(item => item.fillingstation);
        break;
      case 'officer':
        values = data.map(item => item.officer);
        break;
        case 'assignedTime':
        values = data.map(item =>formatDateTime(item.assignedTime));
        break;
       // Using getStatus function here
        break;
        case 'vehicleNo':
          values = data.map(item => item.vehicleNo); // Using getStatus function here
          break;
          case 'location':
            values = data.map(item => item.locality); // Using getStatus function here
            break;
      default:
        break;
    }
  
    // Filter out duplicate values and set the suggestions
    const uniqueValues = [...new Set(values)];
    setSuggestions(uniqueValues);
  };

  useEffect(() => {
    handleSearchTypeChange(searchType);
  }, [data, searchType]);

  const fetchData = async () => {
    const { user_role, user_first_name, user_last_name } = userData;
    const configToken = {
      headers: {
        Authorization: `Bearer ${token}`, // Set the Authorization header with the token
      },
    };
    try {
      let response;
      if (vehicleNo) {
        // Fetch data by vehicle number
        let apiUrl = `${config.apiUrl}/assign/getDeliveredDataByVehicleNo?vehicleNo=${vehicleNo}`;
  
        // Include fromDate and toDate if available and the date picker is initialized
        if (vehiToDate && vehiToDate) {
          const formattedFromDate = moment(fromDate).startOf('day').format('YYYY-MM-DD HH:mm:ss');
          const formattedToDate = moment(toDate).endOf('day').format('YYYY-MM-DD HH:mm:ss');
          apiUrl += `&fromDate=${formattedFromDate}&toDate=${formattedToDate}`;
        }
  
        // Call the API
        response = await axios.get(apiUrl,configToken);
      } else {
        // Fetch data based on date range and include user_role, firstname, and lastname
        const formattedFromDate = moment(fromDate).startOf('day').format('YYYY-MM-DD HH:mm:ss');
        const formattedToDate = moment(toDate).endOf('day').format('YYYY-MM-DD HH:mm:ss');
        let apiUrl = `${config.apiUrl}/assign/getDeliveredData?fromDate=${formattedFromDate}&toDate=${formattedToDate}`;
  
        // Include user_role, user_first_name, and user_last_name in the API URL if available
        if (user_role && user_first_name && user_last_name) {
          apiUrl += `&user_role=${user_role}&firstname=${user_first_name}&lastname=${user_last_name}`;
        }
  
        // Call the API
        
          response = await axios.get(apiUrl,configToken); 
     
        
      }
  
      // Set the data
      setData(response.data);
      // console.log('resssss',response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  
  
  


// Set the flag to true after the initial rendering
useEffect(() => {
    setIsDatePickerInitialized(true);
}, []);


useEffect(() => {
  if(fromDate && toDate){
    fetchData();
  }
  
}, [vehicleNo, fromDate, toDate]);




const handlePdf = async () => {
  try {
    const configToken = {
      headers: {
        Authorization: `Bearer ${token}`, // Set the Authorization header with the token
      },
    };
    const response = await axios.post(`${config.apiUrl}/user/exportUser`, { loginIdentifier: username, password },configToken);
    const { data, message } = response.data;
    if (response.status === 200 ){
      if( data.user.user_role === 'Admin') {
        const doc = new jsPDF();
                   
        // Add header row
        const header = [['S.No', 'Reference No', 'Requested Date', 'Assigned Time', 'Delivered Time', 'Qty', 'DriverName','DriverMobileNo','VehicleNo', 'Filling Station', 'Location', 'Officer']];
        
        // Add data rows
        const dataRows = (filteredData.length > 0 ? filteredData : data).map((item, index) => {
          let address = '';
          if (item.locality) {
            
            address = item.locality
          }
          return[
          index + 1,
          item.id,
          item.request_date ? `${item.request_date.split(' ')[0]} ${item.request_time}` : '',
          item.assignedTime,
          item.deliveredTime,
          item.requestedQuantity,
          item.driverName,
            item.driverMobileNo,
            item.vehicleNo,
          item.fillingstation,
          address, 
          item.username
          ]
      });
        
      
        // Add table to PDF
        doc.autoTable({
          head: header,
          body: dataRows,
          theme: 'grid', // Add grid theme
          styles: { 
            fontSize: 7, // Set the font size to 8
            fontStyle: 'normal' // Set the font style to normal
          }, 
          columnStyles: { 
            3: { cellWidth: 15 }, // Adjust column width for index 3
            5: { cellWidth: 8 } ,
            4: { cellWidth: 15 } // Adjust column width for index 5
          } 
        });
        
        // Save PDF
        doc.save('DeliveredData.pdf');
        
        setError('');
        setShowLoginForm(false);
      }
      else {
          setError('Unauthorized User');
      }
    }
      else {
          setError(message);
      }
    } catch (error) {
      setError('Failed to export Excel file');
    }
  };



  const handleExcel = async () => {
    try {
      const configToken = {
        headers: {
          Authorization: `Bearer ${token}`, // Set the Authorization header with the token
        },
      };
      const response = await axios.post(`${config.apiUrl}/user/exportUser`, { loginIdentifier: username, password },configToken);
      const { data, message } = response.data;
      if (response.status === 200 ){
        if( data.user.user_role === 'Admin') {
          const fileName = "DeliveredData.xlsx";
          const wsData = [
              [
                  "S.No",
                  "Reference No",
                  "Requested Date",
                  "Assigned Time",
                  "Delivered Time",
                  "Qty",
                  "Filling Station",
                  "Officer",
                  "Location", // Add column for Location
                  "Driver Name", // Add column for Driver Name
                  "Driver No", // Add column for Driver Number
                  "Vehicle No" // Add column for Vehicle Number
              ],
              ...(filteredData.length > 0 ? filteredData : data).map((item, index) => [
                  index + 1,
                  item.id,
                  `${item.request_date.split(' ')[0]} ${item.request_time}`,
                  item.assignedTime,
                  item.deliveredTime,
                  item.requestedQuantity,
                  item.fillingstation,
                  item.username,
                  "", // Leave empty for Location (will fill later)
                  "", // Leave empty for Driver Name (will fill later)
                  "", // Leave empty for Driver Number (will fill later)
                  "" // Leave empty for Vehicle Number (will fill later)
              ])
          ];
        
          // Fill location and vehicle data for each row
          wsData.slice(1).forEach((row, index) => {
              const selectedItem = (filteredData.length > 0 ? filteredData : data)[index];
              if (selectedItem && selectedItem.location_data) {
                  row[wsData[0].indexOf("Location")] = selectedItem.locality;
              }
              if (selectedItem) {
                  row[wsData[0].indexOf("Driver Name")] = selectedItem.driverName || "";
                  row[wsData[0].indexOf("Driver No")] = selectedItem.driverMobileNo || "";
                  row[wsData[0].indexOf("Vehicle No")] = selectedItem.vehicleNo || "";
              }
          });
        
          const ws = XLSX.utils.aoa_to_sheet(wsData);
          const wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, "DeliveredData");
        
          // Export the workbook
          XLSX.writeFile(wb, fileName);
          setError('');
          setShowLoginForm(false);
        }
        else {
            setError('Unauthorized User');
        }
      }
        else {
            setError(message);
        }
      } catch (error) {
        setError('Failed to export Excel file');
      }
    };
  
    const formatDateTime = (dateTime) => {
      // Split the date and time parts
      const [datePart, timePart] = dateTime.split('T');
    
      // Remove the milliseconds and 'Z' from the time part
      const formattedTimePart = timePart.split('.')[0];
    
      // Return the formatted date and time
      return `${datePart} ${formattedTimePart}`;
    };

const handleClose = () => {
  // Function to handle closing the login form
  setShowLoginForm(false);
  setUsername('');
  setPassword('');
  setError('');
};

    return (
        <>
            
            <div className="container p-2">
            <div className="flex items-center justify-between p-2">
            <p className={`sm:text-2xl text-xl font-bold mb-1 md:mb-0 `}>Delivered</p>
            {!showLoginForm && (
        <button className=" w-[35px] sm:hidden block bg-green-500 hover:bg-green-600 text-white py-1 px-2  rounded-md" onClick={() => setShowLoginForm(true)}>
        <FontAwesomeIcon icon={faFileExport} className="mr-1" />
    </button>
        )}
            </div>
            <div className="w-full flex flex-col md:flex-row gap-[2px] sm:gap-1 mb-2">
           
    {/* From Date */}
    <div className="flex justify-between sm:justify-normal  sm:w-1/4 md:w-1/4 w-full ">
        <label className='mt-1 sm:mr-2'>From</label>
        <DatePicker
            selected={fromDate}
            onChange={date => setFromDate(date)}
            dateFormat="yyyy-MM-dd"
            className="w-full px-2 py-1 border border-gray-300 rounded-md"
        />
    </div>

    {/* To Date */}
    <div className="flex sm:justify-normal justify-between sm:w-1/4 md:w-1/4 w-full ">
        <label className='mt-1 sm:mr-2'>To</label>
        <DatePicker
            selected={toDate}
            onChange={date => setToDate(date)}
            dateFormat="yyyy-MM-dd"
            className="w-full px-2 py-1 border border-gray-300 rounded-md"
        />
    </div>

    {/* Search Type and Input */}
    <div className="flex sm:w-2/4 md:w-2/4 w-full justify-end">
        
        <div className="flex flex-col md:flex-row gap-[2px] sm:gap-2">
            <select
                value={searchType}
                onChange={(e) => handleSearchTypeChange(e.target.value)}
                className="w-full px-2 py-1 border border-gray-300 rounded-md"
            >
                <option value="referenceNo">Reference No</option>
                <option value="requestedDateTime">Requested Date</option>
                <option value="quantity">Quantity</option>
                <option value="fillingStation">Filling Station</option>
                <option value="assignedTime">Assigned Time</option>
                <option value="deliveredTime">Delivered Time</option>
                <option value="vehicleNo">Vehicle</option>
                <option value="location">Locality</option>
            </select>
            <input
                type="text"
                value={searchQuery}
                onChange={(e) => handleSearch(e.target.value)}
                placeholder="Search..."
                className="w-full px-2 py-1 border border-gray-300 rounded"
                list="suggestions"
            />
            <datalist id="suggestions">
                {suggestions.map((item, index) => (
                    <option key={index} value={item} />
                ))}
            </datalist>
        </div>
    </div>

    {!showLoginForm && (
        <button className=" mt-1 w-[40px] hidden sm:block bg-green-500 hover:bg-green-600 text-white py-1 px-2 rounded-md" onClick={() => setShowLoginForm(true)}>
        <FontAwesomeIcon icon={faFileExport} className="mr-2" />
    </button>
        )}
 
</div>

           
      {!isMobile ? (<div className="w-full h-[65vh] overflow-x-auto  overflow-y-auto ">
    <table id='DeliveredData' className=" w-full overflow-x-auto  overflow-y-auto ">
      <thead className='sticky top-0 text-left' >
            <tr className=' text-white' style={{backgroundColor:'rgb(33,64,154)'}}>
              <th className="px-3 py-3 text-xs font-semibold uppercase ">S.No</th>
              <th className="px-5 py-3 text-xs font-semibold uppercase ">Reference No</th>
              <th className="px-5 py-3 text-xs font-semibold uppercase ">Requested Date</th>
              <th className="px-5 py-3 text-xs font-semibold uppercase ">Assigned Time</th>
              <th className="px-5 py-3 text-xs font-semibold uppercase ">Delivered Time</th>
              <th className="px-5 py-3 text-xs font-semibold uppercase ">Qty</th>
              <th className="px-5 py-3 text-xs font-semibold uppercase ">Vehicle</th>
              <th className="px-5 py-3 text-md font-semibold uppercase "><FontAwesomeIcon icon={faTint} /></th>
              <th className="px-5 py-3 text-xs font-semibold uppercase ">Locality</th>
              <th className="px-5 py-3 text-xs font-semibold uppercase ">Officer</th>
             
             
            </tr>
          </thead>

          <tbody>
            {filteredData.length > 0 ? (
              filteredData.map((item, index) => (
                <tr key={item.id} className='text-left'>
                <td className="px-3 py-3 text-xs">{index + 1}</td>
                <td className="px-5 py-3 text-xs">{item.id}</td>
                <td className="px-5 py-3 text-xs">{item.request_date.split(' ')[0]} {item.request_time}</td>
                <td className="px-5 py-3 text-xs">{formatDateTime(item.assignedTime)}</td> 
                <td className="px-5 py-3 text-xs">{formatDateTime(item.deliveredTime)}</td> 
                <td className="px-5 py-3 text-xs">{item.requestedQuantity}</td>
                <td className="px-5 py-3 text-xs"><FontAwesomeIcon icon={faTruck} className="cursor-pointer text-green-500 mr-1"   onClick={() => openVehicleModal(item)}/>{item.vehicleNo}</td>
                <FillingStationRow item={item.fillingstation} icon={faTint} id={item.id} styl={`px-5 py-3 text-sm text-blue-500`}/>
                <td className="px-5 py-3 ">
                    <FontAwesomeIcon
                      icon={faMapMarkerAlt}
                      className="cursor-pointer text-blue-500 text-sm mr-1"
                    /><span className='text-xs'> {item.locality}</span> 
                  </td>
                <td className="px-5 py-3 text-xs">{item.username} </td> {/* Officer Name */}
                 
              </tr>
              ))
            ) : (
              data.map((item, index) => (
                <tr key={item.id} className='text-left'>
                  <td className="px-3 py-3 text-xs">{index + 1}</td>
                  <td className="px-5 py-3 text-xs">{item.id}</td>
                  <td className="px-5 py-3 text-xs">{item.request_date.split(' ')[0]} {item.request_time}</td>
                  <td className="px-5 py-3 text-xs">{formatDateTime(item.assignedTime)}</td> 
                  <td className="px-5 py-3 text-xs">{formatDateTime(item.deliveredTime)}</td> 
                  <td className="px-5 py-3 text-xs">{item.requestedQuantity}</td>
                  <td className="px-5 py-3 text-xs"><FontAwesomeIcon icon={faTruck} className="cursor-pointer text-green-500 mr-1"   onClick={() => openVehicleModal(item)}/>{item.vehicleNo}</td>
                  <FillingStationRow item={item.fillingstation} icon={faTint} id={item.id} styl={`px-5 py-3 text-sm text-blue-500`}/>
                  <td className="px-5 py-3 ">
                    <FontAwesomeIcon
                      icon={faMapMarkerAlt}
                      className="cursor-pointer text-blue-500 text-sm mr-1"
                    /><span className='text-xs'> {item.locality}</span> 
                  </td>
                  <td className="px-5 py-3 text-xs">{item.username} </td> 
                   
                </tr>
                
              ))
            )
            }
          </tbody>
          
        </table>
      
      </div>):(<div>

        <div className="w-full h-[43vh] md:h-[55vh] sm:h-[50vh] overflow-y-auto text-xs sm:text-sm md:text-base lg:text-lg xl:text-xl">

        
{filteredData.length > 0 ? (
     

        <div className="grid grid-cols-1 p-2 gap-4 ">
        {filteredData.map((item, index) => (
           <div key={index} className=" w-[95%] h-[60vh] border border-10 border-gray-300 rounded-md  transform transition duration-300 ease-in-out hover:scale-105  bg-white"  style={{ boxShadow: 'rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px' }}>

           <table className="w-full text-gray-700 ">
             <tbody>
               <tr className="">
                 <td className="font-semibold px-2 py-2 text-xs sm:text-sm md:text-md uppercase">Reference No</td>
                 <td className="px-2 py-2 text-xs sm:text-sm md:text-md font-semibold">{item.id}</td>
               </tr>
               <tr>
                 <td className="font-semibold px-2 py-2 text-xs sm:text-sm md:text-md uppercase">Request Date</td>
                 <td className="px-2 py-2 text-xs sm:text-sm md:text-md font-semibold">{item.request_date.split(' ')[0]}</td>
               </tr>
               <tr>
                 <td className="font-semibold px-2 py-2 text-xs sm:text-sm md:text-md uppercase">Request Time</td>
                 <td className="px-2 py-2 text-xs sm:text-sm md:text-md font-semibold">{item.request_time}</td>
               </tr>
               <tr>
                 <td className="font-semibold px-2 py-2 text-xs sm:text-sm md:text-md uppercase">Quantity</td>
                 <td className="px-2 py-2 text-xs sm:text-sm md:text-md font-semibold">{item.requestedQuantity}</td>
               </tr>
               <tr>
                 <td className="font-semibold px-2 py-2 text-xs sm:text-sm md:text-md uppercase">Officer</td>
                 <td className="px-2 py-2 text-xs sm:text-sm md:text-md font-semibold">{item.username}</td>
               </tr>
               <tr>
                 <td className="font-semibold px-2 py-2 text-xs sm:text-sm md:text-md uppercase">Filling Station</td>
                 <td className="px-2 py-2 text-xs sm:text-sm md:text-md font-semibold">{item.fillingstation}</td>
               </tr>
               <tr>
                 <td className="font-semibold px-2 py-2 text-xs sm:text-sm md:text-md uppercase">Assigned Time</td>
                 <td className="px-2 py-2 text-xs sm:text-sm md:text-md font-semibold">{formatDateTime(item.assignedTime)}</td>
               </tr>
               <tr>
  <td className="font-semibold px-2 py-2 text-xs sm:text-sm md:text-md uppercase">Delivered Time</td>
  <td className="px-2 py-2 text-xs sm:text-sm md:text-md font-semibold">{formatDateTime(item.deliveredTime)}</td>
</tr>
               <tr>
                 <td className="font-semibold px-2 py-2 text-xs sm:text-sm md:text-md uppercase">Location</td>
                 <td className="px-2 py-2 text-xs sm:text-sm md:text-md font-semibold">
                   <FontAwesomeIcon
                     icon={faMapMarkerAlt}
                     className="cursor-pointer text-blue-500 mr-2"

                   />{item.locality}
                 </td>
               </tr>
               <tr>
                 <td className="font-semibold px-2 py-2 text-xs sm:text-sm md:text-md uppercase">Vehicle</td>
                 <td className="px-2 py-2 text-xs sm:text-sm md:text-md font-semibold">
                   <FontAwesomeIcon
                     icon={faTruck}
                     className="cursor-pointer text-green-500 mr-2"
                     onClick={() => openVehicleModal(item)}
                   />
                 </td>
               </tr>
              
             </tbody>
           </table>
           
         </div>
        ))}
      </div>
      
        
      
    ) : (
      
        <div className="grid grid-cols-1 gap-4 p-2">
       {data.map((item, index) => (
<div key={index} className=" w-[95%] h-[65vh] border border-10 border-gray-300 rounded-md  transform transition duration-300 ease-in-out hover:scale-105  bg-white"  style={{ boxShadow: 'rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px' }}>

<table className="w-full text-gray-700 ">
<tbody>
<tr className="">
  <td className="font-semibold px-2 py-2 text-xs sm:text-sm md:text-md uppercase">Reference No</td>
  <td className="px-2 py-2 text-xs sm:text-sm md:text-md font-semibold">{item.id}</td>
</tr>
<tr>
  <td className="font-semibold px-2 py-2 text-xs sm:text-sm md:text-md uppercase">Request Date</td>
  <td className="px-2 py-2 text-xs sm:text-sm md:text-md font-semibold">{item.request_date.split(' ')[0]}</td>
</tr>
<tr>
  <td className="font-semibold px-2 py-2 text-xs sm:text-sm md:text-md uppercase">Request Time</td>
  <td className="px-2 py-2 text-xs sm:text-sm md:text-md font-semibold">{item.request_time}</td>
</tr>
<tr>
  <td className="font-semibold px-2 py-2 text-xs sm:text-sm md:text-md uppercase">Quantity</td>
  <td className="px-2 py-2 text-xs sm:text-sm md:text-md font-semibold">{item.requestedQuantity}</td>
</tr>
<tr>
                 <td className="font-semibold px-2 py-2 text-xs sm:text-sm md:text-md uppercase">Officer</td>
                 <td className="px-2 py-2 text-xs sm:text-sm md:text-md font-semibold">{item.username}</td>
               </tr>
<tr>
  <td className="font-semibold px-2 py-2 text-xs sm:text-sm md:text-md uppercase">Filling Station</td>
  <td className="px-2 py-2 text-xs sm:text-sm md:text-md font-semibold">{item.fillingstation}</td>
</tr>
<tr>
  <td className="font-semibold px-2 py-2 text-xs sm:text-sm md:text-md uppercase">Assigned Time</td>
  <td className="px-2 py-2 text-xs sm:text-sm md:text-md font-semibold">{formatDateTime(item.assignedTime)}</td>
</tr>
<tr>
  <td className="font-semibold px-2 py-2 text-xs sm:text-sm md:text-md uppercase">Delivered Time</td>
  <td className="px-2 py-2 text-xs sm:text-sm md:text-md font-semibold">{formatDateTime(item.deliveredTime)}</td>
</tr>
<tr>
  <td className="font-semibold px-2 py-2 text-xs sm:text-sm md:text-md uppercase">Location</td>
  <td className="px-2 py-2 text-xs sm:text-sm md:text-md font-semibold">
    <FontAwesomeIcon
      icon={faMapMarkerAlt}
      className="cursor-pointer text-blue-500 mr-2"
    />{item.locality}
  </td>
</tr>
<tr>
  <td className="font-semibold px-2 py-2 text-xs sm:text-sm md:text-md uppercase">Vehicle</td>
  <td className="px-2 py-2 text-xs sm:text-sm md:text-md font-semibold">
    <FontAwesomeIcon
      icon={faTruck}
      className="cursor-pointer text-green-500 mr-2"
      onClick={() => openVehicleModal(item)}
    />
  </td>
</tr>
</tbody>
</table>
<div className="flex items-center justify-end px-4 py-2">

</div>
</div>







 
  
  ))}
      </div>
      
     
    )}


</div>
        
 
</div>

  

)}
            


{vehicleModal && (
  <div className="fixed inset-0 z-40 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
    <div className="fixed inset-0 bg-black bg-opacity-50"></div>
    <div className="relative w-auto max-w-3xl mx-auto my-4">
      {/* Modal content */}
      <div className="bg-white p-8 rounded-lg shadow-md">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-semibold uppercase">Vehicle</h3>
          <button onClick={closeVehicleModal} className="text-gray-700 hover:text-gray-900">
            <svg className="h-6 w-6 text-red-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
        <div className="overflow-y-auto max-h-48"> {/* Limit the height of the content and add scroll if needed */}
        <table className="w-full">
    <tbody>
      <tr className="text-left mb-2 mt-2 p-2">
        <td className="font-semibold uppercase text-xs pr-4">Driver Name</td>
        <td className="pr-4">:</td>
        <td>{selectedItem.driverName}</td>
      </tr>
      <tr className="text-left mb-2 mt-2 p-2">
        <td className=" font-semibold uppercase text-xs pr-4">Driver No</td>
        <td className="pr-4">:</td>
        <td>{selectedItem.driverMobileNo}</td>
      </tr>
      <tr className="text-left mb-2 mt-2 p-2">
        <td className="font-semibold text-xs uppercase pr-4">Vehicle No</td>
        <td className="pr-4">:</td>
        <td>{selectedItem.vehicleNo}</td>
      </tr>
    </tbody>
  </table>
        </div>
      </div>
    </div>
  </div>
)}

{showLoginForm && (
   <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
   <div className="bg-white w-full max-w-xs md:max-w-lg rounded-lg overflow-hidden shadow-xl">
   <div className="px-6 py-4">
         <div className="flex justify-end">
           <button className="text-gray-700 hover:text-gray-900" onClick={handleClose}>
             <svg
               xmlns="http://www.w3.org/2000/svg"
               className="h-6 w-6"
               fill="none"
               viewBox="0 0 24 24"
               stroke="currentColor"
             >
               <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
             </svg>
           </button>
         </div>
         <label htmlFor="username" className="text-gray-700">Username</label>
         <input
           type="text"
           id="username"
           placeholder="Username or Mobile Number"
           value={username}
           onChange={(e) => setUsername(e.target.value)}
           className="border border-gray-400 px-3 py-2 rounded-md mb-2 w-full"
         />
         <label htmlFor="password" className="text-gray-700">Password</label>
         <input
           type="password"
           id="password"
           placeholder="Password"
           value={password}
           onChange={(e) => setPassword(e.target.value)}
           className="border border-gray-400 px-3 py-2 rounded-md mb-2 w-full"
         />
         <button
           onClick={handleExcel}
           className="bg-green-500 hover:bg-green-600 text-white py-2 px-4 rounded-md mr-2"
         >
           Excel
         </button>
         <button
         onClick={handlePdf}
         className="bg-green-500 hover:bg-green-600 text-white py-2 px-4 rounded-lg mr-2"
       >
         PDF
       </button>
         
         {error && <p className="text-red-500 mt-2">{error}</p>}
       </div>
   </div>
 </div>
)}

            </div>
        </>
    );
};

export default DeliveredData;