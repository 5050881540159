import React, { useState, useEffect } from "react";
import { Link, Outlet } from "react-router-dom";
import Navbar from "./Navbar";
import { useNavigate } from "react-router-dom";
import {
  FaBars,
  FaTimes,
  FaHome,
  FaFileAlt,
  FaMap,
  FaSms,
  FaTruck,
  FaClock,
  FaUser,
} from "react-icons/fa";
import { Tooltip } from 'react-tooltip';


function FrontOfficeApp() {
  const [sidebarExpanded, setSidebarExpanded] = useState(false);
  const [role, setRole] = useState(null);
  const [showReport, setShowReport] = useState(false);
  const [showMaster, setShowMaster] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1020);
  const [user, setUser] = useState([]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1020);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const navigate = useNavigate();

  useEffect(() => {
    const auth = JSON.parse(sessionStorage.getItem("authentication"));

    if (!auth) {
      navigate("/");
    } else {
      const user = JSON.parse(sessionStorage.getItem("user"));
      setUser(user);

      setRole(user.user.user_role);
    }
  }, [navigate]);

  const toggleSidebar = () => {
    setSidebarExpanded(!sidebarExpanded);
  };

  return (
    <>
      <div>
        <div
          className={`${isMobile ? "h-screen" : "h-[100vh] overflow-hidden"}`}
          style={{ fontFamily: "serif" }}
        >
          <Navbar className={`${isMobile ? "h-16" : "h-[10vh]"}`} />
          <div
            className={`flex transition-all duration-300 ease-in-out ${
              isMobile ? "flex-col" : "h-[90vh]"
            }`}
            style={{ backgroundColor: "rgb(239,240,249)" }}
          >
            {isMobile ? (
              <div className="flex-1">
                <main className="w-full  mt-0 mb-0 bg-white  rounded-lg">
                  <FaBars
                    className="absolute top-5 right-5 cursor-pointer block md:hidden"
                    onClick={toggleSidebar}
                  />
                  <Outlet />
                </main>
              </div>
            ) : (
              <>
                {role === "billingstaff" ? (
                  <aside className="p-2">{/* Sidebar content for billing staff */}</aside>
                ) : (
                  <div
                  className={`transition-all duration-300 ease-in-out pl-3 pt-6 flex flex-col ${
                    sidebarExpanded ? "w-56" : "w-16"
                  }`}
                >
                  <div className="size-12">
                    <FaBars
                      onClick={() => {
                        setShowReport(false);
                        setShowMaster(false);
                        toggleSidebar();
                      }}
                    />
                  </div>
                  <div className="overflow-y-auto">
                    <ul className="flex flex-col">
                      {(role === "Admin" || role === "Timekeeper" || role === "AE") && (
                        <li>
                          <Link to="/dashboard/CardComponent" data-tooltip-id={!sidebarExpanded ? "dashboard-tooltip" : ""} data-tooltip-content="Dashboard">
                            <div className="flex items-center mb-4">
                              <FaHome className="mr-2 size-6" />
                              {sidebarExpanded && "Dashboard"}
                            </div>
                          </Link>
                          {!sidebarExpanded && <Tooltip id="dashboard-tooltip" />}
                        </li>
                      )}
                      {(role === "Admin" || role === "AE") && (
                        <li>
                          <Link to="/dashboard/newtankerrequest" data-tooltip-id={!sidebarExpanded ? "new-tanker-tooltip" : ""} data-tooltip-content="New Tanker Request">
                            <div className="flex items-center mb-4">
                              <FaTruck className="mr-2 size-6" />
                              {sidebarExpanded && "New Tanker Request"}
                            </div>
                          </Link>
                          {!sidebarExpanded && <Tooltip id="new-tanker-tooltip" />}
                        </li>
                      )}
                      {(role === "Admin" || role === "Timekeeper" || role === "AE") && (
                        <li>
                          <Link to="/dashboard/ViewTanker" data-tooltip-id={!sidebarExpanded ? "view-tanker-tooltip" : ""} data-tooltip-content="View Tanker Request">
                            <div className="flex items-center mb-4">
                              <FaFileAlt className="mr-2 size-6" />
                              {sidebarExpanded && "View Tanker Request"}
                            </div>
                          </Link>
                          {!sidebarExpanded && <Tooltip id="view-tanker-tooltip" />}
                        </li>
                      )}
                      {(role === "Admin" || role === "Timekeeper" || role === "Contractor") && (
                        <li>
                          <Link to="/dashboard/vehicledowntime" data-tooltip-id={!sidebarExpanded ? "vehicle-downtime-tooltip" : ""} data-tooltip-content="Vehicle Down Time">
                            <div className="flex items-center mb-4">
                              <FaClock className="mr-2 size-6" />
                              {sidebarExpanded && "Vehicle Down Time"}
                            </div>
                          </Link>
                          {!sidebarExpanded && <Tooltip id="vehicle-downtime-tooltip" />}
                        </li>
                      )}
                      {(role === "Admin" || role === "Timekeeper") && (
                        <li>
                          <button
                            onClick={() =>
                              setShowMaster((prevState) => {
                                setSidebarExpanded(true);
                                return !prevState;
                              })
                            }
                            data-tooltip-id={!sidebarExpanded ? "masters-tooltip" : ""}
                            data-tooltip-content="Masters"
                          >
                            <div className="flex items-center justify-center mb-4">
                              <FaUser className="mr-2 size-6" />
                              {sidebarExpanded && "Masters"}
                            </div>
                          </button>
                          {!sidebarExpanded && <Tooltip id="masters-tooltip" />}
                          {showMaster && (
                            <div className="flex items-center justify-center mb-4">
                              <ul style={{ listStyleType: "disc", textAlign: "" }}>
                                <li>
                                  <Link to="/dashboard/zone" data-tooltip-id={!sidebarExpanded ? "zone-tooltip" : ""} data-tooltip-content="Master Zone & Ward">Master Zone & Ward</Link>
                                  {!sidebarExpanded && <Tooltip id="zone-tooltip" />}
                                </li>
                                <li>
                                  <Link to="/dashboard/fillingstation" data-tooltip-id={!sidebarExpanded ? "filling-station-tooltip" : ""} data-tooltip-content="Master FillingStation">Master FillingStation</Link>
                                  {!sidebarExpanded && <Tooltip id="filling-station-tooltip" />}
                                </li>
                                <li>
                                  <Link to="/dashboard/RegisterVehicleForm" data-tooltip-id={!sidebarExpanded ? "vehicle-table-tooltip" : ""} data-tooltip-content="Master Vehicle Table">Master Vehicle Table</Link>
                                  {!sidebarExpanded && <Tooltip id="vehicle-table-tooltip" />}
                                </li>
                                <li>
                                  <Link to="/dashboard/DriverMaster" data-tooltip-id={!sidebarExpanded ? "driver-master-tooltip" : ""} data-tooltip-content="Driver Master">Driver Master</Link>
                                  {!sidebarExpanded && <Tooltip id="driver-master-tooltip" />}
                                </li>
                                {role === "Admin" && (
                                  <>
                                    <li>
                                      <Link to="/dashboard/UserRegister" data-tooltip-id={!sidebarExpanded ? "user-master-tooltip" : ""} data-tooltip-content="User Master">User Master</Link>
                                      {!sidebarExpanded && <Tooltip id="user-master-tooltip" />}
                                    </li>
                                    <li>
                                      <Link to="/dashboard/usermaster" data-tooltip-id={!sidebarExpanded ? "user-table-tooltip" : ""} data-tooltip-content="User Table">User Table</Link>
                                      {!sidebarExpanded && <Tooltip id="user-table-tooltip" />}
                                    </li>
                                  </>
                                )}
                              </ul>
                            </div>
                          )}
                        </li>
                      )}
                      {(role === "Admin" || role === "Timekeeper" || role === "Contractor" || role === "AE") && (
                        <li>
                          <button
                            onClick={() => {
                              setShowReport(!showReport);
                              setSidebarExpanded(true);
                            }}
                            data-tooltip-id={!sidebarExpanded ? "reports-tooltip" : ""}
                            data-tooltip-content="Reports"
                          >
                            <div className="flex items-center mb-4">
                              <FaMap className="mr-2 size-6" />
                              {sidebarExpanded && "Reports"}
                            </div>
                          </button>
                          {!sidebarExpanded && <Tooltip id="reports-tooltip" />}
                          {showReport && (
                            <div className="flex items-center justify-center mb-4">
                              <ul style={{ listStyleType: "disc", textAlign: "" }}>
                                {(role === "Admin" || role === "Timekeeper" || role === "AE") && (
                                  <>
                                    <li>
                                      <Link to="/dashboard/AbstractReportForm" data-tooltip-id={!sidebarExpanded ? "abstract-report-tooltip" : ""} data-tooltip-content="Abstract Report">Abstract Report</Link>
                                      {!sidebarExpanded && <Tooltip id="abstract-report-tooltip" />}
                                    </li>
                                    <li>
                                      <Link to="/dashboard/Fillingstationreport" data-tooltip-id={!sidebarExpanded ? "filling-station-report-tooltip" : ""} data-tooltip-content="Fillingstation Report">Fillingstation Report</Link>
                                      {!sidebarExpanded && <Tooltip id="filling-station-report-tooltip" />}
                                    </li>
                                  </>
                                )}
                                {(role === "Admin" || role === "Timekeeper" || role === "Contractor") && (
                                  <>
                                    <li>
                                      <Link to="/dashboard/VehiclewiseReport" data-tooltip-id={!sidebarExpanded ? "vehiclewise-report-tooltip" : ""} data-tooltip-content="Vehiclewise Report">Vehiclewise Report</Link>
                                      {!sidebarExpanded && <Tooltip id="vehiclewise-report-tooltip" />}
                                    </li>
                                    <li>
                                      <Link to="/dashboard/VehicleDownwiseReport" data-tooltip-id={!sidebarExpanded ? "vehicle-downwise-report-tooltip" : ""} data-tooltip-content="Vehicle DownWise Report">Vehicle DownWise Report</Link>
                                      {!sidebarExpanded && <Tooltip id="vehicle-downwise-report-tooltip" />}
                                    </li>
                                  </>
                                )}
                              </ul>
                            </div>
                          )}
                        </li>
                      )}
                      <li>
                        <Link to="/dashboard/Notification" data-tooltip-id={!sidebarExpanded ? "notification-tooltip" : ""} data-tooltip-content="Notification">
                          <div className="flex items-center mb-4">
                            <FaSms className="mr-2 size-6" />
                            {sidebarExpanded && "Notification"}
                          </div>
                        </Link>
                        {!sidebarExpanded && <Tooltip id="notification-tooltip" />}
                      </li>
                    </ul>
                  </div>
                </div>
                )}
                <main className="flex-1 p-6 mt-2 mb-5 bg-white mr-2 rounded-lg transition-all duration-300 ease-in-out">
                  <Outlet />
                </main>
              </>
            )}
          </div>
          {isMobile && sidebarExpanded && (
            <div className="fixed inset-0 bg-gray-900 bg-opacity-50 z-50">
              <div className="fixed inset-y-0 left-0 max-w-xs w-full bg-white shadow-lg z-50 transition-all duration-300 ease-in-out">
                <div className="py-4 px-3 flex justify-end">
                  <FaTimes
                    className="text-3xl text-gray-800 cursor-pointer"
                    onClick={() => {
                      setShowReport(false);
                      setShowMaster(false);
                      toggleSidebar();
                    }}
                  />
                </div>
                <div className="py-4 px-3">
                  <ul className="flex flex-col gap-2">
                    {(role === "Admin" || role === "Timekeeper" || role === "AE") && (
                      <li>
                        <Link to="/dashboard/CardComponent">
                          <div className="flex items-center mb-4">
                            <FaHome className="mr-2 size-6" />
                            {sidebarExpanded && "Dashboard"}
                          </div>
                        </Link>
                      </li>
                    )}
                    {(role === "Admin" || role === "AE") && (
                      <li>
                        <Link to="/dashboard/newtankerrequest">
                          <div className="flex items-center mb-4">
                            <FaTruck className="mr-2 size-6" />
                            {sidebarExpanded && "New Tanker Request"}
                          </div>
                        </Link>
                      </li>
                    )}
                    {(role === "Admin" || role === "Timekeeper" || role === "AE") && (
                      <li>
                        <Link to="/dashboard/ViewTanker">
                          <div className="flex items-center mb-4">
                            <FaFileAlt className="mr-2 size-6" />
                            {sidebarExpanded && "View Tanker Request"}
                          </div>
                        </Link>
                      </li>
                    )}
                    {(role === "Admin" || role === "Timekeeper" || role === "Contractor") && (
                      <li>
                        <Link to="/dashboard/vehicledowntime">
                          <div className="flex items-center mb-4">
                            <FaClock className="mr-2 size-6" />
                            {sidebarExpanded && "Vehicle Down Time"}
                          </div>
                        </Link>
                      </li>
                    )}
                    {(role === "Admin" || role === "Timekeeper") && (
                      <li>
                        <button onClick={() => setShowMaster(!showMaster)}>
                          <div className="flex items-center mb-4">
                            <FaFileAlt className="mr-2 size-6" />
                            {sidebarExpanded && "Masters"}
                          </div>
                        </button>
                        {showMaster && (
                          <div className="text-center">
                            <ul style={{ listStyleType: "disc", textAlign: "center" }}>
                              <li>
                                <Link to="/dashboard/zone">Master Zone & Ward</Link>
                              </li>
                              <li>
                                <Link to="/dashboard/fillingstation">Master FillingStation</Link>
                              </li>
                              <li>
                                <Link to="/dashboard/RegisterVehicleForm">Master Vehicle Table</Link>
                              </li>
                              <li>
                                <Link to="/dashboard/DriverMaster">Driver Master</Link>
                              </li>
                              {role === "Admin" && (
                                <>
                                  <li>
                                    <Link to="/dashboard/UserRegister">User Master</Link>
                                  </li>
                                  <li>
                                    <Link to="/dashboard/usermaster">User Table</Link>
                                  </li>
                                </>
                              )}
                            </ul>
                          </div>
                        )}
                      </li>
                    )}
                    {(role === "Admin" || role === "Timekeeper" || role === "Contractor" || role === "AE") && (
                      <li>
                        <button onClick={() => setShowReport(!showReport)}>
                          <div className="flex items-center mb-4">
                            <FaMap className="mr-2 size-6" />
                            {sidebarExpanded && "Reports"}
                          </div>
                        </button>
                        {showReport && (
                          <div className="text-center">
                            <ul>
                              {(role === "Admin" || role === "Timekeeper" || role === "AE") && (
                                <>
                                  <li>
                                    <Link to="/dashboard/AbstractReportForm">Abstract Report</Link>
                                  </li>
                                  <li>
                                    <Link to="/dashboard/Fillingstationreport">Fillingstation Report</Link>
                                  </li>
                                </>
                              )}
                              <li>
                                <Link to="/dashboard/VehiclewiseReport">Vehiclewise Report</Link>
                              </li>
                              <li>
                                <Link to="/dashboard/VehicleDownwiseReport">Vehicle DownWise Report</Link>
                              </li>
                            </ul>
                          </div>
                        )}
                      </li>
                    )}
                    <li>
                      <Link to="/dashboard/Notification">
                        <div className="flex items-center mb-4">
                          <FaSms className="mr-2 size-6" />
                          {sidebarExpanded && "Notification"}
                        </div>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default FrontOfficeApp;