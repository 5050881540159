import React from 'react';
import Select from 'react-select';
import { FixedSizeList as List } from 'react-window';

const height = 35; // Increased height to show options on a single line

const MenuList = props => {
  const { options, children, maxHeight, getValue } = props;
  const [value] = getValue();
  const initialOffset = options.indexOf(value) * height;

  return (
    <List
      height={maxHeight}
      itemCount={children.length}
      itemSize={height}
      initialScrollOffset={initialOffset}
      width="100%"
    >
      {({ index, style }) => (
        <div style={{ ...style, whiteSpace: 'nowrap' }}>{children[index]}</div>
      )}
    </List>
  );
};

const CustomSelect = ({ options, ...props }) => {
  return (
    <Select
      {...props}
      options={options}
      components={{ MenuList }}
    />
  );
};

export default CustomSelect;
