import React, { useState, useEffect } from 'react';
import axios from 'axios';
import config from '../config.js';

function Notification() {
  const [data, setData] = useState([]);
  const [messages, setMessages] = useState([]);
  const [messages1, setMessages1] = useState([]);
  const user = JSON.parse(sessionStorage.getItem("user"));

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    if(user){
    const token = user ? user.token : null;
    // Get the token from sessionStorage
        const configToken = {
          headers: {
            Authorization: `Bearer ${token}`, // Set the Authorization header with the token
          },
        };
  
    try {
      const response = await axios.get(`${config.apiUrl}/assign/assignedVehicleDetails`,configToken);
      setData(response.data);
      // Once data is fetched, call the sendMessages function for each item
      response.data.forEach((item, index) => {
        sendMessages(item, index);
      });
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }
  };

  const sendMessages = async (item, index) => {
    const token = user ? user.token : null;
    // Get the token from sessionStorage
        const configToken = {
          headers: {
            Authorization: `Bearer ${token}`, // Set the Authorization header with the token
          },
        };
  
    try {
      const response = await axios.get(`${config.apiUrl}/assign/assignedStatusResponse`, {
        params: {
          vehicleNo: item.vehicleNo,
          driverName: item.driverName,
          driverMobileNo: item.driverMobileNo,
          fillingStation: item.fillingStation
        },
        ...configToken
      });
      // Update messages state variables based on the response data
      setMessages(prevMessages => [...prevMessages, response.data.message]);
      setMessages1(prevMessages1 => [...prevMessages1, response.data.message1]);
    } catch (error) {
      console.error('Error updating assigned status:', error);
      // If an error occurs, set empty messages for the current index to avoid misalignment
      setMessages(prevMessages => [...prevMessages, '']);
      setMessages1(prevMessages1 => [...prevMessages1, '']);
    }
  };

  return (
    <div>
        <h1 className="font-semibold text-xl md:text-4xl mb-2">Notifications</h1>
        <div className='w-full  h-[72vh] overflow-y-auto'>
      <table className="w-full border h-[72vh] border-collapse border-gray-200  overflow-x-auto overflow-y-auto">
  <thead className='sticky top-0 text-white' style={{ backgroundColor: 'rgb(33,64,154)' }}>
    <tr>
      <th className="border border-gray-200 px-4 py-1">Request No</th>
      <th className="border border-gray-200 px-4 py-1">Assigned By</th>
      <th className="border border-gray-200 px-4 py-1" colSpan="2">Send Messages</th>
      <th className="border border-gray-200 px-4 py-1">Created Date</th>
      <th className="border border-gray-200 px-4 py-1">Message</th>
      <th className="border border-gray-200 px-4 py-1">Message1</th>
    </tr>
  </thead>
  <tbody>
    {data.map((item, index) => (
      <tr key={index} className="border-b border-gray-200">
        <td className="border border-gray-200 px-4 py-2">{item.requestNo}</td>
        <td className="border border-gray-200 px-4 py-2">{item.adminName}</td>
        <td className="border border-gray-200 px-4 py-2">{item.driverName}, {item.driverMobileNo}</td>
        <td className="border border-gray-200 px-4 py-2">{item.userName}, {item.contractorMobileNumber}</td>
        <td className="border border-gray-200 px-4 py-2">{item.assignedTime.split(' ')[0]} {item.assignedTime.split(' ')[1]}</td>
        <td className="border border-gray-200 px-4 py-2">{messages[index]}</td>
        <td className="border border-gray-200 px-4 py-2">{messages1[index]}</td>
      </tr>
    ))}
  </tbody>
</table>
</div>

    </div>
  );
}

export default Notification;