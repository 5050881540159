import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const FillingStationRow = ({ item ,icon,id,styl}) => {
  return (
    <td className={styl}>
      <FontAwesomeIcon 
        icon={icon} 
        data-tooltip-id={`tooltip-${id}`} 
        data-tooltip-content={item} 
      />
      <Tooltip id={`tooltip-${id}`} place="top" effect="solid" className='!bg-blue-500 !text-white !py-2 !px-3 !rounded !shadow-lg"'/>
      
    </td>
  );
};

export default FillingStationRow;