import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import config from "../../config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faUser } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";

const DriverVehicleMapper = ({ fetchVehicles, onClose }) => {
  const [formData1, setFormData1] = useState({
    vehicleNo: "",
    capacity: "",
    drivername: "",
    driver_mobile_no: "",
  });
  const [vehicleOptions, setVehicleOptions] = useState([]);
  const [driverOptions, setDriverOptions] = useState([]);
  const user = JSON.parse(sessionStorage.getItem("user"));

  const closeModal1 = () => {
    onClose();
    HandleresetFormData1();
  };

  const HandleresetFormData1 = () => {
    setFormData1({
      vehicleNo: "",
      capacity: "",
      drivername: "",
      driver_mobile_no: "",
    });
  };

  const handleChange1 = (selectedOption, actionMeta) => {
    if (selectedOption) {
      setFormData1({ ...formData1, [actionMeta.name]: selectedOption.value });
    }
  };

  const handleSubmit1 = async (e) => {
    e.preventDefault();
    const token = user ? user.token : null;
  // Get the token from sessionStorage
      const configToken = {
        headers: {
          Authorization: `Bearer ${token}`, // Set the Authorization header with the token
        },
      };


    // Split the vehicle number and capacity
    const [vehicleNumber, capacity] = formData1.vehicleNo.split("-");
    const [driverName, driver_mobile_no] = formData1.drivername.split("-");

    // Set the formData with the extracted values
    const formDataWithSplitValues = {
      ...formData1,
      vehicleNo: vehicleNumber,
      capacity: capacity ? parseInt(capacity) : null,
      drivername: driverName,
      driver_mobile_no: driver_mobile_no ? driver_mobile_no : null,
      // Convert capacity to integer if it exists, otherwise set to null
    };

    try {
      const response = await axios.post(
        `${config.apiUrl}/zonewards/driver-vehicle-mapper`,
        formDataWithSplitValues,configToken
      );
      toast.success(response.data.message);
    
      HandleresetFormData1();
      await new Promise((resolve) => setTimeout(resolve, 1000));
      closeModal1();
      fetchVehicles();
    } catch (error) {
      console.error("Error registering vehicle:", error);
      toast.error("Error registering vehicle");
    }
  };

  const modalStyles = `
 
  .modal-content {
    background-color: #fefefe;
    border: 1px solid #888;
    animation-name: modal-slide;
    animation-duration: 0.5s;
  }

  @keyframes modal-slide {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(0);
    }
  }

  
`;

  useEffect(() => {
    const token = user ? user.token : null;
    // Get the token from sessionStorage
        const configToken = {
          headers: {
            Authorization: `Bearer ${token}`, // Set the Authorization header with the token
          },
        };
    const fetchVehicleOptions = async () => {
    

      try {
        const response = await axios.get(
          `${config.apiUrl}/zonewards/getvehicles`,configToken
        );
        setVehicleOptions(
          response.data.vehicles.map((vehicle) => ({
            value: `${vehicle.vehicleNo}-${vehicle.capacity}`,
            label: `${vehicle.vehicleNo}-${vehicle.capacity}`,
          }))
        );
      } catch (error) {
        console.error("Error fetching vehicle options:", error);
      }
    };

    const fetchDriverOptions = async () => {
      try {
        const response = await axios.get(`${config.apiUrl}/zonewards/drivers`,configToken);
        setDriverOptions(
          response.data.map((driver) => ({
            value: `${driver.driverName}-${driver.mobileNo}`,
            label: `${driver.driverName}-${driver.mobileNo}`,
          }))
        );
      } catch (error) {
        console.error("Error fetching driver options:", error);
      }
    };

    fetchVehicleOptions();
    fetchDriverOptions();
  }, []);

  const customStyles = {
    control: (provided) => ({
      ...provided,
      width: "100%"
    }),
  };

  return (
    <div className="">
      

      <div>
      <style>{modalStyles}</style>

        <div className="modal   overflow-y-auto h-full">
          <div className="modal-content m-8 sm:m-0">
            <button onClick={closeModal1} className="close-btn">
              <FontAwesomeIcon icon={faTimes} />
            </button>
            <br />
            <h1 className="text-2xl text-blue-800 sm:text-3xl font-semibold sm:mb-4 mb-2 text-center ">
              <FontAwesomeIcon className="" icon={faUser} /> Driver Vehicle Mapper
            </h1>
         
            <form className="flex flex-wrap p-4 max-w-sm mx-auto" onSubmit={handleSubmit1}>
              <div className="w-full mb-1 pr-4 pb-5 ">
                <label
                  htmlFor="vehicleNo"
                  className="text-gray-700 mb-1 block sm:text-lg text-sm font-semibold"
                >
                  Vehicle Number
                </label>
                <Select
                  options={vehicleOptions}
                  onChange={(selectedOption) =>
                    handleChange1(selectedOption, { name: "vehicleNo" })
                  }
                  placeholder="Select Vehicle"
                  styles={customStyles}
                  required
                  isClearable
                  hideSelectedOptions={false} // Show the clear indicator for selected options
                  filterOption={({ label, value }, input) => {
                    const inputValue = input.toLowerCase().replace(/\s/g, '');
                    const optionValue = value.toLowerCase().replace(/\s/g, '');
                    return optionValue.includes(inputValue);
                  }}
                  className={`w-full border border-gray-400 rounded-lg focus:outline-none focus:border-blue-500 `}
                  // styles={{
                  //   option: (provided, state) => ({
                  //     ...provided,
                  //     color: state.data.label.includes('Trips:') ? 'blue' : 'green'
                  //   })
                  // }}
                />
              </div>

              <div className="w-full mb-1 pb-5 pr-4">
                <label
                  htmlFor="drivername"
                  className="text-gray-700 mb-1 block sm:text-lg text-sm font-semibold"
                >
                  Driver
                </label>
                <Select
                  options={driverOptions}
                  onChange={(selectedOption) =>
                    handleChange1(selectedOption, { name: "drivername" })
                  }
                  placeholder="Select Driver"
                  styles={customStyles}
                  required
                  isClearable
                  hideSelectedOptions={false} // Show the clear indicator for selected options
                  filterOption={({ label, value }, input) => {
                    const inputValue = input.toLowerCase().replace(/\s/g, '');
                    const optionValue = value.toLowerCase().replace(/\s/g, '');
                    return optionValue.includes(inputValue);
                  }}
                  className={`w-full border border-gray-400 rounded-lg focus:outline-none focus:border-blue-500 `}
               
                />
              </div>

              <div className="w-full pr-4 sm:pt-5 text-center">
                <button
                  type="submit"
                  className="bg-blue-500 text-white py-3 px-2 rounded-md hover:bg-blue-600"
                >
                  Register
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DriverVehicleMapper;
