import React, { useState } from 'react';
import axios from 'axios';
import { toast,} from 'react-toastify'; // Import react-toastify for popups
import 'react-toastify/dist/ReactToastify.css'; // Import react-toastify CSS
import config from '../../config';

const UpdateStatusPopup = ({ data, onClose, user_role }) => {
  const getCurrentDateTime = () => {
    const now = new Date();
    const offset = now.getTimezoneOffset() * 60000; // Offset in milliseconds
    const localTime = new Date(now.getTime() - offset);
    return localTime.toISOString().slice(0, 16); // Format the date and time to match the datetime-local input format
  };
  const user = JSON.parse(sessionStorage.getItem("user"));
  const [date, setDate] = useState(getCurrentDateTime());
  
  const [selectedStatus, setSelectedStatus] = useState('');
  const statusOptions = ['pending', 'delivered'];
  const [isUpdating, setIsUpdating] = useState(false); // State to track updating status
  const [isStatusValid, setIsStatusValid] = useState(true);

  const handleStatusChange = (event) => {
    setSelectedStatus(event.target.value);
    if (event.target.value) {
      setIsStatusValid(true); // Set status as valid if an option is selected
    }
  };

  const getStatusOptions = () => {
    if (user_role === 'Admin') {
      return ['pending', 'delivered'];
    } else if (user_role === 'AE') {
      return ['delivered'];
    } else if (user_role === 'Timekeeper') {
      return ['pending'];
    } else {
      return []; // Return empty array if user_role doesn't match any condition
    }
  };



  

  const handleUpdateStatus = async () => {
    setIsUpdating(true); // Set updating status to true
    if (!selectedStatus) {
      setIsStatusValid(false);
      setIsUpdating(false); // Set status as invalid if no option is selected
      return;
    }
    try {
      const token = user ? user.token : null;
      // Get the token from sessionStorage
          const configToken = {
            headers: {
              Authorization: `Bearer ${token}`, // Set the Authorization header with the token
            },
          };
      const response = await axios.put(`${config.apiUrl}/assign/Update${selectedStatus.charAt(0).toUpperCase() + selectedStatus.slice(1)}Status/${data.id}`, {
        date:date,
        // Additional data if needed for the update API
      },configToken);
      setIsUpdating(false); // Set updating status back to false after successful update
      toast.success('Status updated successfully'); // Show success toast
      await new Promise((resolve) => setTimeout(resolve, 1000)); // Wait for 1 second
      onClose(); // Close the popup after showing the success toast
      // console.log(response.data); // Log the API response
    } catch (error) {
      setIsUpdating(false); // Set updating status back to false on error
      console.error('Error updating status:', error);
      toast.error('Error updating status'); // Show error toast
      // Handle error scenario
    }
  };

  return (
    <div className="h-50 flex justify-center items-center ">
    <div className=" rounded-lg shadow-md  sm:w-72 w-[80vw]">
       {/* Include ToastContainer here */}
        <h2  className="text-gray-700 mb-1 block text-lg font-semibold" >Update Status</h2>
        <div className='relative'>
        <select
        className={`w-full h-10 border ${isStatusValid ? 'border-gray-300' : 'border-red-500'} rounded-md pl-3 pr-10 bg-white focus:outline-none focus:border-blue-500`}
        value={selectedStatus}
        onChange={handleStatusChange}
      >
        <option value="">Select Status</option>
        {getStatusOptions().map((status) => (
          <option key={status} value={status}>{status}</option>
        ))}
      </select>
        </div>
        <div className="">
            <label htmlFor="mobileNo" className="text-gray-700 mb-1 block text-lg font-semibold">
              {/* <FontAwesomeIcon icon={faTimeline} className="mr-2" /> */}
             Date
            </label>
            <input
                id="startDateTimeInput"
           
                  type="datetime-local"
                  value={date}
                  onChange={(e) => setDate(e.target.value)}
                  placeholder="YYYY-MM-DDTHH:MM AM/PM"
               
                  className="w-full px-2 py-1 border border-gray-400 rounded-lg focus:outline-none focus:border-blue-500"
                 
                />
          </div>
        <button
          onClick={handleUpdateStatus}
          disabled={isUpdating} // Disable button when updating is in progress
          className={`w-full mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded ${isUpdating ? 'opacity-50 cursor-not-allowed' : ''}`}
        >
          {isUpdating ? 'Updating...' : 'Update'}
        </button>
      </div>
      
         
    </div>
  );
};

export default UpdateStatusPopup;
