import React from 'react';
import { css } from '@emotion/react';
import { ScaleLoader } from 'react-spinners';

const override = css`
  display: block;
  margin: 0 auto;
`;

const LoadingSpinner = ({ loading }) => {
  return (
    <div className="flex justify-center items-center h-[80vh] sm:h-full">
      <ScaleLoader color="#4a90e2" css={override} loading={loading} />
    </div>
  );
};

export default LoadingSpinner;