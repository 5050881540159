import React, { useState, useEffect } from 'react';
import Chart from 'react-apexcharts';

const BarChart = ({ data, stations }) => {
  // State to track the current screen width
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  // Effect to update screen width state on window resize
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Function to truncate labels based on screen width
  const truncateLabel = (label) => {
    if (screenWidth < 768) {
      const maxChar = 4; // Adjust character limit for smaller screens
      return label.length > maxChar ? `${label.substring(0, maxChar)}` : label;
    } else {
      // Split label based on the first space
      const words = label.split(' ');
      return words.length > 1 ? `${words[0]} ${words[1]}` : label;
    }
  };
  

  // Function to format numbers in k (thousand), m (million), or b (billion) format
  const formatNumber = (num) => {
    if (Math.abs(num) >= 1.0e+9) {
      return (Math.abs(num) / 1.0e+9).toFixed(1) + 'b';
    }
    if (Math.abs(num) >= 1.0e+6) {
      return (Math.abs(num) / 1.0e+6).toFixed(1) + 'm';
    }
    if (Math.abs(num) >= 1.0e+3) {
      return (Math.abs(num) / 1.0e+3).toFixed(1) + 'k';
    }
    return num.toString(); // Return as string if no formatting is needed
  };
  

  // Extracting data for labels and series from the input data
  const labels = stations.map(station => truncateLabel(station.FillingStationName));
  const overallRequestedQuantity = [];
  const deliveredQuantity = [];

  // Iterate through all stations and set their data values if available
  stations.forEach(station => {
    const stationData = data.find(item => item.Station === station.FillingStationName);
    if (stationData) {
      overallRequestedQuantity.push(stationData.overallRequestedQuantity);
      deliveredQuantity.push(stationData.deliveredQuantity);
    } else {
      overallRequestedQuantity.push(0);
      deliveredQuantity.push(0);
    }
  });

  // Chart options
  const options = {
    chart: {
      type: 'bar',
      height: 150,
      stacked: true,
      
    },
    plotOptions: {
      
      bar: {
        horizontal: screenWidth < 768,
        borderRadius: 2, // Adding borderRadius for rounded bars
        dataLabels: {
          position: 'center', // Display data labels inside the bars
        },
      },
        
        
     
    },
    xaxis: {
      categories: labels,
      labels: {
        formatter: function (value) {
          return formatNumber(value);
        },
          rotate: 270, // Rotate labels for better readability
      },
      title: {
        text: screenWidth < 768 ? 'Lts' : 'Filling Station',
        style: {
          fontSize: '20px', // Set the font size as needed
        },
    }
    },
    yaxis: {
      title: {
        text:screenWidth < 768 ? 'Filling Station' : 'Lts',
        style: {
          fontSize: '20px', // Set the font size as needed
        },
      },
      labels: {
        formatter: function (value) {
          return formatNumber(value);
        }
      },
      
    },
    legend: {
      position: 'top',
    },
    tooltip: {
      x: {
        formatter: function (val) {
          const stationName = stations.find(station => truncateLabel(station.FillingStationName) === val);
          return stationName ? stationName.FillingStationName : '';
        }
      },
      y: {
        formatter: function (val) {
          return formatNumber(val);
        }
      }
    },
    dataLabels: {
        enabled: true,
        formatter: function (val) {
          return formatNumber(val);
        },
       
      },
    responsive: [
      {
        breakpoint: 768, // Adjust as needed
        options: {
          chart: {
            height: 450,
            width: '100%', // Set the width to 100% to prevent extending when the sidebar opens/closes
          },
        },
      },
    ],
  };

  // Chart data series
  const series = [
    { name: 'Request', data: overallRequestedQuantity },
    { name: 'Delivered', data: deliveredQuantity },
  ];

return (
  <div className="flex flex-col items-center justify-center w-full p-2">
    <div className="w-full overflow-auto pt-3" style={{ minWidth: '300px', maxWidth: '600px' }}>
      <Chart options={options} series={series} type="bar" height={360} />
    </div>
  </div>
);
};

export default BarChart;