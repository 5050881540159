// App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './Components/Login';
import CardComponent from './Components/Dashboard';
import FrontOfficeApp from './Components/homepage';
import NewTankerRequestForm from './Components/NewTanker';
import CheckboxComponent from './Components/TankerMenu';
import Vehicledownwise from './Components/VehicleDownTime';


import ZoneWardTable from './Components/Master/AddZoneandWards';
import DriverForm from './Components/Master/DriverMaster';
import FillingStationTable from './Components/Master/FillingStation';
import VehicleTable from './Components/Master/VehicleTable';
import UserMaster from './Components/Master/UserRegister';
import InventoryTable from './Components/Master/UserMasterHistory';

import VehicleDownwiseReport from './Components/Report/VehicleDownwiseReport';
import Fillingstationreport from './Components/Report/Fillingstationreport';
import AbstractReportForm from './Components/Report/AbstractReport';
import VehicleDeliveryInfo from './Components/Report/VehiclewiseReport';
import Notification from './Components/Notification';


function App() {
  return (
    <Router>
      <Routes >
        <Route path="/" element={<Login />} />
        <Route path="/dashboard/*" element={<FrontOfficeApp />}>
        <Route path="CardComponent" element={<CardComponent />} /> 
          <Route path="newtankerrequest" element={<NewTankerRequestForm />} /> 
          <Route path="ViewTanker" element={<CheckboxComponent />} />
          <Route path="vehicledowntime" element={<Vehicledownwise />} />
          <Route path="fillingstation" element={<FillingStationTable />} />
          <Route path="zone" element={<ZoneWardTable />} />

          <Route path="UserRegister" element={<UserMaster />} />
          <Route path="usermaster" element={<InventoryTable />} />
          <Route path="VehicleDownwiseReport" element={<VehicleDownwiseReport />} />
          <Route path="DriverMaster" element={<DriverForm />} />
          <Route path="RegisterVehicleForm" element={<VehicleTable  />} />
          <Route path="AbstractReportForm" element={<AbstractReportForm/>} />
          <Route path="Fillingstationreport" element={<Fillingstationreport/>}/>
          <Route path="VehiclewiseReport" element={<VehicleDeliveryInfo/>}/>
          <Route path="Notification" element={<Notification/>}/>

        </Route>
      </Routes>
    </Router>
  );
}

export default App;
