import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify"; // Import react-toastify for popups
import "react-toastify/dist/ReactToastify.css";
import config from "../../config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import * as XLSX from 'xlsx';
import {
  faMapMarkerAlt,
  faEdit,
  faTrashAlt,
  faInfoCircle,
  faSave,
  faTimes,
  faTruck,
  faFileExcel,
  faPlus,
  faPen,
  faUser,
  faTachometerAlt,
  faBuilding,
  faFileExport
} from "@fortawesome/free-solid-svg-icons";
import WardData from "./WardId";
import DriverVehicleMapper from "./DriverVehicle";
import jsPDF from "jspdf";
import "jspdf-autotable";

const VehicleTable = () => {
  const [vehicles, setVehicles] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [additionalInfo, setAdditionalInfo] = useState(null);
  const [selectedVehicle, setSelectedVehicle] = useState(null);

  const [showModal1, setShowModal1] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const [historyData, setHistoryData] = useState([]); // State variable for history data
  const [showHistoryModal, setShowHistoryModal] = useState(false);

  const [error, setError] = useState('');
  const [showLoginForm, setShowLoginForm] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
   const [isMobile, setIsMobile] = useState(window.innerWidth < 1020);
   const [selectedColumn, setSelectedColumn] = useState('');
   const [searchValue, setSearchValue] = useState('');
   const user = JSON.parse(sessionStorage.getItem("user")); 

  
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1020);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    fetchVehicles();
  }, []);

  const fetchVehicles = async () => {
    const token = user ? user.token : null;
    // Get the token from sessionStorage
        const configToken = {
          headers: {
            Authorization: `Bearer ${token}`, // Set the Authorization header with the token
          },
        };
  
  
    try {
      const response = await axios.get(
        `${config.apiUrl}/zonewards/getvehicles`,configToken
      );
      setVehicles(response.data.vehicles);
    } catch (error) {
      console.error("Error fetching vehicles:", error);
    }
  };

  const handleOpenEdit = async (vehicle) => {
    setSelectedVehicle(vehicle);
    // console.log("setSelectedVehicle", vehicle);

    setShowModal(true);
  };

  const openModal = () => {
    setShowModal(true);
    setSelectedVehicle(null);
  };
  const closeModal = async () => {
    setShowModal(false);
    fetchVehicles();
  };

  const openModal1 = () => {
    setShowModal1(true);
  };
 
  const closeModal1 = async () => {
    setShowModal1(false);
    fetchVehicles();
  };

  const openHistoryModal = () => {
    setShowHistoryModal(true);
  };

  const closeHistoryModal = () => {
    setShowHistoryModal(false);
  };

  // Function to fetch history data
  const fetchHistoryData = async (vehicle) => {
    const token = user ? user.token : null;
    // Get the token from sessionStorage
        const configToken = {
          headers: {
            Authorization: `Bearer ${token}`, // Set the Authorization header with the token
          },
        };
  
  
    try {
      const response = await axios.get(
        `${config.apiUrl}/zonewards/getdrivervehiclemapper/${vehicle.vehicleNo}`,configToken
      );
      // console.log(response.data);
      setHistoryData(response.data.data);

      // setHistoryData(response.data); // Assuming response.data contains the history data
      openHistoryModal(); // Open the history modal after fetching data
    } catch (error) {
      console.error("Error fetching vehicle history:", error);
      toast.error("Error fetching vehicle history");
    }
  };

  const handleDeleteClick = (vehicle) => {
    setSelectedItem(vehicle);
    setShowConfirmation(true);
  };
  const handleCancelDelete = () => {
    setSelectedItem(null);
    setShowConfirmation(false);
  };

  const handleDelete = async () => {
    const token = user ? user.token : null;
    // Get the token from sessionStorage
        const configToken = {
          headers: {
            Authorization: `Bearer ${token}`, // Set the Authorization header with the token
          },
        };
  
  
    try {
      const response = await fetch(
        `${config.apiUrl}/zonewards/deletevehicle/${selectedItem.vehicleNo}`,
        {
          method: "DELETE",
          headers: configToken.headers,
        }
      );
      if (response.ok) {
        // Remove the deleted item from the local state
        setVehicles(
          vehicles.filter((item) => item.vehicleNo !== selectedItem.vehicleNo)
        );
        toast.success("Record deleted successfully");

        // setFilteredData(filteredData.filter(item => item.id !== selectedItem.id))
        handleCancelDelete();
      } else {
        console.error("Error deleting record");
      }
    } catch (error) {
      console.error("Error deleting record:", error);
    }
  };
    
  const handlePdf = async () => {
    const token = user ? user.token : null;
    // Get the token from sessionStorage
        const configToken = {
          headers: {
            Authorization: `Bearer ${token}`, // Set the Authorization header with the token
          },
        };
  
    try {
      const response = await axios.post(`${config.apiUrl}/user/exportUser`, { loginIdentifier: username, password },configToken);
      const { data, message } = response.data;
      if (response.status === 200 ){
        if( data.user.user_role === 'Admin') {
          const doc = new jsPDF();

          // Add header row
          const header = [[  "S.No",
          "Vehicle No",
          "Capacity",
          "Vehicle Type",
          " Driver Name",
          " Driver Mobile Number",
          " WardID"
        ]];
          
          // Add data rows
          const data =vehicles.map((item, index) => [
            index + 1,
              item.vehicleNo,
              item.capacity,
              item.vehicleType,
              item.drivername,
              item.driver_mobile_no,
              item.wardId,
            // Leave empty for Vehicle Number (will fill later)
          ]);
      
          // Add table to PDF
          doc.autoTable({
            head: header,
            body: data,
          });
      
          // Save PDF
          doc.save('VehicleData.pdf');
          setError('');
          setShowLoginForm(false);
        }
        else {
            setError('Unauthorized User');
        }
      }
        else {
            setError(message);
        }
      } catch (error) {
        setError('Failed to export Excel file');
      }
    };
  
  const handleExcel = async () => {
    const token = user ? user.token : null;
    // Get the token from sessionStorage
        const configToken = {
          headers: {
            Authorization: `Bearer ${token}`, // Set the Authorization header with the token
          },
        };
  
    try {
      const response = await axios.post(`${config.apiUrl}/user/exportUser`, { loginIdentifier: username, password },configToken);
      const { data, message } = response.data;
      if (response.status === 200 ){
        if( data.user.user_role === 'Admin') {
          const fileName = "VehicleTable.xlsx";
          const wsData = [
              [
                  "S.No",
                  "Vehicle No",
                  "Capacity",
                  "Vehicle Type",
                  " Driver Name",
                  " Driver Mobile Number",
                  " WardID",
                 
              ],
              ...vehicles.map((item, index) => [
                index + 1,
                  item.vehicleNo,
                  item.capacity,
                  item.vehicleType,
                  item.drivername,
                  item.driver_mobile_no,
                  item.wardId,
                // Leave empty for Vehicle Number (will fill later)
              ])
          ];
      
          // Fill location and vehicle data for each row
       
      
          const ws = XLSX.utils.aoa_to_sheet(wsData);
          const wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, "VehicleTable");
      
          // Export the workbook
          XLSX.writeFile(wb, fileName);
          setError('');
          setShowLoginForm(false);
        }
        else {
            setError('Unauthorized User');
        }
      }
        else {
            setError(message);
        }
      } catch (error) {
        setError('Failed to export Excel file');
      }
    };
    
  const handleClose = () => {
    // Function to handle closing the login form
    setShowLoginForm(false);
    setUsername('');
    setPassword('');
    setError('');
  };

  const modalStyles = `
  .modal {
    display: flex;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0,0.4);
  }

  .modal-content {
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    // width: 80%;
    animation-name: modal-slide;
    animation-duration: 0.5s;
  }

  @keyframes modal-slide {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(0);
    }
  }

  .close-btn {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }

  .close-btn:hover,
  .close-btn:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }
`;
const openAdditionalInfoModal = (vehicle) => {
  setAdditionalInfo(vehicle);
  // Code to open the additional info modal
};

const filterOptions = [
  { label: 'Vehicle No', value: 'vehicleNo' },
  { label: 'Capacity', value: 'capacity' },
  { label: 'Vehicle Type', value: 'vehicleType' },
  { label: 'Driver Name', value: 'drivername' },
  // { label: 'Driver Mobile Number', value: 'driver_mobile_no' },
  { label: 'Ward ID', value: 'wardId' },
];


const handleSearch = (inputValue) => {
  if (inputValue.trim() === '') {
    fetchVehicles(); // Fetch all vehicles when search input is empty
  } else {
    const filteredData = vehicles.filter((vehicle) => {
      const columnValue = vehicle[selectedColumn];


      // Handle special cases like wardId and capacity which might not be strings
      if (typeof columnValue === 'string') {
        return columnValue.toLowerCase().includes(inputValue.toLowerCase());
      } else {
        // For non-string values, convert to string and then compare
        return String(columnValue).toLowerCase().includes(inputValue.toLowerCase());
      }
    });
    setVehicles(filteredData);
  }
};

useEffect(() => {
  handleSearch(searchValue);
}, [searchValue]);


  return (
    <div className="">
      
      <style>{modalStyles}</style>
      <div>
      {!isMobile ? (
        <div >
  <h1 className="sm:text-2xl font-bold md:mb-2">
    <FontAwesomeIcon className="text-blue-800" icon={faTruck} /> Vehicle Table
  </h1>
  <div className="flex flex-row items-center justify-between mb-2">
  <div className="flex flex-col md:flex-row ">
  <select
    className="mr-2 px-2 py-1 border border-gray-300 rounded  md:mb-0 w-full md:mr-0 md:px-3 md:py-2 md:w-40"
    value={selectedColumn}
    onChange={(e) => {
      setSelectedColumn(e.target.value);
      setSearchValue(''); // Clear search input value
    }}
  >
    <option value="">Select </option>
    {filterOptions.map((option) => (
      <option key={option.value} value={option.value}>
        {option.label}
      </option>
    ))}
  </select>
  <input
  type="text"
  placeholder="Search..."
  value={searchValue}
  className="px-1 py-1 border w-full border-gray-300 rounded  md:w-auto"

  onChange={(e) => setSearchValue(e.target.value)}
/>
 
</div>

    <div>
    <button
      className="text-white py-2 px-4 rounded mr-2"
      onClick={() => openModal1()}
      style={{
        backgroundImage: 'linear-gradient(to right, #003399, #4facfe)',
        fontSize: '0.9rem',
        marginRight: '8px' // Adjusted margin
      }}
    >
     Update Driver <FontAwesomeIcon icon={faUser} />
    </button>
    <button
      className="text-white py-2 px-1 rounded mr-2"
      onClick={() => openModal()}
      style={{
        backgroundImage: "linear-gradient(to right, #003399, #00f2fe)",
                fontSize: '0.9rem',
        marginRight: '8px' // Adjusted margin
      }}
    >
      Vehicle & WardID <FontAwesomeIcon icon={faPlus} />
    </button>
    {!showLoginForm && (
      <button
        className="bg-green-500 hover:bg-green-600 text-white py-2 px-1 rounded "
        onClick={() => setShowLoginForm(true)}
        style={{
          fontSize: '0.9rem',
          marginRight: '8px' // Adjusted margin
        }}
      >
        <FontAwesomeIcon icon={faFileExport} className="mr-1" />
        Export
      </button>
    )}
    </div>
   
  </div>
</div>

) : (
  <div >
    <h1 className="text-lg font-bold text-md">
      <FontAwesomeIcon className="text-blue-800" icon={faTruck} />
      Vehicle Table
    </h1>
    <div className="flex justify-between mb-2 max-w-sm">
    <div className="flex flex-col md:flex-row ">
  <select
    className="mr-2  border border-gray-300 rounded  w-full md:mr-0 md:px-3  md:w-40"
    value={selectedColumn}
    onChange={(e) => {
      setSelectedColumn(e.target.value);
      setSearchValue(''); // Clear search input value
    }}
  >
    {/* <option value="">Select Column</option> */}
    {filterOptions.map((option) => (
      <option key={option.value} value={option.value}>
        {option.label}
      </option>
    ))}
  </select>
  <input
  type="text"
  placeholder="Search..."
  value={searchValue}
  className="px-1  border  border-gray-300 rounded  md:w-auto"

  onChange={(e) => setSearchValue(e.target.value)}
/>
 
</div>

      <div>
      <button
  className="text-white py-1 px-3 rounded mr-1"
  onClick={() => openModal1()}
  style={{
    backgroundImage: "linear-gradient(to right, #4facfe, #00f2fe)",
    fontSize: '0.8rem',
    marginRight: '8px' 
  }}
>
  <FontAwesomeIcon icon={faUser} />
</button>
<button
  className="text-white py-1 px-3 rounded mr-1"
  onClick={() => openModal()}
  style={{
    backgroundImage: "linear-gradient(to right, #fd746c, #ff9068)",
    fontSize: '0.8rem',
    marginRight: '8px' 
  }}
>
  <FontAwesomeIcon icon={faPlus} />
</button>
<button
  className="text-white py-1 px-1 rounded mr-1 bg-green-500"
  onClick={() => setShowLoginForm(true)}
  style={{
    fontSize: '0.8rem', 
    padding: '4px 10px', 
   
  }}
>
  <FontAwesomeIcon icon={faFileExport} />
</button>
      </div>
    
    </div>
  </div>
)}

 </div>

      {!isMobile? (
    <div className="w-full overflow-y-auto" style={{ height: "450px" }}>
    <table className="w-full border-collapse ">
      <thead className="sticky top-0 border-2 border-gray-400">
        <tr className="text-white" style={{ backgroundColor: "rgb(33,64,154)" }}>
          <th className="md:px-4 md:py-3 text-xs leading-4 font-medium uppercase">S.NO</th>
          <th className="md:px-4 md:py-3 text-xs font-medium uppercase">Vehicle No</th>
          <th className="md:px-4 text-xs font-medium uppercase">Capacity</th>
          <th className="md:px-2 md:py-3 text-xs font-medium uppercase">Vehicle Type</th>
          <th className="md:px-2 md:py-3 text-xs font-medium uppercase">Driver Name</th>
          <th className="md:px-2 md:py-3 text-xs font-medium uppercase">Driver Mobile Number</th>
          <th className="md:px-6 md:py-3 text-xs leading-4 font-medium uppercase">Driver History</th>
          <th className="md:px-2 md:py-3 text-xs leading-4 font-medium uppercase">WardID</th>
          <th className="md:px-2 md:py-3 text-xs leading-4 font-medium uppercase">Update ward</th>
          <th className="md:px-6 md:py-3 text-xs font-medium uppercase">Delete</th>
        </tr>
      </thead>
      <tbody>
        {vehicles.map((vehicle, index) => (
          <tr key={vehicle.vehicleNo} className=" border-b-2  text-sm">
            <td className="md:px-3 md:py-3 text-center ">{index + 1}</td>
            <td className="md:px-3 md:py-3 text-center ">
            <span style={{ whiteSpace: 'nowrap' }}>{vehicle.vehicleNo}</span></td>
            <td className="md:px-3 md:py-3 text-center">{vehicle.capacity}</td>
            <td className="md:px-3 md:py-3 text-center ">{vehicle.vehicleType}</td>
            <td className="md:px-3 md:py-3 text-center  ">{vehicle.drivername}</td>
            <td className="md:px-3 md:py-3 text-center">{vehicle.driver_mobile_no}</td>
            <td className="md:px-3 md:py-3  text-center">
              <FontAwesomeIcon
                icon={faSave}
                className="cursor-pointer text-green-500 size-4"
                onClick={() => fetchHistoryData(vehicle)}
              />
            </td>
            <td className="md:px-3 md:py-3 text-center text-xs">{vehicle.wardId}</td>
            <td className="md:px-4 md:py-2 text-sm text-center">
              <td className="md:px-4 md:py-2 text-center">
                <FontAwesomeIcon
                  icon={faEdit}
                  className="cursor-pointer text-blue-700 ml-2"
                  onClick={() => handleOpenEdit(vehicle)}
                />
              </td>
            </td>
            <td className="md:px-4 md:py-2 text-center">
              <FontAwesomeIcon
                icon={faTrashAlt}
                className="cursor-pointer  ml-2"
                style={{ color: "red" }}
                onClick={() => handleDeleteClick(vehicle)}
              />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
): (
  <div className="overflow-y-auto overflow-x-auto w-[90vw] ml-2 " style={{ maxHeight: '70vh' }}>
    <table className="max-w-sm border-collapse text-xs h-[70vh] ">
      <thead className="sticky text-xs top-0 border-2 border-gray-400">
        <tr className="text-white" style={{ backgroundColor: "rgb(33,64,154)" }}>
          <th className="py-1 text-xs leading-4 ">S.NO</th>
          <th className="px-1 py-1 text-xs ">Vehicle No</th>
          <th className="px-1 py-1 text-xs ">Actions</th>
          <th className="px-1 py-1 text-xs ">Driver Name</th>
          <th className="px-1 text-xs leading-4 ">Driver History</th>
          <th className="px-1 text-xs leading-4 ">WardID</th>
          <th className="text-xs leading-4 ">Update ward</th>
          <th className="text-xs ">Delete</th>
        </tr>
      </thead>
      <tbody>
        {vehicles.map((vehicle, index) => (
          <tr key={vehicle.vehicleNo} className="text-xs border-b-2">
            <td className="text-center text-xs">{index + 1}</td>
            <td className="text-center text-xs">
  <span style={{ whiteSpace: 'nowrap' }}>{vehicle.vehicleNo}</span>
</td>

            <td className="text-center text-xs">
                <FontAwesomeIcon
                  icon={faInfoCircle}
                  className="cursor-pointer text-blue-700 ml-2"
                  onClick={() => openAdditionalInfoModal(vehicle)}
                />
              </td>
              <td className="text-center text-xs">
  <span style={{ whiteSpace: 'nowrap' }}>{vehicle.drivername}</span>
</td>
            <td className="text-center text-xs">
              <FontAwesomeIcon
                icon={faSave}
                className="cursor-pointer text-green-500 2"
                onClick={() => fetchHistoryData(vehicle)}
              />
            </td>
            <td className="text-center text-xs">{vehicle.wardId}</td>
            <td className="text-center text-xs">
              <FontAwesomeIcon
                icon={faEdit}
                className="cursor-pointer text-blue-700 "
                onClick={() => handleOpenEdit(vehicle)}
              />
            </td>
            <td className="text-center text-xs">
              <FontAwesomeIcon
                icon={faTrashAlt}
                className="cursor-pointer "
                style={{ color: "red" }}
                onClick={() => handleDeleteClick(vehicle)}
              />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
      {/* Additional Info Modal */}
      {additionalInfo && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75">
          <div className="bg-white p-8 rounded-lg">
            <h2 className="text-xl font-semibold mb-4">Additional Vehicle Details</h2>
            <p>Capacity: {additionalInfo.capacity}</p>
            <p>Vehicle Type: {additionalInfo.vehicleType}</p>
            {/* <p>Driver Name: {additionalInfo.drivername}</p>
            <p>Driver Mobile Number: {additionalInfo.driver_mobile_no}</p> */}
            <button className="bg-red-500 text-white px-4 py-2 mt-4 rounded" onClick={() => setAdditionalInfo(null)}>Close</button>
          </div>
        </div>
      )}
  </div>
)}

      {showModal && (
        <WardData
          selectedVehicle={selectedVehicle}
          onClose={closeModal}
          fetchVehicles={fetchVehicles}
        />
      )}

{showModal1 && (
       <DriverVehicleMapper onClose={closeModal1}  selectedItem={selectedVehicle}  fetchVehicles={fetchVehicles}/>
      )}

{showConfirmation && (
    <>
      {!isMobile ? (
        <div className="fixed inset-0 z-40 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
          <div className="fixed inset-0 bg-black bg-opacity-50"></div>
          <div className="relative w-auto max-w-3xl mx-auto my-4">
            <div className="bg-white p-8 rounded-lg shadow-md">
              <p className="mb-3">
                Do you want to delete this Vehicle - {`${selectedItem.vehicleNo}`}?
              </p>
              <div className="flex justify-center space-x-3">
                <button
                  onClick={handleDelete}
                  className="bg-red-500 hover:bg-red-600 text-white px-2 py-2 rounded-full"
                >
                  Yes
                </button>
                <button
                  onClick={handleCancelDelete}
                  className="bg-gray-400 hover:bg-gray-500 text-white px-2 py-2 rounded-full"
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
      ) :  (
        <div className="fixed inset-0 z-40 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
        <div className="fixed inset-0 bg-black bg-opacity-50"></div>
        <div className="relative w-full max-w-xs mx-auto my-8"> {/* Adjust max-w-xs and top position */}
          <div className="bg-white p-4 rounded-lg shadow-md">
            <p className="mb-3 text-center">
              Do you want to delete this Vehicle - {`${selectedItem.vehicleNo}`}?
            </p>
            <div className="flex justify-center space-x-3">
              <button
                onClick={handleDelete}
                className="bg-red-500 hover:bg-red-600 text-white px-2 py-2 rounded-full"
              >
                Yes
              </button>
              <button
                onClick={handleCancelDelete}
                className="bg-gray-400 hover:bg-gray-500 text-white px-2 py-2 rounded-full"
              >
                No
              </button>
            </div>
          </div>
        </div>
      </div>
      
      
    )
    }
    </>
  )}
  

  {showHistoryModal && (
    <>
     {!isMobile ? (
          <div className="fixed inset-0 z-40 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
          <div className="fixed inset-0 bg-black bg-opacity-50"></div>
          <div className="relative w-auto max-w-3xl mx-auto my-4">
            <div className="bg-white p-8 rounded-lg shadow-md">
              <button
                onClick={closeHistoryModal}
                className="absolute top-2 right-0 mr-4 text-gray-700 hover:text-gray-900"
              >
                <FontAwesomeIcon icon={faTimes} />
              </button>
              <h2 className="text-2xl font-bold text-center mb-3">History Data</h2>
              <div className="max-h-80 overflow-y-auto">
              <table className="w-full border-collapse ">
                {/* Render history data in table format */}
                <thead className="sticky top-0 ">
                  <tr
                    className=" text-white"
                    style={{ backgroundColor: "rgb(33,64,154)" }}
                  >
                    <th className="px-6 py-3   text-xs leading-4 font-medium  uppercase ">
                      S.NO
                    </th>
                    <th className="px-6 py-3 text-xs leading-4 font-medium  uppercase ">
                      Driver Name
                    </th>
                    <th className="px-6 py-3   text-xs leading-4 font-medium  uppercase ">
                      Driver Mobile_Number
                    </th>
                    <th className="px-6 py-3   text-xs leading-4 font-medium  uppercase ">
                      Create Date
                    </th>
                    {/* Add more columns as needed */}
                  </tr>
                </thead>
                <tbody>
                  {historyData.map((item, index) => (
                    <tr key={index} className='text-xs md:text-sm border border-b-2'>
                      <td className="px-3 py-3 text-sm text-center">{index + 1}</td>
                      <td className="px-3 py-3 text-sm text-center">{item.drivername}</td>
                      <td className="px-3 py-3 text-sm text-center">
                        {item.driver_mobile_no}
                      </td>
                      <td className="px-3 py-3 text-sm text-center">{new Date(item.timestamp).toLocaleString(undefined, { hour12: true })}</td>


                    </tr>
                  ))}
                </tbody>
              </table>
              </div>
            </div>
          </div>
        </div>
    ):(
      <div className="fixed inset-0  mb-40 z-40 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
      <div className="fixed inset-0 bg-black bg-opacity-50"></div>
      <div className="relative w-full max-w-xs mx-auto mt-4"> {/* Adjust max-w-xs and remove my-4 */}
        <div className="bg-white p-2 rounded-lg shadow-md">
          <button
            onClick={closeHistoryModal}
            className="absolute top-2 right-0 mr-4 text-gray-700 hover:text-gray-900"
          >
            <FontAwesomeIcon icon={faTimes} />
          </button>
          <h2 className="text-lg font-bold mb-3 text-center">History Data</h2>
          <div className="max-h-80 overflow-y-auto">
          <table className="w-full border-collapse">
            {/* Render history data in table format */}
            <thead className="sticky top-0 ">
              <tr
                className=" text-white"
                style={{ backgroundColor: "rgb(33,64,154)" }}
              >
                <th className="text-xs  text-center px-2 py-2  ">
                  S.NO
                </th>
                <th className="text-xs  text-center px-2 py-2">
                  Driver Name
                </th>
                <th className="  text-xs  text-center px-2 py-2 ">
                  Driver Mobile_Number
                </th>
                <th className="  text-xs  text-center px-2 py-2">
                  Create Date
                </th>
              </tr>
            </thead>
            <tbody>
              {historyData.map((item, index) => (
                <tr key={index}>
                  <td className="px-2 py-2 text-center text-xs">{index + 1}</td>
                  <td className="px-2 py-2 text-center text-xs">{item.drivername}</td>
                  <td className="px-2 py-2 text-center text-xs">{item.driver_mobile_no}</td>
                  <td className="px-2 py-2 text-xs text-center">{new Date(item.timestamp).toLocaleString(undefined, { hour12: true })}</td>

                </tr>
              ))}
            </tbody>
          </table>
          </div>
        </div>
      </div>
    </div>
    
    
    )}
    </>
  )}

{showLoginForm && (
  <>
    {!isMobile ? (
      <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
        <div className="bg-white w-full md:max-w-sm rounded-lg overflow-hidden shadow-xl">
        <div className="px-6 py-4">
              <div className="flex justify-end">
                <button className="text-gray-700 hover:text-gray-900" onClick={handleClose}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
              <label htmlFor="username" className="text-gray-700">Username</label>
              <input
                type="text"
                id="username"
                placeholder="Username or Mobile Number"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                className="border border-gray-400 px-3 py-2 rounded-md mb-2 w-full"
              />
              <label htmlFor="password" className="text-gray-700">Password</label>
              <input
                type="password"
                id="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="border border-gray-400 px-3 py-2 rounded-md mb-2 w-full"
              />
              <button
                onClick={handleExcel}
                className="bg-green-500 hover:bg-green-600 text-white py-2 px-4 rounded-md mr-2"
              >
                Excel
              </button>
              <button
              onClick={handlePdf}
              className="bg-green-500 hover:bg-green-600 text-white py-2 px-4 rounded-lg mr-2"
            >
              PDF
            </button>
              
              {error && <p className="text-red-500 mt-2">{error}</p>}
            </div>
        </div>
      </div>
    ) : (
      <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
        <div className="bg-white w-full max-w-xs md:max-w-lg rounded-lg overflow-hidden shadow-xl">
        <div className="px-6 py-4">
        <div className="flex justify-end">
          <button className="text-gray-700 hover:text-gray-900" onClick={handleClose}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
        <label htmlFor="username" className="text-gray-700">Username</label>
        <input
          type="text"
          id="username"
          placeholder="Username or Mobile Number"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          className="border border-gray-400 px-3 py-2 rounded-md mb-2 w-full"
        />
        <label htmlFor="password" className="text-gray-700">Password</label>
        <input
          type="password"
          id="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className="border border-gray-400 px-3 py-2 rounded-md mb-2 w-full"
        />
        <button
          onClick={handleExcel}
          className="bg-green-500 hover:bg-green-600 text-white py-2 px-4 rounded-md mr-2"
        >
          Excel
        </button>
        <button
        onClick={handlePdf}
        className="bg-green-500 hover:bg-green-600 text-white py-2 px-4 rounded-lg mr-2"
      >
        PDF
      </button>
        
        {error && <p className="text-red-500 mt-2">{error}</p>}
      </div>
        </div>
      </div>
    )}
  </>
)}
    </div>
  );
};

export default VehicleTable;