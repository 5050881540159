import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTruck, faChargingStation, faMobileAlt, faMapMarkerAlt, faUser, faTimeline } from '@fortawesome/free-solid-svg-icons';
import Select from 'react-select';
import config from "../../config";
import axios from 'axios';
import Modal from 'react-modal';
import { toast,} from 'react-toastify'; // Import react-toastify for popups
import 'react-toastify/dist/ReactToastify.css';

const AssignTanker = ({ data, onClose }) => {
  console.log('dta', data);
  const getCurrentDateTime = () => {
    const now = new Date();
    const offset = now.getTimezoneOffset() * 60000; // Offset in milliseconds
    const localTime = new Date(now.getTime() - offset);
    return localTime.toISOString().slice(0, 16); // Format the date and time to match the datetime-local input format
  };

  const [date, setDate] = useState(data.assignedTime ? data.assignedTime : getCurrentDateTime());
  const [selectedTanker, setSelectedTanker] = useState(data.vehicleNo === "" ? '' : data.vehicleNo);
  const [selectedSourceLocation, setSelectedSourceLocation] = useState(data.fillingstation);
  const [driverName, setDriverName] = useState(data.driverName === "" ? '' : data.driverName);
  const [mobileNo, setMobileNo] = useState(data.driverMobileNo === "" ? '' : data.driverMobileNo);
  const [locality, setLocality] = useState(`${data.locality}`);
  const [vehicleData, setVehicleData] = useState([]);
  const [isUpdating, setIsUpdating] = useState(false);
  const [user,setuser]= useState(JSON.parse(sessionStorage.getItem("user")))


  const [errors, setErrors] = useState({}); // State for form validation errors


  useEffect(() => {
  if(user){
    fetchData();
  }
    // Fetch data from backend when component mounts
    
  }, []);
 
  
  
  const fetchData = async () => {
    if (user && user.token) {
      const token = user ? user.token : null;
      // console.log(token);
      const configToken = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      try {
        const response = await axios.get(`${config.apiUrl}/vehicledowntime/lorry-details`, {
          ...configToken,
          params: {
            requestedQuantity: data.requestedQuantity, // Include requestedQuantity in the request parameters
          },
        });
  
        const vehicleData = response.data.map((item) => ({
          vehicleNo: item['vehicleNo'],
          capacity: item['capacity'],
          mobileNo: item['driver_mobile_no'],
          driverName: item['drivername'],
          trips: 0,
        }));
  
        const tripCountsResponse = await axios.get(`${config.apiUrl}/assign/trip-counts`, configToken);
        const tripCounts = tripCountsResponse.data;
  
        const updatedVehicleData = vehicleData.map((vehicle) => {
          const tripCount = tripCounts.find((item) => item.vehicleNo === vehicle.vehicleNo);
          const trips = tripCount ? tripCount.trips : 0;
  
          return {
            ...vehicle,
            trips: trips,
          };
        });
  
        setVehicleData(updatedVehicleData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
  };
  
 
  const handleTankerChange = selectedOption => {
    if (selectedOption) {
      setSelectedTanker(selectedOption.value);
      const selectedVehicle = vehicleData.find(vehicle => vehicle.vehicleNo === selectedOption.value);
      if (selectedVehicle) {
        setDriverName(selectedVehicle.driverName || ''); // If driverName is falsy, set to empty string
        setMobileNo(selectedVehicle.mobileNo || ''); // If mobileNo is falsy, set to empty string
      }
      // Clear the error for selectedTanker when the input has a value
      if (selectedOption.value) {
        setErrors(prevErrors => ({ ...prevErrors, selectedTanker: '' }));
      }
    } else {
      setSelectedTanker('');
      setDriverName(''); // Clear driverName when selection is cleared
      setMobileNo(''); // Clear mobileNo when selection is cleared
    }
  };
  

  // Function to handle change in the driver name
  const handleDriverNameChange = (e) => {
    setDriverName(e.target.value);
    // Clear the error for driverName when the input has a value
    if (e.target.value) {
      setErrors(prevErrors => ({ ...prevErrors, driverName: '' }));
    }
  };

  // Function to handle change in the mobile number
  const handleMobileNoChange = (e) => {
    setMobileNo(e.target.value);
    // Clear the error for mobileNo when the input has a value
    if (e.target.value) {
      setErrors(prevErrors => ({ ...prevErrors, mobileNo: '' }));
    }
  };


  const handleClear = () => {
    setDriverName('');
    setIsUpdating('');
    setMobileNo('');
    setSelectedTanker('');
    setSelectedSourceLocation('');
    setErrors({}); // Clear form validation errors
  }

  // Function to handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsUpdating(true);
    const formErrors = {}; // Temporary object to hold form validation errors

    // Validate required fields
    if (!selectedTanker) {
      formErrors.selectedTanker = 'Vehicle is required';
    }
    if (!driverName) {
      formErrors.driverName = 'Driver name is required';
    }
    if (!mobileNo) {
      formErrors.mobileNo = 'Mobile number is required';
    }

    // Set form errors and stop submission if there are validation errors
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      setIsUpdating(false);
      return;
    }

    try {
      let response;
      const token = user ? user.token : null;
      // Get the token from sessionStorage
          const configToken = {
            headers: {
              Authorization: `Bearer ${token}`, // Set the Authorization header with the token
            },
          };
      if (data.vehicleNo) {
      
        // Call updateVehicleDetails API if data.vehicleNo is present
        response = await axios.put(`${config.apiUrl}/assign/updateVehicleDetails/${data.id}`, {
          vehicleNo: selectedTanker,
          driverName,
          driverMobileNo: mobileNo,
          date:date,
        },configToken);
      } else {
        // Call UpdateAssignedStatus API if data.vehicleNo is not present
        response = await axios.post(`${config.apiUrl}/assign/UpdateAssignedStatus`, {
          id: data.id, // Use props.id from parent component
          driverName,
          driverMobileNo: mobileNo,
          vehicleNo: selectedTanker, // Use selectedTanker from state
          fillingStation: selectedSourceLocation,
          date:date, // Use selectedSourceLocation from state
        },configToken);
      }

      // if (response.status === 200) {
      //   try {
      //     // Prepare payload data
      //     const payload = {
      //       referenceno: data.id.toString(),
      //       locality: data.locality,
      //       lat: 9.9299706,  // Static latitude value (consider making it dynamic if needed)
      //       long: 78.1441737, // Static longitude value
      //       vehicleno: selectedTanker, // Assuming selectedTanker is defined
      //       capacity: data.requestedQuantity, // Assuming requestedQuantity is available in the response data
      //       status: "Assigned",
      //       radius: 1000,
      //       AssignedTime: date, // Ensure date is in ISO format
      //       remarks: 'N/A',
      //     };
      
      //     // Send the POST request using fetch
      //     const responseToRealTech = await fetch('https://gov.igps.io/iTanker/request.php', {
      //       method: 'POST', // Set the method to POST
      //       body: JSON.stringify(payload), // Convert the payload to a JSON string
      //     });
      
      //     // Check for non-200 status codes
      //     if (!responseToRealTech.ok) {
      //       throw new Error(`Error: ${responseToRealTech.status} - ${responseToRealTech.statusText}`);
      //     }
      
      //     // Parse the response data as JSON
      //     const realTechResponseData = await responseToRealTech.json();
      //     console.log("Data successfully sent to RealTech:", realTechResponseData);
      
      //   } catch (error) {
      //     // Handle errors during the request
      //     console.error("Error sending data to RealTech:", error.message);
      //   }
      // }

      if (response.status === 200) {
        try {
          // Prepare the address (locality) to be geocoded
          const address = `${data.locality}, Madurai`;
      
          // Call Google Geocoding API to get lat and long
          const geocodeResponse = await fetch(
            `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=${config.Google_Api_Key}`
          );
      
          if (!geocodeResponse.ok) {
            throw new Error(`Geocoding failed: ${geocodeResponse.status} - ${geocodeResponse.statusText}`);
          }
      
          // Parse the geocode response
          const geocodeData = await geocodeResponse.json();
          if (geocodeData.status !== 'OK') {
            throw new Error(`Geocoding error: ${geocodeData.status}`);
          }else{
            const { lat, lng } = geocodeData.results[0].geometry.location;
          console.log('lat,long',lat,lng);
          
    
         
              // Prepare payload data
              const payload = {
                referenceno: data.id.toString(),
                locality: data.locality,
                lat: lat,  // Static latitude value (consider making it dynamic if needed)
                long: lng, // Static longitude value
                vehicleno: selectedTanker, // Assuming selectedTanker is defined
                capacity: data.requestedQuantity, // Assuming requestedQuantity is available in the response data
                status: "Assigned",
                radius: 1000,
                AssignedTime: date, // Ensure date is in ISO format
                remarks: 'N/A',
              };
          
              // Send the POST request using fetch
              const responseToRealTech = await fetch('https://gov.igps.io/iTanker/request.php', {
                method: 'POST', // Set the method to POST
                body: JSON.stringify(payload), // Convert the payload to a JSON string
              });
          
           
      
          if (!responseToRealTech.ok) {
            throw new Error(`Error: ${responseToRealTech.status} - ${responseToRealTech.statusText}`);
          }
      
          // Parse the response data as JSON
          const realTechResponseData = await responseToRealTech.json();
          console.log("Data successfully sent to RealTech:", realTechResponseData);
          }
          // Extract lat and long from the first result
          
      
        } catch (error) {
          // Handle errors during the request
          console.error("Error sending data to RealTech:", error.message);
        }
      }
      
      

      
      
      
      console.log(response);
      handleClear();
      setIsUpdating(false);
      toast.success('Status updated successfully');
      await new Promise((resolve) => setTimeout(resolve, 1000));
      onClose();
    } catch (error) {
      console.error('Error updating assigned status:', error);
      setIsUpdating(false);
      toast.error('Error updating status');
    }
  };

  const options = vehicleData.map(vehicle => ({
    label: `${vehicle.vehicleNo} - ${vehicle.capacity} ${vehicle.trips ? `Trips: ${vehicle.trips}` : ''}`,
    value: vehicle.vehicleNo
  }));
  const loadingOption = { label: "Loading vehicles...", value: "" };
  const defaultOption = { label: "No vehicles available", value: "" };
  return (
    <>
    <style>
     
    </style>
    <div className="h-[80vh] flex justify-center  ">
      <div className=" sm:p-4 p-2 rounded-lg shadow-md w-[80vw] sm:w-80 ">
      <h1 className="text-2xl sm:text-3xl font-semibold sm:mb-4 mb-2 text-center sticky top ">
          Assign Tanker
        </h1>
        <form onSubmit={handleSubmit} className="space-y-2 h-[68vh] overflow-y-auto">
       

        <div className="">
            <label htmlFor="tanker" className="text-gray-700 mb-1 block text-lg font-semibold">
              <FontAwesomeIcon icon={faTruck} className="mr-2" />
              Select Vehicle
            </label>
           
<Select
  id="tanker"
  value={selectedTanker ? options.find(option => option.value === selectedTanker) : null}
  onChange={handleTankerChange}
  options={vehicleData.length > 0 ? options : [loadingOption]}
  isLoading={vehicleData.length === 0}
  placeholder="Select Tanker"
  isClearable
  hideSelectedOptions={false} // Show the clear indicator for selected options
  filterOption={({ label, value }, input) => {
    const inputValue = input.toLowerCase().replace(/\s/g, '');
    const optionValue = value.toLowerCase().replace(/\s/g, '');
    return optionValue.includes(inputValue);
  }}
  className={`w-full border border-gray-400 rounded-lg focus:outline-none focus:border-blue-500 ${errors.selectedTanker ? 'border-red-500' : ''}`}
  styles={{
    option: (provided, state) => ({
      ...provided,
      color: state.data.label.includes('Trips:') ? 'blue' : 'green'
    })
  }}
/>

            {errors.selectedTanker && <p className="text-red-500 text-sm">{errors.selectedTanker}</p>}
          </div>

          <div className="">
            <label htmlFor="locality" className="text-gray-700 mb-1 block text-lg font-semibold">
              <FontAwesomeIcon icon={faMapMarkerAlt} className="mr-2" />
              Locality
            </label>
            <input
              type="text"
              id="locality"
              value={locality}
              readOnly
              className="w-full px-2 py-1 border border-gray-400 rounded-lg focus:outline-none focus:border-blue-500"
            />
          </div>
          <div className="">
            <label htmlFor="sourceLocation" className="text-gray-700 mb-1 block text-lg font-semibold">
              <FontAwesomeIcon icon={faChargingStation} />
              Filling Station
            </label>
            <input
              type="text"
              id="sourceLocation"
              placeholder="Select Source Location"
              value={selectedSourceLocation}
              readOnly // Make the input read-only to display the value
              className="w-full px-2 py-1 border border-gray-400 rounded-lg focus:outline-none focus:border-blue-500"
            />
          </div>
          <div className="">
            <label htmlFor="driverName" className="text-gray-700 mb-1 block text-lg font-semibold">
              <FontAwesomeIcon icon={faUser} className="mr-2" />
              Driver Name
            </label>
            <input
              type="text"
              id="driverName"
              value={driverName}
              onChange={(e) => setDriverName(e.target.value)}
              readOnly
              className="w-full px-2 py-1 border border-gray-400 rounded-lg focus:outline-none focus:border-blue-500"
            />
          </div>
          <div className="">
            <label htmlFor="mobileNo" className="text-gray-700 mb-1 block text-lg font-semibold">
              <FontAwesomeIcon icon={faMobileAlt} className="mr-2" />
              Mobile No
            </label>
            <input
              type="text"
              id="mobileNo"
              value={mobileNo}
              onChange={(e) => setMobileNo(e.target.value)}
              readOnly
              className="w-full px-2 py-1 border border-gray-400 rounded-lg focus:outline-none focus:border-blue-500"
            />
          </div>
          <div className="">
            <label htmlFor="mobileNo" className="text-gray-700 mb-1 block text-lg font-semibold">
              <FontAwesomeIcon icon={faTimeline} className="mr-2" />
             Date
            </label>
            <input
                id="startDateTimeInput"
           
                  type="datetime-local"
                  value={date}
                  onChange={(e) => setDate(e.target.value)}
                  placeholder="YYYY-MM-DDTHH:MM AM/PM"
               
                  className="w-full px-2 py-1 border border-gray-400 rounded-lg focus:outline-none focus:border-blue-500"
                 
                />
          </div>
         
          <div className="flex justify-center">
            <button
              type="submit"
              disabled={isUpdating} // Disable button when updating is in progress
              className={`w-full mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded ${isUpdating ? 'opacity-50 cursor-not-allowed' : ''}`}
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
    </>
  );
};

export default AssignTanker;