import React, { useState, useEffect } from 'react';
import config from '../../config';
import axios from 'axios';
import UpdateStatusPopup from './UpdateStatus';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faWater, faTruck, faTint, faSyncAlt, faTrashAlt, faEdit, faFileExcel, faFileExport, FaUndoAlt, faUndoAlt, faCheckCircle, faSync, faArrowAltCircleUp, faCogs, faRedo, faUpDown, faSave, faArrowCircleUp, faArrowUp, faArrowUp19, faArrowTrendUp, faCloudUpload, faUpload, faCloudUploadAlt } from '@fortawesome/free-solid-svg-icons';
// import upd from '../assests/update.png'
import AssignTanker from './AssignTanker';
import { toast } from 'react-toastify'; // Import react-toastify for popups
import 'react-toastify/dist/ReactToastify.css';
import * as XLSX from 'xlsx';
import jsPDF from "jspdf";
import "jspdf-autotable";
import FillingStationRow from './Tooltip';



const UpdateTankerStatus = ({ userData, token }) => {
  const [isUpdating, setIsUpdating] = useState(false);
  const getCurrentDateTime = () => {
    const now = new Date();
    const offset = now.getTimezoneOffset() * 60000; // Offset in milliseconds
    const localTime = new Date(now.getTime() - offset);
    return localTime.toISOString().slice(0, 16); // Format the date and time to match the datetime-local input format
  };

  const [date, setDate] = useState(getCurrentDateTime());

  const [tankerData, setTankerData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null); // State to keep track of the selected item
  const [vehicleModal, setVehicleModal] = useState(false);
  const [showStatusUpdate, setShowStatusUpdate] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1020);


  const [searchType, setSearchType] = useState('referenceNo');
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [suggestions, setSuggestions] = useState([]);

  const [error, setError] = useState('');
  const [showLoginForm, setShowLoginForm] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const isTimeKeeper = userData.user_role === 'Timekeeper';

  const isAdmin = userData.user_role === 'Admin'
  const isAE = userData.user_role === 'AE'


  //edit
  const [showEditModal, setShowEditModal] = useState(false);
  const openEditModal = (item) => {
    setSelectedItem(item); // Set the selected item
    setShowEditModal(true);
  };


  const closeEditModal = () => {
    setShowEditModal(false);
    setSelectedItem(null); // Reset the selected item when modal is closed
    fetchData()
  };




  //delete
  const [showConfirmation, setShowConfirmation] = useState(false);
  const handleDeleteClick = (item) => {
    setSelectedItem(item)
    setShowConfirmation(true);
  };

  const handleCancelDelete = () => {
    setSelectedItem(null);
    setShowConfirmation(false);
  };
  const handleDelete = async () => {
    setIsUpdating(true);
    try {
      const configToken = {
        headers: {
          Authorization: `Bearer ${token}`, // Set the Authorization header with the token
        },
      };
      const response = await fetch(`${config.apiUrl}/assign/deleteAssignTanker/${selectedItem.id}`, {
        method: 'DELETE',
        headers: configToken.headers,

      });
      if (response.ok) {
        // Remove the deleted item from the local state
        setTankerData(tankerData.filter(item => item.id !== selectedItem.id));
        setFilteredData(filteredData.filter(item => item.id !== selectedItem.id))
        // Call the backend API to delete the record
        await fetch(`${config.apiUrl}/newtanker/requestTanker/${selectedItem.id}`, {
          method: 'DELETE',
          headers: configToken.headers,
        });
        setIsUpdating(false); // Set updating status back to false after successful update
        toast.success('Deleted successfully');
        // Show success toast
        await new Promise((resolve) => setTimeout(resolve, 1000));
        handleCancelDelete();
      } else {
        setIsUpdating(false); // Set updating status back to false on error
        console.error('Error Deleting');
        toast.error('Error Deleting');
      }
    } catch (error) {
      setIsUpdating(false); // Set updating status back to false on error
      console.error('Error Deleting', error);
      toast.error('Error Deleting');
    }
  };


  const handleAssignDelete = async (items) => {
    setIsUpdating(true);
    try {
      const configToken = {
        headers: {
          Authorization: `Bearer ${token}`, // Set the Authorization header with the token
        },
      };
      const response = await fetch(`${config.apiUrl}/assign/deleteAssignTanker/${items.id}`, {
        method: 'DELETE',
        headers: configToken.headers,

      });
      if (response.ok) {
        // Remove the deleted item from the local state
        setTankerData(tankerData.filter(item => item.id !== items.id));
        setFilteredData(filteredData.filter(item => item.id !== items.id))
        // Call the backend API to delete the record

        setIsUpdating(false); // Set updating status back to false after successful update
        toast.success('Revert successfully');
        // Show success toast


      } else {
        setIsUpdating(false); // Set updating status back to false on error
        console.error('Error Reverting');
        toast.error('Error Reverting');
      }
    } catch (error) {
      setIsUpdating(false); // Set updating status back to false on error
      console.error('Error Reverting', error);
      toast.error('Error Reverting');
    }
  };

  const handleStatusUpdate = async () => {
    setIsUpdating(true);
    // console.log('sele',selectedRows);
    if (selectedRows.length > 0) {
      if (statusToUpdate) {
        try {
          const configToken = {
            headers: {
              Authorization: `Bearer ${token}`, // Set the Authorization header with the token
            },
          };
          const response = await axios.put(`${config.apiUrl}/assign/updateAllStatus`, { ids: selectedRows, statusToUpdate, date: date }, configToken);
          // console.log(response.data);
          setIsUpdating(false); // Set updating status back to false after successful update
          toast.success('Status updated successfully');
          fetchData()
          setSelectedRows([]);
          // Show success toast
          await new Promise((resolve) => setTimeout(resolve, 1000));
          handleUpdateAllClick();
          // Handle success response


        } catch (error) {
          setIsUpdating(false); // Set updating status back to false on error
          console.error('Error updating status:', error);
          toast.error('Error updating status');
        }
      } else {
        setIsUpdating(false);
        toast.error('Select Status');
      }
    } else {
      setIsUpdating(false); // Set updating status back to false on error
      console.error('Error updating status:');
      toast.error(' Select Row');
    }
  };




  const getStatus = (item) => {
    if (item.deliveredStatus) {
      return 'Delivered';
    } else if (item.pendingStatus) {
      return 'Pending';
    } else if (item.assignedStatus) {
      return 'Assigned';
    } else {
      return 'No Status';
    }
  };

  const handleSearch = (query) => {
    setSearchQuery(query);
    const formattedQuery = query.toLowerCase();

    const filtered = tankerData.filter(item => {
      switch (searchType) {
        case 'referenceNo':
          return item.id.toString().toLowerCase().includes(formattedQuery);
        case 'requestedDateTime':
          return `${item.request_date.split(' ')[0]} ${item.request_time}`.toLowerCase().includes(formattedQuery);
        case 'quantity':
          return item.requestedQuantity.toString().includes(query);
        case 'fillingStation':
          return item.fillingstation.toLowerCase().includes(formattedQuery);
        case 'officer':
          return item.officer.toLowerCase().includes(formattedQuery);
        case 'assignedTime':
          const formattedAssignedTime = formatDateTime(item.assignedTime).toLowerCase();
          return formattedAssignedTime.includes(formattedQuery);
        case 'status':
          return getStatus(item).toLowerCase().includes(formattedQuery);
        case 'vehicleNo':
          return item.vehicleNo.toLowerCase().includes(formattedQuery);
        case 'location':
          return item.locality.toLowerCase().includes(formattedQuery);
        default:
          return true;
      }
    });

    setFilteredData(filtered);
  };




  const handleSearchTypeChange = (type) => {
    setSearchType(type);
    setSearchQuery('');
    setFilteredData([]);

    let values = [];
    switch (type) {
      case 'referenceNo':
        values = tankerData.map(item => item.id);
        break;
      case 'requestedDateTime':
        values = tankerData.map(item => `${item.request_date.split(' ')[0]} ${item.request_time}`);
        break;
      case 'quantity':
        values = tankerData.map(item => item.requestedQuantity.toString());
        break;
      case 'fillingStation':
        values = tankerData.map(item => item.fillingstation);
        break;
      case 'officer':
        values = tankerData.map(item => item.officer);
        break;
      case 'assignedTime':
        values = tankerData.map(item => formatDateTime(item.assignedTime)); // Format assignedTime values
        break;
      case 'status':
        values = [...new Set(tankerData.map(item => getStatus(item)))];
        break;
      case 'vehicleNo':
        values = tankerData.map(item => item.vehicleNo);
        break;
      case 'location':
        values = tankerData.map(item => item.locality);
        break;
      default:
        break;
    }

    // Filter out duplicate values and set the suggestions
    const uniqueValues = [...new Set(values)];
    setSuggestions(uniqueValues);
  };


  useEffect(() => {
    handleSearchTypeChange(searchType);
  }, [tankerData, searchType]);



  const [selectedRows, setSelectedRows] = useState([]);
  const [statusToUpdate, setStatusToUpdate] = useState('');

  const handleCheckboxChange = (id) => {
    if (selectedRows.includes(id)) {
      setSelectedRows(selectedRows.filter(rowId => rowId !== id));
    } else {
      setSelectedRows([...selectedRows, id]);
    }
  };

  const handleUpdateAllClick = () => {
    setShowStatusUpdate(!showStatusUpdate);
    // console.log('Update All button clicked');
    if (!showStatusUpdate) {
      fetchData()
    }
    setStatusToUpdate('')
  };




  const openVehicleModal = (item) => {
    setSelectedItem(item); // Set the selected item
    setVehicleModal(true);
    // console.log('item',item);
  };

  const closeVehicleModal = (item) => {
    setSelectedItem(item); // Set the selected item
    setVehicleModal(false);
    // console.log('item',item);
  };


  useEffect(() => {


    fetchData();
  }, []);


  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1020);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); //

  // console.log('window.innerWidth',window.innerWidth,isMobile);

  const fetchData = async () => {
    // console.log('user_role', userData.user_role);
    const { user_first_name, user_last_name, user_role } = userData;
    const configToken = {
      headers: {
        Authorization: `Bearer ${token}`, // Set the Authorization header with the token
      },
    };// Assuming userData contains user_role, firstName, and lastName

    try {
      let url = `${config.apiUrl}/assign/getAssignedData`;

      // Append user_role, firstName, and lastName to the URL if available
      if (user_role === 'AE' && user_first_name && user_last_name) {
        url += `?user_role=${user_role}&firstName=${user_first_name}&lastName=${user_last_name}`;
      } else if (user_role !== 'AE') {
        url += `?user_role=${user_role}`;
      }

      const response = await fetch(url, {
        headers: configToken.headers,
      });
      const data = await response.json();
      // console.log('data', data);
      // Check if data is an array
      if (Array.isArray(data)) {
        setTankerData(data);
      } else {
        console.error('Error: Data is not in the expected format');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };


  // authentication start from this


  const openModal = (item) => {
    setSelectedItem(item); // Set the selected item
    setShowModal(true);

  };

  const closeModal = () => {
    setShowModal(false);
    setSelectedItem(null);
    fetchData();// Reset the selected item when modal is closed
  };


  const handlePdf = async () => {
    try {

      const configToken = {
        headers: {
          Authorization: `Bearer ${token}`, // Set the Authorization header with the token
        },
      };
      const response = await axios.post(`${config.apiUrl}/user/exportUser`, { loginIdentifier: username, password }, configToken);
      const { data, message } = response.data;
      if (response.status === 200) {
        if (data.user.user_role === 'Admin') {
          const doc = new jsPDF();

          // Add header row
          const header = [['S.No', 'ReferenceNo', 'Date', 'Qty', 'DriverName', 'DriverMobileNo', 'VehicleNo', 'Filling', 'Location', 'Officer', 'Assigned Time']];

          // Prepare data rows
          const dataRows = (filteredData.length > 0 ? filteredData : tankerData).map((item, index) => {
            // Define 'item' here or retrieve it from some other source
            // Extract address data from location_data
            let address = '';
            if (item.locality) {
             
              address = item.locality;
            }

            // Return row data
            return [
              index + 1,
              item.id,
              `${item.request_date.split(' ')[0]} ${item.request_time}`,
              item.requestedQuantity,
              item.driverName,
              item.driverMobileNo,
              item.vehicleNo,
              item.fillingstation,
              address, // Include address data
              item.username,
              `${item.assignedTime.split(' ')[0]} ${item.assignedTime.split(' ')[1]}`
              // Include vehicle details
            ];
          });

          // Generate PDF with the updated data rows
          doc.autoTable({
            head: header,
            body: dataRows,
            theme: 'grid', // Add grid theme
            styles: {
              fontSize: 7, // Set the font size to 8
              fontStyle: 'normal' // Set the font style to normal
            },
            columnStyles: {
              3: { cellWidth: 10 }, // Adjust column width for index 3
              5: { cellWidth: 22 } // Adjust column width for index 5
            }
          });

          // Save PDF
          doc.save('updatedData.pdf');
          setError('');
          setShowLoginForm(false);
        }
        else {
          setError('Unauthorized User');
        }
      }
      else {
        setError(message);
      }
    } catch (error) {
      setError('Failed to export Excel file');
    }
  };

  // const formatDateTime = (dateTime) => {
  // console.log('date',dateTime);
  //   const value = moment(dateTime).format('YYYY-MM-DD HH:mm:ss');
  // console.log('vale',value);
  //   return value; 

  // };
  const formatDateTime = (dateTime) => {
    // Split the date and time parts
    const [datePart, timePart] = dateTime.split('T');

    // Remove the milliseconds and 'Z' from the time part
    const formattedTimePart = timePart.split('.')[0];

    // Return the formatted date and time
    return `${datePart} ${formattedTimePart}`;
  };

  const handleExcel = async () => {
    try {
      const configToken = {
        headers: {
          Authorization: `Bearer ${token}`, // Set the Authorization header with the token
        },
      };
      const response = await axios.post(`${config.apiUrl}/user/exportUser`, { loginIdentifier: username, password }, configToken);
      const { data, message } = response.data;
      if (response.status === 200) {
        if (data.user.user_role === 'Admin') {
          const fileName = "updatedData.xlsx";
          const wsData = [
            [
              "S.No",
              "Reference No",
              "Requested Date",
              "Assigned Time",
              "Qty",
              "Filling Station",
              "Officer",
              "Location", // Add column for Location
              "Driver Name", // Add column for Driver Name
              "Driver No", // Add column for Driver Number
              "Vehicle No" // Add column for Vehicle Number
            ],
            ...(filteredData.length > 0 ? filteredData : tankerData).map((item, index) => [
              index + 1,
              item.id,
              `${item.request_date.split(' ')[0]} ${item.request_time}`,
              item.assignedTime,

              item.requestedQuantity,
              item.fillingstation,
              item.username,
              "", // Leave empty for Location (will fill later)
              "", // Leave empty for Driver Name (will fill later)
              "", // Leave empty for Driver Number (will fill later)
              "" // Leave empty for Vehicle Number (will fill later)
            ])
          ];

          // Fill location and vehicle data for each row
          wsData.slice(1).forEach((row, index) => {
            const selectedItem = (filteredData.length > 0 ? filteredData : tankerData)[index];
            if (selectedItem && selectedItem.locality) {
            
              row[wsData[0].indexOf("Location")] = selectedItem.locality;
            }
            if (selectedItem) {
              row[wsData[0].indexOf("Driver Name")] = selectedItem.driverName || "";
              row[wsData[0].indexOf("Driver No")] = selectedItem.driverMobileNo || "";
              row[wsData[0].indexOf("Vehicle No")] = selectedItem.vehicleNo || "";
            }
          });

          const ws = XLSX.utils.aoa_to_sheet(wsData);
          const wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, "updatedData");

          // Export the workbook
          XLSX.writeFile(wb, fileName);
          setError('');
          setShowLoginForm(false);
        }
        else {
          setError('Unauthorized User');
        }
      }
      else {
        setError(message);
      }
    } catch (error) {
      setError('Failed to export Excel file');
    }
  };
  const handleClose = () => {
    // Function to handle closing the login form
    setShowLoginForm(false);
    setUsername('');
    setPassword('');
    setError('');
  };

  return (

    <>
      <style>
        {`
    .status-update-container {
      transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
      transform: scale(0.8);
      opacity: 0;
    }
    
    .status-update-container.show {
      transform: scale(1);
      opacity: 1;
    }
     `}
      </style>
      <div className="container mx-auto">

        <div className=" w-full flex flex-col md:flex-row justify-between md:items-center mb-3 text-xs sm:text-sm">
          <div className="flex items-center justify-between">
            <p className="text-xl font-bold mb-2 md:mb-0">Tanker Status</p>
            {!showLoginForm && (
              <button className="mt-1 w-[40px] sm:hidden block md:mt-0 bg-green-500 hover:bg-green-600 text-white py-1 px-2 rounded-md" onClick={() => setShowLoginForm(true)}>
                <FontAwesomeIcon icon={faFileExport} className="mr-2" />
              </button>
            )}


          </div>

          <div className="flex sm:gap-2 md:gap-2 justify-end">
            <div className={`status-update-container ${showStatusUpdate ? 'show' : ''}`}>
              <div className="flex justify-center items-center sm:gap-2 md:gap-2 sm:mr-0 mr-2">
                <div className='flex sm:flex-row flex-col sm:gap-0  mr-2'>
                  <select
                    className="bg-white border border-gray-300 rounded-md px-2 py-1"
                    value={statusToUpdate}
                    onChange={(e) => setStatusToUpdate(e.target.value)}
                  >
                    <option value="">Status</option>
                    {(isAdmin || isTimeKeeper) && (
                      <option value="pendingStatus">Pending</option>
                    )}
                    {(isAdmin || isAE) && (
                      <option value="deliveredStatus">Delivered</option>)}
                    {/* Add other status options */}
                  </select>
                  <input
                    id="startDateTimeInput"

                    type="datetime-local"
                    value={date}
                    onChange={(e) => setDate(e.target.value)}
                    placeholder="YYYY-MM-DDTHH:MM AM/PM"

                    className="bg-white border border-gray-300 rounded-md px-1 sm-py-0 py-1"

                  />
                </div>
                <button
                  disabled={isUpdating}
                  className={`bg-blue-500 sm:mt-0 mt-6  hover:bg-blue-700 text-white py-1 px-2 rounded ${isUpdating ? 'opacity-50 cursor-not-allowed' : ''}`}
                  onClick={handleStatusUpdate}
                >
                  <FontAwesomeIcon icon={faUpload} />
                </button>
              </div>
            </div>
            <button
              onClick={handleUpdateAllClick}

              className={`bg-blue-500 hover:bg-blue-700 text-white text-sm  h-[50%] sm:full sm:mt-0 mt-6 rounded ${showStatusUpdate ? 'py-1 px-3' : 'py-1 px-2'}`}
            >
              {/* <FontAwesomeIcon className='mr-1' icon={faUpload}/> */}
              {showStatusUpdate ? 'Clear' : 'Update'}
            </button>
          </div>
        </div>
        <div className="flex flex-col md:flex-row mb-4 sm:gap-2 md:gap-2">
          <select
            value={searchType}
            onChange={(e) => handleSearchTypeChange(e.target.value)}
            className="mr-2 px-2 py-1 border border-gray-300 rounded mb-2 md:mb-0 w-full md:mr-0 md:px-3 md:py-2 md:w-40"
          >
            <option value="referenceNo">Reference No</option>
            <option value="requestedDateTime">Requested Date</option>
            <option value="quantity">Quantity</option>
            <option value="fillingStation">Filling Station</option>
            <option value="assignedTime">Assigned Time</option>
            <option value="status">Status</option>
            <option value="vehicleNo">Vehicle</option>
            <option value="location">Locatlity</option>
          </select>
          <div className="flex-grow">
            <input
              type="text"
              value={searchQuery}
              onChange={(e) => handleSearch(e.target.value)}
              placeholder="Search..."
              className="px-1 py-1 border border-gray-300 rounded w-full md:w-auto"
              list="suggestions"
            />
            <datalist id="suggestions">
              {suggestions.map((item, index) => (
                <option key={index} value={item} />
              ))}
            </datalist>
          </div>
          {!showLoginForm && (
            <button className="mt-1 w-[40px] sm:block hidden md:mt-0 bg-green-500 hover:bg-green-600 text-white py-1 px-2 rounded-md" onClick={() => setShowLoginForm(true)}>
              <FontAwesomeIcon icon={faFileExport} className="mr-2" />
            </button>
          )}
        </div>


        {!isMobile ? (<div className="w-full h-[60vh] overflow-x-auto  overflow-y-auto ">
          <table id='updatedData' className=" w-full">
            <thead className='sticky top-0'>
              <tr className=' text-white text-left' style={{ backgroundColor: 'rgb(33,64,154)' }}>
                <th className="px-3 py-3 text-xs font-semibold uppercase ">S.No</th>
                <th className=" "></th>
                <th className="p-3 text-xs font-semibold uppercase ">Reference</th>
                <th className="p-3 text-xs font-semibold uppercase "> Date</th>
                <th className="p-3 text-xs font-semibold uppercase ">Qty</th>
                <th className="p-3 text-xs font-semibold uppercase ">Vehicle</th>
                <th className="p-3 text-sm font-semibold uppercase "><FontAwesomeIcon icon={faTint} /></th>
                <th className="p-3 text-xs font-semibold uppercase "> Locality</th>
                <th className="p-3 text-xs font-semibold uppercase ">Officer</th>
                <th className="p-3 text-xs font-semibold uppercase ">Assigned Time</th>
                <th className="p-3 text-xs font-semibold uppercase ">Status</th>
                <th className="p-3 text-xs font-semibold uppercase "></th>
                {(isAdmin || isTimeKeeper) && <th className="p-3 text-xs font-semibold uppercase"></th>}
                {isAdmin && (
                  <>

                    <th className="p-3 text-xs font-semibold uppercase"></th>
                    <th className="p-3 text-xs font-semibold uppercase"></th>
                  </>
                )}

              </tr>
            </thead>
            <tbody>
              {filteredData.length > 0 ? (
                filteredData.map((item, index) => (
                  <tr key={item.id} className='text-left border-b-2'>
                    <td className="px-3 py-3 text-sm">{index + 1}</td>
                    <td><input type="checkbox" onChange={() => handleCheckboxChange(item.id)} checked={selectedRows.includes(item.id)} /></td>
                    <td className="p-3 text-xs">{item.id}</td>
                    <td className="p-3 text-xs">{item.request_date.split(' ')[0]} {item.request_time}</td>
                    <td className="p-3 text-xs">{item.requestedQuantity}</td>
                    <td className="p-3 "><FontAwesomeIcon icon={faTruck} className="cursor-pointer  text-blue-500 hover:text-blue-700 text-xs " onClick={() => openVehicleModal(item)} /> {item.vehicleNo}</td>
                    <FillingStationRow item={item.fillingstation} icon={faTint} id={item.id} styl={`p-3 text-sm text-blue-500`} />
                    <td className="p-3 text-sm">
                      <FontAwesomeIcon
                        icon={faMapMarkerAlt}
                        className="cursor-pointer text-blue-500 mr-1"
                      // onClick={() => openLocationModal(item)}
                      /> {item.locality}
                    </td>
                    <td className="p-3 text-xs"> {item.username}</td> {/* Officer Name */}
                    <td className="p-3 text-xs">
                      {/* {item.assignedTime.split(' ')[0]} {item.assignedTime.split(' ')[1]} */}
                      {formatDateTime(item.assignedTime)}
                    </td>
                    <td className="p-3 text-xs">
                      {getStatus(item)}
                    </td>

                    <td className="p-3 ">
                      <FontAwesomeIcon icon={faUpload} onClick={() => openModal(item)} className="text-blue-500 hover:text-blue-700 " />
                    </td>
                    {
                      (isAdmin || isTimeKeeper) && <td>
                        <FontAwesomeIcon
                          disabled={isUpdating}
                          icon={faUndoAlt}
                          // className="cursor-pointer text-blue-500"
                          onClick={() => handleAssignDelete(item)}
                          className={`text-red-500 hover:text-red-600  rounded ${isUpdating ? 'opacity-50 cursor-not-allowed' : ''}`}
                        />

                      </td>
                    }
                    {isAdmin && (<>
                      <td className="px-3 py-3 text-sm">
                        <FontAwesomeIcon
                          icon={faEdit}
                          className="cursor-pointer text-blue-500"
                          onClick={() => openEditModal(item)}
                        />
                      </td>


                      <td className="px-3 py-3 text-sm">
                        <FontAwesomeIcon
                          icon={faTrashAlt}
                          className="cursor-pointer text-red-500"
                          onClick={() => handleDeleteClick(item)}
                        />
                      </td>
                    </>
                    )}
                  </tr>
                ))
              ) : (
                tankerData.map((item, index) => (
                  <tr key={item.id} className='text-left border-b-2'>
                    <td className="px-3 py-3 text-sm">{index + 1}</td>
                    <td><input type="checkbox" onChange={() => handleCheckboxChange(item.id)} checked={selectedRows.includes(item.id)} /></td>
                    <td className="p-3 text-xs">{item.id}</td>
                    <td className="p-3 text-xs">{item.request_date.split(' ')[0]} {item.request_time}</td>
                    <td className="p-3 text-xs">{item.requestedQuantity}</td>
                    <td className="p-3 text-xs"><FontAwesomeIcon icon={faTruck} className="cursor-pointer  text-blue-500 hover:text-blue-700" onClick={() => openVehicleModal(item)} /> {item.vehicleNo}</td>
                    <FillingStationRow item={item.fillingstation} icon={faTint} id={item.id} styl={`p-3 text-sm text-blue-500`} />
                    <td className="p-3 text-sm">
                      <FontAwesomeIcon
                        icon={faMapMarkerAlt}
                        className="cursor-pointer text-blue-500 mr-1"
                      // onClick={() => openLocationModal(item)}
                      />{item.locality}
                    </td>
                    <td className="p-3 text-xs"> {item.username}</td> {/* Officer Name */}
                    <td className="p-3 text-xs">
                      {/* {item.assignedTime.split('T')[0]} {item.assignedTime.split('T')[1,8]} */}
                      {formatDateTime(item.assignedTime)}
                    </td>
                    <td className="p-3 text-xs">
                      {getStatus(item)}
                    </td>

                    <td className="p-3 ">
                      <FontAwesomeIcon icon={faUpload} onClick={() => openModal(item)} className="text-blue-500 hover:text-blue-700 " />
                    </td>
                    {
                      (isAdmin || isTimeKeeper) && <td>
                        <FontAwesomeIcon
                          disabled={isUpdating}
                          icon={faUndoAlt}
                          // className="cursor-pointer text-blue-500"
                          onClick={() => handleAssignDelete(item)}
                          className={`text-red-500 hover:text-red-600  rounded ${isUpdating ? 'opacity-50 cursor-not-allowed' : ''}`}
                        />

                      </td>
                    }
                    {isAdmin && (<>
                      <td className="px-3 py-3 text-sm">
                        <FontAwesomeIcon
                          icon={faEdit}
                          className="cursor-pointer text-blue-500"
                          onClick={() => openEditModal(item)}
                        />
                      </td>

                      <td className="px-3 py-3 text-sm">
                        <FontAwesomeIcon
                          icon={faTrashAlt}
                          className="cursor-pointer text-red-500"
                          onClick={() => handleDeleteClick(item)}
                        />
                      </td>
                    </>
                    )}
                  </tr>
                ))
              )}
            </tbody>


          </table>

        </div>) : (
          <div className="w-full h-[45vh] md:h-[55vh] sm:h-[55vh] overflow-y-auto text-xs sm:text-sm md:text-base lg:text-lg xl:text-xl">


            {filteredData.length > 0 ? (


              <div className="grid grid-cols-1 p-2 gap-4">
                {filteredData.map((item, index) => (
                  <div key={index} className=" w-[95%] border border-10 border-gray-300 rounded-md  transform transition duration-300 ease-in-out hover:scale-105  bg-white" style={{ boxShadow: 'rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px' }}>

                    <table className="w-full text-gray-700 ">
                      <tbody>
                        <tr className="">
                          <td className="font-semibold px-2 py-2 text-xs sm:text-sm md:text-md uppercase">Reference No</td>
                          <td className="px-2 py-2 text-xs sm:text-sm md:text-md font-semibold">{item.id}</td>
                        </tr>
                        <tr>
                          <td className="font-semibold px-2 py-2 text-xs sm:text-sm md:text-md uppercase">Request Date</td>
                          <td className="px-2 py-2 text-xs sm:text-sm md:text-md font-semibold">{item.request_date.split(' ')[0]}</td>
                        </tr>
                        <tr>
                          <td className="font-semibold px-2 py-2 text-xs sm:text-sm md:text-md uppercase">Request Time</td>
                          <td className="px-2 py-2 text-xs sm:text-sm md:text-md font-semibold">{item.request_time}</td>
                        </tr>
                        <tr>
                          <td className="font-semibold px-2 py-2 text-xs sm:text-sm md:text-md uppercase">Quantity</td>
                          <td className="px-2 py-2 text-xs sm:text-sm md:text-md font-semibold">{item.requestedQuantity}</td>
                        </tr>
                        <tr>
                          <td className="font-semibold px-2 py-2 text-xs sm:text-sm md:text-md uppercase">Officer</td>
                          <td className="px-2 py-2 text-xs sm:text-sm md:text-md font-semibold">{item.username}</td>
                        </tr>
                        <tr>
                          <td className="font-semibold px-2 py-2 text-xs sm:text-sm md:text-md uppercase">Filling Station</td>
                          <td className="px-2 py-2 text-xs sm:text-sm md:text-md font-semibold">{item.fillingstation}</td>
                        </tr>
                        <tr>
                          <td className="font-semibold px-2 py-2 text-xs sm:text-sm md:text-md uppercase">Assigned Time</td>
                          <td className="px-2 py-2 text-xs sm:text-sm md:text-md font-semibold">{formatDateTime(item.assignedTime)}</td>
                        </tr>
                        <tr>
                          <td className="font-semibold px-2 py-2 text-xs sm:text-sm md:text-md uppercase">Location</td>
                          <td className="px-2 py-2 text-xs sm:text-sm md:text-md font-semibold">
                            <FontAwesomeIcon
                              icon={faMapMarkerAlt}
                              className="cursor-pointer text-blue-500 mr-2"
                            //  onClick={() => openLocationModal(item)}
                            />{item.locality}
                          </td>
                        </tr>
                        <tr>
                          <td className="font-semibold px-2 py-2 text-xs sm:text-sm md:text-md uppercase">Vehicle</td>
                          <td className="px-2 py-2 text-xs sm:text-sm md:text-md font-semibold">
                            <FontAwesomeIcon
                              icon={faTruck}
                              className="cursor-pointer  text-blue-500 hover:text-blue-700  mr-2"
                              onClick={() => openVehicleModal(item)}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td className="font-semibold px-2 py-2 text-xs sm:text-sm md:text-md uppercase">Status</td>
                          <td className="px-2 py-2 text-xs sm:text-sm md:text-md font-semibold">{getStatus(item)}</td>
                        </tr>
                      </tbody>
                    </table>
                    <div className="flex items-center justify-end px-4 py-2">
                      <div className="flex items-center">
                        <input type="checkbox" className='mr-4' onChange={() => handleCheckboxChange(item.id)} checked={selectedRows.includes(item.id)} />
                        {
                          (isAdmin || isTimeKeeper) &&
                          <FontAwesomeIcon
                            disabled={isUpdating}
                            icon={faUndoAlt}
                            // className="cursor-pointer text-blue-500"
                            onClick={() => handleAssignDelete(item)}
                            className={`text-red-500 hover:text-red-600 mr-4  rounded ${isUpdating ? 'opacity-50 cursor-not-allowed' : ''}`}
                          />


                        }
                        {isAdmin && (<>
                          <FontAwesomeIcon
                            icon={faEdit}
                            className="cursor-pointer text-blue-500 mr-4"
                            onClick={() => openEditModal(item)}
                          />
                          <FontAwesomeIcon
                            icon={faTrashAlt}
                            className="cursor-pointer text-red-500 mr-4"
                            onClick={() => handleDeleteClick(item)}
                          />
                        </>
                        )}
                      </div>
                      <button onClick={() => openModal(item)} className="bg-blue-500 text-white py-1 px-2 rounded-full transition duration-300 ease-in-out">Update</button>
                    </div>
                  </div>
                ))}
              </div>



            ) : (

              <div className="grid grid-cols-1 gap-4 p-2">
                {tankerData.map((item, index) => (
                  <div key={index} className=" w-[95%] border border-10 border-gray-300 rounded-md  transform transition duration-300 ease-in-out hover:scale-105  bg-white" style={{ boxShadow: 'rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px' }}>

                    <table className="w-full text-gray-700 ">
                      <tbody>
                        <tr className="">
                          <td className="font-semibold px-2 py-2 text-xs uppercase">Reference No</td>
                          <td className="px-2 py-2 text-xs sm:text-sm md:text-md font-semibold">{item.id}</td>
                        </tr>
                        <tr>
                          <td className="font-semibold px-2 py-2 text-xs sm:text-sm md:text-md uppercase">Request Date</td>
                          <td className="px-2 py-2 text-xs sm:text-sm md:text-md font-semibold">{item.request_date.split(' ')[0]}</td>
                        </tr>
                        <tr>
                          <td className="font-semibold px-2 py-2 text-xs sm:text-sm md:text-md uppercase">Request Time</td>
                          <td className="px-2 py-2 text-xs sm:text-sm md:text-md font-semibold">{item.request_time}</td>
                        </tr>
                        <tr>
                          <td className="font-semibold px-2 py-2 text-xs sm:text-sm md:text-md uppercase">Quantity</td>
                          <td className="px-2 py-2 text-xs sm:text-sm md:text-md font-semibold">{item.requestedQuantity}</td>
                        </tr>
                        <tr>
                          <td className="font-semibold px-2 py-2 text-xs sm:text-sm md:text-md uppercase">Officer</td>
                          <td className="px-2 py-2 text-xs sm:text-sm md:text-md font-semibold">{item.username}</td>
                        </tr>
                        <tr>


                          <td className="font-semibold px-2 py-2 text-xs sm:text-sm md:text-md uppercase">Filling Station</td>
                          <td className="px-2 py-2 text-xs sm:text-sm md:text-md font-semibold">{item.fillingstation}</td>
                        </tr>
                        <tr>
                          <td className="font-semibold px-2 py-2 text-xs sm:text-sm md:text-md uppercase">Assigned Time</td>
                          <td className="px-2 py-2 text-xs sm:text-sm md:text-md font-semibold">{formatDateTime(item.assignedTime)}</td>
                        </tr>
                        <tr>
                          <td className="font-semibold px-2 py-2 text-xs sm:text-sm md:text-md uppercase">Location</td>
                          <td className="px-2 py-2 text-xs sm:text-sm md:text-md font-semibold">
                            <FontAwesomeIcon
                              icon={faMapMarkerAlt}
                              className="cursor-pointer text-blue-500 mr-2"
                            // onClick={() => openLocationModal(item)}
                            />{item.locality}
                          </td>
                        </tr>
                        <tr>
                          <td className="font-semibold px-2 py-2 text-xs sm:text-sm md:text-md uppercase">Vehicle</td>
                          <td className="px-2 py-2 text-xs sm:text-sm md:text-md font-semibold">
                            <FontAwesomeIcon
                              icon={faTruck}
                              className="cursor-pointer  text-blue-500 hover:text-blue-700  mr-2"
                              onClick={() => openVehicleModal(item)}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td className="font-semibold px-2 py-2 text-xs sm:text-sm md:text-md uppercase">Status</td>
                          <td className="px-2 py-2 text-xs sm:text-sm md:text-md font-semibold">{getStatus(item)}</td>
                        </tr>
                      </tbody>
                    </table>
                    <div className="flex items-center justify-end px-4 py-2">
                      <div className="flex items-center">
                        <input type="checkbox" className='mr-4' onChange={() => handleCheckboxChange(item.id)} checked={selectedRows.includes(item.id)} />
                        {(isAdmin || isTimeKeeper) &&
                          <FontAwesomeIcon
                            disabled={isUpdating}
                            icon={faUndoAlt}
                            // className="cursor-pointer text-blue-500"
                            onClick={() => handleAssignDelete(item)}
                            className={`text-red-500 hover:text-red-600 mr-4  rounded ${isUpdating ? 'opacity-50 cursor-not-allowed' : ''}`}
                          />
                        }
                        {isAdmin && (<>
                          <FontAwesomeIcon
                            icon={faEdit}
                            className="cursor-pointer text-blue-500 mr-4"
                            onClick={() => openEditModal(item)}
                          />
                          <FontAwesomeIcon
                            icon={faTrashAlt}
                            className="cursor-pointer text-red-500 mr-4"
                            onClick={() => handleDeleteClick(item)}
                          />
                        </>
                        )}
                      </div>
                      <button onClick={() => openModal(item)} className="bg-blue-500 text-white py-1 px-2 rounded-full transition duration-300 ease-in-out">Update</button>
                    </div>
                  </div>









                ))}
              </div>


            )}


          </div>



        )}


        {showModal && (
          <div className="fixed inset-0 z-40 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
            <div className="fixed inset-0 bg-black bg-opacity-50"></div>
            <div className="relative w-auto max-w-3xl mx-auto my-4">

              <div className="bg-white p-6 rounded-lg shadow-md ">
                <button onClick={closeModal} className="absolute top-2 right-0 mr-4 text-gray-700 hover:text-gray-900">
                  <svg className="h-6 w-6 text-red-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
                <UpdateStatusPopup data={selectedItem} onClose={closeModal} user_role={userData.user_role}
                />
              </div>
            </div>
          </div>
        )}

        {vehicleModal && (
          <div className="fixed inset-0 z-40 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
            <div className="fixed inset-0 bg-black bg-opacity-50"></div>
            <div className="relative w-auto max-w-3xl mx-auto my-4">
              {/* Modal content */}
              <div className="bg-white p-8 rounded-lg shadow-md">
                <div className="flex justify-between items-center mb-4">
                  <h3 className="text-lg font-semibold uppercase">Vehicle</h3>
                  <button onClick={closeVehicleModal} className="text-gray-700 hover:text-gray-900">
                    <svg className="h-6 w-6 text-red-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </button>
                </div>
                <div className="overflow-y-auto max-h-48"> {/* Limit the height of the content and add scroll if needed */}
                  <table className="w-full">
                    <tbody>
                      <tr className="text-left mb-2 mt-2 p-2">
                        <td className="font-semibold uppercase text-xs pr-4">Driver Name</td>
                        <td className="pr-4">:</td>
                        <td>{selectedItem.driverName}</td>
                      </tr>
                      <tr className="text-left mb-2 mt-2 p-2">
                        <td className=" font-semibold uppercase text-xs pr-4">Driver No</td>
                        <td className="pr-4">:</td>
                        <td>{selectedItem.driverMobileNo}</td>
                      </tr>
                      <tr className="text-left mb-2 mt-2 p-2">
                        <td className="font-semibold text-xs uppercase pr-4">Vehicle No</td>
                        <td className="pr-4">:</td>
                        <td>{selectedItem.vehicleNo}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        )}

        {showConfirmation && (
          <div className="fixed inset-0 z-40 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
            <div className="fixed inset-0 bg-black bg-opacity-50"></div>
            <div className="relative sm:w-auto w-[80vw] max-w-3xl mx-auto my-4">
              <div className="bg-white p-6 rounded-lg shadow-md">

                <p className="mb-3">Do you want to delete this request {`${selectedItem.id}`}?</p>
                <div className="flex justify-center space-x-3">
                  <button
                    disabled={isUpdating}
                    onClick={handleDelete}
                    className={`bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded ${isUpdating ? 'opacity-50 cursor-not-allowed' : ''}`}
                  >
                    Yes
                  </button>
                  <button
                    onClick={handleCancelDelete}
                    className="bg-gray-400 hover:bg-gray-500 text-white px-4 py-2 rounded"
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}


        {showEditModal && (
          <div className="fixed inset-0 z-40 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
            <div className="fixed inset-0 bg-black bg-opacity-50"></div>
            <div className="relative w-auto max-w-3xl mx-auto my-4">

              <div className="bg-white p-2 rounded-lg shadow-md">
                <button onClick={closeEditModal} className="absolute top-2 right-0 mr-4 text-gray-700 hover:text-gray-900">
                  <svg className="h-6 w-6 text-red-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
                <AssignTanker
                  data={selectedItem}
                  onClose={closeEditModal}
                />
              </div>
            </div>
          </div>
        )}

        {showLoginForm && (
          <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
            <div className="bg-white w-full max-w-xs md:max-w-lg rounded-lg overflow-hidden shadow-xl">
              <div className="px-6 py-4">
                <div className="flex justify-end">
                  <button className="text-gray-700 hover:text-gray-900" onClick={handleClose}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </button>
                </div>
                <label htmlFor="username" className="text-gray-700">Username</label>
                <input
                  type="text"
                  id="username"
                  placeholder="Username or Mobile Number"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  className="border border-gray-400 px-3 py-2 rounded-md mb-2 w-full"
                />
                <label htmlFor="password" className="text-gray-700">Password</label>
                <input
                  type="password"
                  id="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="border border-gray-400 px-3 py-2 rounded-md mb-2 w-full"
                />
                <button
                  onClick={handleExcel}
                  className="bg-green-500 hover:bg-green-600 text-white py-2 px-4 rounded-md mr-2"
                >
                  Excel
                </button>
                <button
                  onClick={handlePdf}
                  className="bg-green-500 hover:bg-green-600 text-white py-2 px-4 rounded-lg mr-2"
                >
                  PDF
                </button>

                {error && <p className="text-red-500 mt-2">{error}</p>}
              </div>
            </div>
          </div>
        )}


      </div>
    </>
  );
};

export default UpdateTankerStatus;






