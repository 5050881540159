import React, { useState } from "react";
import axios from "axios";
import Background1 from "../assests/per.jpg";
import bg from "../assests/myy.png";
import { useNavigate } from "react-router-dom";
import config from "../config";
import logo1 from '../assests/mc.png';
import lock from '../assests/lock.png';
import lockopen from '../assests/lock open.png';

const Login = () => {
  const [loginData, setLoginData] = useState({
    loginIdentifier: "",
    password: "",
  });
  const navigate = useNavigate();
  const [alertMessage, setAlertMessage] = useState(null);
  const [color, setcolor] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLoginData({ ...loginData, [name]: value.trim() });
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setIsButtonDisabled(true);
    try {
      const response = await axios.post(
        `${config.apiUrl}/log/login`,
        loginData
      );
      if (response.data.status === 200) {
        setAlertMessage("Login Successful!");
        setcolor(true);
        setTimeout(() => {
          setAlertMessage(null);
          sessionStorage.setItem("user", JSON.stringify(response.data.data));
          sessionStorage.setItem('authentication',true);

          const userRole = response.data.data.user.user_role;
          // console.log(userRole);

          const redirectPath = getRedirectPathBasedOnRole(userRole);
          navigate(redirectPath);
          navigate("/dashboard/CardComponent");
          setIsButtonDisabled(false);
        }, 2000);
      }
    } catch (error) {
      console.error("Login failed:", error);
      if (error.response && error.response.status === 401) {
        const errorMessage = error.response.data.message;
        if (errorMessage === "Invalid username or password") {
          setAlertMessage("Invalid username or password");
        } else if (errorMessage === "Invalid username") {
          setAlertMessage("Invalid username or password");
        } else if (errorMessage === "Invalid password") {
          setAlertMessage("Invalid password.");
        } else {
          setAlertMessage("Invalid username or password");
        }
        setTimeout(() => {
          setAlertMessage(null);
          setIsButtonDisabled(false);
        }, 2000);
      } else {
        console.error("An unexpected error occurred:", error);
        setAlertMessage("An unexpected error occurred. Please try again.");
        setTimeout(() => {
          setAlertMessage(null);
          setIsButtonDisabled(false);
        }, 2000);
      }
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const getRedirectPathBasedOnRole = (userRole) => {
    switch (userRole) {
      case "Billing Staff":
        return "/billing";
      case "PatientCare":
        return "/frontoffice";
      case "Purchase Staff":
        return "/purchasing";
      case "Pharmacy Staff":
        return "/pharmacy";
      case "Admin":
        return "/dashboard";
      case "Doctor":
        return "/doctor";
      case "OP Nurse":
        return "/dashboard";
      default:
        return "/homeapp";
    }
  };

  const alertStyle = {
    position: "fixed",
    bottom: "48px",
    left: "50%",
    transform: "translateX(-50%)",
    backgroundColor: color ? "transparent" : "red",
    color: "white",
    padding: "10px",
    borderRadius: "5px",
    boxShadow: "0 0 10px rgba(255, 255, 255, 1)",
    zIndex: "9999",
    display: alertMessage ? "block" : "none",
    opacity: alertMessage ? 1 : 0,
    transition: "opacity 0.5s ease-in-out",
  };

  return (
    <div className="relative">
       <style>
        {`
       @media only screen and (max-width: 767px) {
       
      }
@media screen and (min-width: 1200px) {
  .try {
     width:950px;
  }
       
        `}
      </style>
      <div
        style={{
          backgroundImage: `url(${bg})`,
          backgroundSize: "100% 100%",
          minHeight: "100vh",
          backgroundRepeat: "no-repeat",
          fontFamily: "serif",
        }}
      ></div>

      <div className="absolute top-0 left-0 w-full h-full flex flex-column justify-center items-center">
        <div>
          <div className="flex justify-center items-center">
            <img className="h-20 W-24" src={logo1} alt="Logo" />
          </div>

          <div
            className="try p-4 mt-10 border-5"
            style={{
              // height: "400px",
              boxShadow: "0 0 10px rgba(255, 255, 255, 1)",
              backgroundImage: `url(${Background1})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "100% 100%",
            }}
          >
            <div>
              <div className="text-center text-gray-200 text-3xl">
                <h1>  
                  <b>Water Supply Tanker Fleet Management</b>
                </h1>
              </div>

              <div className="flex justify-end ">
                <div>
                  <div className="text-center text-gray-100 m-4">
                    <h2 className="text-3xl mt-8 mb-0">
                      <b>User Login</b>
                    </h2>
                    <p className="text-xl">
                      Welcome back! Please enter your details
                    </p>
                  </div>
                  <div style={alertStyle}>{alertMessage}</div>

                  <form onSubmit={handleLogin} className="text-center">
                    <div className="">
                      <label htmlFor="loginIdentifier">
                        <b className="text-lg text-white">
                          User Name / Mobile Number
                        </b>
                      </label>
                      <br />
                      <input
                        type="text"
                        id="loginIdentifier"
                        name="loginIdentifier"
                        placeholder="User Name or Mobile Number"
                        className="p-3 rounded w-full" /* Updated width */
                        value={loginData.loginIdentifier}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="mb-4 relative">
  <label htmlFor="password" className="form-label">
    <b className="text-lg text-white">Password</b>
  </label>
  <br />
  <div className="relative">
    <input
      type={showPassword ? 'text' : 'password'}
      id="password"
      name="password"
      placeholder="Password"
      className="p-3 rounded w-full" /* Updated width */
      value={loginData.password}
      onChange={handleChange}
      required
    />
    <button
      type="button"
      onClick={togglePasswordVisibility}
      className="absolute right-2 top-2 focus:outline-none"
      style={{ width: '24px', height: '24px' }} /* Adjust size as needed */
    >
      {showPassword ? (
        <img src={lockopen} alt="Open" style={{ width: '100%', height: '100%' }} />
      ) : (
        <img src={lock} alt="Closed" style={{ width: '100%', height: '100%' }} />
      )}
    </button>
  </div>
</div>
                    <div className="text-center">
                      <button
                        type="submit"
                        className="btn bg-shitw text-white text-lg p-2 rounded"
                        style={{
                          boxShadow: "0 0 10px rgba(255, 255, 255, 1)",
                        }}
                        disabled={isButtonDisabled}
                      >
                        Sign in
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;