import React, { useState, useEffect } from 'react';
import axios from 'axios';
import config from '../config';
import DonutChartApex from './DonutChart';
import BarChart from './Barchart';
import LoadingSpinner from './Loading';

const CardComponent = () => {
  const [countdata, setCountData] = useState(null);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [loading, setLoading] = useState(true); // Add loading state
  const [barChartData, setBarChartData] = useState(null)
  const [fillingstationdata, setFillingStationData] = useState(null)
  const [selectedVehicle, setSelectedVehicle] = useState('10000'); // State variable to store selected vehicle
  const [user,setUser] = useState([]);
  const[usertoken,setusertoken]=useState()
  
  useEffect(() => {
    const userData = JSON.parse(sessionStorage.getItem("user"));
    if (userData) {
      setUser(userData.user);
      setusertoken(userData.token);
    }
  }, []);
  
  useEffect(() => {
    if (user.length !== 0) {
      fetchData();
      fetchBarchartData();
      fetchfillingStationData();
    }
  }, [selectedVehicle, user]);
  
  

  const handleVehicleChange = async (event) => {
    setLoading(true); // Set loading to true when dropdown value changes
    setSelectedVehicle(event.target.value);

    setTimeout(() => {
      setLoading(false); // Set loading back to false after 3 seconds
    }, 3000); 
  };


  const formatNumber = (num) => {
    if (num === null){
      return 0;
    }
    if (Math.abs(num) >= 1.0e+9) {
      return (Math.abs(num) / 1.0e+9).toFixed(1) + 'b';
    }
    if (Math.abs(num) >= 1.0e+6) {
      return (Math.abs(num) / 1.0e+6).toFixed(1) + 'm';
    }
    if (Math.abs(num) >= 1.0e+3) {
      return (Math.abs(num) / 1.0e+3).toFixed(1) + 'k';
    }
    return num.toString(); // Return as string if no formatting is needed
  };


  const fetchfillingStationData = async () => {
    setFillingStationData(null)
    try {
       const token = usertoken ? usertoken : null;
      // console.log(token); // Get the token from sessionStorage
      const configToken = {
        headers: {
          Authorization: `Bearer ${token}`, // Set the Authorization header with the token
        },
      };
      const response = await axios.get(`${config.apiUrl}/zonewards/fillingstationName`,configToken);
      // console.log('resfill', response.data);
      setFillingStationData(response.data.FillingStation);
console.log("name", response.data.FillingStation);
      setTimeout(() => {
        setLoading(false); // Set loading back to false after 3 seconds
      }, 3000);  // Update loading state
    } catch (error) {
      console.error('Error fetching data:', error);
      setFillingStationData(null);
      setTimeout(() => {
        setLoading(false); // Set loading back to false after 3 seconds
      }, 3000);  // Update loading state even in case of error
    }
  };

  const fetchBarchartData = async () => {
    setBarChartData(null); // Reset bar chart data before fetching new data
    setLoading(true); // Set loading state to true
  
    try {
      const { user_role, user_first_name, user_last_name } = user;
      const token = usertoken ? usertoken : null;
  // Get the token from sessionStorage
      const configToken = {
        headers: {
          Authorization: `Bearer ${token}`, // Set the Authorization header with the token
        },
      };
  
      const response = await axios.get(`${config.apiUrl}/assign/barChartData`, {
        params: {
          quantity: selectedVehicle,
          user_role: user_role,
          firstName: user_first_name,
          lastName: user_last_name,
        },
        headers: configToken.headers,
      });
  
      // Check if the response data is valid and update state accordingly
      if (response && response.data) {
        setBarChartData(response.data);
      } else {
        throw new Error('Invalid data received from the server');
      }
    } catch (error) {
      console.error('Error fetching bar chart data:', error);
      setBarChartData(null); // Reset bar chart data in case of error
    } finally {
      setLoading(false); // Always update loading state after API call completes
    }
  };
  
  const fetchData = async () => {
    setCountData(null); // Reset count data before fetching new data
    setLoading(true); // Set loading state to true
    // console.log('user',user.user_role);
    const {user_role,user_first_name,user_last_name} = user
  
    try {
       const token = usertoken ? usertoken : null;
      // Get the token from sessionStorage
          const configToken = {
            headers: {
              Authorization: `Bearer ${token}`, // Set the Authorization header with the token
            },
          };
      const response = await axios.get(`${config.apiUrl}/assign/dashboardReport`, {
        params: {
          startDate: formatDate(startDate),
          endDate: formatDate(endDate),
          quantity: selectedVehicle,
          user_role: user_role,
          firstName:user_first_name,
          lastName: user_last_name,
        },
        headers: configToken.headers,
      });
  
      setCountData(response.data[0]); // Set count data from API response
      // console.log('response.data[0]',response.data[0]);
    } catch (error) {
      console.error('Error fetching data:', error);
      // Handle error state or display error message as needed
    } finally {
      setLoading(false); // Always update loading state after API call completes
    }
  };
  
  const formatDate = (date) => {
    return date.toISOString().split('T')[0];
  };


  return (
    <div className="container h-[87vh] overflow-y-auto">
    <div className="w-full flex justify-center items-center ">
      <label htmlFor="vehicle" className="mr-2 uppercase font-semibold text-gray-700">Vehicle</label>
      <select
        id="vehicle"
        name="vehicle"
        value={selectedVehicle}
        onChange={handleVehicleChange}
        className="border border-gray-300 rounded-md p-2"
      >
        <option value='10000'>Lorry</option>
        <option value="6000">Tractor</option>
        <option value="">All</option>
      </select>
    </div>
    {loading && <LoadingSpinner loading={loading} />}

    {!loading && countdata && fillingstationdata && (
      <div className="container items-center justify-center flex flex-wrap sm:gap-4 gap-1 w-full">
        <div className="w-full sm:w-1/5 border border-gray-300 rounded-md transform transition duration-300 ease-in-out hover:scale-105 bg-white shadow-lg">
          <div className="bg-white rounded-lg shadow-md p-2">
            <h2 className="text-lg font-semibold text-gray-500 mb-2 text-center">Request</h2>
            <div className="grid grid-cols-2 sm:gap-2 gap-1 text-center">
              <div>Count</div>
              <div>{countdata.totalRequests}</div>
              <div>Liters</div>
              <div>{formatNumber(countdata.overallRequestedQuantity)}</div>
            </div>
          </div>
        </div>

        <div className="w-full sm:w-1/5 border border-gray-300 rounded-md transform transition duration-300 ease-in-out hover:scale-105 bg-white shadow-lg">
          <div className="bg-white rounded-lg shadow-md p-2">
            <h2 className="text-lg font-semibold text-[#3182CE] mb-2 text-center">Assigned</h2>
            <div className="grid grid-cols-2 sm:gap-2 gap-1 text-center">
              <div>Count</div>
              <div className='text-[#3182CE]'>{countdata.assignedCount}</div>
              <div>Liters</div>
              <div className='text-[#3182CE]'>{formatNumber(countdata.AssignedRequestedQuantity)}</div>
            </div>
          </div>
        </div>

        <div className="w-full sm:w-1/5 border border-gray-300 rounded-md transform transition duration-300 ease-in-out hover:scale-105 bg-white shadow-lg">
          <div className="bg-white rounded-lg shadow-md p-2">
            <h2 className="text-lg font-semibold text-[#E53E3E] mb-2 text-center">Non-Assigned</h2>
            <div className="grid grid-cols-2 sm:gap-2 gap-1  text-center">
              <div>Count</div>
              <div className='text-[#E53E3E]'>{countdata.notAssignedCount}</div>
              <div>Liters</div>
              <div className='text-[#E53E3E]'>{formatNumber(countdata.notAssignedQuantity)}</div>
            </div>
          </div>
        </div>

        <div className="w-full sm:w-1/5 border border-gray-300 rounded-md transform transition duration-300 ease-in-out hover:scale-105 bg-white shadow-lg">
          <div className="bg-white rounded-lg shadow-md p-2">
            <h2 className="text-lg font-semibold text-[#48BB78] mb-2 text-center">Delivered</h2>
            <div className="grid grid-cols-2 sm:gap-2 gap-1  text-center">
              <div>Count</div>
              <div className='text-[#48BB78]'>{countdata.deliveredCount}</div>
              <div>Liters</div>
              <div className='text-[#48BB78]'>{formatNumber(countdata.deliveredRequestedQuantity)}</div>
            </div>
          </div>
        </div>
      </div>
    )}

    <div className="flex flex-col  items-center md:flex-row  gap-2 justify-center ">
      <div className="w-full md:w-1/3 md:mb-0">
        {!loading && countdata && fillingstationdata && <DonutChartApex data={countdata} />}
      </div>
      <div className="w-full md:w-2/3 ">
        {!loading && barChartData && fillingstationdata && <BarChart data={barChartData} stations={fillingstationdata} />}
      </div>
    </div>
  </div>
  );
};

export default CardComponent;