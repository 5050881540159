import React, { useState, useEffect } from 'react';
import axios from 'axios';
import config from "../config";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import EditRequestPopup from './EditRequestPopup';
import { useNavigate } from "react-router-dom";
import Select, { components } from 'react-select';
import LoadingSpinner from './Loading';
import CustomSelect from './CustomSelect';
import { FaTimes } from 'react-icons/fa';


const ClearIndicator = (props) => {
  return (
    <components.ClearIndicator {...props}>
      <div className="flex items-center">
        <FaTimes style={{ cursor: 'pointer', color: 'red' }} />
      </div>
    </components.ClearIndicator>
  );
};

const NewTankerRequestForm = () => {
  const [selectedRequestData, setSelectedRequestData] = useState([]);
  const [showUpdateButton, setShowUpdateButton] = useState(false); // New state variable
  const user = JSON.parse(sessionStorage.getItem("user"));
  const [userdata, setUserData] = useState([]);


  const [formData, setFormData] = useState({
    requestedQuantity: '',
    numberOfRequests: '',
    locality: '',
    wardName: '',
    zone: '',
    city: 'Madurai, Tamilnadu',
    requestDate: getCurrentDate(),
    requestTime: getCurrentTime(),
    fillingstation: '',

  });

  useEffect(() => {
    if (selectedRequestData) {


      setFormData({
        id: selectedRequestData.id || '',
        requestedQuantity: selectedRequestData.requestedQuantity || '',
        numberOfRequests: selectedRequestData.numberOfRequests || '',
        locality: selectedRequestData.locality || '',
        wardName: selectedRequestData.wardName || '',
        zone: selectedRequestData.zone || '',
        city: selectedRequestData.city || 'Madurai, Tamilnadu',
        requestDate: selectedRequestData.requestDate || getCurrentDate(),
        requestTime: selectedRequestData.requestTime || getCurrentTime(),
        fillingstation: selectedRequestData.fillingstation || '',
      });
      setSelectedLocality({
        value: selectedRequestData.locality,
        label: selectedRequestData.locality
      });
    }
  }, [selectedRequestData]);

  useEffect(() => {
    // Check if selectedRequestData contains data to determine whether to show the update button
    setShowUpdateButton(selectedRequestData && Object.keys(selectedRequestData).length > 0);
  }, [selectedRequestData]);

  // Define a loading state for localitiesWithWards data
  const [loadingLocalities, setLoadingLocalities] = useState(true);
  const [localitiesWithWards, setLocalitiesWithWards] = useState([]);
  const [selectedLocality, setSelectedLocality] = useState(null);
  const [loadingZones, setLoadingZones] = useState(false);
  const [zones, setZones] = useState([]);
  const [fillingstations, setFillingstations] = useState([]);
  const [asuggestions, asetSuggestions] = useState([]);
  const [selectedaddress, setSelectedaddress] = useState('')
  const [debouncedAddress, setDebouncedAddress] = useState('');
  const [isEditPopupOpen, setIsEditPopupOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1020);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1020);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  useEffect(() => {
    fetchFillingStations();
    const user = JSON.parse(sessionStorage.getItem("user"));
    setUserData(user.user)
  }, []); // Empty dependency array ensures this effect runs only once on component mount

  // Function to fetch filling station data from backend
  const fetchFillingStations = async () => {
    try {
      const token = user ? user.token : null;

      const configToken = {
        headers: {
          Authorization: `Bearer ${token}`, // Set the Authorization header with the token
        },
      };

      const response = await axios.get(`${config.apiUrl}/newtanker/fillingstations`, configToken);
      const fillingsuggestions = response.data;
      // // // console.log('fillingstation suggestions:', fillingsuggestions);
      setFillingstations(fillingsuggestions);
    } catch (error) {
      console.error('Error fetching fillingstation suggestions:', error);
    }
  };





  // useEffect hook to update debouncedAddress after selectedAddress changes
  useEffect(() => {
    const timerId = setTimeout(() => {
      setDebouncedAddress(selectedaddress);
    }, 1000); // Adjust this delay as needed (e.g., 500 milliseconds)

    // Cleanup function to clear the timer if selectedAddress changes again before the delay
    return () => {
      clearTimeout(timerId);
    };
  }, [selectedaddress]);

  const navigate = useNavigate();


  useEffect(() => {
    const auth = JSON.parse(sessionStorage.getItem('authentication'));

    if (!auth) {
      navigate('/')

    }
  }, []);


  useEffect(() => {
    fetchLocalitiesWithWards();
  }, []);




  const handleKeyDown = (event) => {
    // Check if the pressed key is the backspace key (keyCode 8)
    if (event.keyCode === 8) {
      // Clear the selectedAddress value
      setSelectedaddress('');
    }
  };

  useEffect(() => {
    // // // console.log("Ward Name:", formData.wardName); 

  }, [formData.wardName]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;


    // // // console.log("Selected Locality:", value); // Log the selected locality

    // Clear wardName and zone when the locality is cleared
    if (name === 'locality' && value === '') {
      setFormData(prevState => ({
        ...prevState,
        locality: '', // Clear the locality
        wardName: '', // Clear the wardName
        zone: '' // Clear the zone
      }));
      setZones([]);
      return; // Exit the function early
    }
    setFormData({
      ...formData,
      [name]: value
    });
    // If the changed field is 'locality', update the ward based on the selected locality
    if (name === 'locality') {
      const selectedLocalityData = localitiesWithWards.find(item => item.Locality === value);
      // // // console.log('Selected Locality Data:', selectedLocalityData); // Log the selected locality data
      if (selectedLocalityData) {
        setFormData(prevState => ({
          ...prevState,
          wardName: selectedLocalityData['Ward']
        }));
      }
    }

    // If the changed field is 'wardName', fetch the zones for the newly selected ward
    if (name === 'wardName') {
      fetchZones(value);
    }
  };

  

 

  const fetchLocalitiesWithWards = () => {
    const source = axios.CancelToken.source(); // Create a cancel token source

    const token = user ? user.token : null;
    // console.log(token); // Get the token from sessionStorage
    const configToken = {
      headers: {
        Authorization: `Bearer ${token}`, // Set the Authorization header with the token
      },
    };

    axios.get(`${config.apiUrl}/newtanker/localities-with-wards`, {
      cancelToken: source.token,
      headers: configToken.headers, // Pass the token to the request config
    })
      .then(response => {
        setLocalitiesWithWards(response.data);
        // console.log('localities', response.data);
        setLoadingLocalities(false); // Update loading state to false after data is fetched
      })
      .catch(error => {
        if (axios.isCancel(error)) {
          // console.log('Request canceled', error.message); // Log the cancelation message
        } else {
          console.error('Error fetching localities with wards:', error);
          setLoadingLocalities(false); // Update loading state to false in case of error
        }
      });

    // Return cleanup function
    return () => {
      source.cancel('Request canceled'); // Cancel the request when the component unmounts
    };
  };

  useEffect(() => {
    const cleanup = fetchLocalitiesWithWards(); // Call fetchLocalitiesWithWards and store the cleanup function

    // Cleanup function to cancel the request if the component unmounts before the request completes
    return cleanup;
  }, []);

  const handleLocalityChange = (selectedOption) => {
    if (!selectedOption) {
      // If the selectedOption is null (i.e., the clear button was clicked), clear the form fields
      setFormData(prevState => ({
        ...prevState,
        locality: '',
        wardName: '',
        zone: ''
      }));
      setSelectedLocality(null);
      setZones([]);
      return;
    }

    setSelectedLocality(selectedOption);

    const selectedValue = selectedOption.value;

    // setFormData(prevState => ({
    //   ...prevState,
    //   locality: selectedValue,
    //   wardName: ''
    // }));

    const selectedLocalityData = localitiesWithWards.find(item => item.Locality === selectedValue);
    if (selectedLocalityData) {
      setFormData(prevState => ({
        ...prevState,
        locality: selectedValue,
        wardName: selectedLocalityData['Ward']
      }));
      // Fetch zones immediately after setting ward name
      fetchZones(selectedLocalityData['Ward']);
    }
  };



  const options = localitiesWithWards.map(localityWithWard => ({
    value: localityWithWard.Locality,
    label: localityWithWard.Locality,
  }));



  const resetFormFields = () => {
    setFormData({
      requestedQuantity: '',
      numberOfRequests: '',
      locality: '',
      wardName: '',
      zone: '',
      city: 'Madurai, Tamilnadu',
      requestDate: getCurrentDate(),
      requestTime: getCurrentTime(),
      fillingstation: '',
    });
    setSelectedaddress('')
    setSelectedLocality(null)
    setZones([])
    setSelectedRequestData([])

  };


  const fetchZones = async (wardName) => {

    setLoadingZones(true); // Set loading state to true before fetching
    try {
      const token = user ? user.token : null;
      // console.log(token); // Get the token from sessionStorage
      const configToken = {
        headers: {
          Authorization: `Bearer ${token}`, // Set the Authorization header with the token
        },
      };
      const response = await axios.get(`${config.apiUrl}/newtanker/getzonesbyward?wardName=${encodeURIComponent(wardName)}`, configToken);
      // console.log("Zones Response:", response.data.zones); // Log the response

      // Assuming the response contains a 'zone' property
      const zoneData = response.data.zones;
      setZones(zoneData);

      // Update the state with the fetched zone data
      setFormData(prevState => ({
        ...prevState,
        zone: zoneData
      }));
    } catch (error) {
      console.error('Error fetching zones:', error);
    }
    finally {
      setLoadingZones(false); // Set loading state to false after fetching
    }
  };


  const handleSubmit = (event) => {
    event.preventDefault();
    if (showUpdateButton) {
      handleSaveChanges(); // If update button is shown, call handleSaveChanges instead
    } else {

      const user = JSON.parse(sessionStorage.getItem("user"));
      const { user_id, user_first_name, user_last_name } = user.user;

      const formDataWithUserDetails = {
        ...formData,
        userid: user_id,
        username: `${user_first_name} ${user_last_name}`
      };


      const requiredFields = ['requestedQuantity', 'numberOfRequests', 'locality', 'wardName', 'zone', 'requestDate', 'requestTime', 'fillingstation'];
      const emptyFields = requiredFields.filter(field => !formDataWithUserDetails[field]);
      if (emptyFields.length > 0) {
        const emptyFieldNames = emptyFields.map(field => field.replace(/([A-Z])/g, ' $1').toLowerCase());
        const errorMessage = `Please fill in the following fields: ${emptyFieldNames.join(', ')}.`;
        toast.error(errorMessage);
        return;
      }
      const token = user ? user.token : null;
      // console.log(token); // Get the token from sessionStorage
      const configToken = {
        headers: {
          Authorization: `Bearer ${token}`, // Set the Authorization header with the token
        },
      }

      axios.post(`${config.apiUrl}/newtanker/newtanker_data`, formDataWithUserDetails, configToken)
        .then(response => {
          // console.log('Data stored successfully:', response.data);
          setFormData({
            requestedQuantity: '',
            numberOfRequests: '',
            locality: '',
            wardName: '',
            zone: '',
            city: 'Madurai, Tamilnadu',
            requestDate: getCurrentDate(),
            requestTime: getCurrentTime(),
            fillingstation: ''
          });
          resetFormFields();
          toast.success("Data submitted successfully!");
        })
        .catch(error => {
          console.error('Error storing data:', error);
        });
    };
  }


  const handleSaveChanges = async () => {
    const { id, requestedQuantity, locality, wardName, zone, fillingstation} = selectedRequestData;

    if (!id) {
      console.error('No id found for selected data.');
      return;
    }





    // Construct the updated data object
    const updatedData = {
      id: id, 
      ...formData

    };

    try {
      const token = user ? user.token : null;
      // console.log(token); // Get the token from sessionStorage
      const configToken = {
        headers: {
          Authorization: `Bearer ${token}`, // Set the Authorization header with the token
          'Content-Type': 'application/json', // Add Content-Type header if needed
        },
      };

      // Send a PUT request to update the data on the server
      const response = await fetch(`${config.apiUrl}/newtanker/newtanker_data/${id}`, {
        method: 'PUT',
        headers: configToken.headers, // Include the headers from configToken
        body: JSON.stringify(updatedData),
      });

      if (response.ok) {
        // Inform the user about the successful update
        toast.success("Data updated successfully!");
        resetFormFields();
        // Set showUpdateButton to false after successful update
        setShowUpdateButton(false);
      } else {
        // Handle error response from the server
        console.error('Failed to update data on the server.');
      }
    } catch (error) {
      console.error('Error updating data:', error);
    }

  };

  const handleDateChange = (e) => {
    setFormData({ ...formData, requestDate: e.target.value });
  };
  // Update the current time every minute
  useEffect(() => {
    const intervalId = setInterval(() => {
      setFormData(prevState => ({
        ...prevState,
        requestTime: getCurrentTime(),
      }));
    }, 60000); // 60000ms = 1 minute

    // Set the initial time when the component mounts
    setFormData(prevState => ({
      ...prevState,
      requestTime: getCurrentTime(),
    }));

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  const handleTimeChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    // console.log("requestTime:", value);
  };

  // Function to handle address change with debouncing
  const handleAddressChange = (event) => {
    setSelectedaddress(event.target.value);
  };

  const handleSuggestionClick = (selectedAddress) => {
    setSelectedaddress(selectedAddress);
  };


  const handleEditButtonClick = () => {
    // Toggle the value of isEditPopupOpen
    setIsEditPopupOpen(!isEditPopupOpen);
  };


  return (
    <>


      {isMobile ? (
        <>
          {loadingLocalities ? (
            <LoadingSpinner loading={loadingLocalities} />
          ) : (
            <>
              <div>
                <button
                  className="bg-blue-500 float-right mr-5 mt-2 text-white font-semibold rounded-md px-4 py-2 hover:bg-blue-600 focus:outline-none"
                  onClick={handleEditButtonClick}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M13.707 5.293a1 1 0 011.414 1.414L6.414 15H9a1 1 0 110 2H5a1 1 0 01-1-1v-4a1 1 0 112 0v2.586l8.293-8.293a1 1 0 011.414 0zM17 3a2 2 0 00-2 2v10a2 2 0 002 2h-1a1 1 0 01-1-1v-2H9a1 1 0 110-2h4a1 1 0 010-2H9a1 1 0 01-1-1V5a2 2 0 00-2-2H3a1 1 0 00-1 1v12a1 1 0 001 1h1a1 1 0 011 1h10a1 1 0 001-1V5a1 1 0 00-1-1z" clipRule="evenodd" />
                  </svg>
                </button>
                {isEditPopupOpen && <EditRequestPopup onClose={() => setIsEditPopupOpen(false)} setSelectedRequestData={setSelectedRequestData} userdata={userdata} token={user.token} />}
                <div className="p-4 bg-red-200 rounded-lg shadow-lg" style={{ fontFamily: "'Vazir', sans-serif" }}>

                  {/* Edit Button */}
                  <h2 className="text-3xl font-semibold mb-4 text-center text-blue-600">New Tanker Request</h2>
                  <form onSubmit={handleSubmit} className="w-full ">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                      {/* Requested Quantity */}
                      <div>
                        <label htmlFor="requestedQuantity" className="block font-semibold mb-2 text-gray-700">Requested Quantity (in Ltrs)*:</label>
                        <select
                          id="requestedQuantity"
                          name="requestedQuantity"
                          value={formData.requestedQuantity}
                          onChange={handleInputChange}
                          className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
                          required
                        >
                          <option value="" disabled>Select</option>
                          <option value="6000">6000</option>
                          <option value="10000">10000</option>
                        </select>
                      </div>
                      {/* Number of Requests */}
                      <div>
                        <label htmlFor="numberOfRequests" className="block font-semibold mb-2 text-gray-700">Number of Requests*:</label>
                        {showUpdateButton ? (
                          <input
                            id="numberOfRequests"
                            name="numberOfRequests"
                            value={formData.numberOfRequests}
                            onChange={handleInputChange}
                            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
                            required
                            readOnly
                          />

                        ) : (
                          <select
                            id="numberOfRequests"
                            name="numberOfRequests"
                            value={formData.numberOfRequests}
                            onChange={handleInputChange}
                            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
                            required
                          >
                            <option value="" disabled>Select</option>
                            {[...Array(20)].map((_, index) => (
                              <option key={index + 1} value={index + 1}>{index + 1}</option>
                            ))}
                          </select>
                        )}
                      </div>
                      {/* Locality */}
                      <div>
                        <label htmlFor="locality" className="block font-semibold mb-2 text-gray-700">
                          Locality:
                        </label>
                        {loadingLocalities ? (
                          <p>Loading...</p>
                        ) : (
                          <CustomSelect
                            id="locality"
                            name="locality"
                            options={options}
                            value={selectedLocality}
                            onChange={handleLocalityChange}
                            // onClick={handleOptionClick} 
                            components={{ ClearIndicator }}
                            isClearable
                          />
                        )}
                      </div>


                    </div>
                    <div className="flex flex-col">
                      {/* Ward */}
                      <div>
                        <label htmlFor="wardName" className="block font-semibold my-2 text-gray-700">Ward*:</label>
                        <input
                          type="text"
                          id="wardName"
                          name="wardName"
                          value={formData.locality ? formData.wardName : ''}
                          onChange={handleInputChange}
                          className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
                          required
                          readOnly
                        />

                        <datalist id="wardList">
                          <option value="">Select Ward</option>
                          {localitiesWithWards.map((item, index) => (
                            <div key={index}>
                              <p>Ward Name: {item['Ward']}</p>

                            </div>
                          ))}
                        </datalist>
                      </div>


                      <div>
                        <label htmlFor="zone" className="block font-semibold mb-2 text-gray-700">Zone*:</label>
                        {loadingZones ? ( // Display loading indicator while zones are being fetched
                          <p>Loading zones...</p>
                        ) : (
                          <select
                            id="zone"
                            name="zone"
                            value={formData.zone}
                            onChange={handleInputChange}
                            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
                            required
                          >
                            {zones.map((zone, index) => (
                              <option key={index} value={zone}>{zone}</option>
                            ))}

                          </select>
                        )}
                      </div>
                      {/* City */}
                      <label htmlFor="city" className="block font-semibold my-2 text-gray-700">City:</label>
                      <input
                        type="text"
                        id="city"
                        name="city"
                        value={formData.city}
                        onChange={handleInputChange}
                        className="w-full px-4 py-1 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500 my-1"
                        readOnly
                      />
                      {/* Request Date */}
                      <label htmlFor="requestDate" className="block font-semibold my-2 text-gray-700">Request Date:</label>
                      <input
                        type="date"
                        id="requestDate"
                        name="requestDate"
                        value={formData.requestDate}
                        onChange={handleDateChange}
                        className="w-full px-4 py-1 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500 my-1"
                        required

                      />
                      {/* Request Time */}
                      <label htmlFor="requestTime" className="block font-semibold my-2 text-gray-700">Time*:</label>
                      <input
                        type="time"
                        id="requestTime"
                        name="requestTime"
                        value={formData.requestTime}
                        onChange={handleTimeChange}
                        className="w-full px-4 py-1 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500 my-1"
                        required

                      />

                      {/* Filling Station */}
                      <label htmlFor="fillingstation" className="block font-semibold mb-2 text-gray-700">Filling Station:</label>
                      <select
                        id="fillingstation"
                        name="fillingstation"
                        value={formData.fillingstation}
                        onChange={handleInputChange}
                        className="w-full px-4 py-1 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
                        required
                      >
                        <option value="">Select Filling Station</option>
                        {fillingstations.map((fillingstation, index) => (
                          <option key={index} value={fillingstation.FillingStationName}>
                            {fillingstation.FillingStationName}
                          </option>
                        ))}
                      </select>
                    

                      {showUpdateButton ? (
                        <button
                          type="button"
                          className="px-4 py-2 ml-5 bg-blue-600 text-white font-semibold rounded-md hover:bg-blue-700 focus:outline-none transition duration-300 my-2"
                          onClick={handleSubmit} // Call handleSubmit for updates
                        >
                          Update
                        </button>
                      ) : (
                        <button
                          type="submit"
                          className="px-4 py-2 ml-5 bg-blue-600 text-white font-semibold rounded-md hover:bg-blue-700 focus:outline-none transition duration-300 my-2"
                        >
                          Submit
                        </button>
                      )}
                      <div className="flex flex-row justify-end mt-0 ml-5">
                        {/* Cancel Button */}
                        <button
                          type="button"
                          className="px-2 py-2 ml-5 mt-2 bg-red-600 text-white font-semibold rounded-md hover:bg-red-700 focus:outline-none transition duration-300 mr-2"
                          onClick={() => {
                            // Call resetFormFields function when Cancel button is clicked
                            resetFormFields();
                            // Set showUpdateButton to false after resetting fields
                            setShowUpdateButton(false);
                          }}
                        >
                          Cancel
                        </button>
                      </div>

                    </div>
                    {/* Location Display */}
                
                  </form>
                </div>
              </div>
            </>
          )}
        </>
      ) : (
        <>
          {loadingLocalities ? (
            <LoadingSpinner loading={loadingLocalities} />
          ) : (
            <>
              <div>
                <button
                  className="bg-blue-500 float-right mr-5 mt-2 text-white font-semibold rounded-md px-4 py-2 hover:bg-blue-600 focus:outline-none"
                  onClick={handleEditButtonClick}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M13.707 5.293a1 1 0 011.414 1.414L6.414 15H9a1 1 0 110 2H5a1 1 0 01-1-1v-4a1 1 0 112 0v2.586l8.293-8.293a1 1 0 011.414 0zM17 3a2 2 0 00-2 2v10a2 2 0 002 2h-1a1 1 0 01-1-1v-2H9a1 1 0 110-2h4a1 1 0 010-2H9a1 1 0 01-1-1V5a2 2 0 00-2-2H3a1 1 0 00-1 1v12a1 1 0 001 1h1a1 1 0 011 1h10a1 1 0 001-1V5a1 1 0 00-1-1z" clipRule="evenodd" />
                  </svg>
                </button>
                {isEditPopupOpen && <EditRequestPopup onClose={() => setIsEditPopupOpen(false)} setSelectedRequestData={setSelectedRequestData} userdata={userdata} token={user.token} />}

                <div className="p-4 rounded-lg shadow-lg" style={{ fontFamily: "'Vazir', sans-serif" }}>

                  {/* Edit Button */}



                  <h2 className="text-3xl font-semibold mb-4 text-center text-blue-600">New Tanker Request</h2>

                  <form onSubmit={handleSubmit} className="w-full ">
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                      {/* Requested Quantity */}
                      <div>
                        <label htmlFor="requestedQuantity" className="block font-semibold mb-2 text-gray-700">Requested Quantity (in Ltrs)*:</label>
                        <select
                          id="requestedQuantity"
                          name="requestedQuantity"
                          value={formData.requestedQuantity}
                          onChange={handleInputChange}
                          className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500 "
                          required
                        >
                          <option value="" disabled>Select</option>
                          <option value="6000">6000</option>
                          <option value="10000">10000</option>
                        </select>
                      </div>
                      {/* Number of Requests */}
                      <div>
                        <label htmlFor="numberOfRequests" className="block font-semibold mb-2 text-gray-700">Number of Requests*:</label>
                        {showUpdateButton ? (
                          <input
                            id="numberOfRequests"
                            name="numberOfRequests"
                            value={formData.numberOfRequests}
                            onChange={handleInputChange}
                            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500 "
                            required
                            readOnly
                          />
                        ) : (
                          <select
                            id="numberOfRequests"
                            name="numberOfRequests"
                            value={formData.numberOfRequests}
                            onChange={handleInputChange}
                            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500 "
                            required
                          >
                            <option value="" disabled>Select</option>
                            {[...Array(20)].map((_, index) => (
                              <option key={index + 1} value={index + 1}>{index + 1}</option>
                            ))}
                          </select>
                        )}

                      </div>
                      {/* Locality */}
                      <div>
                        <label htmlFor="locality" className="block font-semibold mb-2 text-gray-700">
                          Locality:
                        </label>
                        {loadingLocalities ? (
                          <p>Loading...</p>
                        ) : (
                          <CustomSelect
                            id="locality"
                            name="locality"
                            options={options}
                            value={selectedLocality}
                            onChange={handleLocalityChange}
                            // onClick={handleOptionClick}
                            components={{ ClearIndicator }}
                            isClearable
                          />
                        )}
                      </div>


                      {/* Ward */}
                      <div>
                        <label htmlFor="wardName" className="block font-semibold mb-2 text-gray-700">Ward*:</label>
                        <input
                          type="text"
                          id="wardName"
                          name="wardName"
                          value={formData.locality ? formData.wardName : ''}
                          onChange={handleInputChange}
                          className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
                          required
                          readOnly
                        />
                        <datalist id="wardList">
                          <option value="">Select Ward</option>
                          {localitiesWithWards.map((item, index) => (
                            <div key={index}>
                              <p>Ward Name: {item['Ward']}</p>

                            </div>
                          ))}
                        </datalist>

                      </div>

                      {/* Zone */}
                      <div>
                        <label htmlFor="zone" className="block font-semibold mb-2 text-gray-700">Zone*:</label>
                        {loadingZones ? ( // Display loading indicator while zones are being fetched
                          <p>Loading zones...</p>
                        ) : (
                          <select
                            id="zone"
                            name="zone"
                            value={formData.zone}
                            onChange={handleInputChange}
                            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
                            required
                          >
                            {zones.map((zone, index) => (
                              <option key={index} value={zone}>{zone}</option>
                            ))}
                          </select>
                        )}
                      </div>

                      {/* City */}
                      <div>
                        <label htmlFor="city" className="block font-semibold mb-2 text-gray-700">City:</label>
                        <input
                          type="text"
                          id="city"
                          name="city"
                          value={formData.city}
                          onChange={handleInputChange}
                          className="w-full px-4 py-1 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500 "
                          readOnly
                        />
                      </div>

                      {/* Request Date */}
                      <div>
                        <label htmlFor="requestDate" className="block font-semibold mb-2 text-gray-700">Request Date:</label>
                        <input
                          type="date"
                          id="requestDate"
                          name="requestDate"
                          value={formData.requestDate}
                          onChange={handleDateChange}
                          className="w-full px-4 py-1 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500 "
                          required

                        />
                      </div>

                      {/* Request Time */}
                      <div>
                        <label htmlFor="requestTime" className="block font-semibold mb-2 text-gray-700">Time*:</label>
                        <input
                          type="time"
                          id="requestTime"
                          name="requestTime"
                          value={formData.requestTime}
                          onChange={handleTimeChange}
                          className="w-full px-4 py-1 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500 "
                          required

                        />
                      </div>

                      {/* Filling Station */}
                      <div>
                        <label htmlFor="fillingstation" className="block font-semibold mb-2 text-gray-700">Filling Station:</label>
                        <select
                          id="fillingstation"
                          name="fillingstation"
                          value={formData.fillingstation}
                          onChange={handleInputChange}
                          className="w-full px-4 py-1 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
                          required
                        >
                          <option value="">Select Filling Station</option>
                          {fillingstations.map((fillingstation, index) => (
                            <option key={index} value={fillingstation.FillingStationName}>
                              {fillingstation.FillingStationName}
                            </option>
                          ))}
                        </select>
                      </div>

                      <div className="col-span-3 flex items-center">

                        <div className="flex items-center w-full">




                          {showUpdateButton ? (
                            <button
                              type="button"
                              className="px-4 py-2 ml-5 bg-blue-600 text-white font-semibold rounded-md hover:bg-blue-700 focus:outline-none transition duration-300 mt-2 mr-2"
                              onClick={handleSubmit} // Call handleSubmit for updates
                            >
                              Update
                            </button>
                          ) : (
                            <button
                              type="submit"
                              className="px-4 py-2 ml-5 bg-blue-600 text-white font-semibold rounded-md hover:bg-blue-700 focus:outline-none transition duration-300 mt-2 mr-2"
                            >
                              Submit
                            </button>
                          )}
                          <div className="flex justify-end  mt-2 ml-5">
                            {/* Cancel Button */}
                            <button
                              type="button"
                              className="px-4 py-2 bg-red-600 text-white font-semibold rounded-md hover:bg-red-700 focus:outline-none transition duration-300 mr-2"
                              onClick={() => {
                                // Call resetFormFields function when Cancel button is clicked
                                resetFormFields();
                                // Set showUpdateButton to false after resetting fields
                                setShowUpdateButton(false);
                              }}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                </form>
                </div>
              </div>
            </>
          )}
        </>

      )

      }
    </>


  );
};


function getCurrentDate() {
  const date = new Date();
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}

function getCurrentTime() {
  const date = new Date();
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  return `${hours}:${minutes}`;
}

export default NewTankerRequestForm;