import React, { useState, useEffect } from "react";
import axios from "axios"; 
import config from "../../config";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapMarkerAlt,
  faEdit,
  faTrashAlt,
  faInfoCircle,
  faSave,
  faTimes,faPlus,faFileExcel
} from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify"; 
import "react-toastify/dist/ReactToastify.css";
import * as XLSX from 'xlsx';
import jsPDF from "jspdf";
import { FaUser } from "react-icons/fa";

const DriverForm = () => {
  const [driverInfo, setDriverInfo] = useState({
    driverName: "",
    mobileNo: "",
    licenceNo: "",
    licenceExpiryDate: "",
    dob: "",
    address: "",
  });

  const [editableAdditionalInfo, setEditableAdditionalInfo] = useState(null);
  const [drivers, setDrivers] = useState([]);
  const [editableDriver, setEditableDriver] = useState(null);

  const [showModal, setShowModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(false);

  
 const [error, setError] = useState('');
 const [showLoginForm, setShowLoginForm] = useState(false);
 const [username, setUsername] = useState('');
 const [password, setPassword] = useState('');
 const [additionalInfo, setAdditionalInfo] = useState(null);
 const [isMobile, setIsMobile] = useState(window.innerWidth < 1020);
 const [selectedColumn, setSelectedColumn] = useState('');
   const [searchValue, setSearchValue] = useState('');
   const user = JSON.parse(sessionStorage.getItem("user"));

 useEffect(() => {
   const handleResize = () => {
     setIsMobile(window.innerWidth < 1020);
   };

   window.addEventListener('resize', handleResize);

   return () => {
     window.removeEventListener('resize', handleResize);
   };
 }, []);
  // State to keep track of the selected item

  const openModal = () => {
    // setSelectedItem(item); // Set the selected item
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
     setDriverInfo({
        driverName: "",
        mobileNo: "",
        licenceNo: "",
        licenceExpiryDate: "",
        dob: "",
        address: "",
      });
    // setSelectedItem(null); // Reset the selected item when modal is closed
    // fetchData()
  };

  const handleDeleteClick = (driver) => {
    setSelectedItem(driver);
    setShowConfirmation(true);
  };

  const handleCancelDelete = () => {
    setSelectedItem(null);
    setShowConfirmation(false);
  };

  const handleDelete = async () => {
    const token = user ? user.token : null;
    // Get the token from sessionStorage
        const configToken = {
          headers: {
            Authorization: `Bearer ${token}`, // Set the Authorization header with the token
          },
        };
    try {
      const response = await fetch(
        `${config.apiUrl}/zonewards/driverdetails/${selectedItem.id}`,
        {
          method: "DELETE",
          headers: configToken.headers,
        }
      );
      if (response.ok) {
        // Remove the deleted item from the local state
        setDrivers(drivers.filter((item) => item.id !== selectedItem.id));
        // setFilteredData(filteredData.filter(item => item.id !== selectedItem.id))
        handleCancelDelete();
      } else {
        console.error("Error deleting record");
      }
    } catch (error) {
      console.error("Error deleting record:", error);
    }
  };
  const fetchDrivers = async () => {
    const token = user ? user.token : null;
    // Get the token from sessionStorage
        const configToken = {
          headers: {
            Authorization: `Bearer ${token}`, // Set the Authorization header with the token
          },
        }
    try {
      const response = await axios.get(`${config.apiUrl}/zonewards/drivers`,configToken);
      setDrivers(response.data);
    } catch (error) {
      console.error("Error fetching drivers:", error);
    }
  };
 

  const navigate = useNavigate();


  useEffect(() => {
    const auth = JSON.parse(sessionStorage.getItem('authentication'));
  
  
    if (!auth ) {
      navigate('/')
      
    } else {
      
      fetchDrivers();
    }
  }, []);

  const handleEdit = (driver) => {
    setEditableDriver({ ...driver });
    setEditableAdditionalInfo({ ...driver.additionalInfo });
  };
  

  const handleSave = async () => {
    const token = user ? user.token : null;
    // Get the token from sessionStorage
        const configToken = {
          headers: {
            Authorization: `Bearer ${token}`, // Set the Authorization header with the token
          },
        };
    try {
      const response = await axios.put(
        `${config.apiUrl}/zonewards/drivers/${editableDriver.id}`,
        editableDriver,configToken
      );
      toast.success(response.data.message);
      fetchDrivers();
      setEditableDriver(null);
      setEditableDriver(null); 
      setAdditionalInfo(null);
    } catch (error) {
      console.error("Error updating driver:", error);
    }
  };

  const handleCancel = () => {
    setEditableDriver(null);
    setAdditionalInfo(null);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const onlyLettersRegex = /^[a-zA-Z\s]*$/;

    if (name === "driverName") {
      if (!onlyLettersRegex.test(value)) {
        return;
      }
    }

    setEditableDriver((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    setDriverInfo({
      ...driverInfo,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = user ? user.token : null;
    // Get the token from sessionStorage
        const configToken = {
          headers: {
            Authorization: `Bearer ${token}`, // Set the Authorization header with the token
          },
        };
  

    try {
      // Send a POST request to your backend endpoint
    
      const response = await axios.post(
        `${config.apiUrl}/zonewards/drivers`,
        driverInfo,configToken
      );
      setDriverInfo({
        driverName: "",
        mobileNo: "",
        licenceNo: "",
        licenceExpiryDate: "",
        dob: "",
        address: "",
      });
      // console.log(response.data);
      toast.success(response.data.message);
      setTimeout(() => {
        setShowModal(false);
        fetchDrivers();
      }, 3000);
      // Log the response from the server
    } catch (error) {
      console.error("Error:", error);
    }
  };

  
  const handlePdf = async () => {
    const token = user ? user.token : null;
    // Get the token from sessionStorage
        const configToken = {
          headers: {
            Authorization: `Bearer ${token}`, // Set the Authorization header with the token
          },
        };
  
    try {
      const response = await axios.post(`${config.apiUrl}/user/exportUser`, { loginIdentifier: username, password },configToken);
      const { data, message } = response.data;
      if (response.status === 200 ){
        if( data.user.user_role === 'Admin') {
          const doc = new jsPDF();
  
          // Add header row
          const header = [[  "S.No",
          "Driver Name",
          "Mobile No",
          "License No",
          "License Expiry Date",
          "Date Of Birth",
          "Address",
        ]];
          
          // Add data rows
          const data =drivers.map((item, index) => [
            index + 1,
            item.driverName,
            item.mobileNo,
            item.licenceNo,
            item.licenceExpiryDate,
            item.dob,
            item.address,
            // Leave empty for Vehicle Number (will fill later)
          ]);
      
          // Add table to PDF
          doc.autoTable({
            head: header,
            body: data,
          });
      
          // Save PDF
          doc.save('DriverMaster.pdf');
          setError('');
          setShowLoginForm(false);
        }
        else {
            setError('Unauthorized User');
        }
      }
        else {
            setError(message);
        }
      } catch (error) {
        setError('Failed to export Excel file');
      }
    };
  
  const handleExcel = async () => {
    const token = user ? user.token : null;
    // Get the token from sessionStorage
        const configToken = {
          headers: {
            Authorization: `Bearer ${token}`, // Set the Authorization header with the token
          },
        };
  
    try {
      const response = await axios.post(`${config.apiUrl}/user/exportUser`, { loginIdentifier: username, password },configToken);
      const { data, message } = response.data;
      if (response.status === 200 ){
        if( data.user.user_role === 'Admin') {
          const fileName = "DriverMaster.xlsx";
          const wsData = [
              [
                "S.No",
                "Driver Name",
                "Mobile No",
                "License No",
                "License Expiry Date",
                "Date Of Birth",
                "Address",
                 
              ],
              ...drivers.map((item, index) => [
                index + 1,
                  item.driverName,
                  item.mobileNo,
                  item.licenceNo,
                  item.licenceExpiryDate,
                  item.dob,
                  item.address,
                // Leave empty for Vehicle Number (will fill later)
              ])
          ];
      
          // Fill location and vehicle data for each row
       
      
          const ws = XLSX.utils.aoa_to_sheet(wsData);
          const wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, "DriverMaster");
      
          // Export the workbook
          XLSX.writeFile(wb, fileName);
          setError('');
          setShowLoginForm(false);
        }
        else {
            setError('Unauthorized User');
        }
      }
        else {
            setError(message);
        }
      } catch (error) {
        setError('Failed to export Excel file');
      }
    };
    
  const handleClose = () => {
    // Function to handle closing the login form
    setShowLoginForm(false);
    setUsername('');
    setPassword('');
    setError('');
  };
  const openAdditionalInfoModal = (driver) => {
    setAdditionalInfo(driver);
   
  };

  const modalStyles = `
  .modal {
    display: flex;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0,0.4);
  }

  .modal-content {
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    // width: 80%;
    animation-name: modal-slide;
    animation-duration: 0.5s;
  }

  @keyframes modal-slide {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(0);
    }
  }

  .close-btn {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }

  .close-btn:hover,
  .close-btn:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }
`;

  const today = new Date().toISOString().split("T")[0];
  //   const today = new Date().toISOString().split('T')[0];

  const filterOptions = [
    { label: ' Driver Name', value: 'driverName' },
    { label: 'Mobile No', value: 'mobileNo' },
    { label: 'Address', value: 'address' },
 
  ];
 
  const handleSearch = (inputValue) => {
    if (inputValue.trim() === '') {
      fetchDrivers(); // Fetch all drivers when search input is empty
    } else {
      const filteredData = drivers.filter((driverName) => {
        const columnValue = driverName[selectedColumn];
 
        if (typeof columnValue === 'string') {
          return columnValue.toLowerCase().includes(inputValue.toLowerCase());
        } else {
          // For non-string values, convert to string and then compare
          return String(columnValue).toLowerCase().includes(inputValue.toLowerCase());
        }
      });
      setDrivers(filteredData);
    }
  };
  
  useEffect(() => {
    handleSearch(searchValue);
  }, [searchValue]);
  

  return (
    <>
    {!isMobile? (
       <div className="container mx-auto">
              <h1 className="text-2xl font-bold mb-3">
              <FontAwesomeIcon className="text-blue-800" icon={FaUser} /> Driver List</h1>

       <div className="flex justify-between items-center"> {/* Updated */}
       <div className="flex flex-col md:flex-row mb-2 ">
  <select
    className="mr-2 px-2 py-1 border border-gray-300 rounded  md:mb-0 w-full md:mr-0 md:px-3 md:py-2 md:w-40"
    value={selectedColumn}
    onChange={(e) => {
      setSelectedColumn(e.target.value);
      setSearchValue(''); // Clear search input value
    }}
  >
    <option value="">Select </option>
    {filterOptions.map((option) => (
      <option key={option.value} value={option.value}>
        {option.label}
      </option>
    ))}
  </select>
  <input
  type="text"
  placeholder="Search..."
  value={searchValue}
  className="px-1 py-1 border w-full border-gray-300 rounded  md:w-auto"

  onChange={(e) => setSearchValue(e.target.value)}
/>
 
</div>
      <div>
        {!showLoginForm && (
          <button className="text-white text-sm py-2 px-3 rounded-md mr-2" onClick={() => openModal()} 
          style={{ backgroundImage: 'linear-gradient(to right, #003399, #4facfe)' }}
          > {/* Updated */}
          Add New Drivers <FontAwesomeIcon icon={faPlus} className="ml-1" />
        </button>
        )}
        <button className="bg-green-500 hover:bg-green-600 text-white py-1 px-2 rounded-md" onClick={() => setShowLoginForm(true)}> Export
          <FontAwesomeIcon icon={faFileExcel} className="ml-2" />
        </button>
      </div>
    </div>
  
        
        <div className="w-full ">
          <table className="table-auto w-full">
            <thead>
              <tr
                className="text-white border w-20"
                style={{ backgroundColor: "rgb(33,64,154)" }}
              >
                <th
                  scope="col"
                  className="border  px-2 py-3 text-xs font-semibold uppercase"
                >
                  S.No
                </th>
                <th
                  scope="col"
                  className=" border  px-2 py-3 text-xs  whitespace-wrap font-semibold uppercase"
                >
                  Driver Name
                </th>
                <th
                  scope="col"
                  className="border px-2 py-3 text-xs whitespace-wrap font-semibold uppercase"
                >
                  Mobile No
                </th>
                <th
                  scope="col"
                  className="border px-2 py-3 text-xs whitespace-wrap font-semibold uppercase"
                >
                  Licence No
                </th>
                <th
                  scope="col"
                  className="border px-2 py-3 text-xs whitespace-wrap font-semibold uppercase"
                >
                  Licence Expiry Date
                </th>
                <th
                  scope="col"
                  className="border px-5 py-3 text-xs whitespace-wrap font-semibold uppercase"
                >
                  DOB
                </th>
                <th
                  scope="col"
                  className="border px-2 py-3 text-xs  whitespace-wrap font-semibold uppercase"
                >
                  Address
                </th>
                <th className="border px-2 py-3 text-xs font-semibold uppercase">Edit</th>
                <th className="border px-2 py-3 text-xs font-semibold uppercase">Delete</th>
              </tr>
            </thead>
            <tbody>
              {drivers.map((driver, index) => (
                <tr key={driver.id} className='text-center text-sm border-b-2'>
                  <td className="border px-2 py-4 ">
                    {index + 1}
                  </td>
                  <td className="border px-2 py-4 whitespace-wrap overflow-x-auto" style={{ maxWidth: "100px" }}>
                    {/* {editableDriver && editableDriver.id === driver.id ? (
                      <input
                        type="text"
                        className="w-20 text-center border border-sky-500"
                        name="driverName"
                        value={editableDriver.driverName}
                        onChange={handleChange}
                   
                      />
                    ) : ( */}
                      Mr. {driver.driverName}
                    {/* )} */}
                  </td>
                  <td className="border px-2 py-4 whitespace-wrap ">
                    {editableDriver && editableDriver.id === driver.id ? (
                      <input
                        type="text"
                        className="w-20 text-center border border-sky-500"
                        name="mobileNo"
                        value={editableDriver.mobileNo}
                        onChange={handleChange}
                        onInput={(e) => {
                          e.target.value = e.target.value
                            .replace(/\D/, "")
                            .slice(0, 10);
                        }}
                      />
                    ) : (
                      driver.mobileNo
                    )}
                  </td>
                  <td className="border px-2 py-4  whitespace-wrap overflow-x-auto" style={{ maxWidth: "100px" }}>
                    {editableDriver && editableDriver.id === driver.id ? (
                      <input
                        type="text"
                        className="w-28 text-center border border-sky-500"
                        name="licenceNo"
                        value={editableDriver.licenceNo}
                        onChange={handleChange}
                        onInput={(e) => {
                          e.target.value = e.target.value
                            .replace(/[^a-zA-Z0-9\s-]/g, "")
                            .slice(0, 25);
                        }}
                      />
                    ) : (
                      driver.licenceNo
                    )}
                  </td>
                  <td className="border   py-2 whitespace-wrap ">
                    {editableDriver && editableDriver.id === driver.id ? (
                      <input
                        type="date"
                        className="w-26 text-center border border-sky-500"
                        name="licenceExpiryDate"
                        value={editableDriver.licenceExpiryDate}
                        onChange={handleChange}
                        min={today}
                      />
                    ) : (
                      driver.licenceExpiryDate
                    )}
                  </td>
                  <td className="border px-2 py-4 whitespace-wrap">
                    {/* {editableDriver && editableDriver.id === driver.id ? (
                      <input
                        type="date"
                        className="w-26 text-center border border-sky-500"
                        name="dob"
                        value={editableDriver.dob}
                        onChange={handleChange}
                        max={today}
                      />
                    ) : ( */}
                     {driver.dob}
                    {/* )} */}
                  </td>
                  <td className="border px-2 py-4 whitespace-wrap overflow-x-auto " style={{ maxWidth: "100px" }}>
                    {editableDriver && editableDriver.id === driver.id ? (
                      <input
                        type="text"
                        className="w-20 text-center border border-sky-500"
                        name="address"
                        value={editableDriver.address}
                        onChange={handleChange}
                      />
                    ) : (
                      driver.address
                    )}
                  </td>
                  <td className="border px-2 py-4 whitespace-wrap">
                    {editableDriver && editableDriver.id === driver.id ? (
                      <>
                        <FontAwesomeIcon
                          icon={faSave}
                          className="cursor-pointer text-green-500"
                          onClick={handleSave}
                        />
                        <FontAwesomeIcon
                          icon={faTimes}
                          className="cursor-pointer text-red-500 ml-2"
                          onClick={handleCancel}
                        />
                      </>
                    ) : (
                      <FontAwesomeIcon
                        icon={faEdit}
                        className="cursor-pointer text-blue-500"
                        onClick={() => handleEdit(driver)}
                      />
                    )}
                  </td>
  
                  <td className="border px-2 py-4 whitespace-wrap">
                    <FontAwesomeIcon
                      icon={faTrashAlt}
                      className="cursor-pointer  ml-2" style={{ color: "red"}}
                      onClick={() => handleDeleteClick(driver)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
  
        {showModal && (
          <div className="modal">
          <style>{modalStyles}</style>
       <div className="modal-content">
         <button onClick={closeModal} className="close-btn">
           <FontAwesomeIcon icon={faTimes} />
         </button>
                <form onSubmit={handleSubmit} className="p-4">
                <h2 className="text-2xl font-semibold mt-1 text-center mb-2">Add Driver Details</h2>
  
                  <div className="grid grid-cols-2 gap-10 p-6">
                    <div >
                      <label htmlFor="driverName" className="block text-gray-700">
                        Driver Name*
                      </label>
                      <input
                        type="text"
                        id="driverName"
                        name="driverName"
                        value={driverInfo.driverName}
                        onChange={handleChange}
                        required
                        className="mt-1 p-2 w-full border rounded-md"
                      />
                    </div>
                    <div >
                      <label htmlFor="mobileNo" className="block text-gray-700">
                        Mobile No*
                      </label>
                      <input
                        type="text"
                        id="mobileNo"
                        name="mobileNo"
                        value={driverInfo.mobileNo}
                        onChange={handleChange}
                        onInput={(e) => {
                          e.target.value = e.target.value
                            .replace(/\D/, "")
                            .slice(0, 10);
                        }}
                        required
                        className="mt-1 p-2 w-full border rounded-md"
                      />
                    </div>
                    <div >
                      <label htmlFor="licenceNo" className="block text-gray-700">
                        Licence No*
                      </label>
                      <input
                        type="text"
                        id="licenceNo"
                        name="licenceNo"
                        value={driverInfo.licenceNo.toUpperCase()}
                        onChange={handleChange}
                        onInput={(e) => {
                          e.target.value = e.target.value
                            .replace(/[^a-zA-Z0-9\s-]/g, "")
                            .slice(0, 25);
                        }}
                        required
                        className="mt-1 p-2 w-full border rounded-md"
                      />
                    </div>
                    <div >
                      <label
                        htmlFor="licenceExpiryDate"
                        className="block text-gray-700"
                      >
                        Licence Expiry Date*
                      </label>
                      <input
                        type="date"
                        id="licenceExpiryDate"
                        name="licenceExpiryDate"
                        value={driverInfo.licenceExpiryDate}
                        onChange={handleChange}
                        required
                        className="mt-1 p-2 w-full border rounded-md"
                        min={today}
                      />
                    </div>
                    <div >
                      <label htmlFor="dob" className="block text-gray-700">
                        DOB*
                      </label>
                      <input
                        type="date"
                        id="dob"
                        name="dob"
                        value={driverInfo.dob}
                        onChange={handleChange}
                        required
                        className="mt-1 p-2 w-full border rounded-md"
                        max={today}
                      />
                    </div>
                    <div >
                      <label htmlFor="address" className="block text-gray-700">
                        Address
                      </label>
                      <input
                        id="address"
                        name="address"
                        value={driverInfo.address}
                        onChange={handleChange}
                        className="mt-1 p-2 w-full border rounded-md"
                      />
                      {/* <textarea id="address" name="address" value={driverInfo.address} onChange={handleChange} className="mt-1 p-2 w-full border rounded-md"></textarea> */}
                    </div>
                  </div>
                  <div className="text-center">
                    <button
                      type="submit"
                      className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
          
          </div>
        )}
  
        {showConfirmation && (
          <div className="fixed inset-0 z-40 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
            <div className="fixed inset-0 bg-black bg-opacity-50"></div>
            <div className="relative w-auto max-w-3xl mx-auto my-4">
              <div className="bg-white p-8 rounded-lg shadow-md">
                {/* <p className="mb-3">Do you want to delete this request {`${selectedItem.id}`}?</p> */}
                <p className="mb-3">Are you sure to delete this?</p>
  
                <div className="flex justify-center space-x-3">
                  <button
                    onClick={handleDelete}
                    className="bg-red-500 hover:bg-red-600 text-white px-2 py-2 rounded-full"
                  >
                    Yes
                  </button>
                  <button
                    onClick={handleCancelDelete}
                    className="bg-gray-400 hover:bg-gray-500 text-white px-2 py-2 rounded-full"
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
  
        {showLoginForm && (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
    <div className="bg-white w-full max-w-xs md:max-w-lg rounded-lg overflow-hidden shadow-xl">
    <div className="px-6 py-4">
          <div className="flex justify-end">
            <button className="text-gray-700 hover:text-gray-900" onClick={handleClose}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
          <label htmlFor="username" className="text-gray-700">Username</label>
          <input
            type="text"
            id="username"
            placeholder="Username or Mobile Number"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            className="border border-gray-400 px-3 py-2 rounded-md mb-2 w-full"
          />
          <label htmlFor="password" className="text-gray-700">Password</label>
          <input
            type="password"
            id="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="border border-gray-400 px-3 py-2 rounded-md mb-2 w-full"
          />
          <button
            onClick={handleExcel}
            className="bg-green-500 hover:bg-green-600 text-white py-2 px-4 rounded-md mr-2"
          >
            Excel
          </button>
          <button
          onClick={handlePdf}
          className="bg-green-500 hover:bg-green-600 text-white py-2 px-4 rounded-lg mr-2"
        >
          PDF
        </button>
          
          {error && <p className="text-red-500 mt-2">{error}</p>}
        </div>
    </div>
  </div>
  )}
      </div>
    ):(
      <div className="container mx-auto">
<div className="flex justify-between items-center"> {/* Updated */}
<h1 className="text-xl font-bold mb-3">Driver List</h1>
<div>
 {!showLoginForm && (
   <button className="text-white text-sm py-1 px-2 rounded-md mr-2" onClick={() => openModal()} style={{ backgroundImage: 'linear-gradient(to right, #4facfe, #00f2fe)' }}> {/* Updated */}
    <FontAwesomeIcon icon={faPlus} className="" />
 </button>
 )}
 <button className="bg-green-500 hover:bg-green-600 text-white text-sm py-1 px-2 rounded-md mr-2" onClick={() => setShowLoginForm(true)}> 
   <FontAwesomeIcon icon={faFileExcel} className="" />
 </button>
</div>
</div>

 

 <div className="w-full">
  <table className="table-auto w-full">
    <thead>
      <tr className="text-white border" style={{ backgroundColor: "rgb(33,64,154)" }}>
        <th scope="col" className="border px-1 py-1 text-xs">S.No</th>
        <th scope="col" className="border px-1 py-1 text-xs">Driver Name</th>
        <th scope="col" className="border px-1 py-1 text-xs">Mobile No</th>
        <th scope="col" className="border px-1 py-1 text-xs">Additional Details</th>
        <th scope="col" className="border px-1 py-1 text-xs">Edit</th>
        <th scope="col" className="border px-1 py-1 text-xs">Delete</th>
      </tr>
    </thead>
    <tbody>
      {drivers.map((driver, index) => (
        <tr key={driver.id} className='text-center text-xs border-b-2'>
          <td className="border px-1 py-1 whitespace-nowrap">{index + 1}</td>
          <td className="border px-1 py-1 whitespace-wrap overflow-x-auto" style={{ maxWidth: "100px" }}>
            {editableDriver && editableDriver.id === driver.id ? (
              <input
                type="text"
                className="text-center border border-sky-500 w-16"
                name="driverName"
                value={editableDriver.driverName}
                onChange={handleChange}
              />
            ) : (
              driver.driverName
            )}
          </td>
          <td className="border px-1 py-1 whitespace-wrap">
            {editableDriver && editableDriver.id === driver.id ? (
              <input
                type="text"
                className="text-center border border-sky-500 w-16"
                name="mobileNo"
                value={editableDriver.mobileNo}
                onChange={handleChange}
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/\D/, "").slice(0, 10);
                }}
              />
            ) : (
              driver.mobileNo
            )}
          </td>
          <td className="border px-1 py-1 whitespace-wrap">
            <FontAwesomeIcon
              icon={faInfoCircle}
              className="cursor-pointer text-blue-700 ml-2"
              onClick={() => openAdditionalInfoModal(driver)}
            />
          </td>
          <td className="border px-1 py-1 whitespace-wrap">
            {editableDriver && editableDriver.id === driver.id ? (
              <>
                <FontAwesomeIcon
                  icon={faSave}
                  className="cursor-pointer text-green-500"
                  onClick={handleSave}
                />
                <FontAwesomeIcon
                  icon={faTimes}
                  className="cursor-pointer text-red-500 ml-2"
                  onClick={handleCancel}
                />
              </>
            ) : (
              <FontAwesomeIcon
                icon={faEdit}
                className="cursor-pointer text-blue-500"
                onClick={() => handleEdit(driver)}
              />
            )}
          </td>
          <td className="border px-1 py-1 whitespace-wrap">
            <FontAwesomeIcon
              icon={faTrashAlt}
              className="cursor-pointer ml-2"
              style={{ color: "red" }}
              onClick={() => handleDeleteClick(driver)}
            />
          </td>
        </tr>
      ))}
    </tbody>
  </table>
</div>



{additionalInfo && (
  <div className="fixed inset-0 flex items-center text-sm justify-center bg-gray-800 bg-opacity-75">
    <div className="bg-white p-4 rounded-lg relative max-w-xs">
      <h2 className="text-lg font-semibold mb-2">Additional Driver Details</h2>
      <table className="w-full">
        <tbody>
          <tr>
            <td><strong>Licence No:</strong></td>
            <td >
            {editableDriver && editableDriver.id === additionalInfo.id ? (
                <input
                className="w-40"
                  type="text"
                  name="licenceNo"
                  value={editableDriver.licenceNo}
                  onChange={handleChange}
                />
              ) : (
                <span>{additionalInfo.licenceNo}</span>
              )}
            </td>
          </tr>
          <tr>
            <td><strong>Licence Expiry Date:</strong></td>
            <td >
            {editableDriver && editableDriver.id === additionalInfo.id ? (
                <input
                className="w-40"
                  type="date"
                  name="licenceExpiryDate"
                  value={editableDriver.licenceExpiryDate}
                onChange={handleChange}
                />
              ) : (
                <span>{additionalInfo.licenceExpiryDate}</span>
              )}
            </td>
          </tr>
          <tr>
            <td><strong>Date of Birth (DOB):</strong></td>
            <td>
            {editableDriver && editableDriver.id === additionalInfo.id ? (
                <input
                className="w-40"
                  type="date"
                  name="dob"
                  value={editableDriver.dob}
                onChange={handleChange}
                />
              ) : (
                <span>{additionalInfo.dob}</span>
              )}
            </td>
          </tr>
          <tr>
            <td><strong>Address:</strong></td>
            <td className="overflow-auto text-sm"  style={{ maxWidth: "150px" }}>
            {editableDriver && editableDriver.id === additionalInfo.id ? (
                <input
                className="w-50 whitesapce-wrap"
                  type="text"
                  name="address"
                  value={editableDriver.address}
                onChange={handleChange}
                />
              ) : (
                <span>{additionalInfo.address}</span>
              )}
            </td>
          </tr>
        </tbody>
      </table>
      <div className="flex justify-end mt-4">
          {editableDriver && editableDriver.id === additionalInfo.id ? (
            <>
              <button className="bg-green-500 text-white px-4 py-2 rounded mr-2" onClick={handleSave}>Save</button>
              <button className="bg-red-500 text-white px-4 py-2 rounded" onClick={handleCancel}>Cancel</button>
            </>
          ) : (
            <button onClick={() => setAdditionalInfo(null)} className="absolute top-1 right-0 text-gray-700 hover:text-gray-900">
        <svg className="h-6 w-6 text-red-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
        </svg>
      </button>
          )}
        </div>
    </div>
  </div>
)}





{showModal && (
  <div className="modal ">
    <style>{modalStyles}</style>
    <div className="modal-content max-w-xs  md:max-w-md">
      <button onClick={closeModal} className="close-btn">
        <FontAwesomeIcon icon={faTimes} />
      </button>
      <form onSubmit={handleSubmit} className="p-4">
        <h2 className="text-xl md:text-2xl font-semibold mt-1 text-center mb-2">Add Driver Details</h2>

        <div className="grid grid-cols-1 gap-2 p-4">
          <div className="mt-1 mb-2">
            <label htmlFor="driverName" className="block text-gray-700">Driver Name*</label>
            <input
              type="text"
              id="driverName"
              name="driverName"
              value={driverInfo.driverName}
              onChange={handleChange}
              required
              className="mt-1 p-2 w-full border rounded-md"
            />
          </div>
          <div className="mt-1 mb-2">
            <label htmlFor="mobileNo" className="block text-gray-700">Mobile No*</label>
            <input
              type="text"
              id="mobileNo"
              name="mobileNo"
              value={driverInfo.mobileNo}
              onChange={handleChange}
              onInput={(e) => {
                e.target.value = e.target.value.replace(/\D/, "").slice(0, 10);
              }}
              required
              className="mt-1 p-2 w-full border rounded-md"
            />
          </div>
          <div className="mt-1 mb-2">
            <label htmlFor="licenceNo" className="block text-gray-700">Licence No*</label>
            <input
              type="text"
              id="licenceNo"
              name="licenceNo"
              value={driverInfo.licenceNo.toUpperCase()}
              onChange={handleChange}
              onInput={(e) => {
                e.target.value = e.target.value.replace(/[^a-zA-Z0-9\s-]/g, "").slice(0, 25);
              }}
              required
              className="mt-1 p-2 w-full border rounded-md"
            />
          </div>
          <div className="mt-1 mb-2">
            <label htmlFor="licenceExpiryDate" className="block text-gray-700">Licence Expiry Date*</label>
            <input
              type="date"
              id="licenceExpiryDate"
              name="licenceExpiryDate"
              value={driverInfo.licenceExpiryDate}
              onChange={handleChange}
              required
              className="mt-1 p-2 w-full border rounded-md"
              min={today}
            />
          </div>
          <div className="mt-1 mb-2">
            <label htmlFor="dob" className="block text-gray-700">DOB*</label>
            <input
              type="date"
              id="dob"
              name="dob"
              value={driverInfo.dob}
              onChange={handleChange}
              required
              className="mt-1 p-2 w-full border rounded-md"
              max={today}
            />
          </div>
          <div className="mt-1 mb-2">
            <label htmlFor="address" className="block text-gray-700">Address</label>
            <input
              id="address"
              name="address"
              value={driverInfo.address}
              onChange={handleChange}
              className="mt-1 p-2 w-full border rounded-md"
            />
          </div>
        </div>
        <div className="text-center">
          <button
            type="submit"
            className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  </div>
)}


 

 {showConfirmation && (
   <div className="fixed inset-0 z-40 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
     <div className="fixed inset-0 bg-black bg-opacity-50"></div>
     <div className="relative w-auto max-w-3xl mx-auto my-4">
       <div className="bg-white p-8 rounded-lg shadow-md">
         {/* <p className="mb-3">Do you want to delete this request {`${selectedItem.id}`}?</p> */}
         <p className="mb-3">Are you sure to delete this?</p>

         <div className="flex justify-center space-x-3">
           <button
             onClick={handleDelete}
             className="bg-red-500 hover:bg-red-600 text-white px-2 py-2 rounded-full"
           >
             Yes
           </button>
           <button
             onClick={handleCancelDelete}
             className="bg-gray-400 hover:bg-gray-500 text-white px-2 py-2 rounded-full"
           >
             No
           </button>
         </div>
       </div>
     </div>
   </div>
 )}

{showLoginForm && (
  <>
   
  <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
        <div className="bg-white w-full max-w-xs md:max-w-lg rounded-lg overflow-hidden shadow-xl">
        <div className="px-6 py-4">
        <div className="flex justify-end">
          <button className="text-gray-700 hover:text-gray-900" onClick={handleClose}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
        <label htmlFor="username" className="text-gray-700">Username</label>
        <input
          type="text"
          id="username"
          placeholder="Username or Mobile Number"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          className="border border-gray-400 px-3 py-2 rounded-md mb-2 w-full"
        />
        <label htmlFor="password" className="text-gray-700">Password</label>
        <input
          type="password"
          id="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className="border border-gray-400 px-3 py-2 rounded-md mb-2 w-full"
        />
        <button
          onClick={handleExcel}
          className="bg-green-500 hover:bg-green-600 text-white py-2 px-4 rounded-md mr-2"
        >
          Excel
        </button>
        <button
        onClick={handlePdf}
        className="bg-green-500 hover:bg-green-600 text-white py-2 px-4 rounded-lg mr-2"
      >
        PDF
      </button>
        
        {error && <p className="text-red-500 mt-2">{error}</p>}
      </div>
        </div>
      </div>
   
  </>
)}
</div>

    )}
   
    </>
  );
};

export default DriverForm;