import React, { useState, useEffect } from 'react';
import axios from 'axios'; 
import config from '../config';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import time from '../assests/time.png'
import ready from '../assests/ready.webp';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";




function Vehicledownwise() {
  const [vehicleData, setVehicleData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [startDateTime, setStartDateTime] = useState('');
  const [endDateTime, setEndDateTime] = useState('');
  const [reason, setReason] = useState('');
  const [isValid, setIsValid] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [selectedVehicleIndices, setSelectedVehicleIndices] = useState([]);
  const [showTable, setShowTable] = useState(false);
  const [vehicledownData, setVehicleDownData] = useState([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1020);
  const [user ,setUser]= useState(JSON.parse(sessionStorage.getItem("user")))

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1020);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  useEffect(() => {
    // Fetch data from backend when component mounts
    fetchData();
  }, []);

  const fetchData = async () => {
    if(user && user.token){
    const token = user ? user.token : null;
    // Get the token from sessionStorage
        const configToken = {
          headers: {
            Authorization: `Bearer ${token}`, // Set the Authorization header with the token
          },
        };
    try {
      const response = await axios.get(`${config.apiUrl}/vehicledowntime/lorry-details`,configToken);
      const formattedData = response.data.map((item) => ({
      
        vehicleNo: item['vehicleNo'],
        capacity: item['capacity'],
        vehicleType: item['vehicleType']
      }));
      setVehicleData(formattedData);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }
  };
 
  

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleCheckboxChange = (index) => {
    setSelectedVehicleIndices((prevIndices) => {
      // Toggle selection
      if (prevIndices.includes(index)) {
        return prevIndices.filter((i) => i !== index); // Remove index if already selected
      } else {
        return [...prevIndices, index]; // Add index if not selected
      }
    });
  };

  const handleSubmit = async () => {
    const token = user ? user.token : null;
    // Get the token from sessionStorage
        const configToken = {
          headers: {
            Authorization: `Bearer ${token}`, // Set the Authorization header with the token
          },
        };
    setFormSubmitted(true)
    try {
       // Check if startDateTime and endDateTime are both empty
       if (!startDateTime || !endDateTime || !reason ) {
        if (!startDateTime) {
          document.getElementById('startDateTimeInput').classList.add('border-red-500');
          setFormSubmitted(false)
        }
        if (!endDateTime) {
          document.getElementById('endDateTimeInput').classList.add('border-red-500');
          setFormSubmitted(false)
        }
        if (!reason) {
          document.getElementById('reasonInput').classList.add('border-red-500');
          setFormSubmitted(false)
        }
    
        return;
      }

      document.getElementById('startDateTimeInput').classList.remove('border-red-500');
      document.getElementById('endDateTimeInput').classList.remove('border-red-500');
      document.getElementById('reasonInput').classList.remove('border-red-500');
  // Validate end time is at least one second after start time
  if (!isValid) {
    toast.error("End time must be at least one second after start time.");
    setFormSubmitted(false);
    return;
  }
  
      // Prepare data object to send to the backend for each selected vehicle
      const selectedVehicles = selectedVehicleIndices.map((selectedIndex) => {
        const selectedVehicle = vehicleData[selectedIndex];
        return {
          vehicleNo: selectedVehicle.vehicleNo,
          vehicleType: selectedVehicle.vehicleType,
          capacity: selectedVehicle.capacity,
          startDateTime: startDateTime,
          endDateTime: endDateTime, 
          reason
        };
      });

      // Send data for each selected vehicle to the backend API
      await Promise.all(selectedVehicles.map(async (data) => {
        const response = await axios.post(`${config.apiUrl}/vehicledowntime/VehicleDownTime_Data`, data,configToken);
        // console.log(response.data);
      }));

     
      setStartDateTime('');
      setEndDateTime('');
      setReason('');
      setModalOpen(true);

      // Remove selected vehicles from vehicleData state
      const updatedVehicleData = vehicleData.filter((vehicle, index) => !selectedVehicleIndices.includes(index));
      setVehicleData(updatedVehicleData);
      setSelectedVehicleIndices([]);

      toast.success('Data submitted successfully!');
      setModalOpen(false);
      setFormSubmitted(false)
      
    } catch (error) {
      console.error('Error submitting data:', error);
      toast.error('Error submitting data.');
      setFormSubmitted(false)
    }
  };

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setSelectedVehicleIndices([]);
    setReason('');
    fetchData()
   
     
  };
 // Function to get current date and time in the required format
 function getCurrentDateTime() {
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, '0');
  const day = String(now.getDate()).padStart(2, '0');
  const hours = String(now.getHours()).padStart(2, '0');
  const minutes = String(now.getMinutes()).padStart(2, '0');
  const seconds = String(now.getSeconds()).padStart(2, '0');
  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
}

// Handle start date-time change
const handleStartDateTimeChange = (value) => {
  setStartDateTime(value);
  validateForm(value, endDateTime);
};

// Handle end date-time change
const handleEndDateTimeChange = (value) => {
  setEndDateTime(value);
  validateForm(startDateTime, value);
};

// Validate if end date-time is at least one second after start date-time
const validateForm = (start, end) => {
  if (start && end) {
    const startTime = new Date(start).getTime();
    const endTime = new Date(end).getTime();
    setIsValid(endTime > startTime + 1000); // end time must be at least one second after start time
  } else {
    setIsValid(false);
  }
};



useEffect(() => {
  validateForm(startDateTime, endDateTime);
}, [startDateTime, endDateTime]);


  const formatDate = (dateTime) => {
    const options = {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    };
    return new Date(dateTime).toLocaleString('en-US', options);
  };
  
  const filteredData = vehicleData.filter((vehicle) =>
  (vehicle.vehicleNo && vehicle.vehicleNo.toLowerCase().includes(searchTerm.toLowerCase()))
);

const handleReadyClick = async () => {
  const token = user ? user.token : null;
  // Get the token from sessionStorage
      const configToken = {
        headers: {
          Authorization: `Bearer ${token}`, // Set the Authorization header with the token
        },
      };
  try {
    const response = await axios.get(`${config.apiUrl}/vehicledowntime/getVehicleDownTime_Data`,configToken);
    setVehicleDownData(response.data);
    setShowTable((prevState) => !prevState);
    fetchData()
  } catch (error) {
    console.error('Error fetching vehicle downtime data:', error);
  }
};

const handleBeReadyClick = async (vehicleIndex) => {
  const token = user ? user.token : null;
  // Get the token from sessionStorage
      const configToken = {
        headers: {
          Authorization: `Bearer ${token}`, // Set the Authorization header with the token
        },
      };
  try {
    // Get the selected vehicle details
    const selectedVehicle = vehicledownData[vehicleIndex];
    const selectedVehicleId = selectedVehicle.id;
    const TankerNo = selectedVehicle.vehicleNo;

    // Update the vehicle status to ready
    await axios.put(`${config.apiUrl}/vehicledowntime/updateVehicleStatus`, { 
      selectedVehicleIndices: [selectedVehicleId], 
      vehicleNo: [TankerNo] 
    },configToken);

    // Update the end time of the selected vehicle
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0'); // Add leading zero if necessary
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');

    const endTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`; // Format the datetime value
    await axios.put(`${config.apiUrl}/vehicledowntime/updateEndTime`, { 
      id: selectedVehicleId, 
      endDateTime: endTime 
    },configToken);

    // Remove the selected vehicle from vehicledownData state
    const updatedVehicleData = vehicledownData.filter((vehicle) => vehicle.id !== selectedVehicleId);
    setVehicleDownData(updatedVehicleData);

    // Show a success message using toast
    toast.success(`Vehicle ${TankerNo} is now ready`);
  } catch (error) {
    console.error('Error updating vehicle status:', error);
    // Handle errors, show error message, etc.
    toast.error('Failed to update vehicle status');
  }
};


return (
  <div className="max-w-4xl mx-auto overflow-hidden relative">
    {!isMobile ? (
      <div>
        <div className="flex flex-col md:flex-row items-center justify-between mt-4 md:mr-4">
          <div className="flex items-center">
            <img src={time} alt="Time" className="w-8 h-8 mr-2" />
            <h1 className="font-semibold text-gray-700 dark:text-gray-300 text-2xl md:text-4xl">Vehicle Downtime</h1>
          </div>
          <img src={ready} alt="Ready" className="w-8 h-8 cursor-pointer mt-4 md:mt-0" onClick={handleReadyClick} />
        </div>
        {showTable && (
   <div className="max-h-96 overflow-y-auto">
     <h1 className='font-bold text-xl '>Maintenence Vehicles</h1>
 <table className="w-[850px] text-sm text-left text-white-500 dark:text-white mt-10">

<thead style={{ backgroundColor: 'rgb(33,64,154)' }} className="text-xl text-white uppercasee bg-gray-50 dark:bg-gray-700 dark:text-gray-400 sticky top-0">
       <tr>
         <th scope="col" className="px-3 py-1 border border-white ">
           S.No
         </th>
         <th scope="col" className="px-6 py-3 border border-white">
           Vehicle No
         </th>
         <th scope="col" className="px-6 py-3 border border-white">
          StartDateTime
         </th>
         <th scope="col" className="px-6 py-3 border border-white">
          EndDateTime
         </th>
         <th scope="col" className="px-6 py-3 border border-white">
          Reason
         </th>
         <th scope="col" className="px-6 py-3 border border-white">
          BeReady
         </th>
       </tr>
     </thead>
     <tbody>
     {vehicledownData.map((data, index) => (
              <tr key={index} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                <td className="px-6 py-4  border border-gray-200">{index+1}</td>
                <td className="px-6 py-4  border border-gray-200 ">{data.vehicleNo}</td>
                <td className="px-6 py-4  border border-gray-200">{formatDate(data.startDateTime)}</td>
                <td className="px-6 py-4  border border-gray-200">{formatDate(data.endDateTime)}</td>
                <td className="px-6 py-4  border border-gray-200">{data.reason}</td>
                <button className="px-2 py-2 flex justify-center items-center mt-2 ml-6 text-white" style={{ backgroundColor: 'rgb(33,64,154)'}} onClick={() => handleBeReadyClick(index)}>BeReady</button>

              </tr>
            ))}
     </tbody>
   </table>
 </div>
)}
 <div className={`relative shadow-md sm:rounded-lg ${showTable ? 'hidden' : ''} mt-1 md:mt-0`}>
      <div className="p-4">
        <label htmlFor="table-search" className="sr-only">Search</label>
        <div className="relative mt-1">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <svg className="w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd"></path>
            </svg>
          </div>
          <input type="text" id="table-search" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-80 pl-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search for Vehicle No" onChange={handleSearch} value={searchTerm} />
        </div>
      </div>

      <div className={`max-h-80 overflow-y-auto ${showTable ? 'blur' : ''}`}>
      <table className="w-[750px] text-sm text-left text-white-500 dark:text-white">
<thead style={{ backgroundColor: 'rgb(33,64,154)' }} className="text-xl text-white uppercasee bg-gray-50 dark:bg-gray-700 dark:text-gray-400 sticky top-0">
            <tr>
              <th scope="col" className="px-3 py-1 border border-white h">
                S.No
              </th>
              <th scope="col" className="px-6 py-3 border border-white">
                Vehicle No
              </th>
              <th scope="col" className="px-6 py-3 border border-white">
                Capacity
              </th>
              <th scope="col" className="px-6 py-3 border border-white">
                Vehicle Type
              </th>
              <th scope="col" className="px-6 py-3 border border-white">
                Select
              </th>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((vehicle, index) => (
              <tr key={index} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
            
                <td className="px-6 py-4  border border-gray-200">{index+1}</td>
                <td className="px-6 py-4  border border-gray-200">{vehicle.vehicleNo}</td>
                <td className="px-6 py-4  border border-gray-200">{vehicle.capacity}</td>
                <td className="px-6 py-4  border border-gray-200">{vehicle.vehicleType}</td>
                <td className="px-6 py-4  border border-gray-200">
                  <div className="flex items-center justify-center">
                    <input id={`checkbox-table-select-${index}`} type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" 
                   checked={selectedVehicleIndices.includes(index)} onChange={() => handleCheckboxChange(index)} />
                    <label htmlFor={`checkbox-table-select-${index}`} className="sr-only">select</label>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="w-full mt-4 text-center">
        <button
          type="submit"
          className="inline-block px-4 py-2 bg-blue-600 text-white font-semibold rounded-md hover:bg-blue-700 transition duration-300"
          style={{ maxWidth: '200px' }}
          onClick={handleModalOpen}
          disabled={selectedVehicleIndices.length === 0 || formSubmitted}
        >
          Submit
        </button>
      </div>
      {modalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50" >
          <div className="bg-white p-8 rounded-lg relative">
            <h2 className="text-xl font-semibold mb-4 ">Enter Downtime Details</h2>
            <div className="flex flex-col space-y-4">
              <div className="flex">
                <input
                id="startDateTimeInput"
           
                  type="datetime-local"
                  value={startDateTime}
                  onChange={(e) => handleStartDateTimeChange(e.target.value)}
                  placeholder="YYYY-MM-DDTHH:MM AM/PM"
                  min={getCurrentDateTime()}
                  className={`border p-2 rounded w-64 mr-4 border-gray-500${!startDateTime && 'border-red-500'}`}
                  disabled={formSubmitted}
                />
                <input
                 id="endDateTimeInput"
                  type="datetime-local"
                  value={endDateTime}
                  min={getCurrentDateTime()}
                  onChange={(e) => handleEndDateTimeChange(e.target.value)}
                  placeholder="YYYY-MM-DDTHH:MM AM/PM"
                 
                  className={`border p-2 rounded w-64 mr-4 border-gray-500${!endDateTime && 'border-red-500'}`}
                  disabled={formSubmitted}
                />
                <input
                  id="reasonInput"
                  type="text"
                  placeholder="Reason"
                  value={reason}
                  onChange={(e) => setReason(e.target.value)}
                  className={`border p-2 rounded w-64 mr-4  border-gray-500${!reason && 'border-red-500'}`}
                  disabled={formSubmitted}
                />
              </div>
              <div className="flex justify-end">
                <button
                  className="px-4 py-2 bg-blue-600 text-white font-semibold rounded-md hover:bg-blue-700 transition duration-300 mr-2"
                  onClick={handleSubmit}
                  disabled={formSubmitted}
                >
                  Save
                </button>
                <button
        className="absolute top-0 right-0 flex items-center justify-center w-9 h-9 bg-red-500 text-white font-semibold rounded-full hover:bg-red-600 transition duration-300"
        onClick={handleModalClose}
      >
        &times;
      </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
 
      </div>
    ) : (
      <div>
        <div className="flex items-center">
          <img src={time} alt="Time" className="w-6 h-6  md:w-8 md:h-8 mr-2" />
          <h1 className="font-semibold text-gray-700 dark:text-gray-300 text-lg md:text-2xl">Vehicle Downtime</h1>
          <img src={ready} alt="Ready" className=" w-6 h-6  md:w-8 md:h-8 cursor-pointer  ml-5 mt-0 md:mt-0" onClick={handleReadyClick} />
        </div>
        {showTable && ( 
   <div className="max-h-80 w-[95vw] ml-2 overflow-y-auto ">
     <table className="w-[95vw]  text-xs md:text-sm md:mt-36 text-left text-white-500 dark:text-white mt-10">
       <thead style={{ backgroundColor: 'rgb(33,64,154)' }} className="text-xs md:text-xl text-white  sticky top-0">
         <tr>
           <th scope="col" className="px-1 py-1 text-xs md:px-3 md:py-1 border border-white text-center">
             S.No
           </th>
           <th scope="col" className="px-1 py-1 md:px-6  text-xs  border border-white text-center">
             Vehicle No
           </th>
           <th scope="col" className="px-1 py-1 md:px-6 md:py-3 text-xs border border-white text-center">
             StartDate Time
           </th>
           <th scope="col" className="px-1 py-1 md:px-6 md:py-3 text-xs border border-white text-center">
             EndDate Time
           </th>
           <th scope="col" className="px-1 py-1 md:px-6 md:py-3 text-xs border border-white text-center ">
             Reason
           </th>
           <th scope="col" className="px-1 py-1 text-center text-xs border border-white">
             BeReady
           </th>
         </tr>
       </thead>
       <tbody>
         {vehicledownData.map((data, index) => (
           <tr key={index} className="bg-white text-xs border-b hover:bg-gray-50  text-black">
             <td className="px-1 py-1 md:px-6 md:py-4 border border-gray-300 text-center">{index + 1}</td>
             <td className="px-1 py-1 md:px-6 md:py-4 border border-gray-300 text-center">{data.vehicleNo}</td>
             <td className="px-1 py-1 md:px-6 md:py-4 border border-gray-300 text-center">{formatDate(data.startDateTime)}</td>
             <td className="px-1 py-1 md:px-6 md:py-4 border border-gray-300 text-center">{formatDate(data.endDateTime)}</td>
             <td className="px-1 py-1 md:px-6 md:py-4 border border-gray-300 text-center">{data.reason}</td>
             <td className="px-1 py-1  border border-gray-300 text-center">
  <div className="flex justify-center items-center h-full">
    <button
      className="px-2 py-2 flex justify-center items-center text-white bg-blue-500 rounded-full"
      onClick={() => handleBeReadyClick(index)}
    >
      <FontAwesomeIcon icon={faCheckCircle} />
    </button>
  </div>
</td>



           </tr>
         ))}
       </tbody>
     </table>
   </div>
)}

 <div className={`relative shadow-md sm:rounded-lg ${showTable ? 'hidden' : ''} mt-4 md:mt-6`}>
      <div className="p-4">
        <label htmlFor="table-search" className="sr-only">Search</label>
        <div className="relative mt-1">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <svg className="w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd"></path>
            </svg>
          </div>
          <input type="text" id="table-search" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-80 pl-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search for Vehicle No" onChange={handleSearch} value={searchTerm} />
        </div>
      </div>

      <div className={` max-h-80 md:max-h-80 overflow-y-auto ${showTable ? 'blur' : ''}`}>
      <table className="w-full  text-xs md:text-sm  text-left text-white-500 dark:text-white">
<thead style={{ backgroundColor: 'rgb(33,64,154)' }} className=" text-xs  md:text-xl text-white uppercasee sticky top-0">
            <tr>
              <th scope="col" className="px-2 py-1 md:px-3 md:py-1 border border-white ">
                S.No
              </th>
              <th scope="col" className="px-2 py-1 md:px-6 md:py-3 border border-white">
                Vehicle No
              </th>
              <th scope="col" className="px-2 py-1 md:px-6 md:py-3 border border-white">
                Capacity
              </th>
              <th scope="col" className="px-1 py-1 md:px-6 md:py-3 border border-white">
                Vehicle Type
              </th>
              <th scope="col" className="px-1 py-1 md:px-6 md:py-3 border border-white">
                Select
              </th>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((vehicle, index) => (
              <tr key={index} className="bg-white border-b hover:bg-gray-50  text-black">
            
                <td className="px-2 py-1 md:px-6 md:py-4  border border-gray-200">{index+1}</td>
                <td className="px-2 py-1 md:px-6 md:py-4  border border-gray-200">{vehicle.vehicleNo}</td>
                <td className="px-2 py-1 md:px-6 md:py-4  border border-gray-200">{vehicle.capacity}</td>
                <td className="px-2 py-1 md:px-6 md:py-4 border border-gray-200">{vehicle.vehicleType}</td>
                <td className="px-2 py-1 md:px-6 md:py-4  border border-gray-200">
                  <div className="flex items-center justify-center">
                    <input id={`checkbox-table-select-${index}`} type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" 
                   checked={selectedVehicleIndices.includes(index)} onChange={() => handleCheckboxChange(index)} />
                    <label htmlFor={`checkbox-table-select-${index}`} className="sr-only">select</label>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="w-full mt-4 text-center">
        <button
          type="submit"
          className="inline-block px-4 py-2 bg-blue-600 text-white font-semibold rounded-md hover:bg-blue-700 transition duration-300"
          style={{ maxWidth: '200px' }}
          onClick={handleModalOpen}
          disabled={selectedVehicleIndices.length === 0 || formSubmitted}
        >
          Submit
        </button>
      </div>
      {modalOpen && (
  <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
    <div className="bg-white p-4 md:p-8 rounded-lg relative w-full max-w-xs">
      <h2 className="text-lg md:text-xl font-semibold mb-4">Enter Downtime Details</h2>
      <div className="flex flex-col space-y-4">
  <div className="flex flex-col">
    <label htmlFor="startDateTimeInput" className="text-sm">Start Date Time</label>
    <input
      id="startDateTimeInput"
      type="datetime-local"
      value={startDateTime}
      onChange={(e) => handleStartDateTimeChange(e.target.value)}

      min={getCurrentDateTime()}
      placeholder="YYYY-MM-DDTHH:MM AM/PM"
      className={`border p-2 rounded border-gray-500${!startDateTime && ' border-red-500'}`}
      disabled={formSubmitted}
    />
  </div>
  <div className="flex flex-col">
    <label htmlFor="endDateTimeInput" className="text-sm">End Date Time</label>
    <input
      id="endDateTimeInput"
      type="datetime-local"
      value={endDateTime}
      onChange={(e) => handleEndDateTimeChange(e.target.value)}
      placeholder="YYYY-MM-DDTHH:MM AM/PM"
      className={`border p-2 rounded border-gray-500${!endDateTime && ' border-red-500'}`}
      disabled={formSubmitted}
    />
  </div>
  <div className="flex flex-col">
  <label htmlFor="reasonInput" className="text-sm">Reason</label>
  <input
    id="reasonInput"
    type="text"
    placeholder="Reason"
    value={reason}
    onChange={(e) => setReason(e.target.value)}
    className={`border p-2 rounded border-gray-500 text-sm${!reason && ' border-red-500'}`}
    disabled={formSubmitted}
  />
</div>

  <div className="flex justify-end">
    <button
      className="px-4 py-2 bg-blue-600 text-white font-semibold rounded-md hover:bg-blue-700 transition duration-300 mr-2"
      onClick={handleSubmit}
      disabled={formSubmitted}
    >
      Save
    </button>
    <button
      className="absolute top-1 right-1 flex items-center justify-center w-8 h-8 bg-red-500 text-white font-semibold rounded-full hover:bg-red-600 transition duration-300"
      onClick={handleModalClose}
    >
      &times;
    </button>
  </div>
</div>

    </div>
  </div>
)}

    </div>
    <ToastContainer />
      </div>
    )}
   
  </div>
);
}

export default Vehicledownwise;