import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faTrashAlt, faFileExcel, faFileExport, faTint, faBuilding, faFaucet, faPumpSoap, faWater, faTintSlash, faWaterLadder, faGasPump } from '@fortawesome/free-solid-svg-icons';
import config from '../../config';
import AssignTanker from './AssignTanker';
import { toast } from 'react-toastify'; // Import react-toastify for popups
import 'react-toastify/dist/ReactToastify.css';
import * as XLSX from 'xlsx';
import axios from 'axios';
import jsPDF from "jspdf";
import "jspdf-autotable";
import FillingStationRow from './Tooltip';



const TankerTable = ({ userData }) => {

  const [userdata, setuserdata] = useState([])
  const [isUpdating, setIsUpdating] = useState(false);
  const [tankerData, setTankerData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null); // State to keep track of the selected item
  
  const [searchType, setSearchType] = useState('referenceNo');
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1020);
  const [error, setError] = useState('');
  const [showLoginForm, setShowLoginForm] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [usertoken, setusertoken] = useState('')


  useEffect(() => {
    const userData = JSON.parse(sessionStorage.getItem("user"));
    if (userData) {
      setuserdata(userData.user);
      setusertoken(userData.token)
      // console.log('use',userData);
    }
  }, []);

  const isAdminOrTimekeeper = userdata.user_role === 'Admin' || userdata.user_role === 'Timekeeper';

  const isAdmin = userdata.user_role === 'Admin'
  const isAE = userdata.user_role === 'AE'





  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1020);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);



  const handleSearch = (query) => {
    setSearchQuery(query);
    // Filter the tanker data based on the selected search type and search query
    const filtered = tankerData.filter(item => {
      switch (searchType) {
        case 'referenceNo':
          return item.id.toString().toLowerCase().includes(query.toLowerCase());
        case 'requestedDateTime':
          return `${item.request_date.split(' ')[0]} ${item.request_time}`.toLowerCase().includes(query.toLowerCase());

        case 'quantity':
          return item.requestedQuantity.toString().includes(query);
        case 'fillingStation':
          return item.fillingstation.toLowerCase().includes(query.toLowerCase());
        case 'location':
          return item.locality.toLowerCase().includes(query.toLowerCase());
        case 'officer':
          // Assuming there is an officer field in your data
          return item.officer.toLowerCase().includes(query.toLowerCase());
        default:
          return true; // If no specific search type is selected, return all data
      }
    });
    setFilteredData(filtered);
  };


  const handleSearchTypeChange = (type) => {
    setSearchType(type);
    setSearchQuery('');
    setFilteredData([]);

    // Get the array of values based on the selected search type
    let values = [];
    switch (type) {
      case 'referenceNo':
        values = tankerData.map(item => item.id);
        break;
      case 'requestedDateTime':
        values = tankerData.map(item => `${item.request_date.split(' ')[0]} ${item.request_time}`);
        break;
      case 'quantity':
        values = tankerData.map(item => item.requestedQuantity.toString());
        break;
      case 'fillingStation':
        values = tankerData.map(item => item.fillingstation);
        break;
      case 'location':
        values = tankerData.map(item => item.locality);
        break;
      case 'officer':
        values = tankerData.map(item => item.officer);
        break;
      default:
        break;
    }

    // Filter out duplicate values and set the suggestions
    const uniqueValues = [...new Set(values)];
    setSuggestions(uniqueValues);
  };


  useEffect(() => {

    fetchData();

  }, [userdata]);


  //delete


  const [showConfirmation, setShowConfirmation] = useState(false);
  const handleDeleteClick = (item) => {
    setSelectedItem(item)
    setShowConfirmation(true);
  };

  const handleCancelDelete = () => {
    setSelectedItem(null);
    setShowConfirmation(false);
  };
  const handleDelete = async () => {
    setIsUpdating(true);
    try {
      const token = usertoken ? usertoken : null;
      // console.log(token);
      // Get the token from sessionStorage
      const configToken = {
        headers: {
          Authorization: `Bearer ${token}`, // Set the Authorization header with the token
        },
      };
      const response = await fetch(`${config.apiUrl}/newtanker/requestTanker/${selectedItem.id}`, {
        method: 'DELETE',
        headers: configToken.headers,
      });
      if (response.ok) {
        // Remove the deleted item from the local state
        setTankerData(tankerData.filter(item => item.id !== selectedItem.id));
        setFilteredData(filteredData.filter(item => item.id !== selectedItem.id))
        setIsUpdating(false); // Set updating status back to false after successful update
        toast.success('Deleted successfully');
        // Show success toast
        await new Promise((resolve) => setTimeout(resolve, 1000));
        handleCancelDelete();
      } else {
        setIsUpdating(false); // Set updating status back to false on error
        console.error('Error Deleting');
        toast.error('Error Deleting');
      }
    } catch (error) {
      setIsUpdating(false); // Set updating status back to false on error
      console.error('Error Deleting', error);
      toast.error('Error Deleting');
    }
  };

  useEffect(() => {
    handleSearchTypeChange(searchType);
  }, [tankerData, searchType]);




  const fetchData = async () => {
    if (userdata && userdata.user_role) {
      try {
        const token = usertoken ? usertoken : null;
        // console.log(token);
        // Get the token from sessionStorage
        const configToken = {
          headers: {
            Authorization: `Bearer ${token}`, // Set the Authorization header with the token
          },
        };
        const url = `${config.apiUrl}/newtanker/getTankerRequestData`;
        const params = {
          user_role: userdata.user_role,
          firstName: userdata.user_first_name,
          lastName: userdata.user_last_name
        };

        const response = await axios.get(url, { params, headers: configToken.headers, },
        );
        const data = response.data;

        if (Array.isArray(data)) {
          setTankerData(data);
        } else {
          console.error('Error: Data is not in the expected format');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }

  };


  const openModal = (item) => {
    setSelectedItem(item); // Set the selected item
    setShowModal(true);
  };


  const closeModal = () => {
    setShowModal(false);
    setSelectedItem(null); // Reset the selected item when modal is closed
    fetchData()
  };
  const handlePdf = async () => {
    try {
      const token = usertoken ? usertoken : null;
      // Get the token from sessionStorage
      const configToken = {
        headers: {
          Authorization: `Bearer ${token}`, // Set the Authorization header with the token
        },
      };
      const response = await axios.post(`${config.apiUrl}/user/exportUser`, { loginIdentifier: username, password }, { headers: configToken.headers });

      const { data, message } = response.data;
      if (response.status === 200) {
        if (data.user.user_role === 'Admin') {
          const doc = new jsPDF();



          // Add header row
          const header = [["S.No",
            "Reference No",
            "Requested Date",
            "Qty",
            "Filling Station",
            "Officer",
            "Location",]];

          // Add data rows
          const dataRows = (filteredData.length > 0 ? filteredData : tankerData).map((item, index) => {
            let address = '';
            if (item.locality) {
              address = item.locality;
            }
            return [
              index + 1,
              item.id,
              item.request_date ? `${item.request_date.split(' ')[0]} ${item.request_time}` : '',

              item.requestedQuantity,

              item.fillingstation,
              address,

            ]
          });


          // Add table to PDF
          doc.autoTable({
            head: header,
            body: dataRows,

          });

          // Save PDF
          doc.save('ViewTanker.pdf');
          setError('');
          setShowLoginForm(false);
        }
        else {
          setError('Unauthorized User');
        }
      }
      else {
        setError(message);
      }
    } catch (error) {
      setError('Failed to export Excel file');
    }
  };

  const handleExcel = async () => {
    try {
      const token = usertoken ? usertoken : null;
      // Get the token from sessionStorage
      const configToken = {
        headers: {
          Authorization: `Bearer ${token}`, // Set the Authorization header with the token
        },
      };
      const response = await axios.post(`${config.apiUrl}/user/exportUser`, { loginIdentifier: username, password }, { headers: configToken.headers });
      const { data, message } = response.data;
      if (response.status === 200) {
        if (data.user.user_role === 'Admin') {
          const fileName = "viewTanker.xlsx";
          const wsData = [
            [
              "S.No",
              "Reference No",
              "Requested Date",
              "Qty",
              "Filling Station",
              "Officer",
              "Location", // Add column for Location
              //  / Add column for Vehicle Number
            ],
            ...(filteredData.length > 0 ? filteredData : tankerData).map((item, index) => [
              index + 1,
              item.id,
              `${item.request_date.split(' ')[0]} ${item.request_time}`,

              item.requestedQuantity,
              item.fillingstation,
              item.username,
              "", // Leave empty for Location (will fill later)
              // Leave empty for Vehicle Number (will fill later)
            ])
          ];

          // Fill location and vehicle data for each row
          wsData.slice(1).forEach((row, index) => {
            const selectedItem = (filteredData.length > 0 ? filteredData : tankerData)[index];
            if (selectedItem && selectedItem.locality) {
              // const locations = JSON.parse(selectedItem.location_data).map(location => location.address).join(', ');
              row[wsData[0].indexOf("Location")] = selectedItem.locality;
            }
            if (selectedItem) {
              row[wsData[0].indexOf("Driver Name")] = selectedItem.driverName || "";
              row[wsData[0].indexOf("Driver No")] = selectedItem.driverMobileNo || "";
              row[wsData[0].indexOf("Vehicle No")] = selectedItem.vehicleNo || "";
            }
          });

          const ws = XLSX.utils.aoa_to_sheet(wsData);
          const wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, "viewTanker");

          // Export the workbook
          XLSX.writeFile(wb, fileName);
          setError('');
          setShowLoginForm(false);
        }
        else {
          setError('Unauthorized User');
        }
      }
      else {
        setError(message);
      }
    } catch (error) {
      setError('Failed to export Excel file');
    }
  };
  const handleClose = () => {
    // Function to handle closing the login form
    setShowLoginForm(false);
    setUsername('');
    setPassword('');
    setError('');
  };



  return (
    <div className="container mx-auto ">
      <div className="flex items-center justify-between">
        <h1 className="text-xl font-bold mb-3">Tanker Requests</h1>
        {!showLoginForm && (<button className="bg-green-500 block sm:hidden w-[40px] hover:bg-green-600 text-white py-1 px-2 rounded-md" onClick={() => setShowLoginForm(true)}>
          <FontAwesomeIcon icon={faFileExport} className="mr-2" />
        </button>
        )}
      </div>



      <div className="flex flex-col md:flex-row mb-4">
        <select
          value={searchType}
          onChange={(e) => handleSearchTypeChange(e.target.value)}
          className="mr-2 mb-2 md:mb-0 w-full md:mr-0 md:w-auto px-2 py-1 border border-gray-300 rounded md:px-3 md:py-2"
        >
          <option value="referenceNo">Reference No</option>
          <option value="requestedDateTime">Requested Date</option>
          <option value="quantity">Quantity</option>
          <option value="fillingStation">Filling Station</option>
          <option value="location">Locality</option>
        </select>
        <div className="flex-grow">
          <input
            type="text"
            value={searchQuery}
            onChange={(e) => handleSearch(e.target.value)}
            placeholder="Search..."
            className="px-1 py-1 w-full border border-gray-300 rounded md:w-auto"
            list="suggestions"
          />
          <datalist id="suggestions">
            {suggestions.map((item, index) => (
              <option key={index} value={item} />
            ))}
          </datalist>
        </div>
        {!showLoginForm && (
          <button className="mt-1 md:mt-0 bg-green-500 w-[40px] hover:bg-green-600 text-white py-1 px-2 rounded-md hidden sm:block" onClick={() => setShowLoginForm(true)}>
            <FontAwesomeIcon icon={faFileExport} className="mr-2" />
          </button>
        )}

      </div>


      {!isMobile ? (<div className="w-full h-[60vh] overflow-x-auto  overflow-y-auto ">
        <table id='updatedData' className=" w-full">
          <thead className='sticky top-0'>
            <tr className="text-white text-left" style={{ backgroundColor: 'rgb(33,64,154)' }}>
              <th className="px-5 py-3 text-xs font-semibold uppercase">S.No</th>
              <th className="px-5 py-3 text-xs font-semibold uppercase">Reference No</th>
              <th className="px-5 py-3 text-xs font-semibold uppercase">Requested Date</th>
              <th className="px-5 py-3 text-xs font-semibold uppercase">Qty</th>
              <th className="px-5 py-3 text-md font-semibold uppercase"> <FontAwesomeIcon icon={faTint} />
              </th>
              <th className="px-5 py-3 text-xs font-semibold uppercase">Locality</th>
              <th className="px-5 py-3 text-xs font-semibold uppercase">Officer</th>
              {isAdminOrTimekeeper && (<th className="px-5 py-3 text-xs font-semibold uppercase">Assign</th>)}

              {(isAdmin || isAE) && (<th className="px-5 py-3 text-xs font-semibold uppercase"></th>)}
            </tr>
          </thead>
          <tbody>
            {filteredData.length > 0 ? (
              filteredData.map((item, index) => (
                <tr key={item.id} className="text-left border-b-2">
                  <td className="px-5 py-3 text-sm">{index + 1}</td>
                  <td className="px-5 py-3 text-sm">{item.id}</td>
                  <td className="px-5 py-3 text-sm">{item.request_date.split(' ')[0]} {item.request_time}</td>
                  <td className="px-5 py-3 text-sm">{item.requestedQuantity}</td>
                  <FillingStationRow item={item.fillingstation} icon={faTint} id={item.id} styl={`px-5 py-3 text-sm text-blue-500`} />
                  <td className="px-5 py-3 text-sm">
                    <FontAwesomeIcon
                      icon={faMapMarkerAlt}
                      className="cursor-pointer text-blue-500 mr-1"
                      // onClick={() => openLocationModal(item)}
                    />{item.locality}
                  </td>
                  <td className="px-5 py-3 text-xs">{item.username} </td> {/* Officer Name */}
                  {isAdminOrTimekeeper && (<td className="px-5 py-3 text-xs">

                    <button onClick={() => openModal(item)} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                      Assign
                    </button>

                  </td>)}

                  {isAdmin && (<td className="px-3 py-3 text-sm">
                    <FontAwesomeIcon
                      icon={faTrashAlt}
                      className="cursor-pointer text-red-500"
                      onClick={() => handleDeleteClick(item)}
                    />
                  </td>
                  )}
                </tr>

              ))
            ) : (
              tankerData.map((item, index) => (
                <tr key={item.id} className="text-left border-b-2">
                  <td className="px-5 py-3 text-sm">{index + 1}</td>
                  <td className="px-5 py-3 text-sm">{item.id}</td>
                  <td className="px-5 py-3 text-sm">{item.request_date.split(' ')[0]} {item.request_time}</td>
                  <td className="px-5 py-3 text-sm">{item.requestedQuantity}</td>
                  <FillingStationRow item={item.fillingstation} icon={faTint} id={item.id} styl={`px-5 py-3 text-sm text-blue-500`} />
                  <td className="px-5 py-3 text-sm">
                    <FontAwesomeIcon
                      icon={faMapMarkerAlt}
                      className="cursor-pointer text-blue-500 mr-1"
                      // onClick={() => openLocationModal(item)}
                    />{item.locality}
                  </td>
                  <td className="px-5 py-3 text-xs">{item.username} </td> {/* Officer Name */}
                  {isAdminOrTimekeeper && (<td className="px-5 py-3 text-xs">

                    <button onClick={() => openModal(item)} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                      Assign
                    </button>

                  </td>)}
                  {(isAdmin || isAE) && (<td className="px-3 py-3 text-sm">
                    <FontAwesomeIcon
                      icon={faTrashAlt}
                      className="cursor-pointer text-red-500"
                      onClick={() => handleDeleteClick(item)}
                    />
                  </td>
                  )}
                </tr>
              ))
            )}
          </tbody>

        </table>
      </div>) : (

        <div className="w-full h-[60vh] overflow-y-auto ">
          <div className="grid grid-cols-1 gap-4 p-1">
            {filteredData.length > 0 ? (


              filteredData.map((item, index) => (

                <div key={index} className=" w-[98%] border border-10 border-gray-300 rounded-md  transform transition duration-300 ease-in-out hover:scale-105  bg-white " style={{ boxShadow: 'rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px' }}>

                  <table id='ViewTanker' className="w-full text-gray-700 ">
                    <tbody>
                      <tr className="p-1">
                        <td className="font-semibold px-1 py-2 text-xs sm:text-sm md:text-md uppercase">Reference No</td>
                        <td className="px-1 py-2 text-xs sm:text-sm md:text-md font-semibold">{item.id}</td>
                      </tr>
                      <tr className="p-1">
                        <td className="font-semibold px-1 py-2 text-xs sm:text-sm md:text-md uppercase">Request Date</td>
                        <td className="px-1 py-2 text-xs sm:text-sm md:text-md font-semibold">{item.request_date.split(' ')[0]}</td>
                      </tr>
                      <tr className="p-1">
                        <td className="font-semibold px-1 py-2 text-xs sm:text-sm md:text-md uppercase">Request Time</td>
                        <td className="px-1 py-2 text-xs sm:text-sm md:text-md font-semibold">{item.request_time}</td>
                      </tr>
                      <tr className="p-1">
                        <td className="font-semibold px-1 py-2 text-xs sm:text-sm md:text-md uppercase">Quantity</td>
                        <td className="px-1 py-2 text-xs sm:text-sm md:text-md font-semibold">{item.requestedQuantity}</td>
                      </tr>
                      <tr>
                        <td className="font-semibold px-1 py-2 text-xs sm:text-sm md:text-md uppercase">Officer</td>
                        <td className="px-1 py-2 text-xs sm:text-sm md:text-md font-semibold">{item.username}</td>
                      </tr>
                      <tr className="p-1">
                        <td className="font-semibold px-1 py-2 text-xs sm:text-sm md:text-md uppercase">Filling Station</td>
                        <td className="px-1 py-2 text-xs sm:text-sm md:text-md font-semibold">{item.fillingstation}</td>
                      </tr>

                      <tr className="p-1">
                        <td className="font-semibold px-1 py-2 text-xs sm:text-sm md:text-md uppercase">Location</td>
                        <td className="px-1 py-2 text-xs sm:text-sm md:text-md font-semibold">
                          <FontAwesomeIcon
                            icon={faMapMarkerAlt}
                            className="cursor-pointer text-blue-500 mr-2"
                          />{item.locality}
                        </td>
                      </tr>



                    </tbody>
                  </table>
                  <div className="flex items-center justify-end px-4 py-2">
                    <div className="flex items-center">
                      {isAdmin && (<FontAwesomeIcon
                        icon={faTrashAlt}
                        className="cursor-pointer text-red-500 mr-4"
                        onClick={() => handleDeleteClick(item)}
                      />)}
                    </div>
                    {isAdminOrTimekeeper && (<button onClick={() => openModal(item)} className="bg-blue-500 text-white py-1 px-2 rounded-full transition duration-300 ease-in-out">Assign</button>
                    )}
                  </div>

                </div>

              ))
            ) : (
              tankerData.map((item, index) => (
                <div key={index} className=" w-[98%] border border-10 border-gray-300 rounded-md  transform transition duration-300 ease-in-out hover:scale-105  bg-white" style={{ boxShadow: 'rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px' }}>

                  <table className="w-full mt-2 text-gray-700 ">
                    <tbody>
                      <tr className="p-1">
                        <td className="font-semibold px-1 py-2 text-xs sm:text-sm md:text-md uppercase">Reference No</td>
                        <td className="px-1 py-2 text-xs sm:text-sm md:text-md font-semibold">{item.id}</td>
                      </tr>
                      <tr className="p-1">
                        <td className="font-semibold px-1 py-2 text-xs sm:text-sm md:text-md uppercase">Request Date</td>
                        <td className="px-1 py-2 text-xs sm:text-sm md:text-md font-semibold">{item.request_date.split(' ')[0]}</td>
                      </tr>
                      <tr className="p-1">
                        <td className="font-semibold px-1 py-2 text-xs sm:text-sm md:text-md uppercase">Request Time</td>
                        <td className="px-1 py-2 text-xs sm:text-sm md:text-md font-semibold">{item.request_time}</td>
                      </tr>
                      <tr className="p-1">
                        <td className="font-semibold px-1 py-2 text-xs sm:text-sm md:text-md uppercase">Quantity</td>
                        <td className="px-1 py-2 text-xs sm:text-sm md:text-md font-semibold">{item.requestedQuantity}</td>
                      </tr>
                      <tr>
                        <td className="font-semibold px-1 py-2 text-xs sm:text-sm md:text-md uppercase">Officer</td>
                        <td className="px-1 py-2 text-xs sm:text-sm md:text-md font-semibold">{item.username}</td>
                      </tr>
                      <tr className="p-1">
                        <td className="font-semibold px-1 py-2 text-xs sm:text-sm md:text-md uppercase">Filling Station</td>
                        <td className="px-1 py-2 text-xs sm:text-sm md:text-md font-semibold">{item.fillingstation}</td>
                      </tr>

                      <tr className="p-1">
                        <td className="font-semibold px-1 py-2 text-xs sm:text-sm md:text-md uppercase">Location</td>
                        <td className="px-1 py-2 text-xs sm:text-sm md:text-md font-semibold">
                          <FontAwesomeIcon
                            icon={faMapMarkerAlt}
                            className="cursor-pointer text-blue-500 mr-2"
                          />{item.locality}
                        </td>
                      </tr>


                    </tbody>
                  </table>
                  <div className="flex items-center justify-end px-4 py-2">
                    <div className="flex items-center">

                      {isAdmin && (<FontAwesomeIcon
                        icon={faTrashAlt}
                        className="cursor-pointer text-red-500 mr-4"
                        onClick={() => handleDeleteClick(item)}
                      />)}
                    </div>
                    {isAdminOrTimekeeper && (<button onClick={() => openModal(item)} className="bg-blue-500 text-white py-1 px-2 rounded-full transition duration-300 ease-in-out">Assign</button>
                    )}

                  </div>
                </div>
              ))
            )}


          </div>
        </div>



      )}


      {showModal && (
        <div className="fixed inset-0 z-40 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
          <div className="fixed inset-0 bg-black bg-opacity-50"></div>
          <div className="relative w-auto max-w-3xl mx-auto my-4">

            <div className="bg-white p-4 rounded-lg shadow-md">
              <button onClick={closeModal} className="absolute top-2 right-0 mr-4 text-gray-700 hover:text-gray-900">
                <svg className="h-6 w-6 text-red-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
              <AssignTanker
                data={selectedItem}
                onClose={closeModal}
              />
            </div>
          </div>
        </div>
      )}

  


      {showConfirmation && (
        <div className="fixed inset-0 z-40 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
          <div className="fixed inset-0 bg-black bg-opacity-50"></div>
          <div className="relative sm:w-auto w-[80vw] max-w-3xl mx-auto my-4">
            <div className="bg-white p-6 rounded-lg shadow-md">

              <p className="mb-3">Do you want to delete this request {`${selectedItem.id}`}?</p>
              <div className="flex justify-center space-x-3">
                <button
                  disabled={isUpdating}
                  onClick={handleDelete}
                  className={`bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded ${isUpdating ? 'opacity-50 cursor-not-allowed' : ''}`}
                >
                  Yes
                </button>
                <button
                  onClick={handleCancelDelete}
                  className="bg-gray-400 hover:bg-gray-500 text-white px-4 py-2 rounded"
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {showLoginForm && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
          <div className="bg-white w-full max-w-xs md:max-w-lg rounded-lg overflow-hidden shadow-xl">
            <div className="px-6 py-4">
              <div className="flex justify-end">
                <button className="text-gray-700 hover:text-gray-900" onClick={handleClose}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
              <label htmlFor="username" className="text-gray-700">Username</label>
              <input
                type="text"
                id="username"
                placeholder="Username or Mobile Number"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                className="border border-gray-400 px-3 py-2 rounded-md mb-2 w-full"
              />
              <label htmlFor="password" className="text-gray-700">Password</label>
              <input
                type="password"
                id="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="border border-gray-400 px-3 py-2 rounded-md mb-2 w-full"
              />
              <button
                onClick={handleExcel}
                className="bg-green-500 hover:bg-green-600 text-white py-2 px-4 rounded-md mr-2"
              >
                Excel
              </button>
              <button
                onClick={handlePdf}
                className="bg-green-500 hover:bg-green-600 text-white py-2 px-4 rounded-lg mr-2"
              >
                PDF
              </button>

              {error && <p className="text-red-500 mt-2">{error}</p>}
            </div>
          </div>
        </div>
      )}



    </div>

  );

};

export default TankerTable;
