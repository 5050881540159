import React, { useState, useEffect ,useRef} from 'react';
import axios from 'axios';
import config from "../../config";
import { FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import DatePicker from 'react-datepicker'; // Import react-datepicker
import 'react-datepicker/dist/react-datepicker.css';
import { faTruck, faMapMarkerAlt, faSearch ,faFileExport,faTimes,faFileExcel,faInfoCircle} from '@fortawesome/free-solid-svg-icons';
import fill from '../../assests/fill.png';
import { FaPrint } from 'react-icons/fa';
import ReactToPrint from "react-to-print";
import moment from 'moment';
import * as XLSX from 'xlsx';
import jsPDF from "jspdf";
import "jspdf-autotable";


function Fillingstationreport({ fillingStation }) {
  // console.log('filling',fillingStation);
    const [data, setData] = useState([]);
    const [additionalData, setAdditionalData] = useState([]);
    const[showTable,setShowTable]=useState(false);
    const[assignModalData,setAssignModalData]=useState(null);
    const [modalData, setModalData] = useState(null);
    const [locationModal,setLocationModal] = useState(false);
    const [vehicleModal,setVehicleModal] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [selectedFillingstation, setSelectedFillingstation] = useState(''); // Define sel
    const currentMonthStart = moment().startOf('month').format('YYYY-MM-DD');
  const currentMonthEnd = moment().endOf('month').format('YYYY-MM-DD');
     const [fromDate, setFromDate] = useState(currentMonthStart);
    const [toDate, setToDate] = useState(currentMonthEnd);
    const componentRef = useRef();
    const [noDataFound, setNoDataFound] = useState(false);
    const [totalDeliveredRequestsSaved, setTotalDeliveredRequestsSaved] = useState(0);
    const [totalDeliveredQuantityOfWater, setTotalDeliveredQuantityOfWater] = useState(0);
    const [showAdditionalTable, setShowAdditionalTable] = useState(false);
    const [showAssignedTable, setShowAssignedTable] = useState(false);
    const [totalAssignedRequestsSaved, setTotalAssignedRequestsSaved] = useState(0);
    const [totalAssignedQuantityOfWater, setTotalAssignedQuantityOfWater] = useState(0);
    const [assignedData, setAssignedData] = useState([]);
    const [searchType, setSearchType] = useState('referenceNo');
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [suggestions, setSuggestions] = useState([]);
    const [searchadditionType, setAdditionSearchType] = useState('referenceNo');
    const [searchadditionQuery, setSearchAdditionQuery] = useState('');
    const [filteredadditionData, setFilteredAdditionData] = useState([]);
    const [additionsuggestions, setAdditionSuggestions] = useState([]);
    const [deliverederror, setDeliveredError] = useState('');
    const [showDeliveredLoginForm, setDeliveredShowLoginForm] = useState(false);
    const [deliveredusername, setdeliveredUsername] = useState('');
    const [deliveredpassword, setdeliveredPassword] = useState('');
    const [assignederror, setAssignedError] = useState('');
    const [showAssignedLoginForm, setShowAssignedLoginForm] = useState(false);
    const [assignedusername, setAssignedUsername] = useState('');
    const [assignedpassword, setAssignedPassword] = useState('');
    const [isMobile, setIsMobile] = useState(window.innerWidth < 1020);
    const [user,setUser] = useState([]);
    const [usertoken,setusertoken] = useState('')
   
    useEffect(() => {
      const handleResize = () => {
        setIsMobile(window.innerWidth < 1020);
      };
 
      window.addEventListener('resize', handleResize);
 
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

    
  useEffect(() => {
    
    const userData = JSON.parse(sessionStorage.getItem("user"));
    if (userData) {
      setUser(userData.user);
      setusertoken(userData.token)
      // console.log('userdatsa',userData.user);
       
    }
  
   
  }, []);

  {/* Initialize a counter */}
let indexCounter = 1;


   
const openModal = (item) => {
  setModalData(item);
};

const closeModal = () => {
  setModalData(null);
};
const AssignedopenModal = (item) => {
  setAssignModalData(item);
};

const AssignedcloseModal = () => {
  setAssignModalData(null);
};
 
    const openLocationModal = (item) => {
      setSelectedItem(item); // Set the selected item
      setLocationModal(true);
      // console.log('item',item);
    };
 
    const closeLocationModal = (item) => {
      setSelectedItem(null); // Set the selected item
      setLocationModal(false);
    };
 
    const openVehicleModal = (item) => {
      setSelectedItem(item); // Set the selected item
      setVehicleModal(true);
      // console.log('item',item);
    };
 
    const closeVehicleModal = (item) => {
      setSelectedItem(item); // Set the selected item
      setVehicleModal(false);
      // console.log('item',item);
    };
 
    const handleSearch = (query) => {
        setSearchQuery(query);
        // Filter the tanker data based on the selected search type and search query
        const filtered = assignedData.filter(item => { // Change tankerData to assignedData
            switch (searchType) {
                case 'referenceNo':
                    return item.id.toLowerCase().includes(query.toLowerCase());
                    case 'locality':
                        return item.locality.toLowerCase().includes(query.toLowerCase());
                case 'quantity':
                    return item.requestedQuantity.toString().includes(query);
                case 'fillingStation':
                    return item.fillingstation.toLowerCase().includes(query.toLowerCase());
                case 'officer':
                    return item.officer.toLowerCase().includes(query.toLowerCase());
                case 'zone':
                    return item.zone.toLowerCase().includes(query.toLowerCase());
                case 'ward':
                    return item.ward.toLowerCase().includes(query.toLowerCase()); // Using getStatus function here
                case 'vehicleNo':
                    return item.vehicleNo.toLowerCase().includes(query.toLowerCase());  
                default:
                    return true; // If no specific search type is selected, return all data
            }
        });
        setFilteredData(filtered);
    };
   
    const handleSearchTypeChange = (type) => {
        setSearchType(type);
        setSearchQuery('');
        setFilteredData([]);
       
        // Get the array of values based on the selected search type
        let values = [];
        switch (type) {
            case 'referenceNo':
                values = assignedData.map(item => item.id); // Change tankerData to assignedData
                break;
            case 'locality':
                values = assignedData.map(item => item.locality);  // Change tankerData to assignedData
                break;
            case 'quantity':
                values = assignedData.map(item => item.requestedQuantity.toString()); // Change tankerData to assignedData
                break;
            case 'fillingStation':
                values = assignedData.map(item => item.fillingstation); // Change tankerData to assignedData
                break;
            case 'officer':
                values = assignedData.map(item => item.username); // Change tankerData to assignedData
                break;
            case 'zone':
                values = assignedData.map(item => item.zone);  // Change tankerData to assignedData
                break;
            case 'ward':
                values = assignedData.map(item => item.ward);  // Using getStatus function here for assignedData
                break;
            case 'vehicleNo':
                values = assignedData.map(item => item.vehicleNo); // Change tankerData to assignedData
                break;
            default:
                break;
        }
     
        // Filter out duplicate values and set the suggestions
        const uniqueValues = [...new Set(values)];
        setSuggestions(uniqueValues);
    };
   
    useEffect(() => {
        handleSearchTypeChange(searchType);
    }, [assignedData, searchType]); // Change tankerData to assignedData
   
 

    const handleClick = (selectedFillingStation) => {
      const token = usertoken ? usertoken : null;
      // Get the token from sessionStorage
          const configToken = {
            headers: {
              Authorization: `Bearer ${token}`, // Set the Authorization header with the token
            },
          };
        // Fetch data from backend API with the selected filling station as a query parameter
        axios.get(`${config.apiUrl}/report/getDeliveredData`, {
            params: {
                fillingStation: selectedFillingStation,
                fromDate:fromDate,
                toDate:toDate,
                user_role:user.user_role,
                firstName:user.user_first_name,
                lastName:user.user_last_name
               
            },
            ...configToken
        })
        .then(response => {
            setAdditionalData(response.data);
            setShowTable(false)
            setShowAdditionalTable(true);
            setShowAssignedTable(false);
        })
        .catch(error => {
            console.error('Error fetching data:', error);
        });
    };
   
    const handleAssignClick = (selectedFillingStation) => {
      const token = usertoken ? usertoken : null;
      // Get the token from sessionStorage
          const configToken = {
            headers: {
              Authorization: `Bearer ${token}`, // Set the Authorization header with the token
            },
          };
        // Fetch data from backend API with the selected filling station as a query parameter
        axios.get(`${config.apiUrl}/report/getAssignedData`, {
            params: {
                fillingStation: selectedFillingStation,
                fromDate:toDate,
                toDate:toDate,
                user_role:user.user_role,
                firstName:user.user_first_name,
                lastName:user.user_last_name
            },
            ...configToken
        })
        .then(response => {
            setAssignedData(response.data);
            setShowTable(false)
            setShowAdditionalTable(false);
            setShowAssignedTable(true)
        })
        .catch(error => {
            console.error('Error fetching data:', error);
        });
    };
    useEffect(() => {
        // Fetch all data from backend API initially
        fetchAllData();
    }, [user]);

    const fetchAllData = () => {
      // console.log('userrole',user.user_role);
      const token = usertoken ? usertoken : null;
      // Get the token from sessionStorage
          const configToken = {
            headers: {
              Authorization: `Bearer ${token}`, // Set the Authorization header with the token
            },
          };
      if(user && user.user_role) {
        axios.get(`${config.apiUrl}/report/fillingstation_data`, {
          params: {
            user_role:user.user_role,
            firstName:user.user_first_name,
            lastName:user.user_last_name,
            fromDate:fromDate,
            toDate:toDate,
        },
        ...configToken
        })
        .then(response => {
            const { delivered, assigned } = response.data;
   
            // Process delivered data to calculate total delivered requests saved and total delivered quantity of water
            const totalDeliveredRequestsSaved = delivered.reduce((acc, curr) => acc + curr.requestCount, 0);
            const totalDeliveredQuantityOfWater = delivered.reduce((acc, curr) => acc + curr.totalQuantity, 0);
   
            // Process assigned data to calculate total assigned requests saved and total assigned quantity of water
            const totalAssignedRequestsSaved = assigned.reduce((acc, curr) => acc + curr.requestCount, 0);
            const totalAssignedQuantityOfWater = assigned.reduce((acc, curr) => acc + curr.totalQuantity, 0);
   
            // Update state with fetched data and calculated totals
            setData(response.data);
            setTotalDeliveredRequestsSaved(totalDeliveredRequestsSaved);
            setTotalDeliveredQuantityOfWater(totalDeliveredQuantityOfWater);
            setTotalAssignedRequestsSaved(totalAssignedRequestsSaved);
            setTotalAssignedQuantityOfWater(totalAssignedQuantityOfWater);
   
            // Show the table and reset the "no data found" flag
            setShowTable(true);
            setNoDataFound(false);
            setShowAdditionalTable(false);
            setShowAssignedTable(false);
        })
        .catch(error => {
            console.error('Error fetching data:', error);
        });
      }
    };


   
    const handleReportSearch = () => {
      const token = usertoken ? usertoken : null;
      // Get the token from sessionStorage
          const configToken = {
            headers: {
              Authorization: `Bearer ${token}`, // Set the Authorization header with the token
            },
          };
 
    // If both fromDate and toDate are provided, fetch filtered data
    if (fromDate && toDate) {
        axios.get(`${config.apiUrl}/report/fillingstation_data`, {
            params: {
                fromDate: fromDate,
                toDate: toDate,
                user_role:user.user_role,
                firstName:user.user_first_name,
                lastName:user.user_last_name
            },
            ...configToken
        })
        .then(response => {
            if (response.data.length === 0) {
                setNoDataFound(true); // Set flag if no data is found
            } else {

                const { delivered, assigned } = response.data;
   
                // Process delivered data to calculate total delivered requests saved and total delivered quantity of water
                const totalDeliveredRequestsSaved = delivered.reduce((acc, curr) => acc + curr.requestCount, 0);
                const totalDeliveredQuantityOfWater = delivered.reduce((acc, curr) => acc + curr.totalQuantity, 0);
       
                // Process assigned data to calculate total assigned requests saved and total assigned quantity of water
                const totalAssignedRequestsSaved = assigned.reduce((acc, curr) => acc + curr.requestCount, 0);
                const totalAssignedQuantityOfWater = assigned.reduce((acc, curr) => acc + curr.totalQuantity, 0);
       
                // Update state with fetched data and calculated totals
                setData(response.data);
                setTotalDeliveredRequestsSaved(totalDeliveredRequestsSaved);
                setTotalDeliveredQuantityOfWater(totalDeliveredQuantityOfWater);
                setTotalAssignedRequestsSaved(totalAssignedRequestsSaved);
                setTotalAssignedQuantityOfWater(totalAssignedQuantityOfWater);
                // Update state with filtered data and reset the "no data found" flag
                setData(response.data);
                setShowTable(true);
                setNoDataFound(false);
                setShowAdditionalTable(false);
            setShowAssignedTable(false);
            }
        })
        .catch(error => {
            console.error('Error fetching data:', error);
        });
    } else {
        // If fromDate or toDate is not provided, fetch all data
        fetchAllData();
    }
};

    const handlePrint = () => {
        window.print();
      };

      const handleAdditionSearch = (query) => {
        setSearchAdditionQuery(query);
        // Filter the tanker data based on the selected search type and search query
        const additionfiltered = additionalData.filter(item => {
          switch (searchadditionType) {
            case 'addreferenceNo':
              return item.id.toLowerCase().includes(query.toLowerCase());
              case 'addrequestedDateTime':
      return `${item.request_date.split(' ')[0]} ${item.request_time}`.toLowerCase().includes(query.toLowerCase());
      case 'adddeliveredTime':
      return item.deliveredTime.toLowerCase().includes(query.toLowerCase())
      case 'addward':
        return item.ward.toLowerCase().includes(query.toLowerCase());
        case 'addlocality':
            return item.locality.toLowerCase().includes(query.toLowerCase());
            case 'addzone':
            return item.zone.toLowerCase().includes(query.toLowerCase());
   
            case 'addquantity':
              return item.requestedQuantity.toString().includes(query);
            case 'addfillingStation':
              return item.fillingstation.toLowerCase().includes(query.toLowerCase());
            // case 'location':
            //   // You can implement location search logic here based on your data structure
            //   return false; // Placeholder, replace with actual logic
            case 'addofficer':
              // Assuming there is an officer field in your data
              return item.username.toLowerCase().includes(query.toLowerCase());
           case 'addassignedTime':
                return `${item.assignedTime.split(' ')[0]} ${item.assignedTime.split(' ')[1]}`.toLowerCase().includes(query.toLowerCase());  
                  case 'addvehicleNo':
              return item.vehicleNo.toLowerCase().includes(query.toLowerCase());  
            default:
              return true; // If no specific search type is selected, return all data
          }
        });
        setFilteredAdditionData(additionfiltered);
      };
   
   
      const handleAdditionSearchTypeChange = (type) => {
        setAdditionSearchType(type);
        setSearchAdditionQuery('');
        setFilteredAdditionData([]);
       
        // Get the array of values based on the selected search type
        let value = [];
        switch (type) {
          case 'addreferenceNo':
            value =additionalData.map(item => item.id);
            break;
          case 'addrequestedDateTime':
            value = additionalData.map(item => `${item.request_date.split(' ')[0]} ${item.request_time}`);
            break;
            case 'adddeliveredTime':
              value = additionalData.map(item => item.deliveredTime);
              break;
              case 'addward':
                value = additionalData.map(item => item.ward);
                break;
                case 'addlocality':
                    value = additionalData.map(item => item.locality);
                    break;
                    case 'addzone':
                        value = additionalData.map(item => item.zone);
                        break;
          case 'addquantity':
            value = additionalData.map(item => item.requestedQuantity.toString());
            break;
          case 'addfillingStation':
            value = additionalData.map(item => item.fillingstation);
            break;
          case 'addofficer':
            value = additionalData.map(item => item.username);
            break;
            case 'addassignedTime':
            value = additionalData.map(item =>`${item.assignedTime.split(' ')[0]} ${item.assignedTime.split(' ')[1]}`);
            break;
           // Using getStatus function here
           
            case 'addvehicleNo':
              value = additionalData.map(item => item.vehicleNo); // Using getStatus function here
              break;
             
          default:
            break;
        }
     
        // Filter out duplicate values and set the suggestions
        const uniqueValue = [...new Set(value)];
        setAdditionSuggestions(uniqueValue);
      };
   
      useEffect(() => {
        handleAdditionSearchTypeChange(searchadditionType);
      }, [additionalData, searchadditionType]);

      // Handle clearing fromDate
    const clearFromDate = () => {
        setFromDate(null);
     
       
    };

    // Handle clearing toDate
    const clearToDate = () => {
        setToDate(null);      
    };
   
   
    const handleDelivered = () => {
      const token = usertoken ? usertoken : null;
      // Get the token from sessionStorage
          const configToken = {
            headers: {
              Authorization: `Bearer ${token}`, // Set the Authorization header with the token
            },
          };
        // Fetch data from backend API with the selected filling station as a query parameter
        axios.get(`${config.apiUrl}/report/getDelivered`, {
            params: {
                fromDate: fromDate,
                toDate: toDate,
                user_role:user.user_role,
                firstName:user.user_first_name,
                lastName:user.user_last_name
            },
            ...configToken
           
        })
        .then(response => {
            setAdditionalData(response.data);
            setShowTable(false)
            setShowAdditionalTable(true);
            setShowAssignedTable(false);
        })
        .catch(error => {
            console.error('Error fetching data:', error);
        });
    };

   
    const handleAssigned = () => {
      const token = usertoken ? usertoken : null;
      // Get the token from sessionStorage
          const configToken = {
            headers: {
              Authorization: `Bearer ${token}`, // Set the Authorization header with the token
            },
          };
        // Fetch data from backend API with the selected filling station as a query parameter
        axios.get(`${config.apiUrl}/report/getAssigned`, {
            params: {
                fromDate: fromDate,
                toDate: toDate,
                user_role:user.user_role,
                firstName:user.user_first_name,
                lastName:user.user_last_name
            },
            ...configToken
           
        })
        .then(response => {
            setAssignedData(response.data);
            setShowTable(false)
            setShowAdditionalTable(false);
            setShowAssignedTable(true);
        })
        .catch(error => {
            console.error('Error fetching data:', error);
        });
    };

    const handleDeliveredClose = () => {
      // Function to handle closing the login form
      setDeliveredShowLoginForm(false);
      setdeliveredUsername('');
      setdeliveredPassword('');
      setDeliveredError('');
    };

    const exportDeliveredToPdf = async () => {
      const token = usertoken ? usertoken : null;
      // Get the token from sessionStorage
          const configToken = {
            headers: {
              Authorization: `Bearer ${token}`, // Set the Authorization header with the token
            },
          };
      try {
        const response = await axios.post(`${config.apiUrl}/user/exportUser`, { loginIdentifier: deliveredusername, password: deliveredpassword },configToken);
        const { data, message } = response.data;
       
       
        if (response.status === 200 && data.user.user_role === 'Admin') {
          const doc = new jsPDF();
         
 
         
          // Add header row
          const header = [['S.No', 'Reference No', 'Requested Date', 'Assigned Time', 'Delivered Time', 'Qty', 'DriverName','DriverMobileNo','VehicleNo', 'Filling Station', 'Location', 'Officer']];
         
          // Add data rows
          const dataRows = (filteredadditionData.length > 0 ? filteredadditionData : additionalData).map((item, index) => {
            let address = '';
            if (item.location_data) {
              const locations = JSON.parse(item.location_data);
              address = locations.map(location => location.address).join(', ');
            }
            return[
            index + 1,
            item.id,
            item.request_date ? `${item.request_date.split(' ')[0]} ${item.request_time}` : '',
            item.assignedTime,
            item.deliveredTime,
            item.requestedQuantity,
            item.driverName,
              item.driverMobileNo,
              item.vehicleNo,
            item.fillingstation,
            address,
            item.username
            ]
        });
         
       
          // Add table to PDF
          doc.autoTable({
            head: header,
            body: dataRows,
            theme: 'grid', // Add grid theme
            styles: {
              fontSize: 7, // Set the font size to 8
              fontStyle: 'normal' // Set the font style to normal
            },
            columnStyles: {
              3: { cellWidth: 15 }, // Adjust column width for index 3
              5: { cellWidth: 8 } ,
              4: { cellWidth: 15 } // Adjust column width for index 5
            }
          });
         
          // Save PDF
          doc.save('DeliveredData.pdf');
         
          setDeliveredError('');
          setDeliveredShowLoginForm(false);
        } else {
          setDeliveredError('Unauthorized User');
        }
      } catch (error) {
        setDeliveredError('Failed to export PDF file');
      }
    };

    const exportDeliveredToExcel = async () => {
      const token = usertoken ? usertoken : null;
      // Get the token from sessionStorage
          const configToken = {
            headers: {
              Authorization: `Bearer ${token}`, // Set the Authorization header with the token
            },
          };
      try {
        // Make the axios request
        const response = await axios.post(`${config.apiUrl}/user/exportUser`, {
          loginIdentifier: deliveredusername,
          password:deliveredpassword
        },configToken);
   
        // Destructure response data
        const { data, message } = response.data;
   
        // Check if response status is OK
        if (response.status === 200) {
          // Check user role
          if (data.user.user_role === 'Admin') {
            const fileName = "DeliveredData.xlsx";
   
            // Prepare worksheet data
            const wsData = [
              [
                "S.No",
                "Reference No",
                "Requested Date",
                "Assigned Time",
                "Delivered Time",
                "Qty",
                "Filling Station",
                "Officer",
                "Location", // Add column for Location
                "Driver Name", // Add column for Driver Name
                "Driver No", // Add column for Driver Number
                "Vehicle No" // Add column for Vehicle Number
              ],
              ...additionalData.map((item, index) => [
                index + 1,
                item.id,
                `${item.request_date.split(' ')[0]} ${item.request_time}`,
                item.assignedTime,
                item.deliveredTime,
                item.requestedQuantity,
                item.fillingstation,
                item.username,
                "", // Leave empty for Location (will fill later)
                "", // Leave empty for Driver Name (will fill later)
                "", // Leave empty for Driver Number (will fill later)
                "" // Leave empty for Vehicle Number (will fill later)
              ])
            ];
   
            // Fill location and vehicle data for each row
            wsData.slice(1).forEach((row, index) => {
              const selectedItem = additionalData[index];
              if (selectedItem && selectedItem.location_data) {
                const locations = JSON.parse(selectedItem.location_data).map(location => location.address).join(', ');
                row[wsData[0].indexOf("Location")] = locations;
              }
              if (selectedItem) {
                row[wsData[0].indexOf("Driver Name")] = selectedItem.driverName || "";
                row[wsData[0].indexOf("Driver No")] = selectedItem.driverMobileNo || "";
                row[wsData[0].indexOf("Vehicle No")] = selectedItem.vehicleNo || "";
              }
            });
   
            // Create workbook
            const ws = XLSX.utils.aoa_to_sheet(wsData);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, "DeliveredData");
   
            // Export the workbook
            XLSX.writeFile(wb, fileName);
   
            // Clear any previous error
            setDeliveredError('');
            setDeliveredShowLoginForm(false);
          } else {
            // Unauthorized user
            setDeliveredError('Unauthorized User');
          }
        } else {
          // Error in response status
          setDeliveredError(message);
        }
      } catch (error) {
        // Catch any errors
        console.error('Failed to export Excel file:', error);
        setDeliveredError('Failed to export Excel file');
      }
    };
   
    const exportAssignedToPdf = async () => {
      const token = usertoken ? usertoken : null;
      // Get the token from sessionStorage
          const configToken = {
            headers: {
              Authorization: `Bearer ${token}`, // Set the Authorization header with the token
            },
          };
      try {
        // Make axios request if necessary
        const response = await axios.post(`${config.apiUrl}/user/exportUser`, { loginIdentifier: assignedusername, password: assignedpassword },configToken);
        const { data, message } = response.data;
   
        if (response.status === 200 && data.user.user_role === 'Admin') {
          const doc = new jsPDF();
   
          // Add header row
          const header = [['S.No', 'ReferenceNo', 'Date', 'Qty', 'DriverName','DriverMobileNo','VehicleNo', 'Filling', 'Location', 'Officer', 'Assigned Time']];
   
          // Prepare data rows
          const dataRows = (filteredData.length > 0 ? filteredData : assignedData).map((item, index) => {
            // Define 'item' here or retrieve it from some other source
            // Extract address data from location_data
            let address = '';
            if (item.location_data) {
              const locations = JSON.parse(item.location_data);
              address = locations.map(location => location.address).join(', ');
            }
   
            // Return row data
            return [
              index + 1,
              item.id,
              `${item.request_date.split(' ')[0]} ${item.request_time}`,
              item.requestedQuantity,
              item.driverName,
              item.driverMobileNo,
              item.vehicleNo,
              item.fillingstation,
              address, // Include address data
              item.username,
              `${item.assignedTime.split(' ')[0]} ${item.assignedTime.split(' ')[1]}`
              // Include vehicle details
            ];
          });
   
          // Generate PDF with the updated data rows
          doc.autoTable({
            head: header,
            body: dataRows,
            theme: 'grid', // Add grid theme
            styles: {
              fontSize: 7, // Set the font size to 8
              fontStyle: 'normal' // Set the font style to normal
            },
            columnStyles: {
              3: { cellWidth: 10 }, // Adjust column width for index 3
              5: { cellWidth: 22 } // Adjust column width for index 5
            }
          });
   
          // Save PDF
          doc.save('AssignedData.pdf');
        } else {
          console.error('Unauthorized User');
        }
      } catch (error) {
        console.error('Failed to export PDF file:', error);
      }
    };
   
   
    const exportAssignedToExcel = async () => {
      const token = usertoken ? usertoken : null;
      // Get the token from sessionStorage
          const configToken = {
            headers: {
              Authorization: `Bearer ${token}`, // Set the Authorization header with the token
            },
          };
        try {
          const response = await axios.post(`${config.apiUrl}/user/exportUser`, { loginIdentifier: assignedusername, password:assignedpassword },configToken);
          const { data, message } = response.data;
          if (response.status === 200 ){
            if( data.user.user_role === 'Admin') {
              const fileName = "AssignedData.xlsx";
              const wsData = [
                  [
                      "S.No",
                      "Reference No",
                      "Requested Date",
                      "Assigned Time",
                      "Delivered Time",
                      "Qty",
                      "Filling Station",
                      "Officer",
                      "Location", // Add column for Location
                      "Driver Name", // Add column for Driver Name
                      "Driver No", // Add column for Driver Number
                      "Vehicle No" // Add column for Vehicle Number
                  ],
                  ...(filteredData.length > 0 ? filteredData : assignedData).map((item, index) => [
                      index + 1,
                      item.id,
                      `${item.request_date.split(' ')[0]} ${item.request_time}`,
                      item.assignedTime,
                      item.deliveredTime,
                      item.requestedQuantity,
                      item.fillingstation,
                      item.username,
                      "", // Leave empty for Location (will fill later)
                      "", // Leave empty for Driver Name (will fill later)
                      "", // Leave empty for Driver Number (will fill later)
                      "" // Leave empty for Vehicle Number (will fill later)
                  ])
              ];
         
              // Fill location and vehicle data for each row
              wsData.slice(1).forEach((row, index) => {
                  const selectedItem = assignedData[index];
                  if (selectedItem && selectedItem.location_data) {
                      const locations = JSON.parse(selectedItem.location_data).map(location => location.address).join(', ');
                      row[wsData[0].indexOf("Location")] = locations;
                  }
                  if (selectedItem) {
                      row[wsData[0].indexOf("Driver Name")] = selectedItem.driverName || "";
                      row[wsData[0].indexOf("Driver No")] = selectedItem.driverMobileNo || "";
                      row[wsData[0].indexOf("Vehicle No")] = selectedItem.vehicleNo || "";
                  }
              });
         
              const ws = XLSX.utils.aoa_to_sheet(wsData);
              const wb = XLSX.utils.book_new();
              XLSX.utils.book_append_sheet(wb, ws, "AssignedData");
         
              // Export the workbook
              XLSX.writeFile(wb, fileName);
              setAssignedError('');
              setShowAssignedLoginForm(false);
            }
            else {
                setAssignedError('Unauthorized User');
            }
          }
            else {
                setAssignedError(message);
            }
          } catch (error) {
            setAssignedError('Failed to export Excel file');
          }
        };

      const handleAssignedClose = () => {
        // Function to handle closing the login form
        setShowAssignedLoginForm(false);
        setAssignedUsername('');
        setAssignedPassword('');
        setAssignedError('');
      };


    return (
      <>
      {!isMobile?(
         <div>
           
         <div className="flex items-center">
       <img src={fill} alt="Time" className="w-8 h-8 mr-2 " />
       <h1 className="font-semibold text-gray-700 dark:text-gray-300 text-2xl md:text-4xl">Filling Station Report</h1>

     </div>
     


     {!showAdditionalTable && !showAssignedTable &&(
     <div className="flex mt-7 ml-72 ">
             <div className="relative">
                 <input
                     type="date"
                     value={fromDate}
                     onChange={e => setFromDate(e.target.value)}
                     className="border-gray-300 border rounded-l-md p-2"
                 />
               
             </div>
             <div className="relative">
                 <input
                     type="date"
                     value={toDate}
                     onChange={e => setToDate(e.target.value)}
                     className="border-gray-300 border rounded-r-md p-2"
                 />
               
             </div>
             <button
                 onClick={handleReportSearch}
                 className="relative bg-blue-500 hover:bg-blue-600 font-bold py-2 px-4 rounded-r-md"
             >
                 
                 <FontAwesomeIcon icon={faSearch} className="absolute right-3 top-1/2 -translate-y-1/2 cursor-pointer" />
             </button>
         
       
   <ReactToPrint
     trigger={() => (
       <button
         onClick={handlePrint}
         className="bg-green-500 hover:bg-green-600 mb-2 px-4 py-2  rounded-md flex items-center  ml-72  mt-12transition-colors duration-300 ease-in-out"
       >
         <FaPrint />
       </button>
     )}
     content={() => componentRef.current}/>
   
   
 </div>
 )}
 <div ref={componentRef} className="max-h-96  flex justify-center ">
         {noDataFound && (
             <p className="text-red-500 text-center text-2xl mt-24">No data found for the selected date range.</p>
         )}
         {showTable && !noDataFound && (
        <div className="rounded-lg mt-2  w-[75vw] h-[60vh] overflow-y-auto">
 <table className="ml-2  text-sm text-left-500 center sticky top-0 ">
     {/* Table Headers */}
     <thead style={{ backgroundColor: 'rgb(33,64,154)' }} className="text-sm uppercasee text-white sticky top-0">
     <tr>
 <th className="border px-3 py-2 text-center w-1">S.No</th>
 <th className="border px-3 py-2 text-center w-1/6">Filling Station</th>
 <th className="border px-3 py-2 text-center w-1/6">Delivered Requests Saved</th>
 <th className="border px-3 py-2 text-center w-1/6">Quantity of Water (Litres)</th>
 <th className="border px-3 py-2 text-center w-1/6">Assigned Requests Saved</th>
 <th className="border px-3 py-2 text-center w-1/6">Quantity of Water (Litres)</th>
</tr>
     </thead>

     <tbody>


{/* Iterate over delivered filling stations */}
{data.delivered.map((deliveredItem, index) => (
 <tr key={indexCounter++}>
     {/* Render index */}
     <td className="border px-3 py-2 text-center">{indexCounter}</td>
     {/* Render delivered filling station */}
     <td className="border px-3 py-2 text-center ">{deliveredItem.fillingStation}</td>
     {/* Render delivered request count */}
     <td className="border px-3 py-2 text-center ">
         <a
             href="#"
             onClick={() => handleClick(deliveredItem.fillingStation)}
             className="text-blue-500 hover:underline"
         >
             {deliveredItem.requestCount}
         </a>
     </td>
     {/* Render delivered total quantity */}
     <td className="border px-3 py-2 text-center ">
         
         
         {deliveredItem.totalQuantity}</td>
     {/* Find corresponding assigned data for the same filling station */}
     {data.assigned.map((assignedItem, assignedIndex) => (
         assignedItem.fillingStation === deliveredItem.fillingStation && (
             <React.Fragment key={assignedIndex}>
                 {/* Render assigned request count */}
                 <td className="border px-3 py-2 text-center ">
                     <a
                         href="#"
                         onClick={() => handleAssignClick(assignedItem.fillingStation)}
                         className="text-blue-500 hover:underline"
                     >
                         {assignedItem.requestCount}
                     </a>
                 </td>
                 {/* Render assigned total quantity */}
                 <td className="border px-3 py-2 text-center ">{assignedItem.totalQuantity}</td>
             </React.Fragment>
         )
     ))}
     {/* Render empty cells if no corresponding assigned data */}
     {!data.assigned.some(item => item.fillingStation === deliveredItem.fillingStation) && (
         <>
             <td className="border px-3 py-2 text-center ">-</td>
             <td className="border px-3 py-2 text-center ">-</td>
         </>
     )}
 </tr>
))}

{/* Iterate over assigned filling stations */}
{data.assigned.map((assignedItem, index) => (
 // Check if the assigned filling station is not included in the delivered list
 !data.delivered.some(item => item.fillingStation === assignedItem.fillingStation) && (
     <tr key={indexCounter++}>
         {/* Render index */}
         <td className="border px-3 py-2 text-center ">{indexCounter}</td>
         {/* Render assigned filling station */}
         <td className="border px-3 py-2 text-center ">{assignedItem.fillingStation}</td>
         {/* Render empty cells for delivered request count and total quantity */}
         <td className="border px-3 py-2 text-center ">-</td>
         <td className="border px-3 py-2 text-center ">-</td>
         {/* Render assigned request count and total quantity */}
         <td className="border px-3 py-2 text-center ">
             <a
                 href="#"
                 onClick={() => handleAssignClick(assignedItem.fillingStation)}
                 className="text-blue-500 hover:underline"
             >
                 {assignedItem.requestCount}
             </a>
         </td>
         <td className="border px-3 py-2 text-center ">{assignedItem.totalQuantity}</td>
     </tr>
 )
))}



</tbody>
<tfoot>
 <tr>
     <td colSpan="2" className="border px-3 py-2 text-center">Total:</td>
     <td className="border px-3 py-2 text-center">
      <a
             href="#"
             onClick={() => handleDelivered(fillingStation)}
             className="text-blue-500 hover:underline"
         >
           
      {totalDeliveredRequestsSaved}
         </a>
       
     </td>
     <td className="border px-3 py-2 text-center">{totalDeliveredQuantityOfWater}</td>
     <td className="border px-3 py-2 text-center">
         <a
             href="#"
             onClick={() => handleAssigned(fillingStation)}
             className="text-blue-500 hover:underline"
         >
           
            {totalAssignedRequestsSaved}
         </a>
         
       
       
     </td>
     <td className="border px-3 py-2 text-center">{totalAssignedQuantityOfWater}</td>
 </tr>
</tfoot>




 </table>
</div>
 )}
 </div>
   
 {showAdditionalTable && !showAssignedTable &&(
    <div className='mt-8'>

      <button onClick={() => {setShowAdditionalTable(false);setShowTable(true);}} className="text-red-500 mb-4 float-right"> <FontAwesomeIcon icon={faTimes} /></button>
      <div className="flex ">
 {/* From Date */}
 <div className="flex items-center">
             <label className='mr-2'>From</label>
             <DatePicker
                 selected={fromDate}
                 onChange={date => setFromDate(date)}
                 dateFormat="yyyy-MM-dd"
                 className="px-2 py-1 border border-gray-300 rounded-md"
                 readOnly
             />
         </div>

         <div className="flex items-center">
             <label className='mr-2'>To</label>
             <DatePicker
                 selected={toDate}
                 onChange={date => setToDate(date)}
                 dateFormat="yyyy-MM-dd"
                 className="px-2 py-1 border border-gray-300 rounded-md"
                 readOnly
             />
         </div>


     
     <div className="flex items-center">
         <select
             value={searchadditionType}
             onChange={(e) => handleAdditionSearchTypeChange(e.target.value)}
             className="w-full px-2 py-1 border border-gray-300 rounded-md ml-24"
         >
             <option value="addreferenceNo">Reference No</option>
             <option value="addrequestedDateTime">Requested Date</option>
             <option value="addquantity">Quantity</option>
             <option value="addward">Ward</option>
             <option value="addlocality">Locality</option>
             <option value="addzone">Zone</option>
             <option value="addfillingStation">Filling Station</option>
             <option value="addassignedTime">Assigned Time</option>
             <option value="adddeliveredTime">Delivered Time</option>
             <option value="addvehicleNo">Vehicle</option>
             <option value="addofficer">Officer</option>
         </select>
         <input
             type="text"
             value={searchadditionQuery}
             onChange={(e) => handleAdditionSearch(e.target.value)}
             placeholder="Search..."
             className="w-full px-2 py-1 border border-gray-300 rounded"
             list="additionsuggestions"
         />
         <datalist id="additionsuggestions">
             {additionsuggestions.map((item, index) => (
                 <option key={index} value={item} />
             ))}
         </datalist>
     </div>
     {!showDeliveredLoginForm && (
 <button className="ml-8 bg-green-500 hover:bg-green-600 py-1 px-2 rounded-md" onClick={() => setDeliveredShowLoginForm(true)}>
     <FontAwesomeIcon icon={faFileExport} className="mr-2" />
 </button>
 )}
 </div>          
       
         {additionalData.length > 0 && (
             <div className="w-full overflow-x-auto max-h-80 overflow-y-auto">
                 <table id='DeliveredData'className="table-auto w-full">
                     <thead>
                         <tr className='text-white' style={{backgroundColor:'rgb(33,64,154)'}}>
                             <th className="px-3 py-3 text-xs  text-centerfont-semibold uppercase">S.No</th>
                             <th className="px-5 py-3 text-xs text-center font-semibold uppercase">Reference No</th>
                             <th className="px-5 py-3 text-xs text-center font-semibold uppercase">Requested Date</th>
                             <th className="px-5 py-3 text-xs text-center font-semibold uppercase">Assigned Time</th>
                             <th className="px-5 py-3 text-xs text-center font-semibold uppercase">Delivered Time</th>
                             <th className="px-5 py-3 text-xs text-center font-semibold uppercase">Qty</th>
                             <th className="px-5 py-3 text-lg text-center font-semibold uppercase"><FontAwesomeIcon icon={faTruck} /></th>
                             <th className="px-5 py-3 text-xs text-center font-semibold uppercase">Filling Station</th>
                             <th className="px-5 py-3 text-lg  text-center font-semibold uppercase"><FontAwesomeIcon icon={faMapMarkerAlt} /></th>
                             <th className="px-5 py-3 text-xs text-center font-semibold uppercase">Officer</th>
                         </tr>
                     </thead>
                     <tbody>
         {filteredadditionData.length > 0 ? (
           filteredadditionData.map((item, index) => (
             <tr key={item.id}>
             <td className="px-3 py-3 text-xs text-center">{index + 1}</td>
             <td className="px-5 py-3 text-xs text-center">{item.id}</td>
             <td className="px-5 py-3 text-xs text-center">{item.request_date.split(' ')[0]} {item.request_time}</td>
             <td className="px-5 py-3 text-xs text-center">{item.assignedTime}</td>
             <td className="px-5 py-3 text-xs text-center">{item.deliveredTime}</td>
             <td className="px-5 py-3 text-xs text-center">{item.requestedQuantity}</td>
             <td className="px-5 py-3 text-md text-center"><FontAwesomeIcon icon={faTruck} className="cursor-pointer text-green-500"   onClick={() => openVehicleModal(item)}/></td>
             <td className="px-5 py-3 text-xs text-center">{item.fillingstation}</td>
             <td className="px-5 py-3 text-md text-center">
               <FontAwesomeIcon
                 icon={faMapMarkerAlt}
                 className="cursor-pointer text-blue-500"
                 onClick={() => openLocationModal(item)}
               />
             </td>
             <td className="px-5 py-3 text-xs text-center">{item.username} </td> {/* Officer Name */}
             
           </tr>
           ))
         ) : (
            additionalData.map((item, index) => (
                 <tr key={item.id}>
<td className="px-3 py-3 text-center text-xs">{index + 1}</td>
<td className="px-5 py-3 text-center text-xs">{item.id}</td>
<td className="px-5 py-3 text-center text-xs">{item.request_date ? `${item.request_date.split(' ')[0]} ${item.request_time}` : ''}</td>
<td className="px-5 py-3 text-center text-xs">{item.assignedTime}</td>
<td className="px-5 py-3 text-center text-xs">{item.deliveredTime}</td>
<td className="px-5 py-3 text-center text-xs">{item.requestedQuantity}</td>
<td className="px-5 py-3 text-center text-md"><FontAwesomeIcon icon={faTruck} className="cursor-pointer text-green-500"  onClick={() => openVehicleModal(item)}/></td>
<td className="px-5 py-3 text-center text-xs">{item.fillingstation}</td>
<td className="px-5 py-3 text-center text-md">
<FontAwesomeIcon icon={faMapMarkerAlt} className="cursor-pointer text-blue-500 " onClick={() => openLocationModal(item)} />
</td>
<td className="px-5 py-3 text-xs text-center">{item.username}</td> {/* Officer Name */}
</tr>
))

           
         )}
         
       </tbody>
                                 
                   
                 </table>
             </div>
         )}
         </div>
 )}

{showAssignedTable && !showAdditionalTable && (
 <div className='mt-8'>
     <button onClick={() =>{ setShowAssignedTable(false); setShowTable(true); }} className="text-red-500 mb-4 float-right">
         <FontAwesomeIcon icon={faTimes} /> {/* Assuming you want to use the faTimes icon for close */}
     </button>
     <div className="flex mb-5">
 {/* From Date */}
 <div className="relative">
     <label className='mr-2'>FromDate</label>
     <DatePicker
     selected={fromDate}
     onChange={date => setFromDate(date)}
     dateFormat="yyyy-MM-dd"
     className="px-2 py-1 border border-gray-300 rounded-md"
 />
 
  {fromDate && (
                     <button onClick={clearFromDate} className="absolute right-5 top-1/2 -translate-y-1/2 text-gray-400 focus:outline-none">
                         <FontAwesomeIcon icon={faTimes} />
                     </button>
                 )}

     
 </div>
 <div className="relative">
     <label className='mr-2'>ToDate</label>
     <DatePicker
         selected={toDate}
         onChange={date => setToDate(date)}
         dateFormat="yyyy-MM-dd"
         className="px-2 py-1 border border-gray-300 rounded-md"
     />
 

                  {toDate && (
                     <button onClick={clearToDate} className="absolute right-5 top-1/2 -translate-y-1/2 text-gray-400 focus:outline-none">
                         <FontAwesomeIcon icon={faTimes} />
                     </button>
                 )}
   
 </div>

 {/* To Date */}

     <div className="flex items-center">
         <select
             value={searchType}
             onChange={(e) => handleSearchTypeChange(e.target.value)}
             className="mr-2 px-2  ml-16 py-1 border border-gray-300 rounded mb-2 md:mb-0 w-full md:mr-0 md:px-3 md:py-2 md:w-40"
         >
             <option value="referenceNo">Reference No</option>
             <option value="locality">Locality</option>
             <option value="quantity">Quantity</option>
             <option value="fillingStation">Filling Station</option>
             <option value="zone">Zone</option>
             <option value="ward">Ward</option>
             <option value="vehicleNo">Vehicle</option>
             {/* <option value="officer">Officer</option> */}
         </select>
         <input
             type="text"
             value={searchQuery}
             onChange={(e) => handleSearch(e.target.value)}
             placeholder="Search..."
             className="px-1 py-1 border w-full border-gray-300 rounded md:w-auto"
             list="suggestions"
         />
         <datalist id="suggestions">
             {suggestions.map((item, index) => (
                 <option key={index} value={item} />
             ))}
         </datalist>
     </div>
     {!showAssignedLoginForm && (
     <button className="ml-8 bg-green-500 hover:bg-green-600 py-1 px-2 rounded-md" onClick={() => setShowAssignedLoginForm(true)}>
     <FontAwesomeIcon icon={faFileExport} className="mr-2" />
 </button>
     )}

     </div>
     {assignedData.length > 0 && (
         <div className="w-full overflow-x-auto max-h-80 overflow-y-auto">
             <table id='AssignedData'className="table-auto w-full">
                 <thead className='text-white'>
                     <tr className='' style={{backgroundColor:'rgb(33,64,154)'}}>
                         <th className="px-3 py-3 text-xs text-center font-semibold uppercase ">S.No</th>
                         <th className="p-3 text-xs text-center font-semibold uppercase ">Reference</th>
                         <th className="p-3 text-xs text-center font-semibold uppercase "> Date</th>
                         <th className="p-3 text-xs text-center font-semibold uppercase ">Qty</th>
                         <th className="p-3 text-lg text-center font-semibold uppercase "><FontAwesomeIcon icon={faTruck} /></th>
                         <th className="p-3 text-xs  text-center font-semibold uppercase ">Filling</th>
                         <th className="p-3 text-lg  text-center font-semibold uppercase "><FontAwesomeIcon icon={faMapMarkerAlt} /></th>
                         <th className="p-3 text-xs  text-center font-semibold uppercase ">Officer</th>
                         <th className="p-3 text-xs text-center font-semibold uppercase ">Assigned Time</th>
                     </tr>
                 </thead>

                 <tbody>
         {filteredData.length > 0 ? (
           filteredData.map((item, index) => (
             <tr key={item.id} className='text-center border-b-2'>
             <td className="px-3 py-3 text-sm">{index + 1}</td>
             <td className="p-3 text-xs">{item.id}</td>
             <td className="p-3 text-xs">{item.request_date.split(' ')[0]} {item.request_time}</td>
             <td className="p-3 text-xs">{item.requestedQuantity}</td>
             <td className="p-3 text-md"><FontAwesomeIcon icon={faTruck} className="cursor-pointer text-green-500"   onClick={() => openVehicleModal(item)}/></td>
             <td className="p-3 text-sm">{item.fillingstation}</td>
             <td className="p-3 text-md">
               <FontAwesomeIcon
                 icon={faMapMarkerAlt}
                 className="cursor-pointer text-blue-500"
                 onClick={() => openLocationModal(item)}
               />
             </td>
             <td className="p-3 text-xs"> {item.username}</td> {/* Officer Name */}
             <td className="p-3 text-xs">{item.assignedTime.split(' ')[0]} {item.assignedTime.split(' ')[1]}</td>
             
           
         
           </tr>
           ))
         ) : (
           assignedData.map((item, index) => (
                 <tr key={item.id} className='text-center border-b-2'>
                     <td className="px-3 py-3 text-sm">{index + 1}</td>
                     <td className="p-3 text-xs">{item.id}</td>
                     <td className="p-3 text-xs">{item.request_date.split(' ')[0]} {item.request_time}</td>
                     <td className="p-3 text-xs">{item.requestedQuantity}</td>
                     <td className="p-3 text-md"><FontAwesomeIcon icon={faTruck} className="cursor-pointer text-green-500" onClick={() => openVehicleModal(item)} /></td>
                     <td className="p-3 text-sm">{item.fillingstation}</td>
                     <td className="p-3 text-md">
                         <FontAwesomeIcon icon={faMapMarkerAlt} className="cursor-pointer text-blue-500" onClick={() => openLocationModal(item)} />
                     </td>
                     <td className="p-3 text-xs">{item.username}</td> {/* Officer Name */}
                     <td className="p-3 text-xs">{item.assignedTime.split(' ')[0]} {item.assignedTime.split(' ')[1]}</td>
                 </tr>
             ))
         
         )}
         

       </tbody>
               
                     
                   
             </table>
         </div>
     )}
 </div>

)}

         
     {locationModal && (
<div className="fixed inset-0 z-40 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
 <div className="fixed inset-0 bg-black bg-opacity-50"></div>
 <div className="relative w-auto max-w-3xl mx-auto my-4">
   {/* Modal content */}
   <div className="bg-white p-8 rounded-lg shadow-md">
     <div className="flex justify-between items-center mb-4">
       <h3 className="text-lg font-semibold">Location</h3>
       <button onClick={closeLocationModal} className="text-gray-700 hover:text-gray-900">
         <svg className="h-6 w-6 text-red-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
           <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
         </svg>
       </button>
     </div>
     <div className="overflow-y-auto max-h-48"> {/* Limit the height of the content and add scroll if needed */}
       <ol className="list-decimal pl-5"> {/* Use a list to display addresses with numbers */}
         {selectedItem.location_data && JSON.parse(selectedItem.location_data).map((location, index) => (
           <li key={index} className="text-sm mb-2">{location.address}</li>
         ))}
       </ol>
     </div>
   </div>
 </div>
</div>
)}


{vehicleModal && (
<div className="fixed inset-0 z-40 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
 <div className="fixed inset-0 bg-black bg-opacity-50"></div>
 <div className="relative w-auto max-w-3xl mx-auto my-4">
   {/* Modal content */}
   <div className="bg-white p-8 rounded-lg shadow-md">
     <div className="flex justify-between items-center mb-4">
       <h3 className="text-lg font-semibold uppercase">Vehicle</h3>
       <button onClick={closeVehicleModal} className="text-gray-700 hover:text-gray-900">
         <svg className="h-6 w-6 text-red-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
           <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
         </svg>
       </button>
     </div>
     <div className="overflow-y-auto max-h-48"> {/* Limit the height of the content and add scroll if needed */}
     <table className="w-full">
 <tbody>
   <tr className="text-left mb-2 mt-2 p-2">
     <td className="font-semibold uppercase text-xs pr-4">Driver Name</td>
     <td className="pr-4">:</td>
     <td>{selectedItem.driverName}</td>
   </tr>
   <tr className="text-left mb-2 mt-2 p-2">
     <td className=" font-semibold uppercase text-xs pr-4">Driver No</td>
     <td className="pr-4">:</td>
     <td>{selectedItem.driverMobileNo}</td>
   </tr>
   <tr className="text-left mb-2 mt-2 p-2">
     <td className="font-semibold text-xs uppercase pr-4">Vehicle No</td>
     <td className="pr-4">:</td>
     <td>{selectedItem.vehicleNo}</td>
   </tr>
 </tbody>
</table>
     </div>
   </div>
 </div>
</div>
)}

{showDeliveredLoginForm && (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
<div className="bg-white w-full md:max-w-sm rounded-lg overflow-hidden shadow-xl">
<div className="px-6 py-4">
    <div className="flex justify-end">
      <button className="text-gray-700 hover:text-gray-900"  onClick={handleDeliveredClose}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
        </svg>
      </button>
    </div>
    <label htmlFor="username" className="text-gray-700">Username</label>
    <input
                type="text"
                placeholder="Username or Mobile Number"
                value={deliveredusername}
                onChange={(e) => setdeliveredUsername(e.target.value)}
                className="border border-gray-400 px-3 py-2 rounded-md mb-2 w-full"
              />
    <label htmlFor="password" className="text-gray-700">Password</label>
    <input
                type="password"
                placeholder="Password"
                value={deliveredpassword}
                onChange={(e) => setdeliveredPassword(e.target.value)}
                className="border border-gray-400 px-3 py-2 rounded-md mb-2 w-full"
              />
     <button
                onClick={exportDeliveredToExcel}
                className="bg-green-500 hover:bg-green-600 text-white py-2 px-4 rounded-lg mr-5"
              >
                Excel
              </button>
              <button
                onClick={exportDeliveredToPdf}
                className="bg-green-500 hover:bg-green-600 text-white py-2 px-4 rounded-lg mr-2"
              >
                PDF
              </button>
   
              {deliverederror && <p className="text-red-500">{deliverederror}</p>}
            </div>
    </div>
    </div>
   )}
   
   {showAssignedLoginForm && (
  <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
  <div className="bg-white w-full md:max-w-sm rounded-lg overflow-hidden shadow-xl">
<div className="px-6 py-4">
  <div className="flex justify-end">
    <button className="text-gray-700 hover:text-gray-900"  onClick={handleAssignedClose}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
      </svg>
    </button>
  </div>
  <label htmlFor="username" className="text-gray-700">Username</label>
  <input
              type="text"
              placeholder="Username or Mobile Number"
              value={assignedusername}
              onChange={(e) => setAssignedUsername(e.target.value)}
              className="border border-gray-400 px-3 py-2 rounded-md mb-2 w-full"
            />
  <label htmlFor="password" className="text-gray-700">Password</label>
  <input
              type="password"
              placeholder="Password"
              value={assignedpassword}
              onChange={(e) => setAssignedPassword(e.target.value)}
              className="border border-gray-400 px-3 py-2 rounded-md mb-2 w-full"
            />
   <button
              onClick={exportAssignedToExcel}
              className="bg-green-500 hover:bg-green-600 text-white py-2 px-4 rounded-lg mr-5"
            >
              Excel
            </button>
            <button
              onClick={exportAssignedToPdf}
              className="bg-green-500 hover:bg-green-600 text-white py-2 px-4 rounded-lg mr-2"
            >
              PDF
            </button>
 
            {assignederror && <p className="text-red-500">{assignederror}</p>}
          </div>
  </div>
  </div>
   )}



     </div>
      ):(
        <div className="">
        <div className="flex flex-row justify-center items-center">
          <img src={fill} alt="Time" className="w-4 h-4 mr-2" />
          <h1 className="font-semibold text-blue-900 text-xl md:text-4xl">Filling Station Report</h1>
        </div>
         {/* table1*/}
        {!showAdditionalTable && !showAssignedTable && (
         <div className="relative flex flex-col">
         <div className="flex flex-col max-w-xs ">
           <label htmlFor="startDate" className="text-gray-600 mb-1">Start Date:</label>
           <input
                type="date"
                value={fromDate}
                onChange={e => setFromDate(e.target.value)}
                className="border-gray-300 border rounded-md mb-2 md:mb-0 md:mr-2"
              />
             <label htmlFor="endDate" className="text-gray-600 ">End Date:</label>
     <input
                type="date"
                value={toDate}
                onChange={e => setToDate(e.target.value)}
                className="border-gray-300 border rounded-md "
              />
            </div>
            <div className="absolute top-0 right-3 text-xs max-w-sm flex flex-row">
  <button
              onClick={handleReportSearch}
              className="bg-blue-500 hover:bg-blue-600 font-bold h-6 w-8 px-2 rounded-md ml-0 md:ml-4"
            >
              <FontAwesomeIcon icon={faSearch} className="text-white" />
             
            </button>
            <ReactToPrint
              trigger={() => (
                <button
                  onClick={handlePrint}
                  className="bg-green-500 hover:bg-green-600  h-6 w-8  px-2 rounded-md ml-4 "
                >
                  <FaPrint className="text-white" />
                </button>
              )}
              content={() => componentRef.current}
            />
            </div>
          </div>
        )}
     
        <div ref={componentRef} className="mt-5 max-h-80 max-w-xs overflow-auto">
        {noDataFound && (
                      <p className="text-red-500 text-center text-2xl mt-24">No data found for the selected date range.</p>
                  )}
                  {showTable && !noDataFound && (
                 <div className="rounded-lg overflow-y-auto">
           <table className="max-w-xs text-xs">
     {/* Table Headers */}
     <thead className="bg-blue-900 text-white">
              <tr>
          <th className="border px-1 py-1 text-xs text-center">S.No</th>
          <th className="border px-1 py-1 text-xs text-center">Filling Station</th>
          <th className="border px-1 py-1 text-xs text-center ">Delivered Requests Saved</th>
          <th className="border px-1 py-1 text-xs text-center ">Quantity of Water (Litres)</th>
          <th className="border px-1 py-1 text-xs text-center">Assigned Requests Saved</th>
          <th className="border px-1 py-1 text-xs text-center ">Quantity of Water (Litres)</th>
      </tr>
              </thead>
     
              <tbody>
     
     
      {/* Iterate over delivered filling stations */}
      {data.delivered.map((deliveredItem, index) => (
          <tr key={indexCounter++}>
              {/* Render index */}
              <td className="border px-1 py-1 text-center">{indexCounter}</td>
              {/* Render delivered filling station */}
              <td className="border px-1 py-1 text-center ">{deliveredItem.fillingStation}</td>
              {/* Render delivered request count */}
              <td className="border px-1 py-1 text-center ">
                  <a
                      href="#"
                      onClick={() => handleClick(deliveredItem.fillingStation)}
                      className="text-blue-500 hover:underline"
                  >
                      {deliveredItem.requestCount}
                  </a>
              </td>
              {/* Render delivered total quantity */}
              <td className="border px-1 py-1 text-center ">
                 
                 
                  {deliveredItem.totalQuantity}</td>
              {/* Find corresponding assigned data for the same filling station */}
              {data.assigned.map((assignedItem, assignedIndex) => (
                  assignedItem.fillingStation === deliveredItem.fillingStation && (
                      <React.Fragment key={assignedIndex}>
                          {/* Render assigned request count */}
                          <td className="border px-1 py-1 text-center ">
                              <a
                                  href="#"
                                  onClick={() => handleAssignClick(assignedItem.fillingStation)}
                                  className="text-blue-500 hover:underline"
                              >
                                  {assignedItem.requestCount}
                              </a>
                          </td>
                          {/* Render assigned total quantity */}
                          <td className="border px-1 py-1 text-center ">{assignedItem.totalQuantity}</td>
                      </React.Fragment>
                  )
              ))}
              {/* Render empty cells if no corresponding assigned data */}
              {!data.assigned.some(item => item.fillingStation === deliveredItem.fillingStation) && (
                  <>
                      <td className="border px-1 py-1 text-center ">-</td>
                      <td className="border px-1 py-1 text-center ">-</td>
                  </>
              )}
          </tr>
      ))}
     
      {/* Iterate over assigned filling stations */}
      {data.assigned.map((assignedItem, index) => (
          // Check if the assigned filling station is not included in the delivered list
          !data.delivered.some(item => item.fillingStation === assignedItem.fillingStation) && (
              <tr key={indexCounter++}>
                  {/* Render index */}
                  <td className="border px-3 py-2 text-center ">{indexCounter}</td>
                  {/* Render assigned filling station */}
                  <td className="border px-3 py-2 text-center ">{assignedItem.fillingStation}</td>
                  {/* Render empty cells for delivered request count and total quantity */}
                  <td className="border px-3 py-2 text-center ">-</td>
                  <td className="border px-3 py-2 text-center ">-</td>
                  {/* Render assigned request count and total quantity */}
                  <td className="border px-3 py-2 text-center ">
                      <a
                          href="#"
                          onClick={() => handleAssignClick(assignedItem.fillingStation)}
                          className="text-blue-500 hover:underline"
                      >
                          {assignedItem.requestCount}
                      </a>
                  </td>
                  <td className="border px-3 py-2 text-center ">{assignedItem.totalQuantity}</td>
              </tr>
          )
      ))}
     
     
         
      </tbody>
      <tfoot>
          <tr>
              <td colSpan="2" className="border px-3 py-2 text-center">Total:</td>
              <td className="border px-3 py-2 text-center">
               <a
                      href="#"
                      onClick={() => handleDelivered(fillingStation)}
                      className="text-blue-500 hover:underline"
                  >
                     
               {totalDeliveredRequestsSaved}
                  </a>
                 
              </td>
              <td className="border px-3 py-2 text-center">{totalDeliveredQuantityOfWater}</td>
              <td className="border px-3 py-2 text-center">
                  <a
                      href="#"
                      onClick={() => handleAssigned(fillingStation)}
                      className="text-blue-500 hover:underline"
                  >
                     
                     {totalAssignedRequestsSaved}
                  </a>
                 
               
                 
              </td>
              <td className="border px-3 py-2 text-center">{totalAssignedQuantityOfWater}</td>
          </tr>
      </tfoot>
     
     
     
     
          </table>
      </div>
          )}
        </div>
         {/* table2 */}
            {showAdditionalTable && !showAssignedTable &&(
             <div className='mt-8 max-w-sm'>
     
               <button onClick={() => {setShowAdditionalTable(false);setShowTable(true);}} className="text-red-500 mb-4 float-right"> <FontAwesomeIcon icon={faTimes} /></button>
               <div className="flex flex-col items-center">
      {/* From Date and To Date in the same row */}
      <div className="flex items-center mb-5">
      <div className="flex items-center mb-3">
      <div className="flex flex-col mr-4">
          <label className='ml-0'>From</label>
          <DatePicker
              selected={fromDate}
              onChange={date => setFromDate(date)}
              dateFormat="yyyy-MM-dd"
              className="px-2 py-1 border border-gray-300 rounded-md w-3/5"
          />
      </div>
      <div className="flex flex-col">
          <label className='ml-0'>To</label>
          <DatePicker
              selected={toDate}
              onChange={date => setToDate(date)}
              dateFormat="yyyy-MM-dd"
              className="px-2 py-1 border border-gray-300 rounded-md w-3/5"
          />
      </div>
  </div>
 
      </div>
 
      {/* Reference No Dropdown, Search Input Field, and Export Button in the same row */}
      <div className="flex items-center mb-3">
          <select
              value={searchadditionType}
              onChange={(e) => handleAdditionSearchTypeChange(e.target.value)}
              className="border text-sm w-1/2 border-gray-300 rounded-md py-1 px-2 mr-2"
          >
              <option value="addreferenceNo">Reference No</option>
              <option value="addrequestedDateTime">Requested Date</option>
              <option value="addquantity">Quantity</option>
              <option value="addward">Ward</option>
              <option value="addlocality">Locality</option>
              <option value="addzone">Zone</option>
              <option value="addfillingStation">Filling Station</option>
              <option value="addassignedTime">Assigned Time</option>
              <option value="adddeliveredTime">Delivered Time</option>
              <option value="addvehicleNo">Vehicle</option>
              <option value="addofficer">Officer</option>
          </select>
          <input
              type="text"
              value={searchadditionQuery}
              onChange={(e) => handleAdditionSearch(e.target.value)}
              placeholder="Search..."
              className="border text-sm border-gray-300 rounded-md py-1 px-2 w-1/2 mr-1"
              list="additionsuggestions"
          />
          <button className="bg-green-500 text-white hover:bg-green-600 px-1  h-6 w-6 rounded-md " onClick={() => setDeliveredShowLoginForm(true)}>
              <FontAwesomeIcon icon={faFileExport} className="mr-1" />
          </button>
      </div>
  </div>
           
                 
  {additionalData.length > 0 && (
      <div className="max-h-80 overflow-y-auto">
          <table id='DeliveredData' className="max-w-sm border-collapse border border-gray-300">
              <thead>
                  <tr className='text-white' style={{backgroundColor:'rgb(33,64,154)'}}>
                      <th className="text-xs px-2 py-2 text-center border border-gray-300">S.No</th>
                      <th className="text-xs px-2 py-2 text-center border border-gray-300">Reference No</th>
                      <th className="text-xs px-2 py-2 text-center border border-gray-300">Actions</th>
                      <th className="text-xs px-2 py-2 text-center border border-gray-300"><FontAwesomeIcon icon={faTruck} /></th>
                      <th className="text-xs px-2 py-2 text-center border border-gray-300"><FontAwesomeIcon icon={faMapMarkerAlt} /></th>
                      <th className="text-xs px-2 py-2 text-center border border-gray-300">Officer</th>
                  </tr>
              </thead>
              <tbody>
                  {filteredadditionData.length > 0 ? (
                      filteredadditionData.map((item, index) => (
                          <tr key={item.id}>
                              <td className="text-xs px-2 py-2 text-center border border-gray-300">{index + 1}</td>
                              <td className="text-xs px-2 py-2 text-center border border-gray-300">{item.id}</td>
                              <td className="text-xs px-2 py-2 text-center border border-gray-300">
                                  <FontAwesomeIcon
                                      icon={faInfoCircle}
                                      className="cursor-pointer text-blue-500"
                                      onClick={() => openModal(item)}
                                  />
                              </td>
                              <td className="text-xs px-2 py-2 text-center border border-gray-300">
                                  <FontAwesomeIcon
                                      icon={faTruck}
                                      className="cursor-pointer text-green-500"
                                      onClick={() => openVehicleModal(item)}
                                  />
                              </td>
                              <td className="text-xs px-2 py-2 text-center border border-gray-300">
                                  <FontAwesomeIcon
                                      icon={faMapMarkerAlt}
                                      className="cursor-pointer text-blue-500"
                                      onClick={() => openLocationModal(item)}
                                  />
                              </td>
                              <td className="text-xs px-2 py-2 text-center border border-gray-300 overflow-hidden whitespace-nowrap">
                                  <span className="inline-block max-w-[100px] overflow-hidden overflow-ellipsis">
                                      {item.username}
                                  </span>
                              </td>
                          </tr>
                      ))
                  ) : (
                      additionalData.map((item, index) => (
                          <tr key={item.id}>
                              <td className="text-xs px-2 py-2 text-center border border-gray-300">{index + 1}</td>
                              <td className="text-xs px-2 py-2 text-center border border-gray-300">{item.id}</td>
                              <td className="text-xs px-2 py-2 text-center border border-gray-300">
                                  <FontAwesomeIcon
                                      icon={faInfoCircle}
                                      className="cursor-pointer text-blue-500"
                                      onClick={() => openModal(item)}
                                  />
                              </td>
                              <td className="text-xs px-2 py-2 text-center border border-gray-300">
                                  <FontAwesomeIcon
                                      icon={faTruck}
                                      className="cursor-pointer text-green-500"
                                      onClick={() => openVehicleModal(item)}
                                  />
                              </td>
                              <td className="text-xs px-2 py-2 text-center border border-gray-300">
                                  <FontAwesomeIcon
                                      icon={faMapMarkerAlt}
                                      className="cursor-pointer text-blue-500"
                                      onClick={() => openLocationModal(item)}
                                  />
                              </td>
                              <td className="text-xs px-2 py-2 text-center border border-gray-300">{item.username}</td>
                          </tr>
                      ))
                  )}
              </tbody>
          </table>
      </div>
  )}
 
                  </div>
          )}
 
           {/* Modal */}
           {modalData && (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75">
      <div className="bg-white p-4 rounded-lg max-w-xs relative">
        <button onClick={closeModal} className="absolute top-0 right-0 m-2 text-gray-700 hover:text-gray-900">
          <svg className="h-6 w-6 text-red-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
        <h2 className="text-xl font-semibold mb-4">Requested Information</h2>
        <p>Requested Date: {modalData.request_date.split(' ')[0]} {modalData.request_time}</p>
        <p>Assigned Time: {modalData.assignedTime}</p>
        <p>Delivered Time: {modalData.deliveredTime}</p>
        <p>Qty: {modalData.requestedQuantity}</p>
        <p>Filling Station: {modalData.fillingstation}</p>
      </div>
    </div>
  )}
 
 
         {/* table3 */}
         {showAssignedTable && !showAdditionalTable &&(
    <div className='mt-8 max-w-sm'>
 
      <button onClick={() => {setShowAdditionalTable(false);setShowAssignedTable(false);setShowTable(true);}} className="text-red-500 mb-4  float-right"> <FontAwesomeIcon icon={faTimes} /></button>
      <div className="flex flex-col items-center">
  {/* From Date and To Date in the same row */}
  <div className="flex items-center mb-5">
  <div className="flex items-center mb-3">
  <div className="flex flex-col mr-4">
  <label className='ml-0'>From</label>
  <DatePicker
     selected={fromDate}
     onChange={date => setFromDate(date)}
     dateFormat="yyyy-MM-dd"
     className="px-2 py-1 border border-gray-300 rounded-md w-3/5"
  />
  {/* {fromDate && (
                              <button onClick={clearFromDate} className="absolute right-5 top-1/2 -translate-y-1/2 text-gray-400 focus:outline-none">
                                  <FontAwesomeIcon icon={faTimes} />
                              </button>
                          )} */}
         
  </div>
  <div className="flex flex-col">
  <label className='ml-0'>To</label>
  <DatePicker
     selected={toDate}
     onChange={date => setToDate(date)}
     dateFormat="yyyy-MM-dd"
     className="px-2 py-1 border border-gray-300 rounded-md w-3/5"
  />
  {/* {toDate && (
                              <button onClick={clearToDate} className="absolute right-5 top-1/2 -translate-y-1/2 text-gray-400 focus:outline-none">
                                  <FontAwesomeIcon icon={faTimes} />
                              </button>
                          )} */}
  </div>
  </div>
 
  </div>
 
  {/* Reference No Dropdown, Search Input Field, and Export Button in the same row */}
  <div className="flex items-center mb-3">
  <select
     value={searchType}
     onChange={(e) => handleSearchTypeChange(e.target.value)}
     className="border text-sm w-1/2 border-gray-300 rounded-md py-1 px-2 mr-2"
  >
                     <option value="referenceNo">Reference No</option>
                      <option value="locality">Locality</option>
                      <option value="quantity">Quantity</option>
                      <option value="fillingStation">Filling Station</option>
                      <option value="zone">Zone</option>
                      <option value="ward">Ward</option>
                      <option value="vehicleNo">Vehicle</option>
  </select>
  <input
     type="text"
     value={searchQuery}
     onChange={(e) => handleSearch(e.target.value)}
     placeholder="Search..."
     className="border text-sm border-gray-300 rounded-md py-1 px-2 w-1/2 mr-1"
     list="suggestions"
  />
  <datalist id="suggestions">
                      {suggestions.map((item, index) => (
                          <option key={index} value={item} />
                      ))}
                  </datalist>
                  {!showAssignedLoginForm && (
  <button className="bg-green-500 text-white hover:bg-green-600 px-1  h-6 w-6 rounded-md " onClick={() => setDeliveredShowLoginForm(true)}>
     <FontAwesomeIcon icon={faFileExport} className="mr-1" />
  </button>
   )}
              </div>
 
  </div>
 
 
       
  {assignedData.length > 0 && (
  <div className="max-h-80 overflow-y-auto max-w-sm">
  <table id='AssignedData' className="max-w-sm border-collapse border border-gray-300">
     <thead>
     <tr className='text-white' style={{backgroundColor:'rgb(33,64,154)'}}>
                                  <th className="text-xs px-2 py-2 text-center border border-gray-300  ">S.No</th>
                                  <th className="text-xs px-2 py-2 text-center border border-gray-300 ">Reference</th>
                                  <th className="text-xs px-2 py-2 text-center border border-gray-300">Actions</th>
                                  <th className=" text-xs px-2 py-2 text-center border border-gray-300"><FontAwesomeIcon icon={faTruck} /></th>
                                  <th className=" text-xs px-2 py-2 text-center border border-gray-300"><FontAwesomeIcon icon={faMapMarkerAlt} /></th>
                                  <th className=" text-xs px-2 py-2 text-center border border-gray-300 ">Officer</th>
         </tr>
     </thead>
     <tbody>
     {filteredData.length > 0 ? (
             filteredData.map((item, index) => (
                 <tr key={item.id}>
                    <td className=" text-xs px-2 py-2 text-center border border-gray-300">{index + 1}</td>
                      <td className="text-xs px-2 py-2 text-center border border-gray-300">{item.id}</td>
                      <td className="text-xs px-2 py-2 text-center border border-gray-300">
                           <FontAwesomeIcon
                               icon={faInfoCircle}
                               className="cursor-pointer text-blue-500"
                               onClick={() => AssignedopenModal(item)}
                           />
                       </td>
                      {/* <td className="text-xs px-1 py-2 text-center border border-gray-300">{item.request_date.split(' ')[0]} {item.request_time}</td> */}
                      {/* <td className="text-xs px-1 py-2 text-center border border-gray-300">{item.requestedQuantity}</td> */}
                      <td className="text-xs px-2 py-2 text-center border border-gray-300"><FontAwesomeIcon icon={faTruck} className="cursor-pointer text-green-500"   onClick={() => openVehicleModal(item)}/></td>
                      {/* <td className="text-xs px-1 py-2 text-center border border-gray-300">{item.fillingstation}</td> */}
                      <td className="text-xs px-2 py-2 text-center border border-gray-300">
                        <FontAwesomeIcon
                          icon={faMapMarkerAlt}
                          className="cursor-pointer text-blue-500"
                          onClick={() => openLocationModal(item)}
                        />
                      </td>
                      <td className="text-xs px-2 py-2 text-center border border-gray-300"> {item.username}</td> {/* Officer Name */}
                      {/* <td className="text-xs px-1 py-2 text-center border border-gray-300">{item.assignedTime.split(' ')[0]} {item.assignedTime.split(' ')[1]}</td>  */}
                 </tr>
             ))
         ) : (
          assignedData.map((item, index) => (
                 <tr key={item.id}>
                     <td className="text-xs px-2 py-2 text-center border border-gray-300">{index + 1}</td>
                              <td className="text-xs px-2 py-2 text-center border border-gray-300">{item.id}</td>
                              {/* <td className="text-xs px-1 py-2 text-center border border-gray-300">{item.request_date.split(' ')[0]} {item.request_time}</td>
                              <td className="text-xs px-1 py-2 text-center border border-gray-300">{item.requestedQuantity}</td> */}
                                 <td className="text-xs px-2 py-2 text-center border border-gray-300">
                           <FontAwesomeIcon
                               icon={faInfoCircle}
                               className="cursor-pointer text-blue-500"
                               onClick={() => AssignedopenModal(item)}
                           />
                       </td>
                              <td className="text-xs px-2 py-2 text-center border border-gray-300"><FontAwesomeIcon icon={faTruck} className="cursor-pointer text-green-500" onClick={() => openVehicleModal(item)} /></td>
                              {/* <td className="text-xs px-1 py-2 text-center border border-gray-300">{item.fillingstation}</td> */}
                              <td className="text-xs px-2 py-2 text-center border border-gray-300">
                                  <FontAwesomeIcon icon={faMapMarkerAlt} className="cursor-pointer text-blue-500" onClick={() => openLocationModal(item)} />
                              </td>
                              <td className="text-xs px-2 py-2 text-center border border-gray-300">{item.username}</td> {/* Officer Name */}
                              {/* <td className="text-xs px-1 py-2 text-center border border-gray-300">{item.assignedTime.split(' ')[0]} {item.assignedTime.split(' ')[1]}</td> */}
                 </tr>
             ))
         )}
     </tbody>
  </table>
  </div>
  )}
 
         </div>
  )}
     
  {assignModalData && (
      <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75">
        <div className="bg-white p-4 rounded-lg max-w-xs relative">
          <button onClick={AssignedcloseModal} className="absolute top-0 right-0 m-2 text-gray-700 hover:text-gray-900">
            <svg className="h-6 w-6 text-red-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
          <h2 className="text-xl font-semibold mb-4">Assigend Information</h2>
          <p>Requested Date: {assignModalData.request_date.split(' ')[0]} {assignModalData.request_time}</p>
          <p>Qty: {assignModalData.requestedQuantity}</p>
          <p>Filling: {assignModalData.fillingstation}</p>
          <p>Assigned Time: {assignModalData.assignedTime.split(' ')[0]} {assignModalData.assignedTime.split(' ')[1]}</p>
        </div>
      </div>
    )}
                 
              {locationModal && (
        <div className="fixed inset-0 z-40 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
          <div className="fixed inset-0 bg-black bg-opacity-50"></div>
          <div className="relative  max-w-xs mx-auto my-4">
            {/* Modal content */}
            <div className="bg-white p-8 rounded-lg shadow-md">
              <div className="flex justify-between items-center mb-4">
                <h3 className="text-lg font-semibold">Location</h3>
                <button onClick={closeLocationModal} className="text-gray-700 hover:text-gray-900">
                  <svg className="h-6 w-6 text-red-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
              <div className="overflow-y-auto max-h-48"> {/* Limit the height of the content and add scroll if needed */}
                <ol className="list-decimal pl-5"> {/* Use a list to display addresses with numbers */}
                  {selectedItem.location_data && JSON.parse(selectedItem.location_data).map((location, index) => (
                    <li key={index} className="text-sm mb-2">{location.address}</li>
                  ))}
                </ol>
              </div>
            </div>
          </div>
        </div>
      )}
     
     
      {vehicleModal && (
        <div className="fixed inset-0 z-40 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
          <div className="fixed inset-0 bg-black bg-opacity-50"></div>
          <div className="relative w-auto max-w-3xl mx-auto my-4">
            {/* Modal content */}
            <div className="bg-white p-8 rounded-lg shadow-md">
              <div className="flex justify-between items-center mb-4">
                <h3 className="text-lg font-semibold uppercase">Vehicle</h3>
                <button onClick={closeVehicleModal} className="text-gray-700 hover:text-gray-900">
                  <svg className="h-6 w-6 text-red-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
              <div className="overflow-y-auto max-h-48"> {/* Limit the height of the content and add scroll if needed */}
              <table className="w-full">
          <tbody>
            <tr className="text-left mb-2 mt-2 p-2">
              <td className="font-semibold uppercase text-xs pr-4">Driver Name</td>
              <td className="pr-4">:</td>
              <td>{selectedItem.driverName}</td>
            </tr>
            <tr className="text-left mb-2 mt-2 p-2">
              <td className=" font-semibold uppercase text-xs pr-4">Driver No</td>
              <td className="pr-4">:</td>
              <td>{selectedItem.driverMobileNo}</td>
            </tr>
            <tr className="text-left mb-2 mt-2 p-2">
              <td className="font-semibold text-xs uppercase pr-4">Vehicle No</td>
              <td className="pr-4">:</td>
              <td>{selectedItem.vehicleNo}</td>
            </tr>
          </tbody>
        </table>
              </div>
            </div>
          </div>
        </div>
      )}
     
      {showDeliveredLoginForm && (
       <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
       <div className="bg-white w-full max-w-xs md:max-w-lg rounded-lg overflow-hidden shadow-xl">
       <div className="px-6 py-4">
       <div className="flex justify-end">
         <button className="text-gray-700 hover:text-gray-900"  onClick={handleDeliveredClose}>
           <svg
             xmlns="http://www.w3.org/2000/svg"
             className="h-6 w-6"
             fill="none"
             viewBox="0 0 24 24"
             stroke="currentColor"
           >
             <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
           </svg>
         </button>
       </div>
       <label htmlFor="username" className="text-gray-700">Username</label>
       <input
                   type="text"
                   placeholder="Username or Mobile Number"
                   value={deliveredusername}
                   onChange={(e) => setdeliveredUsername(e.target.value)}
                   className="border border-gray-400 px-2 py-1 rounded-md mb-2"
                 />
       <label htmlFor="password" className="text-gray-700">Password</label>
       <input
                   type="password"
                   placeholder="Password"
                   value={deliveredpassword}
                   onChange={(e) => setdeliveredPassword(e.target.value)}
                   className="border border-gray-400 px-2 py-1 rounded-md mb-2"
                 />
        <button
                   onClick={exportDeliveredToExcel}
                   className="bg-green-500 hover:bg-green-600 text-white py-2 px-4 rounded-lg mr-5"
                 >
                   Excel
                 </button>
                 <button
                   onClick={exportDeliveredToPdf}
                   className="bg-green-500 hover:bg-green-600 text-white py-2 px-4 rounded-lg mr-2"
                 >
                   PDF
                 </button>
       
                 {deliverederror && <p className="text-red-500">{deliverederror}</p>}
               </div>
       </div>
       </div>
      )}
     
      {showAssignedLoginForm && (
     <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
     <div className="bg-white w-full max-w-xs md:max-w-lg rounded-lg overflow-hidden shadow-xl">
     <div className="px-6 py-4">
     <div className="flex justify-end">
       <button className="text-gray-700 hover:text-gray-900"  onClick={handleAssignedClose}>
         <svg
           xmlns="http://www.w3.org/2000/svg"
           className="h-6 w-6"
           fill="none"
           viewBox="0 0 24 24"
           stroke="currentColor"
         >
           <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
         </svg>
       </button>
     </div>
     <label htmlFor="username" className="text-gray-700">Username</label>
     <input
                 type="text"
                 placeholder="Username or Mobile Number"
                 value={assignedusername}
                 onChange={(e) => setAssignedUsername(e.target.value)}
                 className="border border-gray-400 px-2 py-1 rounded-md mb-2"
               />
     <label htmlFor="password" className="text-gray-700">Password</label>
     <input
                 type="password"
                 placeholder="Password"
                 value={assignedpassword}
                 onChange={(e) => setAssignedPassword(e.target.value)}
                 className="border border-gray-400 px-2 py-1 rounded-md mb-2"
               />
      <button
                 onClick={exportAssignedToExcel}
                 className="bg-green-500 hover:bg-green-600 text-white py-2 px-4 rounded-lg mr-5"
               >
                 Excel
               </button>
               <button
                 onClick={exportAssignedToPdf}
                 className="bg-green-500 hover:bg-green-600 text-white py-2 px-4 rounded-lg mr-2"
               >
                 PDF
               </button>
     
               {assignederror && <p className="text-red-500">{assignederror}</p>}
             </div>
     </div>
     </div>
      )}

      </div>
      )}
   
    </>
   
    );
}

export default Fillingstationreport;