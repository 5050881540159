
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import config from '../../config';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useNavigate } from "react-router-dom";
import * as XLSX from 'xlsx';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapMarkerAlt,
  faEdit,
  faTrashAlt,
  faSave,
  faFileExport, faTimes,faPlus,faFileExcel
} from "@fortawesome/free-solid-svg-icons";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { toast } from 'react-toastify';
import { Users } from 'lucide-react';


const AbstractReportForm = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [result, setResult] = useState(null);
  const [isUpdating, setIsUpdating] = useState(false);
  const [error, setError] = useState('');
  const [showLoginForm, setShowLoginForm] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [selectedFormat, setSelectedFormat] = useState(null); 

   const [isMobile, setIsMobile] = useState(window.innerWidth < 1020);
   const [user,setUser] = useState([]);
   const [usertoken,setusertoken] = useState('')
   

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1020);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log('user.user_role', user ? user.user_role : 'User not set yet');
    const token = usertoken ? usertoken : null;
    // console.log(token);
    // Get the token from sessionStorage
        const configToken = {
          headers: {
            Authorization: `Bearer ${token}`, // Set the Authorization header with the token
          },
        };

    if (user && user.user_role) {
        try {
            const response = await axios.get(`${config.apiUrl}/assign/tanker-abstract-report`, {
                params: {
                    startDate: formatDate(startDate),
                    endDate: formatDate(endDate),
                    user_role: user.user_role,
                    firstName: user.user_first_name,
                    lastName: user.user_last_name
                },
                ...configToken
            }, );

            setResult(response.data);
        } catch (error) {
            console.error('Error fetching data:', error);
            setResult(null);
        }
    } else {
        console.warn('User information not available yet');
    }
};


  const formatDate = (date) => {
    return date.toISOString().split('T')[0];
  };

  const navigate = useNavigate();


  useEffect(() => {
    const auth = JSON.parse(sessionStorage.getItem('authentication'));
    const userData = JSON.parse(sessionStorage.getItem("user"));
    if (userData) {
      setUser(userData.user);
      // console.log('userdatsa',userData.user);
      setusertoken(userData.token);
       
    }
  
    if (!auth ) {
      navigate('/')
      
    } 
  }, []);
  useEffect (()=>{
 if(user && user.user_role){
  handleSubmit({ preventDefault: () => {} });
 }
  }, [user])


  const handleExcel = async () => {
  
        const fileName = "AbstractReport.xlsx";
      const wsData = [
          [
              "Total",
              "Assigned",
              "Filling",
              "Delivered",
              "Non-Delivered",
              "Requested Qty in liters",
              "Supplied Qty in liters"
             
          ],
          ...result.map((item, index) => [
            item.totalRequests,
            item.assignedCount ,
            item.filledCount,
            item.deliveredCount,
            item.notDeliveredCount,
            item.overallRequestedQuantity,
            item.deliveredRequestedQuantity
              
          ])
      ];
  
      const ws = XLSX.utils.aoa_to_sheet(wsData);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "AbstractReport");
  
      // Export the workbook
      XLSX.writeFile(wb, fileName);
        setError('');
        setShowLoginForm(false);
      }
    
  
  
  const handleClose = () => {
    // Function to handle closing the login form
    setShowLoginForm(false);
    setUsername('');
    setPassword('');
    setError('');
  };

const handlePdf = async () => {
 
      const doc = new jsPDF();

      // Add header row
      const header = [['Total', 'Assigned', 'Filling', 'Delivered', 'Non-Delivered', 'Requested Qty in liters', 'Supplied Qty in liters']];
      
      // Add data rows
      const data = result.map(item => [item.totalRequests, item.assignedCount, item.filledCount, item.deliveredCount, item.notAssignedCount + item.totalRequests + item.assignedCount, item.overallRequestedQuantity, item.deliveredRequestedQuantity]);
  
      // Add table to PDF
      doc.autoTable({
        head: header,
        body: data,
      });
  
      // Save PDF
      doc.save('AbstractReport.pdf');
      setError('');
      setShowLoginForm(false);
    } 
 

const handleLogin = async () => {
  const token = usertoken ? usertoken : null;
  // Get the token from sessionStorage
      const configToken = {
        headers: {
          Authorization: `Bearer ${token}`, // Set the Authorization header with the token
        },
      };

    try {
      const response = await axios.post(`${config.apiUrl}/user/exportUser`, { loginIdentifier: username, password },configToken);
      const { data, message } = response.data;
  
      if (response.status === 200 && data.user.user_role === 'Admin') {
    toast.success('Login successful!');
    setShowLoginForm(false);
    setUsername('');
    setPassword('');
    
    // If login is successful, proceed with exporting the data
    if (selectedFormat === 'excel') {
      handleExcel(result);
    } else if (selectedFormat === 'pdf') {
      handlePdf(result);
    }
  }
  } catch (error) {
    toast.error('Failed to login.');
  }
};





  return (
    <>
    {!isMobile? (
       <div className="container mx-auto">
       <h1 className="font-semibold text-blue-900  uppercase text-xl mb-4 md:text-2xl">Abstract Report</h1>
   <form onSubmit={handleSubmit} className="sm:w-2/3 flex flex-col md:flex-row sm:items-center space-y-4 md:space-y-0 md:space-x-4 mb-4">
  <div className="flex-grow w-full sm:w-1/3">
    <label htmlFor="startDate" className="text-gray-600">From:</label>
    <DatePicker
      id="startDate"
      selected={startDate}
      onChange={(date) => setStartDate(date)}
      className="px-2 py-1 border border-gray-300 rounded-md"
    />
  </div>
  <div className="flex-grow w-full sm:w-1/3">
    <label htmlFor="endDate" className="text-gray-600">To:</label>
    <DatePicker
      id="endDate"
      selected={endDate}
      onChange={(date) => setEndDate(date)}
      className="px-2 py-1 border border-gray-300 rounded-md"
    />
  </div>
  <div className="flex items-center"> 
    <button
      type="submit"
      className={`bg-blue-500 hover:bg-blue-700 text-white py-1 px-2 rounded ${isUpdating ? 'opacity-50 cursor-not-allowed' : ''}`}
    >
      Generate
    </button>
    {!showLoginForm && (
        <button className="ml-2 w-[40px] hidden sm:block bg-green-500 hover:bg-green-600 text-white py-1 px-2 rounded-md" onClick={() => setShowLoginForm(true)}>
        <FontAwesomeIcon icon={faFileExport} className="mr-2" />
    </button>
        )}




  </div>
</form>



      {result && (
        <div className="w-full container mx-auto h-[60vh] overflow-x-auto  overflow-y-auto ">
          <table  id='AbstructReport' className="w-full">
            <thead className='sticky top-0'>
              <tr className="text-white" style={{ backgroundColor: 'rgb(33,64,154)' }}>
                <th className="px-5 py-3 text-xs font-semibold uppercase">Total</th>
                <th className="px-5 py-3 text-xs font-semibold uppercase">Assigned</th>
                {/* <th className="px-5 py-3 text-xs font-semibold uppercase">Filling</th> */}
                <th className="px-5 py-3 text-xs font-semibold uppercase">Delivered</th>
                <th className="px-5 py-3 text-xs font-semibold uppercase">Non-Delivered</th>
                <th className="px-5 py-3 text-xs font-semibold uppercase">Requested Qty in liters</th>
                <th className="px-5 py-3 text-xs font-semibold uppercase">Supplied Qty in liters</th>
              </tr>
            </thead>
            <tbody>
              {result.map((item, index) => (
                <tr key={item.id} className="text-center border-b-2">
                  <td className="px-5 py-3 text-sm">{item.totalRequests}</td>
                  <td className="px-5 py-3 text-sm">{item.assignedCount}</td>
                  {/* <td className="px-5 py-3 text-sm">{item.filledCount}</td> */}
                  <td className="px-5 py-3 text-sm">{item.deliveredCount}</td>
                  <td className="px-5 py-3 text-sm">{item.notAssignedCount }</td>
                  <td className="px-5 py-3 text-md">{item.overallRequestedQuantity}</td>
                  <td className="px-5 py-3 text-xs">{item.deliveredRequestedQuantity}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

{showLoginForm && (
  <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
    <div className="bg-white w-full relative max-w-md md:max-w-lg rounded-lg overflow-hidden shadow-xl">
      <div className="px-6 py-4">
        <div className="flex justify-end mb-4">
          <button className="text-red-300 hover:text-red-500" onClick={handleClose}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
        <label htmlFor="username" className="text-gray-700">Username</label>
        <input
          type="text"
          id="username"
          placeholder="Username or Mobile Number"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          className="border border-gray-400 px-3 py-2 rounded-md mb-2 w-full"
        />
        <label htmlFor="password" className="text-gray-700">Password</label>
        <input
          type="password"
          id="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className="border border-gray-400 px-3 py-2 rounded-md mb-2 w-full"
        />
        <div className="flex justify-end"> {/* Changed the div class to flex justify-end */}
        <button
           onClick={handleExcel}
           className="bg-green-500 hover:bg-green-600 text-white py-2 px-4 rounded-md mr-2"
         >
           Excel
         </button>
         <button
         onClick={handlePdf}
         className="bg-green-500 hover:bg-green-600 text-white py-2 px-4 rounded-lg mr-2"
       >
         PDF
       </button>
        </div>
        {error && <p className="text-red-500 mt-2">{error}</p>}
      </div>
    </div>
  </div>
)}





    </div>
    ): (
      <div className="container">
<h1 className="font-semibold text-blue-900  text-center uppercase text-lg  md:mb-4 md:text-3xl">Abstract Report</h1>
<form onSubmit={handleSubmit} className="relative flex flex-col sm:flex-row sm:items-center space-y-4 mb-4">
<div className="flex flex-col sm:flex-row sm:items-center sm:space-x-4">
<div className="flex flex-col">
<label htmlFor="startDate" className="text-gray-600">Start:</label>
<DatePicker
  id="startDate"
  selected={startDate}
  onChange={(date) => setStartDate(date)}
  className="px-2 py-1 border border-gray-300 rounded-md text-sm "
/>
</div>
<div className="flex flex-col">
<label htmlFor="endDate" className="text-gray-600">End:</label>
<DatePicker
  id="endDate"
  selected={endDate}
  onChange={(date) => setEndDate(date)}
  className="px-2 py-1 border border-gray-300 rounded-md text-sm "
/>
</div>
</div>
<div className="absolute top-8 right-8 flex justify-end items-start sm:static">
<button
type="submit"
className={`bg-blue-500 hover:bg-blue-700 text-white text-xs py-1 px-2 rounded ${isUpdating ? 'opacity-50 cursor-not-allowed' : ''}`}
>
Generate
</button>
{!showLoginForm && (
        <button className=" mt-1 w-[40px] hidden sm:block bg-green-500 hover:bg-green-600 text-white py-1 px-2 rounded-md" onClick={() => setShowLoginForm(true)}>
        <FontAwesomeIcon icon={faFileExport} className="mr-2" />
    </button>
        )}

</div>
</form>







{result && (
  <div className=" container m-auto overflow-y-auto">
    <table id='AbstructReport' className="max-w-xs">
      <thead className='sticky top-0'>
        <tr className="text-white" style={{ backgroundColor: 'rgb(33,64,154)' }}>
          <th className="px-1 py-1 text-xs font-semibold">Total</th>
          <th className="px-1 py-1 text-xs font-semibold">Assigned</th>
          <th className="px-1 py-1 text-xs font-semibold">Delivered</th>
          <th className="px-1 py-1 text-xs font-semibold">Non-Delivered</th>
          <th className="px-1 py-1 text-xs font-semibold">Requested Qty in liters</th>
          <th className="px-1 py-1 text-xs font-semibold">Supplied Qty in liters</th>
        </tr>
      </thead>
      <tbody>
        {result.map((item, index) => (
          <tr key={item.id} className="text-center border-b-2">
            <td className="px-1 py-1 text-xs">{item.totalRequests}</td>
            <td className="px-1 py-1 text-xs">{item.assignedCount}</td>
            <td className="px-1 py-1 text-xs">{item.deliveredCount}</td>
            <td className="px-1 py-1 text-xs">{item.notAssignedCount}</td>
            <td className="px-1 py-1 text-xs">{item.overallRequestedQuantity}</td>
            <td className="px-1 py-1 text-xs">{item.deliveredRequestedQuantity}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
)}

{showLoginForm && (
   <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
   <div className="bg-white w-full max-w-xs md:max-w-lg rounded-lg overflow-hidden shadow-xl">
   <div className="px-6 py-4">
         <div className="flex justify-end">
           <button className="text-gray-700 hover:text-gray-900" onClick={handleClose}>
             <svg
               xmlns="http://www.w3.org/2000/svg"
               className="h-6 w-6"
               fill="none"
               viewBox="0 0 24 24"
               stroke="currentColor"
             >
               <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
             </svg>
           </button>
         </div>
         <label htmlFor="username" className="text-gray-700">Username</label>
         <input
           type="text"
           id="username"
           placeholder="Username or Mobile Number"
           value={username}
           onChange={(e) => setUsername(e.target.value)}
           className="border border-gray-400 px-3 py-2 rounded-md mb-2 w-full"
         />
         <label htmlFor="password" className="text-gray-700">Password</label>
         <input
           type="password"
           id="password"
           placeholder="Password"
           value={password}
           onChange={(e) => setPassword(e.target.value)}
           className="border border-gray-400 px-3 py-2 rounded-md mb-2 w-full"
         />
         <button
           onClick={handleExcel}
           className="bg-green-500 hover:bg-green-600 text-white py-2 px-4 rounded-md mr-2"
         >
           Excel
         </button>
         <button
         onClick={handlePdf}
         className="bg-green-500 hover:bg-green-600 text-white py-2 px-4 rounded-lg mr-2"
       >
         PDF
       </button>
         
         {error && <p className="text-red-500 mt-2">{error}</p>}
       </div>
   </div>
 </div>
)}
</div>
    ) }
   
    </>
  );
};

export default AbstractReportForm;