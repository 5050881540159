import React, { useState,useEffect } from 'react';
import TankerTable from '../Components/ViewTanker/ViewTanker';
import UpdateTankerStatus from '../Components/ViewTanker/UpdateTankerStatus';
import DeliveredData from '../Components/ViewTanker/DeliveredData';
import { useNavigate } from "react-router-dom";

const CheckboxComponent = () => {
  const [activeCheckbox, setActiveCheckbox] = useState('checkbox1');
  const [user,setUser] = useState([]);
  
  const navigate = useNavigate();
  useEffect(() => {
    const userData = JSON.parse(sessionStorage.getItem("user"));
    if (userData) {
      setUser(userData);
    }
  }, []);


  useEffect(() => {
    const auth = JSON.parse(sessionStorage.getItem('authentication'));
   
  
    if (!auth ) {
      navigate('/')
      
    } 
  }, []);// Set initial state to 'checkbox1'

  const handleCheckboxChange = (checkboxName) => {
    setActiveCheckbox(checkboxName);
  };

  return (
    <div className='container p-2'>
      <div className='flex items-center justify-center w-[30%]'>
        <div className='flex justify-between w-full text-xs sm:text-sm md:text-base lg:text-lg xl:text-xl'>
          <label className='flex items-center cursor-pointer '>
            <input
              type="checkbox"
              checked={activeCheckbox === 'checkbox1'}
              className='mr-1 appearance-none w-5 h-5 border border-gray-300 rounded-md checked:bg-pink-500 checked:border-transparent'
              onChange={() => handleCheckboxChange('checkbox1')}
            />
            <span>Request</span>
          </label>
          <label className='flex items-center cursor-pointer'>
            <input
              type="checkbox"
              checked={activeCheckbox === 'checkbox2'}
              className='mr-1 appearance-none w-5 h-5 border border-gray-300 rounded-md checked:bg-blue-500 checked:border-transparent'
              onChange={() => handleCheckboxChange('checkbox2')}
            />
            <span>Assign</span>
          </label>
          <label className='flex items-center cursor-pointer'>
            <input
              type="checkbox"
              checked={activeCheckbox === 'checkbox3'}
              className='mr-1 appearance-none w-5 h-5 border border-gray-300 rounded-md checked:bg-green-500 checked:border-transparent'
              onChange={() => handleCheckboxChange('checkbox3')}
            />
            <span>Delivered</span>
          </label>
        </div>
      </div>

      {activeCheckbox === 'checkbox1' && <TankerTable userData={user.user} />}
      {activeCheckbox === 'checkbox2' && <UpdateTankerStatus userData={user.user} token={user.token} />}
      {activeCheckbox === 'checkbox3' && <DeliveredData userData={user.user} token={user.token}  />}
    </div>
  );
};

export default CheckboxComponent;