import React, { useState, useEffect } from 'react';
import axios from 'axios';
import config from "../../config";
import { FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt,faWater,faInfoCircle,faTruck,faTint,faSyncAlt,faTrashAlt,faEdit   } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from "react-router-dom";
import { toast} from "react-toastify"; // Import react-toastify for popups
import "react-toastify/dist/ReactToastify.css";
import UserMaster from './UserRegister';

const InventoryTable = () => {
  const [inventoryData, setInventoryData] = useState([]);
  const [selectedRole, setSelectedRole] = useState('AE');

  const [modalData, setModalData] = useState({ tanker: '' });
  const [showModal, setShowModal] = useState(false);

  const [selectedItem, setSelectedItem] = useState(null); // State to keep track of the selected item

  const [showEditModal, setShowEditModal] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const [Close, setClose] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1020);
  const [detailsData, setDetailsData] = useState({ role: '', mobileNumber: '', email: '' });
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const user = JSON.parse(sessionStorage.getItem("user"));

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1020);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const openEditModal =async  (user) => {
    setSelectedItem(user); // Set the selected item
    if (user.user_role === "AE") {
      // Pass the user's ward ID to the updateWardValue function
      await updateWardValue(user.user_wardid);
       // console.log("update wardId", user.user_wardid);
    }
    if (user.user_role === "Timekeeper") {
      // Pass the user's ward ID to the updateWardValue function
      await updateFillingStationValue(user.filling_station_report);
      // console.log("update Filling_Station", user.filling_station_report);
    }
    if (user.user_role === "Contractor") {
      // Pass the user's ward ID to the updateWardValue function
      await updateFillingContractorVehicle(user.selected_vehicles);
      // console.log("update selected_vehicles", user.selected_vehicles);
    }
    setShowEditModal(true);
  
  };
  const updateWardValue = async (user_wardid) => {
    const token = user ? user.token : null;
    // Create the configToken object for headers
    const configToken = {
      headers: {
        Authorization: `Bearer ${token}`, // Set the Authorization header with the token
      },
    };
  
    if (user_wardid !== null) {
      // Split and trim user_wardid into an array of IDs
      const wardIds = Array.isArray(user_wardid)
        ? user_wardid
        : user_wardid.split(",").map((id) => id.trim());
  
      // Use a for...of loop to iterate through each ID
      for (const id of wardIds) {
        try {
          // Make the PUT request to update the ward value
          const response = await axios.put(
            `${config.apiUrl}/zonewards/updateOpenward/${id}`,
            null, // No data to send in the body
            configToken
          );
  
          // Log the response message and ID
          // console.log(response.data.message);
          // console.log(id);
  
        } catch (error) {
          // Log any errors that occur
          console.error("Error updating TankerWard value:", error);
        }
      }
    }
  };
  

const updateFillingStationValue = async (filling_station_report) => {
  const token = user ? user.token : null;
  // Get the token from sessionStorage
      const configToken = {
        headers: {
          Authorization: `Bearer ${token}`, // Set the Authorization header with the token
        },
      };

  if (filling_station_report!== null) {
    // Check if wardId is already an array
    const wardIds = Array.isArray(filling_station_report)
      ? filling_station_report
      : filling_station_report.split(",").map((id) => id.trim());

    // Iterate through each wardId and update the TankerWard value
    for (const id of wardIds) {
      try {
        // Send a PUT request to update the TankerWard value to FALSE for the current ward ID
        const response = await axios.put(
          `${config.apiUrl}/zonewards/updateOpenFilling/${id}`,configToken
        );
        // console.log(response.data.message);
        // console.log(response.id);
        // Handle success if needed
      } catch (error) {
        console.error("Error updating TankerWard value:", error);
        // Handle error if needed
      }
    }
  }
};

const updateFillingContractorVehicle = async (selected_vehicles) => {
  const token = user ? user.token : null;
  // Get the token from sessionStorage
      const configToken = {
        headers: {
          Authorization: `Bearer ${token}`, // Set the Authorization header with the token
        },
      };

  if (selected_vehicles!== null) {
    // Check if wardId is already an array
    const wardIds = Array.isArray(selected_vehicles)
      ? selected_vehicles
      : selected_vehicles.split(",").map((id) => id.trim());

    // Iterate through each wardId and update the TankerWard value
    for (const id of wardIds) {
      try {
        // Send a PUT request to update the TankerWard value to FALSE for the current ward ID
        const response = await axios.put(
          `${config.apiUrl}/zonewards/updateContractorVehicle/${id}`,configToken
        );
        // console.log(response.data.message);
        // console.log(response.id);
        // Handle success if needed
      } catch (error) {
        console.error("Error updating TankerWard value:", error);
        // Handle error if needed
      }
    }
  }
};

const closeEditModal1 = () => {
  setShowEditModal(false);
 
};
const closeEditModal = () => {
 // console.log('button clicked');
 setShowEditModal(false);
  if (selectedItem && selectedItem.user_wardid) {
    handleCloseUpdateward(selectedItem.user_wardid);
  } else if(selectedItem && selectedItem.filling_station_report)  {
    handleCloseUpdateFillingstation(selectedItem.filling_station_report);
  }else if(selectedItem && selectedItem.selected_vehicles)  {
    handleCloseUpdateContractorVehicle(selectedItem.selected_vehicles);
  }
};
const handleDeleteClick = async(user) => {
  setSelectedItem(user);
  setShowConfirmation(true);
};


const handleCancelDelete = () => {
  setSelectedItem(null);
  setShowConfirmation(false);
};

const openModal = (tanker) => {
  setModalData({tanker });
  setShowModal(true);
};

const closeModal = () => {
  setShowModal(false);
};

const openDetailsModal = (role, mobileNumber, email) => {
  setDetailsData({ role, mobileNumber, email });
  setShowDetailsModal(true);
};

const closeDetailsModal = () => {
  setShowDetailsModal(false);
};

const handleDelete = async () => {
  const token = user ? user.token : null;
  // Get the token from sessionStorage
      const configToken = {
        headers: {
          Authorization: `Bearer ${token}`, // Set the Authorization header with the token
        },
      };

  try {
 // console.log("selectedItem.user_id", selectedItem.user_id);
    const response = await axios.delete(`${config.apiUrl}/user/deleteuser/${selectedItem.user_id}`,configToken);
    if (response.status === 200) {
      // Handle success, such as updating the frontend table after deletion
      toast.success(response.data.message);
    // Set the selected item
      if (selectedItem.user_role === "AE") {
        // Pass the user's ward ID to the updateWardValue function
        await updateWardValue(selectedItem.user_wardid);
        // console.log("update wardId", selectedItem.user_wardid);
      }
      if (selectedItem.user_role === "Timekeeper") {
        // Pass the user's ward ID to the updateWardValue function
        await updateFillingStationValue(selectedItem.filling_station_report);
        // console.log("update Filling_Station", selectedItem.filling_station_report);
      }
      if (selectedItem.user_role === "Contractor") {
        // Pass the user's ward ID to the updateWardValue function
        await updateFillingContractorVehicle(selectedItem.selected_vehicles);
        // console.log("update selected_vehicles", selectedItem.selected_vehicles);
      }
      // console.log('User deleted successfully');
      // You may also fetch updated data from the server here
    } else {
      toast.success(response.data.message);

      console.error('Error deleting user');
    }
  
    await new Promise((resolve) => setTimeout(resolve, 1000));
    setShowConfirmation(false);

    fetchInventoryData();
  } catch (error) {
    console.error('Error deleting user:', error);
  }
};

const handleCloseUpdateward = async (user_wardid) => {
  const token = user ? user.token : null;
  // Get the token from sessionStorage
  const configToken = {
    headers: {
      Authorization: `Bearer ${token}`, // Set the Authorization header with the token
    },
  };

  // Check if wardId is already an array
  const wardIds = Array.isArray(user_wardid)
    ? user_wardid
    : user_wardid.split(",").map((id) => id.trim());

  // Iterate through each wardId and update the TankerWard value
  for (const id of wardIds) {
    try {
      // Send a PUT request to update the TankerWard value to FALSE
      const response = await axios.put(
        `${config.apiUrl}/zonewards/closeupdateward/${id}`,
        null, // No body needed for the PUT request
        configToken
      );

      // console.log(response.data.message);
      // console.log(id);
      // Handle success if needed
    } catch (error) {
      toast.error("Error updating TankerWard value");
      console.error("Error updating TankerWard value:", error);
      // Handle error if needed
    }
  }
};

const handleCloseUpdateFillingstation = async (filling_station_report) => {
  const token = user ? user.token : null;
  // Get the token from sessionStorage
  const configToken = {
    headers: {
      Authorization: `Bearer ${token}`, // Set the Authorization header with the token
    },
  };

  // Check if filling_station_report is already an array
  const stationIds = Array.isArray(filling_station_report)
    ? filling_station_report
    : filling_station_report.split(",").map((id) => id.trim());

  // Iterate through each stationId and update the FillingStation value
  for (const id of stationIds) {
    try {
      // Send a PUT request to update the FillingStation value
      const response = await axios.put(
        `${config.apiUrl}/zonewards/closeupdateFilling/${id}`, 
        null, // No body needed for the PUT request
        configToken
      );
      
      // console.log(response.data.message);
      // console.log(id);
      // Handle success if needed
    } catch (error) {
      toast.error("Error updating FillingStation value");
      console.error("Error updating FillingStation value:", error);
      // Handle error if needed
    }
  }
};

const handleCloseUpdateContractorVehicle = async (selected_vehicles) => {
  const token = user ? user.token : null;
  // Get the token from sessionStorage
  const configToken = {
    headers: {
      Authorization: `Bearer ${token}`, // Set the Authorization header with the token
    },
  };

  // Check if selected_vehicles is already an array
  const vehicleIds = Array.isArray(selected_vehicles)
    ? selected_vehicles
    : selected_vehicles.split(",").map((id) => id.trim());

  // Iterate through each vehicleId and update the ContractorVehicle value
  for (const id of vehicleIds) {
    try {
      // Send a PUT request to update the ContractorVehicle value
      const response = await axios.put(
        `${config.apiUrl}/zonewards/closeupdateContractorVehicle/${id}`,
        null, // No body needed for the PUT request
        configToken
      );

      // console.log(response.data.message);
      // console.log(id);
      // Handle success if needed
    } catch (error) {
      toast.error("Error updating ContractorVehicle value");
      console.error("Error updating ContractorVehicle value:", error);
      // Handle error if needed
    }
  }
};


const fetchInventoryData = async () => {
  const token = user ? user.token : null;
  // Get the token from sessionStorage
      const configToken = {
        headers: {
          Authorization: `Bearer ${token}`, // Set the Authorization header with the token
        },
      };

  try {
    const response = await axios.get(`${config.apiUrl}/user/getuser`,configToken);
    setInventoryData(response.data.data);
  } catch (error) {
    console.error('Error fetching user inventory data:', error);
  }
};

const navigate = useNavigate();
  useEffect(() => {
    const auth = JSON.parse(sessionStorage.getItem('authentication'));  
    if (!auth ) {
      navigate('/')
    } else {
     
      fetchInventoryData();
    }
  }, []);

  const handleRoleChange = (e) => {
    setSelectedRole(e.target.value);
  };

  const filteredData = inventoryData.filter(user => {
    if (selectedRole === 'AE') {
      return user.user_role === 'AE';
    } else if (selectedRole === 'Timekeeper') {
      return user.user_role === 'Timekeeper';
    } else if (selectedRole === 'Contractor') {
      return user.user_role === 'Contractor';
    } else {
      // return true; // Show all data if no role is selected
    }
  });

  return (
    <>
    {!isMobile ? (
      <div className="">
      <h1 className="text-2xl font-bold my-4">User Inventory Report</h1>
      <div className="mb-4">
        <label htmlFor="roleSelect" className="mr-2">Select Role:</label>
        <select id="roleSelect" value={selectedRole} onChange={handleRoleChange}>
          {/* <option value="">All Roles</option> */}
          <option value="AE">AE</option>
          <option value="Timekeeper">Timekeeper</option>
          <option value="Contractor">Contractor</option>

        </select>
      </div>
      <div className=" overflow-y-auto"  style={{ height: '400px' }}>

      <table className="w-full border-collapse border border-gray-400">
      <thead className="sticky top-0">

      <tr className=' text-white' style={{backgroundColor:'rgb(33,64,154)'}}>
            <th className="px-6 py-3 text-center text-xs font-medium  uppercase tracking-wider">S.No</th>
            <th className="px-6 py-3 text-center text-xs font-medium  uppercase tracking-wider">User Name</th>
            <th className="px-6 py-3 text-center text-xs font-medium  uppercase tracking-wider">Role</th>
            <th className="px-6 py-3 text-center text-xs font-medium  uppercase tracking-wider">Mobile Number</th>
            <th className="px-6 py-3 text-center text-xs font-medium  uppercase tracking-wider">Email ID</th>
            {/* Add more table headers as needed */}
            {selectedRole === 'AE' && (
              <>
               <th className="px-6 py-3 text-center text-xs font-medium  uppercase tracking-wider">Zone </th>
               <th className="px-6 py-3 text-center text-xs font-medium  uppercase tracking-wider">Ward ID </th>
              </>
            )}
            {selectedRole === 'Timekeeper' && (
               <th className="px-6 py-3 text-center text-xs font-medium  uppercase tracking-wider">Filling Station</th>
            )}
              {selectedRole === 'Contractor' && (
               <th className="px-6 py-3 text-center text-xs font-medium  uppercase tracking-wider">Tanker</th>
            )}
            <th className="px-6 py-3 text-center text-xs font-medium  uppercase tracking-wider">Edit</th>
            <th className="p-3 text-xs font-semibold uppercase ">Delete</th>

          </tr>
        </thead>
        <tbody className="bg-white text-sm divide-y divide-gray-200">
          {filteredData.map((user, index) => (
            <tr key={user.user_id}>
              <td className="px-6 py-4 whitespace-nowrap">{index + 1}</td>
              <td className="px-6 py-4 whitespace-nowrap">{user.user_title}. {user.user_first_name} {user.user_last_name}</td>
              <td className="px-6 py-4 whitespace-nowrap">{user.user_role}</td>
              <td className="px-6 py-4 whitespace-nowrap">{user.user_mobile_number}</td>
              <td className="px-6 py-4 " style={{wordWrap:'break-word'}}>{user.user_email}</td>
              {selectedRole === 'AE' && (
                <>
                  <td className="px-6 py-4 whitespace-nowrap">{user.user_zone} </td>
                  <td className="px-6 py-4 text-center" style={{wordWrap:'break-word'}}>{user.user_wardid}</td>
                </>
              )}
              {selectedRole === 'Timekeeper' && (
                                  <td className="px-6 py-4 whitespace-nowrap">{user.filling_station_report}</td>

              )}
               {selectedRole === 'Contractor' && (
                                  <td className="px-6 py-4 " style={{wordWrap:'break-word', minWidth:'200px'}}>{user.selected_vehicles}</td>

              )}
                 <td className="px-3 py-3 text-sm">
  <FontAwesomeIcon
    icon={faEdit}
    className="cursor-pointer text-blue-500"
    onClick={() => openEditModal(user)}
  />
</td>
<td className=" px-3 py-3 text-sm">
                  <FontAwesomeIcon
                    icon={faTrashAlt}
                    className="cursor-pointer  ml-2" style={{ color: "red"}}
                    onClick={() => handleDeleteClick(user)}
                  />
                </td>
            </tr>
          ))}
        </tbody>
      </table>
      </div>


 
    </div>
    ) : (
      <div className="">
      <h1 className="text-xl font-bold my-2">User Inventory Report</h1>
      <div className="mb-4">
        <label htmlFor="roleSelect" className="mr-4">Select Role:</label>
        <select id="roleSelect" value={selectedRole} onChange={handleRoleChange}>
          {/* <option value="">All Roles</option> */}
          <option value="AE">AE</option>
          <option value="Timekeeper">Timekeeper</option>
          <option value="Contractor">Contractor</option>
        </select>
      </div>
      <div className="overflow-x-auto " >
        <table className="w-full border-collapse border border-gray-400">
          <thead className="sticky top-0 text-xs">
            <tr className=' text-white' style={{ backgroundColor: 'rgb(33,64,154)' }}>
              <th className="text-center px-1 py-2 text-xs">S.No</th>
              <th className="text-center px-1 py-2 text-xs">User Name</th>
              <th className="text-center px-1 py-2 text-xs">user details</th>
            
              {selectedRole === 'AE' && (
                <>
                  <th className="text-center px-1 py-2 text-xs">Zone </th>
                  <th className="text-center px-1 py-2 text-xs">WardId </th>
                </>
              )}
              {selectedRole === 'Timekeeper' && (
                <th className="text-center px-1 py-2 text-xs">Filling Station</th>
              )}
              {selectedRole === 'Contractor' && (
                <th className="text-center px-1 py-2  text-xs">Tanker</th>
              )}
              <th className="text-center px-1 py-2 text-xs">Edit</th>
              <th className="text-center px-1 py-2 text-xs ">Delete</th>
             
            </tr>
          </thead>
          <tbody className="bg-white text-xs divide-y divide-gray-200 max-h-80 overflow-y-auto">
            {filteredData.map((user, index) => (
              <tr key={user.user_id}>
                <td className="whitespace-wrap px-1 text-center py-2 text-xs">{index + 1}</td>
                <td className="whitespace-wrap px-1 text-center py-2 text-xs">{user.user_title}. {user.user_first_name} {user.user_last_name}</td>
                <td className="text-center whitespace-wrap px-1 py-2 text-xs">
  <FontAwesomeIcon
    icon={faInfoCircle}
    className="cursor-pointer text-blue-500 mr-2"
    onClick={() => openDetailsModal(user.user_role, user.user_mobile_number, user.user_email)}
  />
</td>

                {selectedRole === 'AE' && (
                  <>
                    <td className="whitespace-nowrap text-center px-1 py-2 text-xs">{user.user_zone} </td>
                    <td className="text-xs px-1 py-2 text-center" style={{ wordWrap: 'break-word' }}>{user.user_wardid}</td>
                  </>
                )}
                {selectedRole === 'Timekeeper' && (
                  <td className="whitespace-nowrap px-1 py-2 text-center">{user.filling_station_report}</td>
                )}
                {selectedRole === 'Contractor' && (
                  <td className="text-center px-1 py-2" style={{wordWrap:'break-word', minWidth:'40px'}}>{user.selected_vehicles}
                  {/* <FontAwesomeIcon
                    icon={faInfoCircle}
                    className="cursor-pointer text-blue-500"
                    onClick={() => openModal( user.selected_vehicles)}
                  /> */} 
                </td>
                
                )}
                <td className="text-xs px-1 py-2 text-center">
                  <FontAwesomeIcon
                    icon={faEdit}
                    className="cursor-pointer text-blue-500"
                    onClick={() => openEditModal(user)}
                  />
                </td>
                <td className=" text-xs text-center px-1 py-2">
                  <FontAwesomeIcon
                    icon={faTrashAlt}
                    className="cursor-pointer text-red-500" 
                    onClick={() => handleDeleteClick(user)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {showModal && (
  <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75">
    <div className="bg-white p-4 rounded-lg max-w-xs relative"> {/* Adjust max-w-sm as needed */}
      <button onClick={closeModal} className="absolute top-0 right-0 text-gray-700 hover:text-gray-900">
        <svg className="h-6 w-6 text-red-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
        </svg>
      </button>
      <div>
        <p className='text-sm'><strong>Tanker:</strong> {modalData.tanker}</p>
      </div>
    </div>
  </div>
)}
    



{showDetailsModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75">
          <div className="bg-white p-4 rounded-lg max-w-xs  relative">
            <button onClick={closeDetailsModal} className="absolute top-0 right-0 text-gray-700 hover:text-gray-900">
              <svg className="h-6 w-6 text-red-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
            <div>
              <p><strong>Role:</strong> {detailsData.role}</p>
              <p><strong>Mobile Number:</strong> {detailsData.mobileNumber}</p>
              <p><strong>Email:</strong> {detailsData.email}</p>
            </div>
          </div>
          
        </div>
      )}
    </div>
    
    )}
          {showEditModal && (
  <div className="fixed inset-0 z-40 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
    <div className="fixed inset-0 bg-black bg-opacity-50"></div>
    <div className="relative w-full max-w-sm mt-20 md:mt-0 md:max-w-xl mx-auto max-h-screen md:max-h-[80vh] p-2">
    <div className="bg-white p-4 rounded-lg shadow-md">
          <button onClick={closeEditModal} className="absolute top-2 right-0 mr-4 text-gray-700 hover:text-gray-900">
            <svg className="h-6 w-6 text-red-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
          <UserMaster
          data={selectedItem}
          onClose={closeEditModal1}
          fetchInventoryData={fetchInventoryData} 
        />
        </div>
    </div>
  </div>
)}



{showConfirmation && (
        <div className="fixed inset-0 z-40 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
          <div className="fixed inset-0 bg-black bg-opacity-50"></div>
          <div className="relative w-auto max-w-3xl mx-auto my-4">
            <div className="bg-white p-8 rounded-lg shadow-md">
              <p className="mb-3">Are you sure you want to remove the user {`${selectedItem.user_title} ${selectedItem.user_first_name} ${selectedItem.user_last_name}`}?</p>


              <div className="flex justify-center space-x-3">
                <button
                  onClick={handleDelete}
                  className="bg-red-500 hover:bg-red-600 text-white px-2 py-2 rounded-full"
                >
                  Yes
                </button>
                <button
                  onClick={handleCancelDelete}
                  className="bg-gray-400 hover:bg-gray-500 text-white px-2 py-2 rounded-full"
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    
    </>
  );
};

export default InventoryTable;