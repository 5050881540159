import React, { useState, useEffect ,useRef} from "react";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaSearch,FaPrint} from "react-icons/fa";
import config from "../../config";
import report from '../../assests/report.png';
import ReactToPrint from "react-to-print";
import * as XLSX from 'xlsx';
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
   faTimes,faPlus,faFileExport
} from "@fortawesome/free-solid-svg-icons";
import jsPDF from "jspdf";
import "jspdf-autotable";

function VehicleDownwiseReport() {
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [downtimeData, setDowntimeData] = useState([]);
  const componentRef = useRef();
  const [error, setError] = useState('');
  const [showLoginForm, setShowLoginForm] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1020);
  const [user,setUser] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [usertoken,setusertoken] = useState('')

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1020);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const navigate = useNavigate();
  const handlePrint = () => {
    window.print();
  };

  useEffect(() => {
    const auth = JSON.parse(sessionStorage.getItem('authentication'));
    const userData = JSON.parse(sessionStorage.getItem("user"));
    if (userData) {
      setUser(userData.user);
      setusertoken(userData.token)
      // console.log('userdatsa',userData.user);
       
    }
  
    if (!auth ) {
      navigate('/')
      
    } 
  }, []);


  const formatDateForBackend = (date, endOfDay = false) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is zero-based
    const day = String(date.getDate()).padStart(2, '0');
  
    if (endOfDay) {
      return `${year}-${month}-${day}T23:59:59.999Z`; // End of the day
    } else {
      return `${year}-${month}-${day}T00:00:00.000Z`; // Start of the day
    }
  };
  
  const handleFromDateChange = (date) => {
    setFromDate(date);
  };
  
  const handleToDateChange = (date) => {
    setToDate(date);
  };
  
  const handleSearch = async () => {
    if(user && user.user_role){
    const token = usertoken ? usertoken : null;
    // Get the token from sessionStorage
    const configToken = {
      headers: {
        Authorization: `Bearer ${token}`, // Set the Authorization header with the token
      },
    };
    
    try {
      let url = `${config.apiUrl}/vehicledowntime/vehicledowntimereport`;
    
      // Add query parameters based on conditions
      const params = new URLSearchParams();
    
      if (user && user.user_role) {
        params.append('user_role', user.user_role);
        if (user.user_role === 'Contractor' && user.user_id) {
          params.append('user_id', user.user_id);
        }
      }
    
      if (fromDate && toDate) {
        const formattedFromDate = formatDateForBackend(fromDate);
        const formattedToDate = formatDateForBackend(toDate, true); // End of the day for toDate
        params.append('fromDate', formattedFromDate);
        params.append('toDate', formattedToDate);
      }
    
      // Append the query parameters to the URL
      url += `?${params.toString()}`;
    
      const response = await axios.get(url, configToken);
      let filteredData = response.data;
    
      // Filter data based on the search query
      if (searchQuery) {
        const formattedSearchQuery = searchQuery.replace(/\s/g, '').toLowerCase();
        filteredData = filteredData.filter(item =>
          item.vehicleNo.replace(/\s/g, '').toLowerCase().includes(formattedSearchQuery)
        );
      }
    
      // Update downtimeData with filteredData if searchQuery is not empty, otherwise use the original data
      setDowntimeData(searchQuery ? filteredData : response.data); // Set the filtered data
    } 
    catch (error) {
      console.error('Error fetching downtime report:', error);
    }
  }
  };
  
  useEffect(() => {
    handleSearch(); // Fetch downtime report on component mount
  }, [user,fromDate, toDate,searchQuery]);

  const handleKeyDown = (e) => {
    if (e.key === 'Backspace') {
      setSearchQuery('');
  
    }
  };

    // Function to format date and time
    const formatDate = (dateTime) => {
      const options = {
        day: '2-digit',
        month: 'short',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
      };
      return new Date(dateTime).toLocaleString('en-US', options);
    };
     // Function to print the table
   

    
  const calculateHours = (startDate, endDate) => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    return Math.floor(Math.abs(end - start) / 36e5); // 36e5 is the number of milliseconds in an hour
  };

  const getTotalHours = () => {
    return downtimeData.reduce((total, item) => total + calculateHours(item.startDateTime, item.endDateTime), 0);
  };

  const exportToPdf = async () => {
    const token = usertoken ? usertoken : null;
    // Get the token from sessionStorage
        const configToken = {
          headers: {
            Authorization: `Bearer ${token}`, // Set the Authorization header with the token
          },
        };
    try {
      const response = await axios.post(`${config.apiUrl}/user/exportUser`, { loginIdentifier: username, password },
        configToken
      ); // Changed endpoint to PDF export
      const { data, message } = response.data;
      
      if (response.status === 200 && data.user.user_role === 'Admin') {
        const doc = new jsPDF();
        const totalHours = getTotalHours();
      
        // Add table to PDF
        doc.autoTable({
          head: [
            ["S.No", "Vehicle No", "Start Downtime", "End Downtime", "Hours"]
          ],
          body: downtimeData.map((item, index) => [
            index + 1,
            item.vehicleNo,
            formatDate(item.startDateTime),
            formatDate(item.endDateTime),
            calculateHours(item.startDateTime, item.endDateTime)
          ]),
          foot: [["", "", "", "Total Hours", totalHours]]
        });
      
        // Save PDF
        doc.save("VehicleDowntimeReport.pdf");
        setError('');
        setShowLoginForm(false);
      } else {
        setError('Unauthorized User');
      }
    } catch (error) {
      setError('Failed to export PDF file');
    }
  };



const exportToExcel = async () => {
  const token = usertoken ? usertoken : null;
  // Get the token from sessionStorage
      const configToken = {
        headers: {
          Authorization: `Bearer ${token}`, // Set the Authorization header with the token
        },
      };
  try {
    const response = await axios.post(`${config.apiUrl}/user/exportUser`, { loginIdentifier: username, password },configToken);
    const { data, message } = response.data;

    if (response.status === 200 ){
    if( data.user.user_role === 'Admin') {
     
      const fileName = "VehicleDownReportData.xlsx";
      const totalHours = getTotalHours();
      
      // Create the worksheet data array
      const wsData = [
          ["S.No", "Vehicle No", "Start Downtime", "End Downtime", "Hours"], // Header row
          ...downtimeData.map((item, index) => [
              index + 1,
              item.vehicleNo,
              formatDate(item.startDateTime),
              formatDate(item.endDateTime),
              calculateHours(item.startDateTime, item.endDateTime)
          ]),
          [" ", "", "", "Total Hours", totalHours] // Total hours row
      ];
  
      // Create worksheet
      const ws = XLSX.utils.aoa_to_sheet(wsData);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "DowntimeData");
  
      // Export the workbook
      XLSX.writeFile(wb, fileName);
      
      setError('');
      setShowLoginForm(false);
    }
    else {
      setError('Unauthorized User');
    }
  }
    else {
      setError(message);
    }
  } catch (error) {
    setError('Failed to export Excel file');
  }
};
const handleClose = () => {
  // Function to handle closing the login form
  setShowLoginForm(false);
  setUsername('');
  setPassword('');
};
  

  return (
    <>


    {!isMobile?(
       <div className="container mx-auto p-8">
       <div className="text-center">
         <h1 className="text-3xl  text-center font-bold mb-3 mt-0 text-blue-700">Vehicle Wise Down Time Report</h1>
       </div>
       <div className="flex items-center justify-center space-x-4 mb-4">
       <div className="flex flex-col">
  <label className="text-black">From Date:</label>
  <div className="relative">
    <DatePicker
      selected={fromDate}
      onChange={handleFromDateChange}
      className="border border-blue-500 px-3 py-2 rounded-md w-48"
    />
    {fromDate && (
      <button
        className="absolute top-3 right-3 focus:outline-none"
        onClick={() => setFromDate(null)}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-4 w-4 text-gray-500 hover:text-gray-700"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M2.293 2.293a1 1 0 011.414 0L10 8.586l6.293-6.293a1 1 0 111.414 1.414L11.414 10l6.293 6.293a1 1 0 01-1.414 1.414L10 11.414l-6.293 6.293a1 1 0 01-1.414-1.414L8.586 10 2.293 3.707a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
      </button>
    )}
  </div>
</div>
<div className="flex flex-col">
  <label className="text-black">To Date:</label>
  <div className="relative">
    <DatePicker
      selected={toDate}
      onChange={handleToDateChange}
      className="border border-blue-500 px-3 py-2 rounded-md w-48"
    />
    {toDate && (
      <button
        className="absolute top-3 right-3 focus:outline-none"
        onClick={() => setToDate(null)}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-4 w-4 text-gray-500 hover:text-gray-700"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M2.293 2.293a1 1 0 011.414 0L10 8.586l6.293-6.293a1 1 0 111.414 1.414L11.414 10l6.293 6.293a1 1 0 01-1.414 1.414L10 11.414l-6.293 6.293a1 1 0 01-1.414-1.414L8.586 10 2.293 3.707a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
      </button>
    )}
  </div>
</div>
<div className="flex flex-col">
            <label className="text-black">Search Vehicle No:</label>
            <div className="relative">
              <input
                type="text"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="border border-blue-500 px-3 py-2 rounded-md w-full"
                placeholder="Enter vehicle number"
                onKeyDown={handleKeyDown}
              />
            </div>
          </div>

        
      
      
         <ReactToPrint
           trigger={() => (
             <button
               onClick={handlePrint}
               className="bg-green-500 hover:bg-green-600 mt-4 text-white px-2 h-8 w-8  rounded-md flex items-center transition-colors duration-300 ease-in-out"
             >
               <FaPrint />
             </button>
           )}
           content={() => componentRef.current}
          
         />
         {!showLoginForm && (
             <button className="ml-[20px]  mt-4 bg-green-500 hover:bg-green-600 text-white px-2 h-8 w-8 rounded-md"  onClick={() => setShowLoginForm(true)}>
           <FontAwesomeIcon icon={faFileExport} className="mr-2" />
       </button>
           )}
   
   </div>
   
          <div  className="max-h-96 overflow-y-auto flex justify-center ">
          
         {downtimeData.length > 0 ? (
          <div className=" overflow-y-auto " ref={componentRef}>
     <table  id="downtimeTable" className="w-[750px] text-sm text-left text-white-500 center">
       {/* Table Headers */}
       <thead style={{ backgroundColor: 'rgb(33,64,154)' }} className="text-sm uppercasee text-white sticky top-0">
         <tr>
           <th className="border border-gray-500 px-4 py-2">S.No</th>
           <th className="border border-gray-500 px-4 py-2">Vehicle No</th>
           <th className="border border-gray-500 px-4 py-2">Start Downtime</th>
           <th className="border border-gray-500 px-4 py-2">End Downtime</th>
           <th className="border border-gray-500 px-4 py-2">Hours</th>
         </tr>
       </thead>
       {/* Table Body */}
       <tbody>
         {downtimeData.map((item, index) => (
           <tr key={index}>
             <td className="border border-gray-500 px-4 py-2">{index + 1}</td>
             <td className="border border-gray-500 px-4 py-2">{item.vehicleNo}</td>
             <td className="border border-gray-500 px-4 py-2">{formatDate(item.startDateTime)}</td>
             <td className="border border-gray-500 px-4 py-2">{formatDate(item.endDateTime)}</td>
             <td className="border border-gray-500 px-4 py-2">{calculateHours(item.startDateTime, item.endDateTime)}</td>
           </tr>
         ))}
       </tbody>
       <tfoot>
                 <tr>
                   <td colSpan="4" className="border border-gray-500 px-4 py-2 text-right font-bold">Total Hours:</td>
                   <td className="border border-gray-500 px-4 py-2 font-bold">{getTotalHours()}</td>
                 </tr>
               </tfoot>
     </table>
     </div>
   ) : (
     <p  className="text-2xl" >No details found.</p>
   )}
         </div>
   
         {showLoginForm && (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
    <div className="bg-white w-full max-w-sm  rounded-lg overflow-hidden shadow-xl">
    <div className="px-6 py-4">
    <div className="flex justify-end">
      <button className="text-gray-700 hover:text-gray-900"  onClick={handleClose}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
        </svg>
      </button>
    </div>
    <label htmlFor="username" className="text-gray-700">Username</label>
    <input
 type="text"
 placeholder="Username or Mobile Number"
 value={username}
                onChange={(e) => setUsername(e.target.value)}
                className="border border-gray-400 px-3 py-2 rounded-md mb-2 w-full"
              />
    <label htmlFor="password" className="text-gray-700">Password</label>
    <input
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="border border-gray-400 px-3 py-2 rounded-md mb-2 w-full"
              />
     <button
                 onClick={exportToExcel}
                className="bg-green-500 hover:bg-green-600 text-white py-2 px-4 rounded-lg mr-5"
              >
                Excel
              </button>
              <button
               onClick={exportToPdf}
                className="bg-green-500 hover:bg-green-600 text-white py-2 px-4 rounded-lg mr-2"
              >
                PDF
              </button>
    
              {error && <p className="text-red-500">{error}</p>}
            </div>
    </div>
    </div>
   )}
       </div>
    ):(
      <div className="container ">
   <div className="text-center">
  <h1 className="text-lg font-bold mb-3 text-blue-700 mr-2">Vehicle Wise Down Time Report</h1>
</div>
<div className="flex flex-row md:flex-row">
  <div className="flex flex-col ml-10">
    <label className="text-black">From Date:</label>
    <div className="relative">
      <DatePicker
        selected={fromDate}
        onChange={handleFromDateChange}
        className="border border-blue-500 px-2 py-1 rounded-md w-3/4"
      />
      {fromDate && (
      <button
        className="absolute top-2 right-10 focus:outline-none"
        onClick={() => setFromDate(null)}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-4 w-4 text-gray-500 hover:text-gray-700"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M2.293 2.293a1 1 0 011.414 0L10 8.586l6.293-6.293a1 1 0 111.414 1.414L11.414 10l6.293 6.293a1 1 0 01-1.414 1.414L10 11.414l-6.293 6.293a1 1 0 01-1.414-1.414L8.586 10 2.293 3.707a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
      </button>
    )}
    </div>
  </div>
  <div className="flex flex-col">
    <label className="text-black">To Date:</label>
    <div className="relative">
      <DatePicker
        selected={toDate}
        onChange={handleToDateChange}
        className="border border-blue-500 px-2 py-1 rounded-md w-3/4"
      />
        {toDate && (
      <button
        className="absolute top-2 right-10 focus:outline-none"
        onClick={() => setToDate(null)}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-4 w-4 text-gray-500 hover:text-gray-700"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M2.293 2.293a1 1 0 011.414 0L10 8.586l6.293-6.293a1 1 0 111.414 1.414L11.414 10l6.293 6.293a1 1 0 01-1.414 1.414L10 11.414l-6.293 6.293a1 1 0 01-1.414-1.414L8.586 10 2.293 3.707a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
      </button>
    )}
    </div>
  </div>
</div>
<div className="flex justify-center mt-4 ">
  
  <ReactToPrint
    trigger={() => (
      <button
        onClick={handlePrint}
        className="bg-green-500 hover:bg-green-600 text-white h-8 w-8 px-2  rounded-md flex items-center transition-colors duration-300 ease-in-out ml-4"
      >
        <FaPrint />
      </button>
    )}
    content={() => componentRef.current}
  />
  {!showLoginForm && (
    <button className="bg-green-500 hover:bg-green-600 text-white h-8 w-8 px-2  rounded-md ml-4" onClick={() => setShowLoginForm(true)}>
      <FontAwesomeIcon icon={faFileExport} />
    </button>
  )}
</div>


      <div ref={componentRef} className="max-h-80 overflow-y-auto mt-4 max-w-sm">
        {downtimeData.length > 0 ? (
          <table id="downtimeTable" className="max-w-sm text-xs text-center text-white-500 ">
            <thead style={{ backgroundColor: 'rgb(33,64,154)' }} className="text-xs text-white sticky top-0">
              <tr>
                <th className="border border-gray-500 px-2 py-1">S.No</th>
                <th className="border border-gray-500 px-2 py-1">Vehicle No</th>
                <th className="border border-gray-500 px-2 py-1">Start Downtime</th>
                <th className="border border-gray-500 px-2 py-1">End Downtime</th>
                <th className="border border-gray-500 px-2 py-1">Hours</th>
              </tr>
            </thead>
            <tbody>
              {downtimeData.map((item, index) => (
                <tr key={index}>
                  <td className="border border-gray-500 px-2 py-1">{index + 1}</td>
                  <td className="border border-gray-500 px-2 py-1">{item.vehicleNo}</td>
                  <td className="border border-gray-500 px-2 py-1">{formatDate(item.startDateTime)}</td>
                  <td className="border border-gray-500 px-2 py-1">{formatDate(item.endDateTime)}</td>
                  <td className="border border-gray-500 px-2 py-1">{calculateHours(item.startDateTime, item.endDateTime)}</td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <td colSpan="4" className="border border-gray-500 px-2 py-1 text-right font-bold">Total Hours:</td>
                <td className="border border-gray-500 px-2 py-1 font-bold">{getTotalHours()}</td>
              </tr>
            </tfoot>
          </table>
        ) : (
          <p className="text-lg text-center mt-4">No details found.</p>
        )}
      </div>
      {showLoginForm && (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
    <div className="bg-white w-full max-w-xs md:max-w-lg rounded-lg overflow-hidden shadow-xl">
    <div className="px-6 py-4">
    <div className="flex justify-end">
      <button className="text-gray-700 hover:text-gray-900"  onClick={handleClose}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
        </svg>
      </button>
    </div>
    <label htmlFor="username" className="text-gray-700">Username</label>
    <input
 type="text"
 placeholder="Username or Mobile Number"
 value={username}
                onChange={(e) => setUsername(e.target.value)}
                className="border border-gray-400 px-2 py-1 rounded-md mb-2"
              />
    <label htmlFor="password" className="text-gray-700">Password</label>
    <input
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="border border-gray-400 px-2 py-1 rounded-md mb-2"
              />
     <button
                 onClick={exportToExcel}
                className="bg-green-500 hover:bg-green-600 text-white py-2 px-4 rounded-lg mr-5"
              >
                Excel
              </button>
              <button
               onClick={exportToPdf}
                className="bg-green-500 hover:bg-green-600 text-white py-2 px-4 rounded-lg mr-2"
              >
                PDF
              </button>
    
              {error && <p className="text-red-500">{error}</p>}
            </div>
    </div>
    </div>
   )}
    </div>
    )}
   
    </>
  );
}



export default VehicleDownwiseReport;