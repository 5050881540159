import React, { useState, useEffect } from "react";
import axios from "axios";
import config from "../../config";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapMarkerAlt,
  faEdit,
  faTrashAlt,
  faSave,
  faTimes,faPlus,faFileExcel,faFileExport
} from "@fortawesome/free-solid-svg-icons";
import { toast} from "react-toastify"; // Import react-toastify for popups
import "react-toastify/dist/ReactToastify.css";
import * as XLSX from 'xlsx';
import jsPDF from "jspdf";
import "jspdf-autotable";

const FillingStationTable = () => {
  const [fillingStationName, setFillingStationName] = useState(null);
  const [fillingStations, setFillingStations] = useState([]);

  const [filteredStations, setFilteredStations] = useState([]);

  const [editfillingStations, seteditfillingStations] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [errors, setErrors] = useState({}); 
  const [error, setError] = useState('');
  const [showLoginForm, setShowLoginForm] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');// State for form validation errors
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1020);
  const [searchQuery, setSearchQuery] = useState('');
  const user = JSON.parse(sessionStorage.getItem("user"));

  const navigate = useNavigate();


  useEffect(() => {
    const auth = JSON.parse(sessionStorage.getItem('authentication'));  
    if (!auth ) {
      navigate('/')    
    } else {   
      fetchFillingStations();
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1020);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  const fetchFillingStations = async () => {
    const token = user ? user.token : null;
    // Get the token from sessionStorage
        const configToken = {
          headers: {
            Authorization: `Bearer ${token}`, // Set the Authorization header with the token
          },
        };
    try {
      const response = await axios.get(
        `${config.apiUrl}/zonewards/getfillingstation`,configToken
      );
      setFillingStations(response.data.results);
      // console.log(response.data.results);
    } catch (error) {
      console.error("Error fetching filling stations:", error);
      // Handle error
    }
  };

  useEffect(() => {
    filterZoneWards();
}, [searchQuery, fillingStations]);

 
    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    
    const filterZoneWards = () => {
        const filtered = fillingStations.filter(station =>
          station.
          FillingStationName
          .toLowerCase().includes(searchQuery.toLowerCase()) 
        );
        setFilteredStations(filtered);
    };

  
  
  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
       setShowModal(false);
  };

  const handleDeleteClick = (station) => {
    setSelectedItem(station);
    setShowConfirmation(true);
  };

  const handleCancelDelete = () => {
    setSelectedItem(null);
    setShowConfirmation(false);
  };

  const handleDelete = async () => {
    const token = user ? user.token : null;
    // Get the token from sessionStorage
        const configToken = {
          headers: {
            Authorization: `Bearer ${token}`, // Set the Authorization header with the token
          },
        };
    try {
      const response = await fetch(
        `${config.apiUrl}/zonewards/deletefillingstation/${selectedItem.id}`,
        {
          method: "DELETE",
          headers: configToken.headers,

        }
      );
      if (response.ok) {
        // Remove the deleted item from the local state
        setFillingStations(
          fillingStations.filter((item) => item.id !== selectedItem.id)
        );
        toast.success("Record deleted successfully");

        // setFilteredData(filteredData.filter(item => item.id !== selectedItem.id))
        handleCancelDelete();
      } else {
        console.error("Error deleting record");
      }
    } catch (error) {
      console.error("Error deleting record:", error);
    }
  };

  const handleEdit = (station) => {
    seteditfillingStations({ ...station });
  };

  const handleSave = async () => {
    const token = user ? user.token : null;
    // Get the token from sessionStorage
        const configToken = {
          headers: {
            Authorization: `Bearer ${token}`, // Set the Authorization header with the token
          },
        };
  
    try {
      const response = await axios.put(
        `${config.apiUrl}/zonewards/updatefillingstation/${editfillingStations.id}`,
        editfillingStations,
        configToken
      );
      toast.success(response.data.message);
      fetchFillingStations();
      seteditfillingStations(null);
    } catch (error) {
      if (error.response && error.response.status === 400) {
        // Handle specific error code (Fillingstation already exists)
        toast.error(error.response.data.message);
      } else {
        // Handle other errors
        console.error("Error updating driver:", error);
        toast.error("An error occurred while updating the filling station.");
      }
    }
  };

  const handleCancel = () => {
    seteditfillingStations(null);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const onlyLettersRegex = /^[a-zA-Z\s]*$/;

    if (name === "driverName") {
      if (!onlyLettersRegex.test(value)) {
        return;
      }
    }

    seteditfillingStations((prevState) => ({
      ...prevState,
      [name]: value.trim(),
    }));
  };

  const handleSubmit = async (e) => {
    const token = user ? user.token : null;
    // Get the token from sessionStorage
        const configToken = {
          headers: {
            Authorization: `Bearer ${token}`, // Set the Authorization header with the token
          },
        };
  
    e.preventDefault();

    const formErrors = {}; // Temporary object to hold form validation errors
  
    // Validate required fields
    if (!fillingStationName) {
      formErrors.fillingStationName = 'fillingStationName is required';
    }

    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }
    try {
      await axios.post(`${config.apiUrl}/zonewards/fillingstation`, {
        FillingStationName: fillingStationName,
      
      },  configToken);
      // Optionally, you can add success message or redirect to another page
      toast("Filling station created successfully");
      setFillingStationName(null);
      closeModal()
      fetchFillingStations();
    } catch (error) {

      console.error('Error creating Filling station name :', error);
      // Check if the error response has status code 400
      if (error.response && error.response.status === 400) {
          // WardID already exists, show toast message
          toast.error('Filling station name already exists');
          setFillingStationName(null);

      } else {
          // Handle other errors
          console.error("Error creating filling station:", error);
        }
      // Handle error
    }
  };
  const modalStyles = `
  .modal {
    display: flex;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0,0.4);
  }

  .modal-content {
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    // width: 80%;
    animation-name: modal-slide;
    animation-duration: 0.5s;
  }

  @keyframes modal-slide {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(0);
    }
  }

  .close-btn {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }

  .close-btn:hover,
  .close-btn:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }
`;
const handlePdf = async () => {
  const token = user ? user.token : null;
  // Get the token from sessionStorage
      const configToken = {
        headers: {
          Authorization: `Bearer ${token}`, // Set the Authorization header with the token
        },
      };
  try {
    const response = await axios.post(`${config.apiUrl}/user/exportUSer`, { loginIdentifier: username, password },configToken);
    const { data, message } = response.data;
    if (response.status === 200 ){
      if( data.user.user_role === 'Admin') {
        const doc = new jsPDF();

      // Add header row
      const header = [[ "S.No",
      "FillingStation"]];
      
      // Add data rows
      const data = fillingStations.map((item, index) => [
        index + 1,
        item.FillingStationName ,
       
    ])
  
      // Add table to PDF
      doc.autoTable({
        head: header,
        body: data,
      });
  
      // Save PDF
      doc.save('FillingStationData.pdf');
        setError('');
        setShowLoginForm(false);
      }
      else {
          setError('Unauthorized User');
      }
    }
      else {
          setError(message);
      }
    } catch (error) {
      setError('Failed to export Excel file');
    }
  };

const handleExcel = async () => {
  const token = user ? user.token : null;
  // Get the token from sessionStorage
      const configToken = {
        headers: {
          Authorization: `Bearer ${token}`, // Set the Authorization header with the token
        },
      };
  try {
    const response = await axios.post(`${config.apiUrl}/user/exportUser`, { loginIdentifier: username, password },configToken);
    const { data, message } = response.data;
    if (response.status === 200 ){
      if( data.user.user_role === 'Admin') {
        const fileName = "FillingStationData.xlsx";
        const wsData = [
            [
                "S.No",
                "FillingStation",
               
            ],
            ...fillingStations.map((item, index) => [
                index + 1,
                item.FillingStationName ,
               
            ])
        ];
      
        const ws = XLSX.utils.aoa_to_sheet(wsData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "fillingStation");
      
        // Export the workbook
        XLSX.writeFile(wb, fileName);
        setError('');
        setShowLoginForm(false);
      }
      else {
          setError('Unauthorized User');
      }
    }
      else {
          setError(message);
      }
    } catch (error) {
      setError('Failed to export Excel file');
    }
  };
  
const handleClose = () => {
  // Function to handle closing the login form
  setShowLoginForm(false);
  setUsername('');
  setPassword('');
  setError('');
};
  return (
    <div>
      
      <div className="flex justify-between mb-3">
      <div className="flex justify-start items-center">

  <h1 className="md:text-2xl text-sm font-bold">Filling Station</h1>
  <input
    type="text"
    placeholder="Search Filling Stations"
    value={searchQuery}
    onChange={handleSearchChange}
      className="sm:p-1 border border-gray-300 rounded-md sm:ml-2 mr-1"
    />
     </div>
  <div className="flex items-center"> 


    {/* Conditionally render the "+" icon for "Add New" on mobile screens */}
   <div className="hidden md:flex"> {/* Hide on mobile screens */}
    <button className="text-white text-xs py-1 px-4 rounded" onClick={() => openModal()} style={{  backgroundImage: "linear-gradient(to right, #003399, #00f2fe)",   fontSize: '0.9rem'}}>
      Add New FillingStation
      <FontAwesomeIcon icon={faPlus} className="ml-1" /> {/* Added margin to separate icon */}
    </button>
    </div>


    {/* Conditionally render the "ADD NEW" button on laptop screens */}
    <div className="md:hidden mr-1"> {/* Hide on laptop screens */}
      <button
        className="text-white text-xs py-1 px-4 rounded"
        onClick={() => openModal()}
        style={{ backgroundImage: 'linear-gradient(to right, #4facfe, #00f2fe)' }}
      >
        <FontAwesomeIcon icon={faPlus} /> {/* Display only the icon */}
      </button>
    </div>


    {/* Conditionally render the "Export" button with the same approach */}
    <div className="hidden md:flex"> {/* Hide on mobile screens */}
      {!showLoginForm && (
        <button className="bg-green-500 hover:bg-green-600 text-white py- px-2 rounded ml-1" onClick={() => setShowLoginForm(true)}>
        <FontAwesomeIcon icon={faFileExport} className="mr-2" />
        Export
      </button>
      )}
    </div>
    
    {/* Conditionally render the "Export" button with the same approach */}
    <div className="md:hidden"> {/* Hide on laptop screens */}
      {!showLoginForm && (
        <button
          className="text-white text-xs py-1 px-2 rounded bg-green-500"
          onClick={() => setShowLoginForm(true)}
         
        >
          <FontAwesomeIcon icon={faFileExport} />
        </button>
      )}
    </div>
  </div>
</div>



    <div className="w-full overflow-y-auto " style={{ maxHeight: "450px" }}> 
      <table className="w-full border-collapse">
        <thead className="sticky top-0 border-2 border-gray-400 ">
          <tr className="text-white" style={{ backgroundColor: "rgb(33,64,154)" }}>
            <th className="text-xs md:text-xs px-2 py-1 uppercase">S.No</th> 
            <th className="text-xs md:text-xs px-2 py-1 uppercase">Filling Station Name</th>
            <th className="text-xs md:text-xs px-2 py-1 uppercase">Edit</th>
            <th className="text-xs md:text-xs px-2 py-1 uppercase">Delete</th>
          </tr>
        </thead>
        <tbody className="xl:text-base ">
          {filteredStations.map((station, index) => (
            <tr key={station.id} className="border border-b-2">
              <td className="px-3 py-2 text-center">{index + 1}</td>
              <td className="px-3 py-2 text-center">
                {editfillingStations && editfillingStations.id === station.id ? (
                  <input
                    type="text"
                    className="w-1/2 border border-sky-500 " 
                    name="FillingStationName"
                    value={editfillingStations.FillingStationName}
                    onChange={handleChange}
                  />
                ) : (
                  station.FillingStationName
                )}
              </td>
              
              <td className="px-3 py-2 text-center flex items-center justify-center"> {/* Add flexbox classes */}
  {editfillingStations && editfillingStations.id === station.id ? (
    <>
      <FontAwesomeIcon
        icon={faSave}
        className="cursor-pointer text-green-500 mr-2"
        onClick={handleSave}
        size="sm:lg xs"
      />
      <FontAwesomeIcon
        icon={faTimes}
        className="cursor-pointer text-red-500 ml-2 "
        onClick={handleCancel}
        size="sm:lg xs"
      />
    </>
  ) : (
    <FontAwesomeIcon
      icon={faEdit}
      className="cursor-pointer text-blue-500 "
      onClick={() => handleEdit(station)}
      size="sm:lg xs"

    />
  )}
</td>

              <td className="px-3 py-2 text-center">
                <FontAwesomeIcon
                  icon={faTrashAlt}
                  className="cursor-pointer ml-2"
                  style={{ color: "red" }}
                  size="sm:lg xs"

                  onClick={() => handleDeleteClick(station)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
    {showModal && (
      <div className="modal  overflow-y-auto h-full">
        <style>{modalStyles}</style>
        <div className="modal-content max-w-xs md:max-w-sm ">
          <button onClick={closeModal} className="close-btn">
            <FontAwesomeIcon icon={faTimes} className=" text-red-500"/>
          </button>
          <br/>
          <form onSubmit={handleSubmit}>
            <div className="gap-2 text-center">
              <label htmlFor="fillingStationName" className="text-gray-700 text-2xl ">
                Filling Station Name
              </label>
           
              <input
                type="text"
                id="fillingStationName"
                className="mt-4 p-2  w-full border border-gray-700 rounded-md"
                value={fillingStationName}
                onChange={(e) => setFillingStationName(e.target.value)}
                required
              />
            </div>
            <div className="text-center mt-4">
              <button
                type="submit"
                className="bg-blue-500 text-white px-2 py-1 rounded-md hover:bg-blue-600"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    )}

{showConfirmation && (
      <div className="fixed inset-0 z-40 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
        <div className="fixed inset-0 bg-black bg-opacity-50"></div>
        <div className="relative w-auto max-w-3xl mx-auto my-4">
          <div className="bg-white p-4 rounded-lg shadow-md">
            <p className="mb-2">Are you sure to delete this?</p>
            <div className="flex justify-center space-x-3">
              <button
                onClick={handleDelete}
                className="bg-red-500 hover:bg-red-600 text-white px-2 py-2 rounded-full"
              >
                Yes
              </button>
              <button
                onClick={handleCancelDelete}
                className="bg-gray-400 hover:bg-gray-500 text-white px-2 py-2 rounded-full"
              >
                No
              </button>
            </div>
          </div>
        </div>
      </div>
    )}

{showLoginForm && (
  <>
    {!isMobile ? (
      <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
        <div className="bg-white w-full md:max-w-sm rounded-lg overflow-hidden shadow-xl">
        <div className="px-6 py-4">
              <div className="flex justify-end">
                <button className="text-gray-700 hover:text-gray-900" onClick={handleClose}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
              <label htmlFor="username" className="text-gray-700">Username</label>
              <input
                type="text"
                id="username"
                placeholder="Username or Mobile Number"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                className="border border-gray-400 px-3 py-2 rounded-md mb-2 w-full"
              />
              <label htmlFor="password" className="text-gray-700">Password</label>
              <input
                type="password"
                id="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="border border-gray-400 px-3 py-2 rounded-md mb-2 w-full"
              />
              <button
                onClick={handleExcel}
                className="bg-green-500 hover:bg-green-600 text-white py-2 px-4 rounded-md mr-2"
              >
                Excel
              </button>
              <button
              onClick={handlePdf}
              className="bg-green-500 hover:bg-green-600 text-white py-2 px-4 rounded-lg mr-2"
            >
              PDF
            </button>
              
              {error && <p className="text-red-500 mt-2">{error}</p>}
            </div>
        </div>
      </div>
    ) : (
      <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
        <div className="bg-white w-full max-w-xs md:max-w-lg rounded-lg overflow-hidden shadow-xl">
        <div className="px-6 py-4">
        <div className="flex justify-end">
          <button className="text-gray-700 hover:text-gray-900" onClick={handleClose}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
        <label htmlFor="username" className="text-gray-700">Username</label>
        <input
          type="text"
          id="username"
          placeholder="Username or Mobile Number"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          className="border border-gray-400 px-3 py-2 rounded-md mb-2 w-full"
        />
        <label htmlFor="password" className="text-gray-700">Password</label>
        <input
          type="password"
          id="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className="border border-gray-400 px-3 py-2 rounded-md mb-2 w-full"
        />
        <button
          onClick={handleExcel}
          className="bg-green-500 hover:bg-green-600 text-white py-2 px-4 rounded-md mr-2"
        >
          Excel
        </button>
        <button
        onClick={handlePdf}
        className="bg-green-500 hover:bg-green-600 text-white py-2 px-4 rounded-lg mr-2"
      >
        PDF
      </button>
        
        {error && <p className="text-red-500 mt-2">{error}</p>}
      </div>
        </div>
      </div>
    )}
  </>
)}
    </div>
  );
};

export default FillingStationTable;