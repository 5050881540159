import React, { useState, useEffect } from "react";
import config from "../../config";
import axios from "axios";
import { toast} from "react-toastify"; // Import react-toastify for popups
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { Data } from "@react-google-maps/api";

const UserMaster = ({ data, onClose, fetchInventoryData }) => {
  const [formData, setFormData] = useState({
    user_title: "",
    user_first_name: "",
    user_last_name: "",
    user_email: "",
    user_mobile_number: "",
    user_role: "",
    user_password: "",
    user_zone: "",
    user_wardid: [],
    filling_station_report: [],
    selected_vehicles: [],
  });
  const [emailAvailability, setEmailAvailability] = useState(null);
  const [checkedState, setCheckedState] = useState({});
  const [error, setError] = useState(null);
  const [zones, setZones] = useState([]);
  const [wards, setWards] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [fillingStations, setFillingStations] = useState([]);
  const [checkedFillingStations, setCheckedFillingStations] = useState([]);
  const [checkedVehicles, setCheckedVehicles] = useState([]);
  const user = JSON.parse(sessionStorage.getItem("user"))



  useEffect(() => {
    const token = user ? user.token : null;
    // Get the token from sessionStorage
        const configToken = {
          headers: {
            Authorization: `Bearer ${token}`, // Set the Authorization header with the token
          },
        };
  
    const fetchData = async () => {
      if (data) {
        // console.log("DATA", data);
  
        let defaultWardId = [];
        if (data.user_wardid !== null) {
          defaultWardId = Array.isArray(data.user_wardid)
            ? data.user_wardid.map((id) => parseInt(id)) // Convert to integers
            : data.user_wardid.split(",").map((id) => parseInt(id.trim())); // Convert to integers
        }
  
        // console.log("Default Ward ID:", defaultWardId);

        let defaultfillingstation = [];
        if (data.filling_station_report !== null) {
          defaultfillingstation = Array.isArray(data.filling_station_report)
            ? data.filling_station_report.map((id) => parseInt(id)) // Convert to integers
            : data.filling_station_report.split(",").map((id) => (id.trim())); // Convert to integers
        }
  
        // console.log("Default Fillinstation:", defaultfillingstation);

        let defaultContractorVehicle = [];
        if (data.selected_vehicles !== null) {
          defaultContractorVehicle = Array.isArray(data.selected_vehicles)
            ? data.selected_vehicles.map((id) => parseInt(id)) // Convert to integers
            : data.selected_vehicles.split(",").map((id) => (id.trim())); // Convert to integers
        }
  
        // console.log("Default ContractorVehicle:", defaultContractorVehicle);
  
        setFormData({
          user_title: data.user_title,
          user_first_name: data.user_first_name,
          user_last_name: data.user_last_name,
          user_email: data.user_email,
          user_mobile_number: data.user_mobile_number,
          user_role: data.user_role,
          user_password: data.user_password,
          user_zone: data.user_zone,
          user_wardid: [], // Reset user_wardid initially
          filling_station_report:  [],
          selected_vehicles: Array.isArray(data.selected_vehicles)
            ? data.selected_vehicles
            : [],
        });
  
        if (data.user_zone !== null) {
          try {
            const response = await axios.get(
              `${config.apiUrl}/zonewards/fetchAEBasedWards?zone=${data.user_zone}`,configToken
            );
            // console.log("API Response:", response);
            // console.log("API Response Data:", response.data);
  
            if (response && response.data) {
              const fetchedWards = response.data;
              // console.log("Fetched Wards:", fetchedWards);
  
              const filteredDefaultWardId = defaultWardId.filter((user_wardid) =>
                fetchedWards.some((ward) => ward.WardID === user_wardid)
              );
  
              // console.log("Filtered Default Ward ID:", filteredDefaultWardId);
  
              // Ensure unique values in filteredDefaultWardId
              const uniqueWardIds = [...new Set(filteredDefaultWardId)];
              // console.log("Unique Ward IDs:", uniqueWardIds);
  
              setFormData((prevFormData) => ({
                ...prevFormData,
                user_wardid: uniqueWardIds,
              }));
              setWards(fetchedWards)
  
              uniqueWardIds.forEach((user_wardid) => {
                const ward = fetchedWards.find((w) => w.WardID === user_wardid);
                // console.log("Found ward:", ward);
                if (ward) {
                  handleCheckboxChange(
                    { target: { checked: true } },
                    ward
                  );
                }
              });
            } else {
              console.error(
                "Error fetching ward zone wise: Response or response.data is undefined"
              );
            }
          } catch (error) {
            console.error("Error fetching ward zone wise:", error);
            // Handle error gracefully, such as displaying an error message to the user
          }
        }

        if (data.filling_station_report !== null) {
          try {
            const response = await axios.get(
              `${config.apiUrl}/zonewards/fillingstation`,configToken
            );
            // console.log("API Response:", response);
            // console.log("API Response Data:", response.data.results);
  
            if (response && response.data.results) {
              const fetchedFillingStation = response.data.results;
              // console.log("Fetched Filling:", fetchedFillingStation);
  
              const filteredDefaultFilling = defaultfillingstation.filter((filling_station_report) =>
                fetchedFillingStation.some((station) => station.FillingStationName === filling_station_report)
              );
  
              // console.log("Filling StationName Default Ward ID:", filteredDefaultFilling);
  
              // Ensure unique values in filteredDefaultWardId
              const uniqueFillingStations = [...new Set(filteredDefaultFilling)];
              // console.log("Unique FILLING IDs:", uniqueFillingStations);
  
              setFormData((prevFormData) => ({
                ...prevFormData,
                filling_station_report: uniqueFillingStations,
              }));
              setFillingStations(fetchedFillingStation)
  
              uniqueFillingStations.forEach((filling_station_report) => {
                const Station = fetchedFillingStation.find((w) => w.FillingStationName === filling_station_report);
                // console.log("Found ward:", Station);
                if (Station) {
                  handleCheckboxChange3(
                    { target: { checked: true } },
                    Station
                  );
                }
              });
            } else {
              console.error(
                "Error fetching ward zone wise: Response or response.data is undefined"
              );
            }
          } catch (error) {
            console.error("Error fetching ward zone wise:", error);
            // Handle error gracefully, such as displaying an error message to the user
          }
        }
        if (data.selected_vehicles !== null) {
          try {
            const response = await axios.get(
              `${config.apiUrl}/zonewards/getUservehicles`,configToken
            );
            // console.log("API Response:", response);
            // console.log("API Response Data:", response.data.vehicles);
  
            if (response && response.data.vehicles) {
              const fetchedVehicles = response.data.vehicles;
              // console.log("Fetched Filling:", fetchedVehicles);
  
              const filteredDefaultVehicle = defaultContractorVehicle.filter((selected_vehicles ) =>
                fetchedVehicles.some((vehicle) => vehicle.vehicleNo === selected_vehicles )
              );
  
              // console.log("Default vehicle:", filteredDefaultVehicle);
  
              // Ensure unique values in filteredDefaultWardId
              const uniquevehicles = [...new Set(filteredDefaultVehicle)];
              // console.log("Unique FILLING IDs:", uniquevehicles);
  
              setFormData((prevFormData) => ({
                ...prevFormData,
                selected_vehicles : uniquevehicles,
              }));
              setFillingStations(fetchedVehicles)
  
              uniquevehicles.forEach((selected_vehicles ) => {
                const Station = fetchedVehicles.find((vehicle) => vehicle.vehicleNo === selected_vehicles );
                // console.log("Found ward:", Station);
                if (Station) {
                  handleCheckboxChange2(
                    { target: { checked: true } },
                    Station
                  );
                }
              });
            } else {
              console.error(
                "Error fetching ward zone wise: Response or response.data is undefined"
              );
            }
          } catch (error) {
            console.error("Error fetching ward zone wise:", error);
            // Handle error gracefully, such as displaying an error message to the user
          }
        }
       
      }
    };
    fetchData(); // Call the fetchData function within useEffect
  }, [data]);
    

  useEffect(() => {
    
  
    if (formData.user_role === "Contractor") {
      fetchVehicles(); 
    }
    else if (formData.user_role === "Timekeeper") {
      Fetchfillingstation(); 
    }
  }, [formData.user_role]);

  // Function to fetch vehicles
  const token = user ? user.token : null;
    // Get the token from sessionStorage
        const configToken = {
          headers: {
            Authorization: `Bearer ${token}`, // Set the Authorization header with the token
          },
        };
  const fetchVehicles = () => {
    axios
      .get(`${config.apiUrl}/zonewards/getUservehicles`,configToken)
      .then((response) => {
        setVehicles(response.data.vehicles);
      })
      .catch((error) => {
        console.error("Error fetching vehicles:", error);
      });
  };

  useEffect(() => {
    const updatedCheckedState = {};
    wards.forEach((ward) => {
      updatedCheckedState[ward["WardID"]] = formData.user_wardid.includes(
        ward["WardID"]
      );
    });
    setCheckedState(updatedCheckedState);
  }, [formData.user_wardid, wards]);

  const navigate = useNavigate();

  useEffect(() => {
    const auth = JSON.parse(sessionStorage.getItem('authentication'));
    
  
    if (!auth ) {
      navigate('/')
      
    } else {
      fetchLocalities();
    // Fetchfillingstation();
    }
  }, []);

  const fetchLocalities = () => {
    const token = user ? user.token : null;
    // Get the token from sessionStorage
        const configToken = {
          headers: {
            Authorization: `Bearer ${token}`, // Set the Authorization header with the token
          },
        };
    axios
      .get(`${config.apiUrl}/zonewards/zones`,configToken)
      .then((response) => {
        setZones(response.data.Zones);
      })
      .catch((error) => {
        console.error("Error fetching localities:", error);
      });
  };

  const Fetchfillingstation = () => {
    const token = user ? user.token : null;
    // Get the token from sessionStorage
        const configToken = {
          headers: {
            Authorization: `Bearer ${token}`, // Set the Authorization header with the token
          },
        };
    axios
      .get(`${config.apiUrl}/zonewards/fillingstation`,configToken)
      .then((response) => {
        setFillingStations(response.data.results);
        // console.log("Filling station:", response.data.results); // Log the value here
      })
      .catch((error) => {
        console.error("Error fetching filling station:", error);
      });
  };

  const fetchWardZoneWise = (zone) => {
    const token = user ? user.token : null;
    // console.log(token);
    // Get the token from sessionStorage
    const configToken = {
      headers: {
        Authorization: `Bearer ${token}`, // Set the Authorization header with the token
      },
    };
  
    return new Promise((resolve, reject) => {
      setWards([]);
      axios
        .get(`${config.apiUrl}/zonewards/fetchAEBasedWards?zone=${zone}`, configToken)
        .then((response) => {
          setWards(response.data);
          resolve(response.data); // Resolve the promise with the response data
        })
        .catch((error) => {
          if (error.response && error.response.status === 404) {
            // Handle 404 errors by displaying a toast message
            toast.error('Wards not available');
          } else {
            // For other errors, you can handle them as needed
            console.error('Error:', error);
          }
          reject(error); // Reject the promise with the error
        });
    });
  };
  

  const handleWardChange = (event) => {
    const { value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      user_zone: value,
      user_wardid: [],
    }));
    fetchWardZoneWise(value); // Fetch ward and zone details for the selected zone
  };

  const handleCheckboxChange = (event, ward) => {
    const { checked } = event.target;
    const { WardID } = ward;
  
    setFormData((prevState) => {
      let updatedWardids = [...prevState.user_wardid];
  

      if (checked && !updatedWardids.includes(WardID)) {
        updatedWardids.push(WardID);
    } else if (!checked) {
      updatedWardids = updatedWardids.filter((id) => id !== WardID);
    }

      return {
        ...prevState,
        user_wardid: updatedWardids,
      };
    });
    setCheckedState((prevState) => ({
      ...prevState,
      [WardID]: checked,
  }));
  };
  
  const handleCheckboxChange2 = (event, vehicle) => {
    const { checked } = event.target;
    const { vehicleNo } = vehicle;
  
    setFormData((prevState) => {
      let updatedSelectedVehicles = [...prevState.selected_vehicles];
  
      if (checked && !updatedSelectedVehicles.includes(vehicleNo)) {
        updatedSelectedVehicles.push(vehicleNo);
      } else if  (!checked){
        updatedSelectedVehicles = updatedSelectedVehicles.filter(
          (selectedVehicle) => selectedVehicle !== vehicleNo
        );
      }
  
      // console.log("updatedSelectedVehicles:", updatedSelectedVehicles);
  
      return {
        ...prevState,
        selected_vehicles: updatedSelectedVehicles,
      };
    });
    setCheckedVehicles((prevState) => ({
      ...prevState,
      [vehicleNo]: checked,
  }));
  };
  

  const handleChange = async (e) => {

    const { name, value } = e.target;
    const onlyLettersRegex = /^[a-zA-Z\s]*$/;

    if (name === "user_first_name" || name === "user_last_name") {
      if (!onlyLettersRegex.test(value)) {
        return;
      }
    }

    if (name === "user_role") {
      // Reset fields based on user_role
      setFormData((prevState) => {
        let updatedData = { ...prevState };
        if (value === "Admin") {
          updatedData = {
            ...updatedData,
            // user_password: "",
            user_zone: "",
            user_wardid: [],
            filling_station_report: [],
            selected_vehicles: [],
          };
          setWards([])
        } else if (value === "AE") {
          updatedData = {
            ...updatedData,
            filling_station_report: [],
            selected_vehicles: [],
          };
        } else if (value === "Timekeeper") {
          // Reset zone and wardids when changing to Timekeeper
          updatedData = {
            ...updatedData,
            user_zone: "",
            user_wardid: [],
            selected_vehicles: [],
          };
          setWards([])
        } else if (value === "Contractor") {
          // Reset zone and wardids when changing to Timekeeper
          updatedData = {
            ...updatedData,
            // user_password: "",
            user_zone: "",
            user_wardid: [],
            filling_station_report: [],
          };
          setWards([])
        }
        return updatedData;
      });
    }

    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const HandleresetFormData = () => {
    setFormData({
      user_title: "",
      user_first_name: "",
      user_last_name: "",
      user_email: "",
      user_mobile_number: "",
      user_role: "",
      user_password: "",
      user_zone: "",
      user_wardid: [],
      filling_station_report: [],
      selected_vehicles: [],
    });
    setFillingStations([])
    setWards([])
  };

  const handleCheckboxChange3 = (event, station) => {
    const { checked } = event.target;
    const { FillingStationName } = station;

    setFormData((prevState) => {
      let updatedFillingStations = [...prevState.filling_station_report];

      if (checked && !updatedFillingStations.includes(FillingStationName)) {
        // If checkbox is checked, add the vehicleNo to the selected vehicles array
        updatedFillingStations.push(FillingStationName);
      } else if (!checked){
        // If checkbox is unchecked, remove the vehicleNo from the selected vehicles array
        updatedFillingStations = updatedFillingStations.filter(
          (station) => station !== FillingStationName
        );
      }

      // console.log("updatedFillingStations:", updatedFillingStations);

      return {
        ...prevState,
        filling_station_report: updatedFillingStations,
      };
    });
    setCheckedFillingStations((prevState) => ({
      ...prevState,
      [FillingStationName]: checked,
    }));
  };

  const handleSubmit = async (e) => {
    const token = user ? user.token : null;
    // Get the token from sessionStorage
        const configToken = {
          headers: {
            Authorization: `Bearer ${token}`, // Set the Authorization header with the token
          },
        };
    e.preventDefault();
   
    // Validate form fields
    if (!formData.user_email.includes("@gmail.com")) {
      setError("Please provide a valid Gmail address.");
      return;
    }
  
    if (formData.user_mobile_number.length !== 10) {
      setError("Mobile number must be 10 digits long.");
      return;
    }
  
    if (
      formData.user_role === "Contractor" &&
      formData.selected_vehicles.length === 0
    ) {
      toast.error("Please select at least one vehicle.");
      return;
    } else if (
      formData.user_role === "AE" &&
      formData.user_wardid.length === 0
    ) {
      toast.error("Please select at least one Ward ID.");
      return;
    } else if (
      formData.user_role === "Timekeeper" &&
      formData.filling_station_report.length === 0
    ) {
      toast.error("Please select at least one filling_station.");
      return;
    }
  
    try {
      // Prepare form data to be sent to the server
      const requestData = {
        user_title: formData.user_title,
        user_first_name: formData.user_first_name,
        user_last_name: formData.user_last_name,
        user_email: formData.user_email.toLowerCase().trim(),
        user_mobile_number: formData.user_mobile_number,
        user_role: formData.user_role,
        user_password: formData.user_password,
        user_zone: formData.user_zone,
        user_wardid: formData.user_wardid.length > 0 ? formData.user_wardid : null,
        filling_station_report: formData.filling_station_report.length > 0 ? formData.filling_station_report : null,
        selected_vehicles: formData.selected_vehicles.length > 0 ? formData.selected_vehicles : null,
      };
      if (data) {
        // If data exists, it means we're updating an existing user
        const response = await axios.put(`${config.apiUrl}/user/updateuser/${data.user_id}`, requestData,configToken);
        if (response.status === 200) {
          // Handle success
          toast.success("User updated successfully!");
                await new Promise((resolve) => setTimeout(resolve, 1000));

          onClose(true); // Close the modal after successful update
          fetchInventoryData()
        } else {
          setError("Error updating user data. Please try again.");
          onClose(false);
        }
      } else {
        const emailAvailable = await handleCheckEmail();
        // console.log(emailAvailable);
        if (!emailAvailable) {
          setError("Email is already in use.");
          return;
        }else{
      
       
        // If data doesn't exist, it means we're registering a new user
        const response = await axios.post(`${config.apiUrl}/user/register`, requestData,configToken);
        if (response.status === 200) {
          // Handle success
          toast.success("User registered successfully!");
          
          HandleresetFormData();
        } else {
          setError("Error registering user. Please try again.");
        }
      }
      }
    } catch (error) {
      console.error("Error registering user:", error);
      setError("Internal server error. Please try again later.");
    }
  };

  const handleCheckEmail = async () => {
    const token = user ? user.token : null;
    // Get the token from sessionStorage
        const configToken = {
          headers: {
            Authorization: `Bearer ${token}`, // Set the Authorization header with the token
          },
        };
  
    try {
      const response = await fetch(
        `${config.apiUrl}/user/check-email?email=${formData.user_email}`,configToken
      );
      const data = await response.json();
   
      setEmailAvailability(data.message === "Email available");
      // console.log(data);
      return data.message === "Email available"; 

    } catch (error) {
      console.error("Error checking email availability:", error);
      setEmailAvailability(null);
      return false; 
    }
  };

  return (
    <div
      style={{ fontFamily: "'Vazir', sans-serif" }}
      className="h-[90vh] "
    >
      

      <h2 className="text-2xl font-semibold mb-1 text-center text-blue-600 sticky top-0">
        Registration Form
      </h2>
      

      <form className="flex flex-wrap pl-14 pr-10 sm:text-sm text-xs h-[80vh] overflow-auto" onSubmit={handleSubmit} >
         <div className="w-full mb-3  pr-4">
          <div className=" ">
          <label
            htmlFor="user_email"
            className="block font-semibold mb-1 text-gray-700"
          >
          User Name
          </label>
          </div>
         
          <div className=" flex flex-wrap pt-5 pl-4 border-2 border-gray-200">
          <div className="relative w-full md:w-1/3 sm:w-1/3 mb-6 sm:mb-1 pr-4">
  <label
    htmlFor="user_title"
    className="block font-semibold mb-1 text-gray-700 absolute left-2 -top-5 transition-all duration-300 origin-top bg-white pt-2 w-12"
  >
    Title
  </label>
  <select
    id="user_title"
    name="user_title"
    value={formData.user_title}
    onChange={handleChange}
    className="w-full px-2 py-5 pb-0  border border-gray-300 rounded-md "
    required
  >
    <option value="">Select Title</option>
    <option value="Mr">Mr</option>
    <option value="Mrs">Mrs</option>
  </select>
</div>

        <div className="relative w-full md:w-1/3 sm:w-1/3 mb-6 sm:mb-1  pr-4">
          <label
            htmlFor="user_first_name"
            className="block font-semibold mb-1 text-gray-700 absolute left-2 -top-5 transition-all duration-300 origin-top bg-white p-1"
          >
            First Name
          </label>
          <input
            type="text"
            id="user_first_name"
            name="user_first_name"
            value={formData.user_first_name.trim()}
            onChange={handleChange}
            className="w-full px-4 py-4 pb-0 border border-gray-300 rounded-md "
            required
          />
        </div>
        <div className="relative w-full md:w-1/3 sm:w-1/3 mb-6 sm:mb-1 pr-4">
          <label
            htmlFor="user_last_name"
            className="block font-semibold mb-1 text-gray-700 absolute left-2 -top-5 transition-all duration-300 origin-top bg-white p-1"
          >
            Last Name
          </label>
          <input
            type="text"
            id="user_last_name"
            name="user_last_name"
            value={formData.user_last_name.trim()}
            onChange={handleChange}
            className="w-full px-4 py-4 pb-0  border border-gray-300 rounded-md "
            required
          />
        </div>
          </div>
          </div>
    

        <div className="w-full md:w-1/2 mb-1 pr-4">
          <label
            htmlFor="user_email"
            className="block font-semibold mb-1 text-gray-700"
          >
            Email
          </label>
          <input
            type="email"
            id="user_email"
            name="user_email"
            value={formData.user_email.toLowerCase().trim()}
            onChange={handleChange}
            onBlur={handleCheckEmail}
            className="w-full px-4 py-2 border border-gray-300 rounded-md "
            required
          />

          {formData.user_email &&
            !formData.user_email.includes("@gmail.com") && (
              <p className="text-red-500">
                Please provide a valid Gmail address.
              </p>
            )}
          {/* {emailAvailability === false && (
            <p className="text-red-500">Email already exists</p>
          )} */}
                {error && <div className="text-red-500 mb-1">{error}</div>}

      
        </div>

        <div className="w-full md:w-1/2 mb-1 pr-4">
          <label
            htmlFor="user_mobile_number"
            className="block font-semibold mb-1 text-gray-700"
          >
            Mobile Number
          </label>
          <input
            type="text"
            id="user_mobile_number"
            name="user_mobile_number"
            value={formData.user_mobile_number}
            onInput={(e) => {
              e.target.value = e.target.value.replace(/\D/, "").slice(0, 10);
            }}
            onChange={handleChange}
            className="w-full px-4 py-2 border border-gray-300 rounded-md "
            required
          />
          {formData.user_mobile_number &&
            formData.user_mobile_number.length !== 10 && (
              <p className="text-red-500">
                Mobile number must be 10 digits long.
              </p>
            )}
        </div>
        <div className="w-full md:w-1/2 mb-1 pr-4">
          <label
            htmlFor="user_role"
            className="block font-semibold mb-1 text-gray-700"
          >
            Role
          </label>
          <select
            id="user_role"
            name="user_role"
            value={formData.user_role}
            onChange={handleChange}
            className="w-full px-4 py-2 border border-gray-300 rounded-md "
            required
          >
            <option value="">Select Role</option>
            <option value="Admin">Admin</option>
            <option
              value="AE"
              onClick={() => {
                setFormData({
                  ...formData,
                  user_zone: "",
                  user_wardid: [],
                  filling_station_report: [],
                });
                setWards([]); // Clear the wards when "AE" is selected
              }}
            >
              AE
            </option>
            <option
              value="Timekeeper"
              onClick={() => {
                setFormData({
                  ...formData,
                  user_zone: "",
                  user_wardid: [],
                  // filling_station_report: [],
                });
                setWards([]); // Clear the wards when "Timekeeper" is selected
              }}
            >
              Timekeeper
            </option>
            <option>Contractor</option>
          </select>
        </div>
        
{!data && (
  <div className="w-full md:w-1/2 mb-1 pr-4">
    <label
      htmlFor="user_password"
      className="block font-semibold mb-1 text-gray-700"
    >
      Password
    </label>
    <input
      type="password"
      id="user_password"
      name="user_password"
      value={formData.user_password}
      onChange={handleChange}
      className="w-full px-4 py-2 border border-gray-300 rounded-md "
      required
    />
  </div>
)}

        {formData.user_role === "AE" && (
          <>
            <div className="w-full md:w-1/2 mb-1 pr-4">
              <label
                htmlFor="user_zone"
                className="block font-semibold mb-1 text-gray-700"
              >
                Zone
              </label>
              <select
                id="user_zone"
                name="user_zone"
                value={formData.user_zone}
                onChange={handleWardChange}
                className="w-full px-4 py-2 border border-gray-300 rounded-md "
                required
              >
                <option value="">Select Zone</option>
                {zones.map((zone, index) => (
                  <option key={index} value={zone}>
                    {zone}
                  </option>
                ))}
              </select>
            </div>


            <div className="w-full md:w-1/2 mb-1">
  <label className="block font-semibold mb-1 text-gray-700">
    Wards
  </label>
  <div
    className=" overflow-y-auto border border-gray-300 h-20 mr-4 rounded"
    style={{ maxHeight: "calc(100vh - 520px)" }}
  >
    <div className="flex flex-wrap">
      {wards.map((ward, index) => (
        <label
          key={index}
          className="inline-flex items-center mr-4"
        >
          <input
            type="checkbox"
            value={ward["WardID"]}
            onChange={(event) => handleCheckboxChange(event, ward)}
            checked ={checkedState[ward["WardID"]]}

            className="form-checkbox h-3 w-3 text-indigo-600 rounded focus:ring-indigo-500"
          />
          <span className="ml-2">{ward["WardID"]} - {ward["WardName"]}</span>
        </label>
      ))}
    </div>
  </div>
</div>


<div className="w-full md:w-1/2 mb-1 pr-4">
  <label className="block font-semibold mb-1 text-gray-700">
    Selected Wards
  </label>
  <div
    className=" overflow-y-auto border border-gray-300 rounded-md"
    style={{ maxHeight: "calc(100vh - 560px)" }}
  >
    <div className="w-full px-4 py-2  ">
      {formData.user_wardid.map((ward, index) => (
        <span
          key={index}
          className="block mb-1 bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2"
        >
          {ward}
        </span>
      ))}
    </div>
  </div>
</div>

          </>
        )}

{formData.user_role === "Timekeeper" && (
                 <>
                 <div className="w-full  md:w-1/2  mb-1 pr-4">
                   <label
                     htmlFor="filling_station_report"
                     className="block font-semibold mb-1 text-gray-700"
                   >
                     Filling Station Report
                   </label>
                   <div
                               className="mt-4 overflow-y-auto"
                               style={{ maxHeight: "calc(100vh - 550px)" }}
                             >
                          <div className="grid grid-cols-1 pl-6 gap-2  overflow-y-auto">     
                   {fillingStations.map((station) => (
                 <label key={station.id} className="inline-flex items-center">
                   <input
                     type="checkbox"
                     value={station.FillingStationName}
                     onChange={(event) => handleCheckboxChange3(event, station)}
                     checked={checkedFillingStations[station.FillingStationName]}
                     className="form-checkbox h-4 w-4 text-indigo-600 rounded focus:ring-indigo-500"
                    //  required
                   />
                   <span className="ml-2">{station.FillingStationName}</span>
                 </label>
               ))}
                   </div>
                   </div> 
                 </div>
                   <div className="w-full md:w-1/2 mb-1 ">
                   <label className="block font-semibold mb-1 text-gray-700">
                     Selected FillingStation
                   </label>
                   <div
                     className="w-full px-4 py-2 border border-gray-300 rounded-md  overflow-y-auto"
                     style={{ maxHeight: "calc(100vh - 520px)" }}
                   >
                     {formData.filling_station_report.map((station, index) => {
                       return (
                         <span
                           key={index}
                           className="block bg-gray-200 rounded-full px-3 py-1 text-xs md:text-sm font-semibold text-gray-700 m-1"
                         >
                           {station}
                         </span>
                       );
                     })}
                   </div>
                 </div>
                 </>
        )}
        {formData.user_role === "Contractor" && (
          <>
            <div className="w-full md:w-1/2 mb-1 pr-4">
              <label className="block font-semibold mb-1 text-gray-700 text-sm">
                Select Vehicle(s)
              </label>
              <div
                className="mt-4 overflow-y-auto"
                style={{ maxHeight: "calc(100vh - 550px)" }}
              >
                <div className="grid grid-cols-2 gap-4">
                  {vehicles.map((vehicle) => (
                    <label
                      key={vehicle.id}
                      className="inline-flex items-center"
                    >
                      <input
                        type="checkbox"
                        value={vehicle.vehicleNo}
                        onChange={(event) =>
                          handleCheckboxChange2(event, vehicle)
                        }
                        checked ={checkedVehicles[vehicle.vehicleNo]}
                        className="form-checkbox h-4 w-4 text-indigo-600 rounded focus:ring-indigo-500"
                      />
                      <span className="ml-2">{vehicle.vehicleNo}</span>
                    </label>
                  ))}
                </div>
              </div>
            </div>

            <div className="w-full md:w-1/2 mb-1 text-xs md:text-sm ">
              <label className="block font-semibold mb-1 text-gray-700">
                Selected vehicle
              </label>
              <div
                className="w-full px-4 py-2 border border-gray-300 rounded-md  overflow-y-auto"
                style={{ maxHeight: "calc(100vh - 520px)" }}
              >
                {formData.selected_vehicles.map((vehicle, index) => {
                  return (
                    <span
                      key={index}
                      className="block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 m-1"
                    >
                      {vehicle}
                    </span>
                  );
                })}
              </div>
            </div>
          </>
        )}

          {data ? (
                  <div className="w-1/2 text-center mt-10">
                    <button
                      type="submit"
                      className="mt-6 bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
                    >
                      Update
                    </button>
                  </div>
                ) : (
                  <div className="w-full text-center mt-4">
                  <button
                    type="submit"
                    className=" bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
                  >
                    Register
                  </button>
                  </div>
                )}
      </form>
    </div>
  );
};

export default UserMaster;