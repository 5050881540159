import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { FaSearch, FaTimes } from 'react-icons/fa'; // Import search icon from react-icons
import DeliveredData from "../ViewTanker/DeliveredData"
import config from "../../config";
import * as XLSX from 'xlsx';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
   faTimes,faPlus,faFileExcel
} from "@fortawesome/free-solid-svg-icons";
import moment from 'moment';

const VehicleDeliveryInfo = () => {
  const [originalData, setOriginalData] = useState([]);
  const [deliveryData, setDeliveryData] = useState([]);
  const [selectedContractor, setSelectedContractor] = useState('');
  const currentMonthStart = moment().startOf('month').format('YYYY-MM-DD');
  const currentMonthEnd = moment().endOf('month').format('YYYY-MM-DD');

  // Initialize the state variables with these dates
  const [fromDate, setFromDate] = useState(currentMonthStart);
  const [toDate, setToDate] = useState(currentMonthEnd);
  const [fromDateValid, setFromDateValid] = useState(true);
  const [toDateValid, setToDateValid] = useState(true);
  const [vehicleNo, setVehicleNo] = useState(null); // State to hold maxDeliveredTime
  const [showVehicleDeliveryInfo, setShowVehicleDeliveryInfo] = useState(true); // State to manage visibility of VehicleDeliveryInfo
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1020);
  const [searchClicked, setSearchClicked] = useState(false); // State to track if the search button has been clicked
  const [error, setError] = useState('');
  const [showLoginForm, setShowLoginForm] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [contractors, setContractors] = useState([]);
  const [user,setUser] = useState([]);
  const [usertoken,setusertoken] = useState('')

  useEffect(() => {
    fetchData();

    const handleResize = () => {
      setIsMobile(window.innerWidth < 1020);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); // Fetch data when component mounts

  useEffect(() => {
    const auth = JSON.parse(sessionStorage.getItem('authentication'));
    const userData = JSON.parse(sessionStorage.getItem("user"));
    if (userData) {
      setUser(userData.user);
      setusertoken(userData.token)
      // console.log('userdatsa',userData.user);
       
    }
  
  
  }, []);

  useEffect(() => {
    fetchData();

  }, [user]); // Fetch data when component mounts

  const fetchData = async () => {
    if(user && user.user_role && fromDate && toDate) {
      const token = usertoken ? usertoken : null;
      // Get the token from sessionStorage
          const configToken = {
            headers: {
              Authorization: `Bearer ${token}`, // Set the Authorization header with the token
            },
          };
    try {
      const response = await axios.get(`${config.apiUrl}/report/vehicle-delivery-info`,{
        params: {
          user_role:user.user_role,
          user_id: user.user_id,
          startTime:fromDate,
          endTime:toDate,
        
        },
        ...configToken
      });
      setDeliveryData(response.data);
      setOriginalData(response.data);
      // console.log('vehicle Delivery',response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }
  };

  const handleSearch = async () => {
    // Check if fromDate and toDate are filled
    const isFromDateValid = !!fromDate;
    const isToDateValid = !!toDate;
    setFromDateValid(isFromDateValid);
    setToDateValid(isToDateValid);

    if (isFromDateValid && isToDateValid) {
      const token = usertoken ? usertoken : null;
      // Get the token from sessionStorage
          const configToken = {
            headers: {
              Authorization: `Bearer ${token}`, // Set the Authorization header with the token
            },
          };
      try {
        // console.log('User ID:', selectedContractor);
        const params = {
          user_id: user.user_role === 'Contractor' ? user.user_id : selectedContractor || null,
          startTime: fromDate,
          endTime: toDate,
        };

        const response = await axios.get(`${config.apiUrl}/report/contractor-vehicle-info-with`, {
          params,
          ...configToken,  // Spread the configToken to include headers
        });
        setDeliveryData(response.data);
        setSearchClicked(true);
      } catch (error) {
        console.error('Error searching data:', error);
      }
    }
  };
  
// Event handler to handle click on number of trips
const handleClickTrips = (vehicleNo) => {
  if (user.user_role !== "Contractor"){
  setVehicleNo(vehicleNo);
  // console.log(vehicleNo);
  setShowVehicleDeliveryInfo(false); // Hide VehicleDeliveryInfo component
  }
};



// Event handler to handle cancel button click
const handleCancel = () => {
 
  setShowVehicleDeliveryInfo(true); // Show VehicleDeliveryInfo component
};
const handlecCancel = () => {
  setDeliveryData(originalData); // Assuming originalData contains the original data
  setSearchClicked(false); // Reset the searchClicked state
  setFromDate(''); // Clear fromDate
  setToDate(''); // Clear toDate
  setSelectedContractor('');
};

useEffect(() => {
  // Fetch contractors from backend when the component mounts
  async function fetchContractors() {
    if(user && user.user_role){
    
    const token = usertoken ? usertoken : null;
    // Get the token from sessionStorage
        const configToken = {
          headers: {
            Authorization: `Bearer ${token}`, // Set the Authorization header with the token
          },
        };
    try {
      const response = await axios.get(`${config.apiUrl}/report/contractors`,configToken); // Adjust the endpoint as needed
      setContractors(response.data);
    } catch (error) {
      console.error('Error fetching contractors:', error);
    }
  }
  }
  fetchContractors();
}, [user]);



  return (
    <div className="container mx-auto p-2 md:p-4">
 {!isMobile ? (
  showVehicleDeliveryInfo && (
    <div>
      <h1 className="text-xl font-bold mb-4">Vehicle Delivery Information</h1>
      <div className="flex space-x-4 mb-4">
      { user.user_role !== 'Contractor' && <>    <label htmlFor="contractor" className="flex items-center">Contractor Name:</label>
      <select
      id="contractor"
      value={selectedContractor}
      onChange={(e) => setSelectedContractor(e.target.value)}
      className="px-4 py-2 bg-gray-200 rounded-lg focus:outline-none focus:bg-white focus:border-blue-500"
    >
      <option value="">Select Contractor</option>
      {contractors.map(contractor => (
        <option key={contractor.user_id} value={contractor.user_id}>
          {contractor.full_name}
        </option>
      ))}
    </select>
    </>
}
<label htmlFor="fromDate" className="flex items-center">From Date:</label>
      <input
        id="fromDate"
        type="date"
        value={fromDate}
        onChange={(e) => {
          setFromDate(e.target.value);
          if (e.target.value) {
            setFromDateValid(true);
          }
        }}
        className={`px-4 py-2 border rounded-lg focus:outline-none ${fromDateValid ? 'bg-gray-200 focus:bg-white focus:border-blue-500' : ' border-red-500'}`}
      />
      <label htmlFor="toDate" className="flex items-center">To Date:</label>
      <input
        id="toDate"
        type="date"
        value={toDate}
        onChange={(e) => {
          setToDate(e.target.value);
          if (e.target.value) {
            setToDateValid(true);
          }
        }}
        className={`px-4 py-2 rounded-lg border focus:outline-none ${toDateValid ? 'bg-gray-200 focus:bg-white focus:border-blue-500' : ' border-red-500'}`}
      />
      <button
        onClick={handleSearch}
        className="flex items-center px-4 py-2 bg-blue-500 text-white rounded-lg focus:outline-none hover:bg-blue-600"
      >
        <FaSearch className="mr-2" /> Search
      </button>

      </div>
      <div className="overflow-y-auto max-h-96">
      {searchClicked && (
<div className="flex justify-end">
    <FaTimes
      className="cursor-pointer text-red-500"
      onClick={handlecCancel}
    />
     {!showLoginForm && (
          <button className="ml-[10px] mt-1 bg-green-500 hover:bg-green-600 text-white py-1 px-2 rounded-md"  onClick={() => setShowLoginForm(true)}>
        <FontAwesomeIcon icon={faFileExcel} className="mr-2" />
    </button>
        )}
  </div>
   )}
        <table className="table-auto min-w-full divide-y divide-gray-200">
          <thead className="text-white sticky top-0" style={{ backgroundColor: 'rgb(33,64,154)' }}>
            <tr>
              <th className="px-6 py-3 text-center text-xs font-medium  uppercase tracking-wider">S.No</th>
              <th className="px-6 py-3 text-center text-xs font-medium  uppercase tracking-wider">Vehicle No</th>
              <th className="px-6 py-3 text-center text-xs font-medium  uppercase tracking-wider">Capacity</th>
              <th className="px-6 py-3 text-center text-xs font-medium  uppercase tracking-wider">No. of Trips</th>
              <th className="px-6 py-3 text-center text-xs font-medium  uppercase tracking-wider">Quantity of Water (Lts)</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200 text-center">
            {deliveryData.map((item, index) => (
              <tr key={index}>
                <td className="px-6 py-4 whitespace-nowrap">{index + 1}</td>
                <td className="px-6 py-4 whitespace-nowrap">{item.vehicleNo}</td>
                <td className="px-6 py-4 whitespace-nowrap">{item.capacity}</td>
                <td className="px-6 py-4 whitespace-nowrap" onClick={() => handleClickTrips(item.vehicleNo)}> 
                <a
        href="#"
        className="text-blue-500 hover:underline"
        onClick={() => handleClickTrips(item.vehicleNo)}
      >
        {item.trips}
      </a></td>
                <td className="px-6 py-4 whitespace-nowrap">{item.totalRequestedQty}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
) : (
  showVehicleDeliveryInfo && (
    <div>
      <h1 className="text-xl font-bold text-blue-900 mb-4">Vehicle Delivery Information</h1>
      <div className="flex flex-col md:flex-row mb-2 text-sm">
  <label htmlFor="contractor" className="flex items-center">Contractor Name:</label>
  <select
    id="contractor"
    value={selectedContractor}
    onChange={(e) => setSelectedContractor(e.target.value)}
    className="px-1 py-2  bg-gray-200 rounded-lg focus:outline-none focus:bg-white focus:border-blue-500"
  >
    <option value="">Select Contractor</option>
    {contractors.map(contractor => (
      <option key={contractor.user_id} value={contractor.user_id}>
        {contractor.full_name}
      </option>
    ))}
  </select>

  <div className="relative flex flex-row items-center max-w-xs mt-4">
  <label htmlFor="fromDate" className="flex ml-3">From:</label>
  <input
    id="fromDate"
    type="date"
    value={fromDate}
    onChange={(e) => setFromDate(e.target.value)}
    className="px-1 py-1 bg-gray-200 rounded-md mr-2 md:ml-0 w-20 focus:bg-white focus:border-blue-500"
  />
  <label htmlFor="toDate" className="flex items-center">To:</label>
  <input
    id="toDate"
    type="date"
    value={toDate}
    onChange={(e) => setToDate(e.target.value)}
    className="px-1 py-1 bg-gray-200 rounded-md mr-2 md:ml-0 w-20 focus:bg-white focus:border-blue-500"
  />
  <button
    onClick={handleSearch}
    className="flex items-center justify-center w-8 h-8 ml-3 bg-blue-500 text-white rounded-full focus:outline-none hover:bg-blue-600"
  >
    <FaSearch />
  </button>
</div>







  
 
</div>



<div className="max-h-80 max-w-sm overflow-y-auto">
{searchClicked && (
<div className="flex justify-end">
    <FaTimes
      className="cursor-pointer text-red-500"
      onClick={handlecCancel}
    />
  </div>
   )}
  <table className="w-full text-xs divide-gray-200">
    <thead className="text-white" style={{ backgroundColor: 'rgb(33,64,154)' }}>
      <tr>
        <th className="px-2 py-2  text-center font-semibold uppercase">S.No</th>
        <th className="px-2 py-2  text-center font-semibold  uppercase">Vehicle No</th>
        <th className="px-1 py-2   text-center font-semibold  uppercase">Capacity</th>
        <th className="px-1 py-2  text-center font-semibold  uppercase">No. of Trips</th>
        <th className="px-1 py-1  text-center font-semibold uppercase">Quantity of Water (Lts)</th>
      </tr>
    </thead>
    <tbody className="bg-white divide-y divide-gray-200">
      {deliveryData.map((item, index) => (
        <tr key={index}>
          <td className="px-2 py-2  text-center whitespace-nowrap">{index + 1}</td>
          <td className="px-1 py-2 text-center  whitespace-nowrap">{item.vehicleNo}</td>
          <td className="px-2 py-2 text-center whitespace-nowrap">{item.capacity}</td>
          <td className="px-2 py-1 text-center whitespace-nowrap">
      <a
        href="#"
        className="text-blue-500 hover:underline"
        onClick={() => handleClickTrips(item.vehicleNo)}
      >
        {item.trips}
      </a>
    </td>
          <td className="px-1 py-1 text-center whitespace-nowrap">{item.totalRequestedQty}</td>
        </tr>
      ))}
    </tbody>
  </table>
</div>

    </div>
  )
)}
  {!isMobile && !showVehicleDeliveryInfo && (
  <div style={{ position: 'relative' }}> 
    <DeliveredData vehicleNo={vehicleNo} userData={user} vehiFromDate={fromDate} vehiToDate={toDate} token={usertoken}/>
    <FaTimes 
      onClick={handleCancel}
      style={{ position: 'absolute', top: '0', right: '0' }} 
    /> 
  </div>
)}

{isMobile && !showVehicleDeliveryInfo && (
        <div className="flex flex-col items-center">
          <DeliveredData vehicleNo={vehicleNo} userData={user} vehiFromDate={fromDate} vehiToDate={toDate} token={usertoken}/>
          <button
            onClick={handleCancel}
            className="mt-4 px-4 py-2 bg-blue-500 text-white rounded-lg focus:outline-none hover:bg-blue-600"
          >
            Close
          </button>
        </div>
      )}





    </div>
  );
};

export default VehicleDeliveryInfo;