import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast} from "react-toastify"; // Import react-toastify for popups
import "react-toastify/dist/ReactToastify.css";
import config from "../../config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import {
  faMapMarkerAlt,
  faEdit,
  faTrashAlt,
  faSave,
  faTimes,
  faTruck,
  faPlus,
  faPen,
  faUser,
  faTachometerAlt,
  faBuilding,
} from "@fortawesome/free-solid-svg-icons";
import Select from 'react-select';

const WardData = ({ selectedVehicle,  onClose,fetchVehicles  }) => {
// console.log("selectedVehicle", selectedVehicle);
  const [formData, setFormData] = useState({
    vehicleNo: "",
    capacity: "",
    vehicleType: "",
    Status: "",
    drivername: "",
    driver_mobile_no: "",
    user_zone: [],
    wardId: [],
  });
  const [checkedState, setCheckedState] = useState({});
  const [zones, setZones] = useState([]);
  const [wards, setWards] = useState([]);
  const [editMode, setEditMode] = useState(false); // State variable to track edit mode
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1020);
  const user = JSON.parse(sessionStorage.getItem("user"));

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1020);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
 
useEffect(() => {
  fetchLocalities();
}, []);


// useEffect(() => {
//   if (formData.capacity && !formData.vehicleType) {
//     // Trigger handleChange to update vehicleType based on capacity
//     handleChange({ target: { name: 'capacity', value: formData.capacity } });
//   }
// }, [formData.capacity, formData.vehicleType]);

useEffect(() => {
  const token = user ? user.token : null;
  // Get the token from sessionStorage
      const configToken = {
        headers: {
          Authorization: `Bearer ${token}`, // Set the Authorization header with the token
        },
      };
  const fetchData = async () => {
   
  
    if (selectedVehicle) {
      try {
        setEditMode(true);

        let defaultWardId = [];
        if (selectedVehicle.wardId !== null) {
          defaultWardId = Array.isArray(selectedVehicle.wardId)
            ? selectedVehicle.wardId.map(id => parseInt(id))
            : selectedVehicle.wardId.split(",").map(id => parseInt(id.trim()));
        }

        // Set formData including vehicleType and capacity
        setFormData({
          vehicleNo: selectedVehicle.vehicleNo,
          capacity: selectedVehicle.capacity,
          vehicleType: selectedVehicle.vehicleType,
          drivername: selectedVehicle.drivername,
          driver_mobile_no: selectedVehicle.driver_mobile_no,
          user_zone: selectedVehicle.zone.split(",").map(zone => zone.trim()),
          wardId: []
        });

        // Fetch wards based on selected vehicle zone
        if (selectedVehicle.zone !== null) {
          const zones = selectedVehicle.zone; // No need to split zones here
          const response = await axios.get(`${config.apiUrl}/zonewards/wards?zone=${zones}`,configToken);
          // console.log('API Response:', response);
          // console.log('API Response Data:', response.data);

          if (response && response.data) {
            const fetchedWards = response.data;
            setWards(fetchedWards);

            const filteredDefaultWardId = defaultWardId.filter(wardId =>
              fetchedWards.some(ward => ward.WardID === wardId)
            );

            const uniqueWardIds = [...new Set(filteredDefaultWardId)];

            setFormData(prevFormData => ({
              ...prevFormData,
              wardId: uniqueWardIds
            }));

            uniqueWardIds.forEach(wardId => {
              const ward = fetchedWards.find(w => w.WardID === wardId);
              if (ward) {
                handleCheckboxChange({ target: { checked: true } }, ward);
              }
            });
          } else {
            console.error('Error fetching ward zone wise: Response or response.data is undefined');
          }
        }
      } catch (error) {
        console.error('Error fetching ward zone wise:', error);
        // Handle error gracefully, such as displaying an error message to the user
      }
    }
  };

  fetchData(); // Call the fetchData function within useEffect
}, [selectedVehicle]);




const fetchLocalities = () => {
  const token = user ? user.token : null;
  // Get the token from sessionStorage
      const configToken = {
        headers: {
          Authorization: `Bearer ${token}`, // Set the Authorization header with the token
        },
      };

  axios
    .get(`${config.apiUrl}/zonewards/zones`,configToken)
    .then((response) => {
      setZones(response.data.Zones);
    })
    .catch((error) => {
      console.error("Error fetching localities:", error);
    });
};
const fetchWardZoneWise = (zones) => {
  const token = user ? user.token : null;
  // Get the token from sessionStorage
      const configToken = {
        headers: {
          Authorization: `Bearer ${token}`, // Set the Authorization header with the token
        },
      };

  return Promise.all(
    zones.map(zone => {
      return axios
        .get(`${config.apiUrl}/zonewards/wards?zone=${zone}`,configToken)
        .then(response => response.data)
        .catch(error => {
          console.error("Error fetching ward and zone details:", error);
          return [];
        });
    })
  );
};


const handleZoneChange = async (selectedOptions) => {
  if (selectedOptions) {
    const selectedZones = selectedOptions.map(option => option.value);

    try {
      const wardsForZones = await fetchWardZoneWise(selectedZones);
      const mergedWards = wardsForZones.flat();

      // Update wards with mergedWards
      setWards(mergedWards);

      // Reset checked state
      const updatedCheckedState = {};
      mergedWards.forEach((ward) => {
        updatedCheckedState[ward["WardID"]] = formData.wardId.includes(ward["WardID"]);
      });
      // console.log('updatedCheckedState',updatedCheckedState);
      setCheckedState(updatedCheckedState);
      // console.log('wards',wards);

      // Update formData with selected zones
      setFormData(prevState => ({
        ...prevState,
        user_zone: selectedZones,
      }));
    } catch (error) {
      console.error("Error fetching ward details:", error);
      setWards([]);
      setCheckedState({});
    }
  } else {
    // If no zones selected, clear wards and checked state
    setWards([]);
    setCheckedState({});
    // Clear formData user_zone
    setFormData(prevState => ({
      ...prevState,
      user_zone: [],
    }));
  }

  // Remove data for removed zones
  const removedZones = zones.filter(zone => !selectedOptions.some(option => option.value === zone));
  removedZones.forEach(zone => {
    const uniqueWardIds = wards.filter(ward => ward.Zone === zone).map(ward => ward.WardID);
    uniqueWardIds.forEach(wardId => {
      // Set checked state to false for the removed wards
      setCheckedState(prevState => ({
        ...prevState,
        [wardId]: false,
      }));
      // Remove data associated with the removed wards from formData
      setFormData(prevState => ({
        ...prevState,
        wardId: prevState.wardId.filter(id => id !== wardId),
      }));
    });
  });
  
};



  const closeModal = () => {
    onClose(); 
    HandleresetFormData();
};
  
  useEffect(() => {
    const updatedCheckedState = {};
    wards.forEach((ward) => {
      updatedCheckedState[ward["WardID"]] = formData.wardId.includes(
        ward["WardID"]
      );
    });
    setCheckedState(updatedCheckedState);
  }, [formData.wardId, wards]);

  const handleCheckboxChange = (event, ward) => {
    const { checked } = event.target;
    const { WardID } = ward;
 
    setFormData((prevState) => {
        let updatedWardIds = [...prevState.wardId];


        if (checked && !updatedWardIds.includes(WardID)) {
            updatedWardIds.push(WardID);
        } else if (!checked) {
            updatedWardIds = updatedWardIds.filter((id) => id !== WardID);
        }


        return {
            ...prevState,
            wardId: updatedWardIds,
        };
    });


    // Update checkedState for manual changes
    setCheckedState((prevState) => ({
        ...prevState,
        [WardID]: checked,
    }));
};

const handleChange = (e) => {
  const { name, value } = e.target;

  // Check if the changed field is 'capacity'
  if (name === 'capacity') {
    // Determine the corresponding vehicle type based on the selected capacity
    const vehicleType = value === '10000' ? 'Lorry' : value === '6000' ? 'Tractor' : '';

    // Update the form data with the new vehicleType
    setFormData({ ...formData, [name]: value, vehicleType: vehicleType });
  } else {
    // For other fields, simply update the form data
    setFormData({ ...formData, [name]: value });
  }
};

  
  const HandleresetFormData = () => {
    setFormData({
      vehicleNo: "",
      capacity: "",
      vehicleType: "",
      Status: "",
      drivername: "",
      driver_mobile_no: "",
      user_zone: [],
      wardId: [],
    });
    setWards([]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if status is empty, set it to '0'
    if (!formData.Status) {
      setFormData({ ...formData, Status: "0" });
    }

    const wardIds = formData.wardId.join(", "); // Convert array to comma-separated string
    const zoness = formData.user_zone.join(", "); 
    try {
      if (selectedVehicle) {
        // Update existing vehicle
        await handleUpdate();
      } else {
        const token = user ? user.token : null;
        // Get the token from sessionStorage
            const configToken = {
              headers: {
                Authorization: `Bearer ${token}`, // Set the Authorization header with the token
              },
            };
      
        // Submit new vehicle
        const response = await axios.post(
          `${config.apiUrl}/zonewards/register-vehicle`,
          {
            ...formData,
            wardId: wardIds,
            zone: zoness,
          },configToken
        );
        // toast.success(response.data.message);
        // console.log("Registration response:", response); // Log the response
        if (response.status === 200) {
            toast.success(response.data.message);
            HandleresetFormData(); 
            await new Promise((resolve) => setTimeout(resolve, 1000));
            fetchVehicles();
            closeModal();
        } else {
            toast.error("Error registering vehicle");
        }
           }
   
    } catch (error) {
      // console.error("Error registering vehicle:", error);
      if (
        error.response &&
        error.response.status === 400 &&
        error.response.data.error === "Vehicle Number already exists"
      ) {
        // Display toast notification for duplicate vehicle number
        toast.error("Vehicle Number already exists");
        // HandleresetFormData();

      } else {
        toast.error("Error registering vehicle");
      }
    }
  };

  const handleUpdate = async () => {
    // Check if status is empty, set it to '0'
    if (!formData.Status) {
      setFormData({ ...formData, Status: "0" });
    }
  
    const wardIds = formData.wardId.join(", "); // Convert array to comma-separated string
    const zoness = formData.user_zone.join(", "); 
    const token = user ? user.token : null;
    // Get the token from sessionStorage
        const configToken = {
          headers: {
            Authorization: `Bearer ${token}`, // Set the Authorization header with the token
          },
        };
  
  
    try {
      const response = await axios.put(
        `${config.apiUrl}/zonewards/updatevehicle/${selectedVehicle.vehicleNo}`,
        {
          ...formData, // Spread existing formData fields
          wardId: wardIds, // Add wardId field with comma-separated ward IDs
          zone: zoness,
          vehicleType: formData.vehicleType, // Add zone field
        },configToken
      );
      // console.log("update response:", response); // Log the response
  
      toast.success(response.data.message); // Show success toast
      await new Promise((resolve) => setTimeout(resolve, 1000));
      closeModal();
      fetchVehicles(); // Optionally, you can update the list of vehicles after successful insertion
    } catch (error) {
      console.error("Error updating vehicle:", error);
      toast.error("Error updating vehicle");
    }
  };
  
  const modalStyles = `
  .modal {
    display: flex;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0,0.4);
  }

  .modal-content {
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    // width: 80%;
    animation-name: modal-slide;
    animation-duration: 0.5s;
  }

  @keyframes modal-slide {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(0);
    }
  }

  .close-btn {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }

  .close-btn:hover,
  .close-btn:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }
`;

  return (
    <div>
          
          <div className="modal ">
          
    
          <style>{modalStyles}</style>
    
    <div className="modal h-screen overflow-y-auto">
      {!isMobile? (
          <div className="modal-content "  style={{width:'800px'}}>
             <button onClick={closeModal} className="close-btn">
               <FontAwesomeIcon icon={faTimes} />
             </button>
             <h1 className="text-2xl text-blue-800 sm:text-3xl font-semibold sm:mb-4 mb-2 text-center ">
               <FontAwesomeIcon className="" icon={faTruck} /> Add New Vehicle
             </h1>
             <form className="flex flex-wrap p-2" onSubmit={handleSubmit}>
               {/* First input pair */}
               <div className="w-full md:w-1/2 mb-1 pr-4 pb-5">
                 <label
                   htmlFor="vehicleNo"
                   className="text-gray-700 mb-1 block text-lg font-semibold"
                 >
                   <FontAwesomeIcon icon={faTruck} className="mr-2" />
                   Vehicle Number
                 </label>
                 <input
                   type="text"
                   id="vehicleNo"
                   name="vehicleNo"
                   value={formData.vehicleNo}
                   onChange={handleChange}
                   className="border rounded-md p-2 w-full"
                   required
                   readOnly={!!selectedVehicle} 
                 />
               </div>
               <div className="w-full md:w-1/2 mb-1 pr-4 pb-5 ">
                 <label
                   htmlFor="capacity"
                   className="text-gray-700  mb-1 block text-lg font-semibold"
                 >
                   <FontAwesomeIcon icon={faTachometerAlt} /> Capacity
                 </label>
                 <select
                   id="capacity"
                   name="capacity"
                   value={formData.capacity}
                   onChange={handleChange}
                   className="border rounded-md p-3 w-full bg-white"
                   required
                   disabled={!!selectedVehicle}
                 >
                   <option value="">Select Capacity</option>
                   <option value="10000">10000</option>
                   <option value="6000">6000</option>
                 </select>
               </div>

        <div className="w-full md:w-1/2 mb-1 pr-4 pb-5">
          <label
            htmlFor="vehicleType"
            className="text-gray-700 mb-1 block text-lg font-semibold"
          >
            <FontAwesomeIcon icon={faTruck} /> Vehicle Type
          </label>
          <select
            id="vehicleType"
            name="vehicleType"
            value={formData.vehicleType}
            // onChange={handleChange}
            className="border rounded-md p-2 w-full bg-white"
            required
            disabled={!!selectedVehicle}
          >
            
            <option value="">Select Vehicle Type</option>
            <option value={formData.vehicleType}>{formData.vehicleType}</option>
          </select>
        </div>
    

               <div className="w-full md:w-1/2 mt-1 pr-4 pb-5">
                 <label
                   htmlFor="user_zone"
                   className="block font-semibold mb-1 text-gray-700"
                 >
                   <FontAwesomeIcon icon={faMapMarkerAlt} /> Zone
                 </label>
                 <Select
  id="user_zone"
  name="user_zone"
  value={formData.user_zone ? formData.user_zone.map(zone => ({ label: zone, value: zone })) : null}
  onChange={handleZoneChange}
  options={zones.map(zone => ({ label: zone, value: zone }))}
  isClearable={true} // Enable clear icon
  isMulti={true} // Enable multiple selection
/>

               </div>


               <div className="w-full md:w-1/2 mb-1 pr-4" >
                 <label className="block font-semibold mb-1 text-gray-700">
                   <FontAwesomeIcon icon={faBuilding} /> Wards
                 </label>
                 <div
                   className="mt-4 overflow-y-auto border border-gray-300 p-4 rounded h-[500px]"
                   style={{ maxHeight: "calc(100vh - 550px)" }}
                 >
                <div className="grid  md:grid-cols-4 gap-4">
  {wards.map((ward, index) => (
    <label key={index} className="inline-flex items-center">
      <input
        type="checkbox"
        value={ward["WardID"]}
        onChange={(event) => handleCheckboxChange(event, ward)}
        checked={checkedState[ward["WardID"]]}
        className="form-checkbox h-5 w-5 text-indigo-600 rounded mr-2"
      />
      {ward["WardID"]}
    </label>
  ))}
</div>
                 </div>
               </div>

              
          <div className="w-full md:w-1/2 mb-1 pr-4">
                 <label className="block font-semibold mb-1 text-gray-700">
                 <FontAwesomeIcon icon={faBuilding} /> Selected Wards
                 </label>
                 <div
                   className="mt-4 overflow-y-auto"
                   style={{ maxHeight: "calc(100vh - 550px)" }}
                 >
       <div className="w-full px-4 py-4 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500">
  {formData.wardId
    // Filter out ward IDs for which corresponding wards are not found
    .filter(wardID => {
      return wards.find(w => w.WardID === wardID);
    })
    // Map the remaining ward IDs to JSX elements
    .map((wardID, index) => {
      const ward = wards.find(w => w.WardID === wardID); // Find ward by WardID
      return (
        <span
          key={index}
          className="inline-block mb-1 bg-gray-200 rounded-full px-1 py-1 text-xs font-semibold text-gray-700"
        >
          {`${ward.WardID} - ${ward.WardName}`}
        </span>
      );
    })}
</div>


                 </div>
               </div> 
               <div className="w-full mb-1 pr-4">

               {editMode ? (
                 <div className="w-1/2 text-center mt-10">
                   <button
                     type="submit"
                     className="mt-6 bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
                   >
                     Update
                   </button>
                 </div>
               ) : (
                <div className=" text-center ">
                 <button
                   type="submit"
                   className="mt-6 bg-blue-500 text-white  px-4 py-2 rounded-md hover:bg-blue-600"
                 >
                   Register
                 </button>
                 </div>
               )}
               </div>
             </form>
           </div>
       ):(
        <div className="modal-content h-[90vh] " style={{width:'95vw'}}>
          <div className="sticky top-0">
    <button onClick={closeModal} className="close-btn">
      <FontAwesomeIcon icon={faTimes} />
    </button>
    <h1 className="text-2xl text-blue-800 sm:text-3xl font-semibold sm:mb-4 mb-2 text-center ">
      <FontAwesomeIcon className="" icon={faTruck} /> Add New Vehicle
    </h1>
    </div>
    <form className="p-2  h-[70vh] overflow-auto w-full" onSubmit={handleSubmit}>
      <div className="mb-4">
        <label htmlFor="vehicleNo" className="text-gray-700 font-semibold block mb-1">
          <FontAwesomeIcon icon={faTruck} className="mr-2" />
          Vehicle Number
        </label>
        <input
          type="text"
          id="vehicleNo"
          name="vehicleNo"
          value={formData.vehicleNo}
          onChange={handleChange}
          className="border rounded-md p-1 w-full"
          required
          readOnly={!!selectedVehicle} 
        />
      </div>
      <div className="mb-4">
        <label htmlFor="capacity" className="text-gray-700 font-semibold block mb-1">
          <FontAwesomeIcon icon={faTachometerAlt} /> Capacity
        </label>
        <select
          id="capacity"
          name="capacity"
          value={formData.capacity}
          onChange={handleChange}
          className="border rounded-md p-1 w-full bg-white"
          required
          disabled={!!selectedVehicle}
        >
          <option value="">Select Capacity</option>
          <option value="10000">10000</option>
          <option value="6000">6000</option>
        </select>
      </div>
      <div className="mb-4">
        <label htmlFor="vehicleType" className="text-gray-700 font-semibold block mb-1">
          <FontAwesomeIcon icon={faTruck} /> Vehicle Type
        </label>
        <select
          id="vehicleType"
          name="vehicleType"
          value={formData.vehicleType}
          onChange={handleChange}
          className="border rounded-md p-1 w-full bg-white"
          required
          disabled={!!selectedVehicle}
        >
          <option value="">Select Vehicle Type</option>
          <option value={formData.vehicleType}>{formData.vehicleType}</option>
        </select>
      </div>
      <div className="w-full md:w-1/2 mt-1 pr-4 pb-5">
                 <label
                   htmlFor="user_zone"
                   className="block font-semibold mb-1 text-gray-700"
                 >
                   <FontAwesomeIcon icon={faMapMarkerAlt} /> Zone
                 </label>
                 <Select
  id="user_zone"
  name="user_zone"
  value={formData.user_zone ? formData.user_zone.map(zone => ({ label: zone, value: zone })) : null}
  onChange={handleZoneChange}
  options={zones.map(zone => ({ label: zone, value: zone }))}
  isClearable={true} // Enable clear icon
  isMulti={true} // Enable multiple selection
/>

               </div>

      <div className="mb-4" style={{ maxHeight: '100px' }}>
        <label htmlFor="user_zone" className="text-gray-700 font-semibold block mb-1">
          <FontAwesomeIcon icon={faBuilding} /> Wards
        </label>
        <div className="overflow-x-auto border border-gray-300 p-2 rounded" style={{ maxHeight: '100px' }}>
  <div className="grid grid-cols-6 text-xs md:grid-cols-6 gap-4">
    {wards.map((ward, index) => (
      <label key={index} className="inline-flex items-center">
        <input
          type="checkbox"
          value={ward["WardID"]}
          onChange={(event) => handleCheckboxChange(event, ward)}
          checked={checkedState[ward["WardID"]]}
          className="form-checkbox h-5 w-5 text-indigo-600 rounded mr-2"
        />
        {ward["WardID"]}
      </label>
    ))}
  </div>
</div>

      </div>
      <div className="mb-4">
  <label htmlFor="user_zone" className="text-gray-700 font-semibold block mb-1">
    <FontAwesomeIcon icon={faBuilding} /> Selected Wards
  </label>
  <div className="overflow-y-auto border border-gray-300 p-2 rounded" style={{ maxHeight: '100px' }}>
  {formData.wardId
    // Filter out ward IDs for which corresponding wards are not found
    .filter(wardID => {
      return wards.find(w => w.WardID === wardID);
    })
    // Map the remaining ward IDs to JSX elements
    .map((wardID, index) => {
      const ward = wards.find(w => w.WardID === wardID); // Find ward by WardID
      return (
        <span
          key={index}
          className="inline-block mb-1 bg-gray-200 rounded-full px-1 py-1 text-xs font-semibold text-gray-700"
        >
          {`${ward.WardID} - ${ward.WardName}`}
        </span>
      );
    })}
  </div>
</div>

      {editMode ? (
        <div className="text-center mt-5">
          <button
            type="submit"
            className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
          >
            Update
          </button>
        </div>
      ) : (
        <button
          type="submit"
          className="mt-6 bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 w-full"
        >
          Register
        </button>
      )}
    </form>
  </div>

      )}
     
    </div>
    </div>
        </div>
);
};


export default WardData;