import React, { useState, useEffect } from 'react';
import { Link, Route, useNavigate } from 'react-router-dom';
import { FaSyncAlt, FaSignOutAlt, FaHome, FaFileAlt, FaMap, FaBars, FaTimes, FaLock, FaLockOpen,FaClock  } from 'react-icons/fa';
import logo1 from '../assests/mc.png';
import logo from '../assests/mclogo.png';
import config from "../config";
import profileImg from '../assests/profile.png';
import { toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import lock from '../assests/lock.png';
import lockopen from '../assests/lock open.png';


const UserProfile = ({ user }) => {
  const navigate = useNavigate();
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState(null);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false); 
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
const [showNewPassword, setShowNewPassword] = useState(false);
const [showConfirmPassword, setShowConfirmPassword] = useState(false);
// State for dropdown visibility

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown); // Toggle dropdown visibility
  };
  const handleLogout = async () => {
    const logoutEventData = {
      userId: user.user.user_id,
      userFirstName: user.user.user_first_name,
      userLastName: user.user.user_last_name,
      userRole: user.user.user_role,
    };
    setIsButtonDisabled(true);
    const token = user ? user.token : null;
    // Get the token from sessionStorage
        const configToken = {
          headers: {
            Authorization: `Bearer ${token}`, // Set the Authorization header with the token
          },
        };
  
    try {
      const response = await fetch(`${config.apiUrl}/user/logout`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          ...configToken.headers,
        },
        body: JSON.stringify(logoutEventData),
      });

      const result = await response.json();

      if (response.status === 200) {
        sessionStorage.removeItem("user");
        sessionStorage.removeItem('authentication')
        navigate("/");
        setIsButtonDisabled(false);
      } else {
        console.error("Error logging logout event:", result.message);
      }
    } catch (error) {
      console.error("Error logging logout event:", error.message);
    }
  };

  const handleChangePassword = () => {
    setShowChangePasswordModal(true);
        // Check if input fields are empty and apply red border if necessary
    
  };

  const handleCloseModal = () => {
    setShowChangePasswordModal(false);
    // Reset password fields
    setCurrentPassword('');
    setNewPassword('');
    setConfirmPassword('');
    setError(null); // Clear error message when closing modal

    setFormSubmitted(false);
  };

  const handleConfirmChangePassword = async () => {  
    const token = user ? user.token : null;
    // Get the token from sessionStorage
        const configToken = {
          headers: {
            Authorization: `Bearer ${token}`, // Set the Authorization header with the token
          },
        };
  
    try {
      // Check for empty fields only if the form is submitted
      if (formSubmitted && (!currentPassword || !newPassword || !confirmPassword)) {
        // toast.error('Please fill in all required fields.');
        return;
      }
      if (!currentPassword || !newPassword) {
        toast.error('Current password and new password are required.');
        return;
      }
     
  
      // Validate new password and confirm password match
      if (newPassword !== confirmPassword) {
        toast.error('New password and confirm password do not match.');
        return;
      }
      if (currentPassword === newPassword === confirmPassword) {
        toast.error('current password and New password will be match');
        return;
      }
      
      // Send request to backend to update password
      const response = await fetch(`${config.apiUrl}/user/changepassword`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          ...configToken.headers,
        },
        body: JSON.stringify({
          userId: user.user.user_id,
          currentPassword: currentPassword, // Send hashed current password
          newPassword: newPassword,
        }),
      });
  
      const data = await response.json();
      // console.log(data,"data")
  
      if (response.ok) {
        // Password successfully changed
        // Close the change password modal and reset form fields
        setShowChangePasswordModal(false);
        setCurrentPassword('');
        setNewPassword('');
        setConfirmPassword('');
        setError(null);
        setFormSubmitted(false);
        // Display success toast
        toast.success('Password updated successfully');
      }else {
        // Display error toast with the message from the backend
        if (data.message === 'Incorrect current password') {
          toast.error('Incorrect current password');
        }  else {
        setError(data.message);  // Set error message from response
      }
    }
    } catch (error) {
      console.error('Error changing password:', error.message);
      setError('Error changing password. Please try again.'); // Generic error message
    }
  };
  
  const toggleShowCurrentPassword = () => {
    setShowCurrentPassword(!showCurrentPassword);
  };
  
  const toggleShowNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };
  
  const toggleShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  

  return (
    <div className="flex-grow-0 font-serif">
      <div className="flex items-center mr-4">
      {/* <p className="text-xs sm:text-sm mt-6 mr-8" onClick={handleChangePassword}>Change Password</p> */}
        <div >
       
          <p className="text-xs sm:text-sm">
            <p>{user ? ` ${user.user.user_role}` : "Guest"}</p>
          
          <p className="text-[10px] sm:text-sm">{` ${user.user.user_first_name} ${user.user.user_last_name} `}</p>
          </p>
          </div>
      
          <div className="flex items-center relative">
        <img
          src={profileImg}
          alt="Profile"
          className="w-7 h-7 sm:w-14 sm:h-14 mr-1 rounded-full cursor-pointer"
          onClick={toggleDropdown} // Toggle dropdown on profile image click
        />
        {showDropdown && ( // Render dropdown only if showDropdown is true
          <div className="absolute top-0 right-0 w-40 mt-14  bg-white border border-gray-300 rounded-md shadow-md">
            <button onClick={handleChangePassword} className="block w-full px-2 py-1 text-left hover:bg-gray-100">
              Change Password
            </button>
            <hr className="" />
            <div className='flex justify-center items-center cursor-pointer hover:bg-gray-100' onClick={handleLogout}>
            <button  className=" px-2 py-1 ">
              Logout
            </button> 
            
              <FaSignOutAlt />
              </div>
         
          </div>
        )}
      </div>
        {/* <div className="mt-2 mr-9">
          <button
            className="bg-transparent border-none focus:outline-none"
            onClick={handleLogout}
            disabled={isButtonDisabled}
          >
            <FaSignOutAlt />
          </button>
        </div> */}

      </div>
      {showChangePasswordModal && (
      <div className="fixed inset-0 z-50">
      <div className="absolute inset-0 bg-black opacity-50 backdrop-filter blur-sm">  </div>{/* Apply blur effect to the background */}
      <div className="fixed inset-0 flex items-center justify-center">
      <div className="relative">
      <button onClick={handleCloseModal} className="absolute top-0 right-0 bg-red-400 hover:bg-red-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
        <FaTimes />
      </button>
      <div className="bg-white p-6 rounded-lg shadow-md w-[400px] h-[350px]">
      <div className="relative">
  <label className="block  items-center">
    <button type="button" onClick={toggleShowCurrentPassword}   style={{ width: '24px', height: '24px' }} className="absolute right-2 top-8 focus:outline-none">
     {showCurrentPassword ? (
        <img src={lockopen} alt="Open" style={{ width: '100%', height: '100%' }} />
      ) : (
        <img src={lock} alt="Closed" style={{ width: '100%', height: '100%' }} />
      )}
    </button>
    Current Password:
  </label>
  <input
    type={showCurrentPassword ? 'text' : 'password'}
    value={currentPassword}
    className={`border border-blue-500 rounded w-full px-3 py-2 mt-1 mb-4 ${formSubmitted && !currentPassword && 'border-red-500'}`}
    onChange={(e) => setCurrentPassword(e.target.value)}
    required
  />
</div>

<div className="relative">
  <label className=" flex items-center">
  <button type="button" onClick={toggleShowNewPassword}   style={{ width: '24px', height: '24px' }} className="absolute right-2 top-8 focus:outline-none">
     {showNewPassword ? (
        <img src={lockopen} alt="Open" style={{ width: '100%', height: '100%' }} />
      ) : (
        <img src={lock} alt="Closed" style={{ width: '100%', height: '100%' }} />
      )}
    </button>
    New Password:
  </label>
  <input
    type={showNewPassword ? 'text' : 'password'}
    value={newPassword}
    onChange={(e) => setNewPassword(e.target.value)}
    className={`border border-blue-500 rounded w-full px-3 py-2 mt-1 mb-4 ${formSubmitted && !newPassword && 'border-red-500'} `}
    required
  />
</div>

<div className="relative">
  <label className="block  items-center">
  <button type="button" onClick={toggleShowConfirmPassword}   style={{ width: '24px', height: '24px' }} className="absolute right-2 top-8 focus:outline-none">
     {showConfirmPassword ? (
        <img src={lockopen} alt="Open" style={{ width: '100%', height: '100%' }} />
      ) : (
        <img src={lock} alt="Closed" style={{ width: '100%', height: '100%' }} />
      )}
    </button>
    Confirm Password:
  </label>
  <input
    type={showConfirmPassword ? 'text' : 'password'}
    value={confirmPassword}
    onChange={(e) => setConfirmPassword(e.target.value)}
    className={`border border-blue-500 rounded w-full px-3 py-2 mt-1 mb-4 ${formSubmitted && !confirmPassword && 'border-red-500'} `}
    required
  />
</div>
        {error && <p className="text-red-500">{error}</p>}
        <div className="flex justify-end">
          <button onClick={() => {setFormSubmitted(true); handleConfirmChangePassword();}} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">Submit</button>
        </div>
      </div>
    </div>
      </div>
    </div>
   
    
      )}   
        
  </div>
  );
};

const Navbar = () => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const userData = JSON.parse(sessionStorage.getItem("user"));
    if (userData) {
      setUser(userData);
    }
  }, []);

  return (
    <div className="border-b border-white">
      <header className="w-full">
        <nav className="container mx-auto px-4 md:px-6 flex justify-between items-center bg-light max-h-18 flex-shrink-0">
          <Link to="/dashboard" className="navbar-brand flex-shrink-0 flex items-center">
            <img src={logo} alt="Logo" className='mr-2 md:w-10 w-10'  />
            <h1 className="font-bold md:text-3xl sm:text-2xl text-sm" style={{
              background: 'linear-gradient(0deg, #21409A, #21409A)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
            }}>
              Madurai Corporation
            </h1>
          </Link>
          <div className=" md:mr-10">
            {user && <UserProfile user={user} />}
          </div>
        </nav>
      </header>
    </div>
  );
};


export default Navbar;