import React, { useState, useEffect } from 'react';
import axios from 'axios';
import config from "../../config";
import { toast} from "react-toastify"; 
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faTrash,
  faSearch,
  faFileExport,  faSave,
  faFileExcel,
  faTimes,faPlus
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import * as XLSX from 'xlsx';
import jsPDF from "jspdf";
import "jspdf-autotable";

const ZoneWardForm = ({ onClose }) => {
    const [zones, setZones] = useState([]);
    const [selectedZone, setSelectedZone] = useState('');
    const [wardID, setWardID] = useState('');
    const [wardName, setWardName] = useState('');
    const [isNewZone, setIsNewZone] = useState(false);
    const [newZone, setNewZone] = useState('');
    const [isMobile, setIsMobile] = useState(window.innerWidth < 1020);
    const user = JSON.parse(sessionStorage.getItem("user"));

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1020);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  

    // Fetch existing zones from backend
    useEffect(() => {
        const fetchZones = async () => {
          const token = user ? user.token : null;
          // Get the token from sessionStorage
              const configToken = {
                headers: {
                  Authorization: `Bearer ${token}`, // Set the Authorization header with the token
                },
              };
            try {
                const response = await axios.get(`${config.apiUrl}/zonewards/zones`,configToken);
                setZones(response.data.Zones);
            } catch (error) {
                console.error('Error fetching zones:', error);
                // Handle error
            }
        };
        fetchZones();
    }, []);

    const handlereset = ()=>{
      setSelectedZone('')
      setIsNewZone('')
            setWardID('')
            setWardName('')
          }
          const closeModal = () => {
            onClose(); // Call the onClose function passed as a prop
        };

        const handleSubmit = async (e) => {
          e.preventDefault();
          const token = user ? user.token : null;
          // Get the token from sessionStorage
              const configToken = {
                headers: {
                  Authorization: `Bearer ${token}`, // Set the Authorization header with the token
                },
              };
          try {
              // If isNewZone is true, use newZone value, otherwise use selectedZone
              const zoneToUse = isNewZone ? newZone : selectedZone;
      
              await axios.post(`${config.apiUrl}/zonewards/postzonesandwards`, { Zone: zoneToUse, WardID: wardID, WardName: wardName },configToken);
      
              // Optionally, you can add success message or redirect to another page
              toast.success('Zone and ward created successfully');
              await new Promise((resolve) => setTimeout(resolve, 1000));
              closeModal();
              handlereset();
          } catch (error) {
              console.error('Error creating zone and ward:', error);
              if (error.response && error.response.status === 400) {
                  // WardID or WardName already exists, show toast message from backend response
                  toast.error(error.response.data.error);
              } else {
                  // Handle other errors
                  toast.error('Error creating zone and ward');
              }
          }
      };
   
    const handleZoneChange = (e) => {
        setSelectedZone(e.target.value);
        setIsNewZone(false);
    };

  

    const modalStyles = `
    .modal {
      display: flex;
      position: fixed;
      z-index: 1;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: rgb(0,0,0);
      background-color: rgba(0,0,0,0.4);
    }
  
    .modal-content {
      background-color: #fefefe;
      margin: auto;
      padding: 20px;
      border: 1px solid #888;
      animation-name: modal-slide;
      animation-duration: 0.5s;
    }
  
    @keyframes modal-slide {
      from {
        transform: translateX(100%);
      }
      to {
        transform: translateX(0);
      }
    }
  
    .close-btn {
      color: #aaa;
      float: right;
      font-size: 28px;
      font-weight: bold;
    }
  
    .close-btn:hover,
    .close-btn:focus {
      color: black;
      text-decoration: none;
      cursor: pointer;
    }
  `;
  
  const modalsStyles = `
  .modal {
    display: flex;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(3px); 
  }

  .modal-content {
    background-color: #fefefe;
    margin: auto;
    padding: 10px; /* Reduce padding for smaller screens */
    border: 1px solid #888;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%; /* Set width to 90% for smaller screens */
    max-width: 300px; /* Set max-width for smaller screens */
  }

  .close-btn {
    color: red;
    float: right;
    font-size: 20px; /* Reduce font size for smaller screens */
  
  }

  .close-btn:hover,
  .close-btn:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }
`;



    return (
      <>
       {!isMobile ? (
         <div className="modal  overflow-y-auto h-full">
         <style>{modalStyles}</style>
      <div className="modal-content">
        <button onClick={closeModal} className="close-btn">
          <FontAwesomeIcon icon={faTimes} />
        </button>
                <h2 className="text-xl font-semibold mt-1 text-center mb-4">ADD ZONE AND WARD</h2>
                
                <form className=" p-5" onSubmit={handleSubmit} style={{width:'400px'}}>
                    <div className="mb-4">
                        <label htmlFor="zone" className="block text-gray-700">Zone</label>
                        <input
                            type="text"
                            id="zone"
                            className="mt-1 p-2 w-full border border-gray-300 rounded-md"
                            placeholder="Select or enter Zone"
                            list="zoneList"
                            value={selectedZone}
                            onChange={handleZoneChange}
                            required
                        />
                        <datalist id="zoneList">
                            {/* <option value="">Select Zone</option> */}
                            {zones.map((zone, index) => (
                                <option key={index} value={zone}>{zone}</option>
                            ))}
                        </datalist>
                    </div>

                    <div className="mb-4">
                        <label htmlFor="wardID" className="block text-gray-700">Ward ID</label>
                        <input
                            type="number"
                            id="wardID"
                            className="mt-1 p-2 w-full border border-gray-300 rounded-md"
                            value={wardID}
                            onInput={(e) => {
                              // Replace non-numeric characters and limit the length to 10 characters
                              e.target.value = e.target.value.replace(/\D/g, '');
                              // Update the wardID state with the modified value
                              setWardID(e.target.value);
                          }}
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="wardName" className="block text-gray-700">Ward Name</label>
                        <input
                            type="text"
                            id="wardName"
                            className="mt-1 p-2 w-full border border-gray-300 rounded-md"
                            value={wardName}
                            onChange={(e) => setWardName(e.target.value.toUpperCase())}
                            required
                        />
                    </div>
                    <br/>
                    <div className="text-center">
                  <button
                    type="submit"
                    className=" bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 "
                  >
                    Submit
                  </button>
                </div>
                  
                 
                </form>
            </div>
        </div>
       ) : (
        <div className="modal overflow-y-auto h-full">
        <style>{modalsStyles}</style>
        <div className="modal-content">
          <button onClick={closeModal} className="close-btn">
            <FontAwesomeIcon icon={faTimes} />
          </button>
          <h2 className="text-md font-semibold mt-1 text-center mb-4">ADD ZONE AND WARD</h2>
          
          <form className="p-3" onSubmit={handleSubmit} style={{ width: '100%' }}>
            <div className="mb-4">
              <label htmlFor="zone" className="block  text-gray-700">Zone</label>
              <input
                type="text"
                id="zone"
                className="mt-1 p-2 w-full border border-gray-300 rounded-md"
                placeholder="Select or enter Zone"
                list="zoneList"
                value={selectedZone}
                onChange={handleZoneChange}
                required
              />
              <datalist id="zoneList">
                {zones.map((zone, index) => (
                  <option key={index} value={zone}>{zone}</option>
                ))}
              </datalist>
            </div>
            <div className="mb-4">
              <label htmlFor="wardID" className="block text-gray-700">Ward ID</label>
              <input
                type="number"
                id="wardID"
                className="mt-1 p-2 w-full border border-gray-300 rounded-md"
                value={wardID}
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/\D/g, '');
                  setWardID(e.target.value);
                }}
                required
              />
            </div>
            <div className="mb-4">
              <label htmlFor="wardName" className="block text-gray-700">Ward Name</label>
              <input
                type="text"
                id="wardName"
                className="mt-1 p-2 w-full border border-gray-300 rounded-md"
                value={wardName}
                onChange={(e) => setWardName(e.target.value.toUpperCase())}
                required
              />
            </div>
            <div className="text-center">
              <button
                type="submit"
                className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
       ) }
     
        </>
    );
};

const ZoneWardTable = () => {
    const [zoneWards, setZoneWards] = useState([]);
    const [filteredZoneWards, setFilteredZoneWards] = useState([]);
    const [showForm, setShowForm] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedItem, setSelectedItem] = useState(null);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [editableZoneWard, seteditableZoneWard] = useState(null);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 1020);
    const [error, setError] = useState('');
    const [showLoginForm, setShowLoginForm] = useState(false);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const user = JSON.parse(sessionStorage.getItem("user"));

    useEffect(() => {
      const handleResize = () => {
        setIsMobile(window.innerWidth < 1020);
      };
      window.addEventListener('resize', handleResize);
  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);


    useEffect(() => {
      filterZoneWards();
  }, [searchQuery, zoneWards]);


      const fetchZoneWards = async () => {
        const token = user ? user.token : null;
        // Get the token from sessionStorage
            const configToken = {
              headers: {
                Authorization: `Bearer ${token}`, // Set the Authorization header with the token
              },
            };
        try {
            const response = await axios.get(`${config.apiUrl}/zonewards/getzonesandwards`,configToken);
            setZoneWards(response.data.zoneWards);
          // console.log("wardid check", response.data.zoneWards);
        } catch (error) {
            console.error('Error fetching zone wards:', error);
            // Handle error
        }
    };

    const navigate = useNavigate();

  useEffect(() => {
    const auth = JSON.parse(sessionStorage.getItem('authentication'));
    if (!auth ) {
      navigate('/')  
    } else { 
      fetchZoneWards();
    }
  }, []);
    
    const handleDeleteClick = (zoneWard) => {
      setSelectedItem(zoneWard);
      setShowConfirmation(true);
    };

    const handleCancelDelete = () => {
      setSelectedItem(null);
      setShowConfirmation(false);
    };

    const handleDelete = async () => {
        try {
          const token = user ? user.token : null;
          // Get the token from sessionStorage
              const configToken = {
                headers: {
                  Authorization: `Bearer ${token}`, // Set the Authorization header with the token
                },
              };
            const response = await fetch(
                `${config.apiUrl}/zonewards/deletezonesandwards/${selectedItem.id}`,
                {
                    method: "DELETE",
                   headers: configToken.headers,
                }
            );
            if (response.ok) {
                // Remove the deleted item from the local state
                setFilteredZoneWards(filteredZoneWards.filter((item) => item.id !== selectedItem.id));
                    handleCancelDelete();

               // Show toast for successful deletion
                toast.success("Record deleted successfully");
            } else {
                console.error("Error deleting record");
                // Show toast for error deleting record
                toast.error("Error deleting record");
            }
        } catch (error) {
            console.error("Error deleting record:", error);
            // Show toast for error deleting record
            toast.error("Error deleting record");
        }
    };
     
    const handleEdit = (zoneWard) => {
        seteditableZoneWard({ ...zoneWard });
      };
    
      const handleSave = async () => {
        const token = user ? user.token : null;
        // Get the token from sessionStorage
            const configToken = {
              headers: {
                Authorization: `Bearer ${token}`, // Set the Authorization header with the token
              },
            };
      
        try {
            const response = await axios.put(
                `${config.apiUrl}/zonewards/updatezonesandwards/${editableZoneWard.id}`,
                editableZoneWard,configToken
            );
            toast.success(response.data.message);
            fetchZoneWards();
            seteditableZoneWard(null);
        } catch (error) {
            console.error("Error updating zoneWard:", error);
            if (error.response && error.response.status === 400) {
                // WardName already exists, show toast message from backend response
                toast.error(error.response.data.error);
            } else if (error.response && error.response.status === 404) {
                // Record not found, show toast message from backend response
                toast.error(error.response.data.error);
            } else {
                // Handle other errors
                toast.error('Error updating zoneWard');
            }
        }
    };
    
      const handleCancel = () => {
        seteditableZoneWard(null);
      };
    
    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };
   
    const filterZoneWards = () => {
        const filtered = zoneWards.filter(zoneWard =>
            zoneWard.Zone.toLowerCase().includes(searchQuery.toLowerCase()) ||
            zoneWard.WardName.toLowerCase().includes(searchQuery.toLowerCase()) ||
            zoneWard.WardID === parseInt(searchQuery)
        );
        setFilteredZoneWards(filtered);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;  
      
    
        seteditableZoneWard((prevState) => ({
          ...prevState,
          [name]: value,
        }));
    
       
      };
      const openModal = () => {
        setShowForm(true);
      };
      const closeModal = () => {
        setShowForm(false);
        fetchZoneWards();
        // HandleresetFormData();
      };
      const exportToExcel = async () => {
        const token = user ? user.token : null;
        // Get the token from sessionStorage
            const configToken = {
              headers: {
                Authorization: `Bearer ${token}`, // Set the Authorization header with the token
              },
            };
      
        try {
          const response = await axios.post(`${config.apiUrl}/user/exportUser`, { loginIdentifier: username, password },configToken);
          const { data, message } = response.data;
          if (response.status === 200 ){
            if( data.user.user_role === 'Admin') {
              const fileName = "Zone and Ward Data.xlsx";
              const wsData = [
                  [
                      "S.No",
                      "Zone",
                      "Ward ID",
                      "Ward Name",
                  ],
                  ...filteredZoneWards.map((item, index) => [
                      index + 1,
                      item.Zone,
                      item.WardID,
                      item.WardName,
                  ])
              ];
         
              const ws = XLSX.utils.aoa_to_sheet(wsData);
              const wb = XLSX.utils.book_new();
              XLSX.utils.book_append_sheet(wb, ws, "ZoneWardData");
         
              // Export the workbook
              XLSX.writeFile(wb, fileName);
              setError('');
              setShowLoginForm(false);
            }
            else {
                setError('Unauthorized User');
            }
          }
            else {
                setError(message);
            }
          } catch (error) {
            setError('Failed to export Excel file');
          }
        };
  
        const exporttoPdf = async () => {
          const token = user ? user.token : null;
          // Get the token from sessionStorage
              const configToken = {
                headers: {
                  Authorization: `Bearer ${token}`, // Set the Authorization header with the token
                },
              };
        
          try {
            const response = await axios.post(`${config.apiUrl}/user/exportUser`, { loginIdentifier: username, password },configToken);
            const { data, message } = response.data;
            if (response.status === 200 ){
              if( data.user.user_role === 'Admin') {
                   // Create a reference to the table
    const table = document.getElementById('ZoneWardData');
  
    // Initialize jsPDF instance
    const doc = new jsPDF();
  
  
          // Add header row
          const header = [[  "S.No",
          "Zone",
          "Ward ID",
          "Ward Name",]];
        
          // Add data rows
          const data = filteredZoneWards.map((item, index) =>[
             index + 1,
            item.Zone,
            item.WardID,
            item.WardName
          ]);
      
          // Add table to PDF
          doc.autoTable({
            head: header,
            body: data,
          });
    // Save PDF
    doc.save('ZoneWardData.pdf');
  
                setError('');
                setShowLoginForm(false);
              }
              else {
                  setError('Unauthorized User');
              }
            }
              else {
                  setError(message);
              }
            } catch (error) {
              setError('Failed to export Excel file');
            }
          };
          
        const handleClose = () => {
          // Function to handle closing the login form
          setShowLoginForm(false);
          setUsername('');
          setPassword('');
          setError('');
        };
     

    return (
      <>
      {!isMobile? (
        <div className="relative max-w-xlg mx-auto">
<div className="flex justify-between mb-4">
  <div className="flex justify-start items-center">
    <h1 className="font-bold md:text-2xl mr-2">Zone Wards</h1>
    <input
        type="text"
        value={searchQuery}
        onChange={handleSearchChange}
        className="p-1 border border-gray-300 rounded-md ml-4 "
        style={{width:'260px'}}
        placeholder="Search Zone, Ward Name, or Ward ID"
      />
  </div>
  <div className="flex items-center"> {/* Parent container for buttons */}
    <button   className="text-white py-1 px-2 rounded mr-2"
     onClick={() => openModal()}
     style={{
      backgroundImage: 'linear-gradient(to right, #003399, #4facfe)',
      fontSize: '0.9rem',
      marginRight: '8px' // Adjusted margin
    }}>
      Add New Zone & Ward
      <FontAwesomeIcon icon={faPlus} className="ml-1" /> {/* Added margin to separate icon */}
    </button>
    {!showLoginForm && (
      <button className="bg-green-500 hover:bg-green-600 text-white py-1 px-2 rounded " onClick={() => setShowLoginForm(true)}>
        <FontAwesomeIcon icon={faFileExport} className="mr-1" />
        Export
      </button>
    )}
  </div>
</div>





  <div className=" overflow-y-auto"  style={{ height: '450px' }}>
     
      <table className="w-full border-collapse text-xs">
          <thead className="sticky top-0">
              <tr className=' text-white' style={{backgroundColor:'rgb(33,64,154)'}}>
                  <th className="text-xs md:text-sm px-4 py-2">S.No</th>
                  <th className="text-xs md:text-sm px-6 py-2">Zone</th>
                  <th className="text-xs md:text-sm  px-4 py-2">Ward ID</th>
                  <th className="text-xs md:text-sm  px-4 py-2">Ward Name</th>
                  <th className="text-xs md:text-sm px-6 py-2">Edit</th>
                  <th className="text-xs md:text-sm px-4 py-2">Delete</th>
              </tr>
          </thead>
          <tbody>
          {filteredZoneWards.map((zoneWard, index) => (
                  <tr key={zoneWard.id} className='text-xs md:text-sm border border-b-2'>
                      <td className="text-xs md:text-sm px-4 py-2 text-center"> {index + 1}</td>
                      <td className="text-xs md:text-sm px-4 py-2 text-center">
                      {editableZoneWard && editableZoneWard.id === zoneWard.id ? (
          <input
            type="text"
            className="w-12 border border-sky-500 "
            name="Zone"
            value={editableZoneWard.Zone}
            onChange={handleChange}
          
          />
        ) : (
          zoneWard.Zone
        )}
                          
                          </td>
                      <td className="text-xs md:text-sm px-4 py-2 text-center">
                      {/* {editableZoneWard && editableZoneWard.id === zoneWard.id ? (
          <input
            type="text"
            className="w-10 border border-sky-500 "
            name="WardID"
            value={editableZoneWard.WardID}
            onChange={handleChange}
           
          />
        ) : ( */}
          {zoneWard.WardID}
        {/* )} */}
                        </td>
                      <td className="text-xs md:text-sm px-4 py-2">
                      {/* {editableZoneWard && editableZoneWard.id === zoneWard.id ? (
          <input
            type="text"
            className="w-full border border-sky-500"
            name="WardName"
            value={editableZoneWard.WardName}
            onChange={handleChange}
          
          />
        ) : ( */}
          {zoneWard.WardName}
        {/* )} */}
        </td>
                    
                      <td className=" text-center md:text-sm  px-4 py-2 ">
        {editableZoneWard && editableZoneWard.id === zoneWard.id ? (
          <>
            <FontAwesomeIcon
              icon={faSave}
              className="cursor-pointer text-green-500"
              onClick={handleSave}
            />
            <FontAwesomeIcon
              icon={faTimes}
              className="cursor-pointer text-red-500 ml-2"
              onClick={handleCancel}
            />
          </>
        ) : (
          <>
        
           <FontAwesomeIcon
            icon={faEdit}
            className="cursor-pointer text-blue-500 ml-4"
            onClick={() => handleEdit(zoneWard)}
          />
          </>
         
        )}
      </td>
                      <td className=" text-center md:text-sm px-4 py-2">
                      <FontAwesomeIcon
                    icon={faTrash}
                    onClick={() => handleDeleteClick(zoneWard)}
                    style={{
                      cursor: "pointer",
                      marginRight: "10px",
                      color: "red",
                    }}
                  />
                      </td>
                  </tr>
              ))}
          </tbody>
      </table>
  </div>
  {showForm && <ZoneWardForm onClose={closeModal} />}


  {showConfirmation && (
<div className="fixed inset-0 z-40 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
<div className="fixed inset-0 bg-black bg-opacity-50"></div>
<div className="relative w-auto max-w-3xl mx-auto my-4">
  <div className="bg-white p-8 rounded-lg shadow-md">
    {/* <p className="mb-3">Do you want to delete this request {`${selectedItem.id}`}?</p> */}
    <p className="mb-3">Are you sure to Remove this Ward {`${selectedItem.WardID} - ${selectedItem.WardName} `}?</p>

    <div className="flex justify-center space-x-3">
      <button
        onClick={handleDelete}
        className="bg-red-500 hover:bg-red-600 text-white px-2 py-2 rounded-full"
      >
        Yes
      </button>
      <button
        onClick={handleCancelDelete}
        className="bg-gray-400 hover:bg-gray-500 text-white px-2 py-2 rounded-full"
      >
        No
      </button>
    </div>
  </div>
</div>
</div>
)}
{showLoginForm && (
  <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
    <div className="bg-white w-full  md:max-w-sm rounded-lg overflow-hidden shadow-xl">
      <div className="px-6 py-4">
        <div className="flex justify-end">
          <button className="text-gray-700 hover:text-gray-900" onClick={handleClose}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
        <label htmlFor="username" className="text-gray-700">Username</label>
        <input
          type="text"
          id="username"
          placeholder="Username or Mobile Number"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          className="border border-gray-400 px-3 py-2 rounded-md mb-2 w-full"
        />
        <label htmlFor="password" className="text-gray-700">Password</label>
        <input
          type="password"
          id="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className="border border-gray-400 px-3 py-2 rounded-md mb-2 w-full"
        />
         <button
        onClick={exportToExcel}
        className="bg-green-500 hover:bg-green-600 text-white py-2 px-4 rounded-lg mr-5"
      >
        Excel
      </button>
      <button
        onClick={exporttoPdf}
        className="bg-green-500 hover:bg-green-600 text-white py-2 px-4 rounded-lg mr-2"
      >
        PDF
      </button>
       
        {error && <p className="text-red-500 mt-2">{error}</p>}
      </div>
    </div>
  </div>
)}
</div>
      ):(
        <div className="max-w-xl mx-auto">
<div className="flex justify-between mb-4 items-center">
 
  <h1 className="font-bold text-sm ">Zone Wards</h1>

 
  <div className="flex items-center mt-1">
  <input
      type="text"
      value={searchQuery}
      onChange={handleSearchChange}
      className="p border border-gray-300 rounded-sm text-sm mr-2"
      placeholder="Search..."
    />
     <button
        className="text-white text-xs py-1 px-2 mr-2  rounded"
        onClick={() => openModal()}
        style={{ backgroundImage: 'linear-gradient(to right, #4facfe, #00f2fe)' }}
      >
        <FontAwesomeIcon icon={faPlus} /> {/* Display only the icon */}
      </button>
    {!showLoginForm &&  <button
          className="text-white text-xs py-1 px-2 rounded bg-green-500"
          onClick={() => setShowLoginForm(true)}
         
        >
          <FontAwesomeIcon icon={faFileExport} />
        </button>}



  </div>
</div>





        
        <div className="overflow-y-auto" style={{ maxHeight: '450px' }}>
    <table className="w-full border-collapse text-xs">
        <thead className="sticky top-0">
            <tr className='text-white' style={{ backgroundColor: 'rgb(33, 64, 154)' }}>
                <th className="py-1">S.No</th>
                <th className="px-2 py-1">Zone</th>
                <th className="px-2 py-1">Ward ID</th>
                <th className="px-2 py-1">Ward Name</th>
                <th className="px-1 py-1">Edit</th>
                <th className="px-1 py-1">Delete</th>
            </tr>
        </thead>
        <tbody>
            {filteredZoneWards.map((zoneWard, index) => (
                <tr key={zoneWard.id} className='border border-b-2'>
                    <td className="text-center">{index + 1}</td>
                    <td className="text-center">
                        {editableZoneWard && editableZoneWard.id === zoneWard.id ? (
                            <input
                                type="text"
                                className="w-12 border border-sky-500"
                                name="Zone"
                                value={editableZoneWard.Zone}
                                onChange={handleChange}
                            />
                        ) : (
                            zoneWard.Zone
                        )}
                    </td>
                    <td className="px-2 py-1 text-center">
                        {zoneWard.WardID}
                    </td>
                    <td className="px-2 py-1">
                        {editableZoneWard && editableZoneWard.id === zoneWard.id ? (
                            <input
                                type="text"
                                className="w-full border border-sky-500"
                                name="WardName"
                                value={editableZoneWard.WardName}
                                onChange={handleChange}
                            />
                        ) : (
                            zoneWard.WardName
                        )}
                    </td>
                    <td className="text-center ">
                        {editableZoneWard && editableZoneWard.id === zoneWard.id ? (
                            <>
                                <FontAwesomeIcon
                                    icon={faSave}
                                    className="cursor-pointer text-green-500 mr-2 "
                                    onClick={handleSave}
                                    size="md"
                                />
                                <FontAwesomeIcon
                                    icon={faTimes}
                                    className="cursor-pointer text-red-500 "
                                    onClick={handleCancel}
                                    size="lg"
                                />
                            </>
                        ) : (
                            <>
                                <FontAwesomeIcon
                                    icon={faEdit}
                                    className="cursor-pointer text-blue-500"
                                    onClick={() => handleEdit(zoneWard)}
                                />
                            </>
                        )}
                    </td>
                    <td className="text-center px-2 py-1">
                        <FontAwesomeIcon
                            icon={faTrash}
                            onClick={() => handleDeleteClick(zoneWard)}
                            style={{ cursor: "pointer", color: "red" }}
                        />
                    </td>
                </tr>
            ))}
        </tbody>
    </table>
</div>

        {showForm && <ZoneWardForm onClose={closeModal} />}
        {showConfirmation && (
      <div className="fixed inset-0 z-40 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
        <div className="fixed inset-0 bg-black bg-opacity-50"></div>
        <div className="relative w-auto max-w-3xl mx-auto my-4">
          <div className="bg-white p-8 rounded-lg shadow-md">
            {/* <p className="mb-3">Do you want to delete this request {`${selectedItem.id}`}?</p> */}
            <p className="mb-3">Are you sure to delete this {`${selectedItem.id}`}?</p>

            <div className="flex justify-center space-x-3">
              <button
                onClick={handleDelete}
                className="bg-red-500 hover:bg-red-600 text-white px-2 py-2 rounded-full"
              >
                Yes
              </button>
              <button
                onClick={handleCancelDelete}
                className="bg-gray-400 hover:bg-gray-500 text-white px-2 py-2 rounded-full"
              >
                No
              </button>
            </div>
          </div>
        </div>
      </div>
    )}

{showLoginForm && (
  <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
    <div className="bg-white w-full max-w-xs rounded-lg overflow-hidden shadow-xl">
      <div className="px-6 py-4">
        <div className="flex justify-end">
          <button className="text-gray-700 hover:text-gray-900" onClick={handleClose}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
        <label htmlFor="username" className="text-gray-700">Username</label>
        <input
          type="text"
          id="username"
          placeholder="Username or Mobile Number"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          className="border border-gray-400 px-3 py-2 rounded-md mb-2 w-full"
        />
        <label htmlFor="password" className="text-gray-700">Password</label>
        <input
          type="password"
          id="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className="border border-gray-400 px-3 py-2 rounded-md mb-2 w-full"
        />
        <button
        onClick={exportToExcel}
        className="bg-green-500 hover:bg-green-600 text-white py-2 px-4 rounded-lg mr-5"
      >
        Excel
      </button>
      <button
        onClick={exporttoPdf}
        className="bg-green-500 hover:bg-green-600 text-white py-2 px-4 rounded-lg mr-2"
      >
        PDF
      </button>
        
        {error && <p className="text-red-500 mt-2">{error}</p>}
      </div>
    </div>
  </div>
)}
    </div>
      )}
        
        </>
    );
};

export default ZoneWardTable;