import React,{useEffect,useState} from 'react';
import Chart from 'react-apexcharts';

const DonutChartApex = ({ data }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1020);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1020);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  // Calculate total quantity
  const totalQuantity1 = data?.AssignedRequestedQuantity + data?.deliveredRequestedQuantity + data?.notAssignedQuantity;
  const totalQuantity2 = data?.assignedCount + data?.deliveredCount + data?.notAssignedCount;

  // Define chart options
  const formatNumber = (num) => {
    if (Math.abs(num) >= 1.0e+9) {
      return (Math.abs(num) / 1.0e+9).toFixed(1) + 'b';
    }
    if (Math.abs(num) >= 1.0e+6) {
      return (Math.abs(num) / 1.0e+6).toFixed(1) + 'm';
    }
    if (Math.abs(num) >= 1.0e+3) {
      return (Math.abs(num) / 1.0e+3).toFixed(1) + 'k';
    }
    return num.toString(); // Return as string if no formatting is needed
  };

  // Define chart options
  const chartOptions = {
    chart: {
      type: 'donut',
      dropShadow: {
        enabled: true,
        color: '#111',
        top: -1,
        left: 3,
        blur: 3,
        opacity: 0.2,
      },
    },
    labels: ['Assigned', 'Delivered', 'Not Assigned'], // Added 'Total' label
    colors: ['#3182CE', '#48BB78', '#E53E3E', '#8B5CF6'],
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            total: {
              showAlways: true,
              show: true,
              formatter: function (w) {
                // Display totalQuantity as the formatted value in the center
                return formatNumber(totalQuantity1);
              },
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontSize: '20px',
              fontWeight: 'bold',
              color: '#333',
              offsetY: 0,
            },
          },
        },
      },
    },
    tooltip: {
      y: {
        formatter: function (value) {
          return formatNumber(value);
        }
      }
    },
    responsive: [
      {
        breakpoint: 680,
        options: {
          chart: {
            width: 280,
          },
          legend: {
            position: 'bottom',
          },
        },
      },
    ],
  };

  const chartOptions2 = {
    chart: {
      type: 'donut',
      dropShadow: {
        enabled: true,
        color: '#111',
        top: -1,
        left: 3,
        blur: 3,
        opacity: 0.2,
      },
    },
    labels: ['Assigned', 'Delivered', 'Not Assigned'], // Added 'Total' label
    colors: ['#3182CE', '#48BB78', '#E53E3E', '#8B5CF6'],
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            total: {
              showAlways: true,
              show: true,
              formatter: function (w) {
                // Display totalQuantity as the value in the center
                return totalQuantity2;
              },
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontSize: '20px',
              fontWeight: 'bold',
              color: '#333',
              offsetY: 0,
            },
          },
        },
      },
    },
    responsive: [
      {
        breakpoint: 680,
        options: {
          chart: {
            width: 280,
          },
          legend: {
            position: 'bottom',
          },
        },
      },
    ],
  };

  // Prepare chart data array including the total
  const chartData1 = [data?.AssignedRequestedQuantity || 0, data?.deliveredRequestedQuantity || 0, data?.notAssignedQuantity || 0];
  const chartData2 = [data?.assignedCount|| 0, data?.deliveredCount || 0, data?.notAssignedCount || 0];

  return (
    <>
    {isMobile? (
        <div className="flex flex-col justify-center items-center ml-6">
        <div className="w-full p-1 mb-4 sm:mb-0">
          <Chart options={chartOptions} series={chartData1} type="donut" height={180} />
        </div>
        <div className="w-full ">
          <Chart options={chartOptions2} series={chartData2} type="donut" height={180} />
        </div>
      </div>
    ):(
      <div className="flex flex-col justify-center ml-20 items-center md:fixed top-2/3 transform -translate-y-1/2">
      <div className="w-full p-1 mb-4 sm:mb-0">
        <Chart options={chartOptions} series={chartData1} type="donut" height={200} />
      </div>
      <div className="w-full ">
        <Chart options={chartOptions2} series={chartData2} type="donut" height={200} />
      </div>
    </div>
    )}
  
   
   </>
  );
};

export default DonutChartApex;